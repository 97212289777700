import React from 'react'

export default function Video({ src, alt, title, description, onVideoClick, key }) {
    return (
        <div key={key} className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="video-box">
                <div onClick={onVideoClick} className="pop-video-wrapper">
                    <a href="#" data-toggle="modal" data-target="#modalSingelVideo"
                        data-direction='right' alt="">
                        <img src={src} alt={alt} />
                        <div onClick={onVideoClick} className="video-img-overlay" data-toggle="modal" data-target="#modalSingelVideo">
                            <i className="material-icons">play_circle_outline</i>
                        </div>
                    </a>
                </div>
                <div onClick={onVideoClick} className="video-title" data-toggle="modal" data-target="#modalSingelVideo">
                    <h3>{title}</h3>
                </div>
                <div className="video-desc">
                    <p>{description}
                    </p>
                </div>
            </div>
        </div>
    )
}
