import React, {Component} from 'react';

import MeetGS1Body from '../MeetGS1Body';
import profilePlaceholder from '../../../../../assets/user/avatarplaceholder.png';

import Room from './Room';
import Pusher from 'pusher-js';
import { baseUrl } from '../../../../../constants/url/urlConstants';
import DBNetwork from '../../../../../api/DBNetwork';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../../constants/Pusher/Pusher'
const { connect } = require('twilio-video');

class VideoChat extends Component {

	constructor(props) {
		super(props);

		var end_timestamp = new Date(this.props.vc_created_at.getTime() + (10 * 60000));
		console.log("END TIME");
		console.log(end_timestamp);

		// Get seconds between times
		var delta = Math.abs(end_timestamp - new Date()) / 1000;
		// calculate (and subtract) whole minutes
		const minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;
		// what's left is seconds
		const seconds = Math.floor(delta % 60);

		console.log(`MINUTES: ${minutes} SECONDS: ${seconds}`);

		this.state = {
			room: null,
			pusher: null,
			messages: [],
			chatMsg: "",


			timer_interval: null,
			timer_show: false,
			timer_min: minutes,
			timer_sec: seconds,
		}

		this.muteAudio = this.muteAudio.bind(this);
		this.muteVideo = this.muteVideo.bind(this);

		this.onInputchange = this.onInputchange.bind(this);
		this.sendChat = this.sendChat.bind(this);
		this.startTimer = this.startTimer.bind(this);

		this.startTimer();
	}

	startTimer = () => {

		this.state.timer_interval = setInterval(() => {
			if (this.state.timer_sec == 0 && this.state.timer_min == 0) {
				// todo show snackbar
				this.setState({
					joined: false,
					timer_min: 10,
					timer_sec: 0,
					pusher: null,
				});
				clearInterval(this.state.timer_interval);

				this.props.endChat();
				this.props.resetVCState();

			}
			else if (this.state.timer_sec == 0) {
				this.setState({
					timer_min: this.state.timer_min - 1,
					timer_sec: 59
				});
			}
			else {
				this.setState({
					timer_sec: this.state.timer_sec - 1,
				});
			}
		}, 1000);


	}

	sendChat = async (event) => {
		event.preventDefault();
		console.log(this.state.chatMsg);
		console.log("HERE");
		console.log(this.state.chatMsg != "");
		if (this.state.chatMsg != ""){
			console.log("SENDING THE MSG");
			try {
				const url = baseUrl("api/video-chat/chat/" + this.props.match_id);
				const respone = await DBNetwork.post(url, {"msg": this.state.chatMsg});
				this.setState({
					chatMsg: ""
				});
			} catch (error) {
				console.log("Error");
			}
		}
	}

	async componentDidMount() {
		try {
			const room = await connect(this.props.twilio_token, {
				name: this.props.twilio_room,
				audio: true,
				video: true,
			});

			this.setState({room: room});
			console.log("SETTING UP PUSHER IN VC");
			if (this.state.pusher == null) {

				let pusher = new Pusher(PUSHER_APP_KEY, {
					cluster: PUSHER_CLUSTER,
					encrypted: false
				});
				this.state.pusher = pusher;

				pusher.connection.bind('error', function (err) {
					if (err?.code === 404) {
						console.log('Over limit!');
					}
				});

				const channel = pusher.subscribe('videochat.chat.' + this.props.match_id);
				channel.bind("App\\Events\\VideoChatMessageEvent", data => {
					console.log("Message received");
					const send_by_me = this.props.user.id == data.sender_id;
					var d = new Date();
					var time = d.getHours() + ":" + d.getMinutes();

					let msgObj = {
						"message": data.message,
						"username": data.username,
						"right": send_by_me,
						"time": time
					}

					let {messages} = this.state;
					messages.push(msgObj);
					this.forceUpdate();
				});
			}
		} catch(err) {
			console.log(err);
		}
	};

	muteAudio = () => {
		this.state.room.localParticipant.audioTracks.forEach(publication => {
			if(publication.track.isEnabled)
				publication.track.disable();
			else
				publication.track.enable();
		});
	}
	muteVideo = () => {
		this.state.room.localParticipant.videoTracks.forEach(publication => {
			if(publication.track.isEnabled)
				publication.track.disable();
			else
				publication.track.enable();
		});
	}

	onInputchange = (event) => {
		this.setState({
		  [event.target.name]: event.target.value
		});
	  }

	render() {
		return (
			<div className="start_one_on_one paired-chat-warp">
				<div className="player-chat-room">
					<div className="row">
						<div className="col-1">
							<div className="player1-chat chat-player">
								<div className="player-imgframe">
									<img src={this.props.user.avtar ? this.props.user.avtar : profilePlaceholder} className="img-fluid rounded-circle"
										alt="Player-Image" />
								</div>
								<div className="chat-player-name player1-name text-center pt-3">
									<h6 className="match-blue-text">{this.props.user.name + ' ' + this.props.user.last_name}</h6>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="video-chatting-wrap">
								<div className="video-chat-wrapper">
									{this.state.room &&
										<Room resetVCState={this.props.resetVCState} endChat={this.props.endChat} room={this.state.room} />
									}
								</div>
							</div>
							<div className="video-actions-wrapper d-flex align-items-center justify-content-center">
								<div className="video-option-icon">
									<i className="fa fa-video-camera" aria-hidden="true"></i>
								</div>
								<div className="custom-control custom-switch gs-custom-switch">
									<input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={this.muteVideo} />
									<label className="custom-control-label" for="customSwitch1"></label>
								</div>
								<div className="video-option-icon">
									<i className="fa fa-microphone" aria-hidden="true"></i>
								</div>
								<div className="custom-control custom-switch gs-custom-switch">
									<input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={this.muteAudio}/>
									<label className="custom-control-label" for="customSwitch2"></label>
								</div>
							</div>
							<div className="player-name matching-intrest text-center py-4">
								<h4 className="match-blue-text text-uppercase">Matching Interest {this.props.foi}</h4>
								<div className="chat-time-left">
									<h4 className="countdown-time">Time Left : <span id="countdownTimer"></span> </h4>
									<h4 className="match-blue-text">
										{this.state.timer_min <= 9 ? 0 : ""}{this.state.timer_min}:{this.state.timer_sec <= 9 ? 0 : ""}{this.state.timer_sec}</h4>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="player-chat-wrap chat-modal">
								<div className="oneto1-player-chat">
									<div className="card box-wrap-card">
										<div
											className="card-header bg-white border-0 p-2 d-flex align-items-center justify-content-between">
											<div
												className="card-actions-wrap d-flex justify-content-end align-items-center">
												<a href="" data-target="#chat-box-wrapper"
													className="smallIframe ml-5 mr-2">
													<i aria-hidden="true" className="fa fa-times"></i>
												</a>
											</div>
										</div>
										<div className="card-body gs-card-body-scrollbar p-0 m-3">
											<div className="direct-chat-messages">
												{
													this.state.messages.map((msg, index) => {
														return (
															<div key={index} className={`direct-chat-msg ${msg.right ? "right" : ""}`}>
																<div className="direct-chat-text">
																	<span>
																		{msg.message}
																	</span>
																</div>
																<div className="direct-chat-infos clearfix">
																	<span className="direct-chat-timestamp">{msg.time}</span>
																	<span className="direct-chat-name">{msg.right ? "You" : msg.username }</span>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
										<div className="card-footer bg-white">
											<form onSubmit={this.sendChat}>
												<div className="input-group mb-2">
													<input
														name="chatMsg"
														type="text" placeholder="Antwoord" required="required"
														className="form-control fix-rounded-right"
														value={this.state.chatMsg}
														onChange={this.onInputchange}/>
													<div className="input-group-append">
														<span className="input-group-text bg-white">
															<button type="submit">
																<i aria-hidden="true"
																		className="fa fa-paper-plane-o"></i>
															</button>
														</span>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-1">
							<div className="player2-chat chat-player">
								<div className="player-imgframe">
									<img src={this.props.matched_user ? (this.props.matched_user.avatar ? this.props.matched_user.avatar : profilePlaceholder) : profilePlaceholder} className="img-fluid rounded-circle"
										alt="Player-Image" />
								</div>
								<div className="chat-player-name player2-name text-center pt-3">
									<h6 className="match-blue-text">{this.props.matched_user ? this.props.matched_user.name : ''}</h6>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}

}


export default VideoChat;
