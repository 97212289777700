import React, { Component } from 'react';
import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../constants/Pusher/PusherConstants';
import PusherWrapper from '../../../../hooks/PusherWrapper';

export default class TestProg extends Component {

    constructor(props) {
        super(props);

        this.state = {

            pusher: null
        }

    }
    componentDidMount() {

        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;
          
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe('SessionUpdate.' + this.props.eventId);
            channel.bind("App\\Events\\SessionUpdateEvent", data => {
                console.log("data Session" + data);
                this.props.sessionNowCallback(data, this.props.sessionData);
            });
        }
    }
    render() {

        return (
            <div></div>
        );
    }
}
