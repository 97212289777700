import Pusher from "pusher-js";
import React, { Component, useContext, useEffect, useState } from "react";
import { baseUrl } from "../../../../../../constants/url/urlConstants";

import PusherConstants, {
  ChatEvent,
  Chat_Channel,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  CommonEvent,
} from "../../../../../../constants/Pusher/PusherConstants";
// import ChatInput from '../../../../../../UIComponets/ChatInput';
import toast from "react-hot-toast";
import DBNetwork from "../../../../../../api/DBNetwork";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import ChatMessages from "./Chats/ChatsMessages";
import ChatInput from "./Chats/ChatInput";
import ChatSearch from "./ChatSearch/ChatSearch";
import { Link } from "react-router-dom";
import ChatThumb from "./ChatThumb/ChatThumb";
import ChatModal from "./ChatModal/ChatModal";
import ChatComponent from "./ChatComponent/ChatComponent";
import { changeColor } from "../../../../../../utlis/changeColor";
import {
  Context as defaultContext,
  Context as DefaultSectionContext,
} from "../../../../../../context/DefaultSectionContext";
import getAuthDetails from "../../../../../../hooks/getAuthDetails";
import { PusherEvent } from "../../../../../../hooks/PusherEvent";

const ChatMenu = (props) => {
  const {
    onSendChatMessage,
    state: {
      eventsPageData: {
        user,
        page_data: { chat, event },
      },
      pusherEvent,
    },
  } = useContext(defaultContext);
  const userDetails = user?.id;
  const color = event?.color;
  const systemId = localStorage.getItem("system_id");
  const uniqueId = getAuthDetails(user?.id, systemId);
  const eventId = event?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUserName] = useState(null);
  const [message, setMessage] = useState("");

  const chatContainer = React.createRef();
  const inputref = React.createRef();

  useEffect(() => {
    changeColor(color);
    scrollToMyRef();
  }, []);

  useEffect(() => {
    if (pusherEvent?.action === PusherEvent.INSERT_CHAT && pusherEvent?.data) {
      const data = pusherEvent?.data;
      if (
        data?.message?.id !== 0
          ? data?.message?.id === userDetails
          : data?.message?.id === props.ip_address
      )
        toast(`new message received from ${data?.user_name}`);
    } else if (pusherEvent?.action === PusherEvent.DELETE_CHAT) {
    }
  }, [pusherEvent]);
  const onSendMessageEnter = (e) => {
    if (e.key === "Enter" && message?.length > 0) {
      setIsLoading(true);
      const payload = {
        username: username,
        message: message,
        event_id: eventId,
        system_id: systemId,
      };

      onSendChatMessage(payload, (response) => {
        if (response.data) {
          if (response?.data?.moderate_message) {
            toast(response?.data?.moderate_message);
          }
          setMessage("");
          setIsLoading(false);
        }
      });
    }
  };
  const onSendMessage = (e, type) => {
    e.preventDefault();
    if (message?.length > 0) {
      setIsLoading(false);
      e?.currentTarget?.setAttribute("disabled", "disabled");
      const payload = {
        username: username,
        message: message,
        event_id: eventId,
        system_id: systemId,
      };

      onSendChatMessage(payload, (response) => {
        if (response.data) {
          if (response?.data?.moderate_message) {
            toast(response?.data?.moderate_message);
          }
          setMessage("");
          setIsLoading(false);
          e?.currentTarget?.removeAttribute("disabled");
        }
      });
    }
  };

  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    chatContainer.current.scrollTo(0, scroll);
  };

  let chats;
  const chatData = [...(chat ?? [])];
  if (chatData?.length > 0) {
    chats = chatData?.map((chat, index) => {
      const trimmedName = chat?.user_name;
      const userId = chat?.user_id;

      return (
        <div
          key={index}
          className={`direct-chat-msg ${
            userId
              ? userId === userDetails
                ? "right"
                : ""
              : chat?.ip_address === systemId
              ? "right"
              : ""
          }`}
        >
          <div
            className={`direct-chat-text ${
              userId
                ? userId === userDetails
                  ? "cmn-border-color"
                  : ""
                : chat?.ip_address === systemId
                ? "cmn-border-color"
                : ""
            }`}
            style={{
              borderColor: userId
                ? userId === userDetails
                  ? color
                  : ""
                : chat?.ip_address === systemId
                ? color
                : "",
            }}
          >
            {chat?.message}
          </div>

          <div className="direct-chat-infos">
            <span
              className="direct-chat-timestamp cmn-text-color"
              style={{ color: color }}
            >
              {chat?.time}{" "}
            </span>
            <span className="direct-chat-name">{chat?.user_name}</span>{" "}
            {chat?.moderated === 0 ? (
              <span className="exclamation_hover">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span className="hover-title">{chat?.moderated_msg}</span>
              </span>
            ) : null}
          </div>
        </div>
      );
    });
  }
  return (
    <>
      {/*  When Chat is Open Via Navigation Button from  right side of display */}
      {!props.isPinChat && (
        <ChatModal>
          <div className="card-header bg-white p-2">
            <div className="card-actions-wrap d-flex justify-content-between align-items-center">
              <ChatThumb
                isThumbLoding={props.isThumbLoding}
                linkClassName="chat-box-wrapper"
                onPinChat={props.onPinChat}
              />
              <div className="d-flex justify-content-between align-items-center">
                <ChatSearch />
                <a
                  href="#"
                  className="ml-2 small-iframe"
                  data-target="chat-box-wrapper"
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            ref={chatContainer}
            className="card-body card-body-scrollbar p-0 mx-3"
          >
            <ChatMessages messages={chats} color={color} />
          </div>
          <ChatInput
            ref={inputref}
            containerClass="input-group px-4 mb-2"
            message={message}
            setMessage={setMessage}
            onSendMessageEnter={onSendMessageEnter}
            onSendMessage={onSendMessage}
            isLoading={isLoading}
          />
        </ChatModal>
      )}

      {/*  When Chat Pinned in the display */}
      {props.isPinChat && (
        <ChatComponent
          isChatThumbLoding={props.isChatThumbLoding}
          onPinChat={props.onPinChat}
        >
          <div id="modeChatBox" className="box-wrap-modal mode-chat-box">
            <div className="card box-wrap-card cmn-border-color">
              <div className="card-header bg-white p-2">
                <div className="card-actions-wrap d-flex justify-content-end align-items-center">
                  <ChatSearch />
                </div>
              </div>
              <div className="card-body">
                <div className="event-info-accordion QA-accordion">
                  <div className="card popup-card-wrapper">
                    <div
                      ref={chatContainer}
                      id="chat-body"
                      className="card-body p-0"
                    >
                      <div className="card-body-scrollbar p-0 mx-3">
                        <ChatMessages messages={chats} color={color} />
                      </div>
                    </div>
                    <div className="card-footer bg-white border-0 px-0">
                      <ChatInput
                        containerClass="input-group"
                        message={message}
                        setMessage={setMessage}
                        onSendMessageEnter={onSendMessageEnter}
                        onSendMessage={onSendMessage}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ChatComponent>
      )}
    </>
  );
};

export default ChatMenu;
