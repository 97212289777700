import React, { Component, useContext, useState } from "react";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import image from "../../../../../../assets/images/breakout-room.png";
import {
  Breakout_Channel,
  Breakout_Event,
} from "../../../../../../constants/Pusher/PusherConstants";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Context as defaultContext,
  Context as DefaultSectionContext,
} from "../../../../../../context/DefaultSectionContext";
import LoaderInfiniteScroll from "../../../../../../UIComponets/LoaderInfiniteScroll/LoaderInfiniteScroll";
import AliceCarousel from "react-alice-carousel";
const infiniteScroll = {
  height: "auto",
  overflowY: "auto",
  overflowX: "hidden",
};
const BreakOutRoom = (props) => {
  const {
    filterBreakoutRoomData,
    state: {
      eventsPageData: {
        page_data: { breakout_room, event, breakout_categories },
      },
    },
  } = useContext(defaultContext);

  const [isLoading, setIsLoading] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const onCategorySelection = async (value) => {
    setIsLoading(true);
    setSelectedCategory(value);
    const payload = {
      event_id: event?.id,
      category: value,
      // , length
    };

    try {
      await filterBreakoutRoomData(payload, (response) => {
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 4 },
    1024: { items: 1 },
  };

  let rooms = [...(breakout_room ?? [])];
  let breakoutRooms;
  let items;
  if (rooms?.length > 0) {
    let position = rooms?.sort((a, b) => a.position - b.position);
    items = position?.map((room, index) => {
      if (!room.status) return;
      return (
        <div
          key={index}
          className="col-12 col-sm-6 col-md-4 col-lg-4 m-auto py-4"
        >
          <div className="breakout-rooms-item">
            <div className="card border-0 rounded-0">
              <div className="card-body border-0">
                <a target={room?.target} href={room?.link} target="_blank">
                  <div className="brk-room-image-frame">
                    <div className="et_pb_image_wrap">
                      <img
                        src={imageWithStorage + room?.thumbnail}
                        className="img-fluid"
                        alt={room?.name}
                      />
                    </div>
                  </div>
                </a>
                <div className="brk-room-details-wrap">
                  <div className="brk-room-chapter">
                    {room?.file && (
                      <a
                        className="mr-2"
                        href={imageWithStorage + room?.file}
                        download
                        target="_blank"
                      >
                        <i className="fa fa-copy" aria-hidden="true"></i>
                      </a>
                    )}
                    <a
                      href={room?.link ? room?.link : "#"}
                      target={room?.target}
                    >
                      {room?.name}
                    </a>
                  </div>
                  {room?.url_link && (
                    <div className="brk-room-links">
                      <a
                        target={room?.target}
                        href={room?.url_link ? room?.url_link : "#"}
                      >
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  } else {
    return <div></div>;
  }

  breakoutRooms = items?.chunk(6).map((i) => {
    return (
      <div className="item">
        <div className="row"> {i} </div>
      </div>
    );
  });

  let isAnyLive = rooms?.filter((room) => room.status === 1).length;
  let categories = [...breakout_categories];
  let _category;
  if (categories?.length > 0) {
    _category = categories.map((cat, index) => {
      return (
        <option key={index} value={cat?.id}>
          {cat?.name}
        </option>
      );
    });
  } else _category = <></>;

  const DropDown = categories?.length > 0 && (
    <div className="text-right">
      <div className="ml-auto w-25">
        <select
          mutiple="true"
          onChange={(e) => onCategorySelection(e.target.value)}
          className="custom-select mr-sm-2"
          id="inlineFormCustomSelect"
          value={selectedCategory}
        >
          <option value="">All</option>
          {_category}
        </select>
      </div>
    </div>
  );
  const Loader = (
    <div className="vod-item">
      <div className="card">
        <div className="card-body">
          <div className="text-center">
            <div
              className="spinner-border text-primary text-center"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const Content = isLoading ? (
    Loader
  ) : (
    <div className="container-fluid">
      {DropDown}
      {props.children}
      <AliceCarousel
        // mouseTracking
        items={breakoutRooms}
        // infinite
        // autoPlay
        // autoPlayControls={true}
        // disableDotsControls
        disableButtonsControls
        // autoPlayInterval={2000}
        responsive={responsive}
      />
    </div>
  );

  // const Wrapper =
  return (
    <section className="breakout-rooms-wrapper">
      <div className="breakout-rooms-section">
        {!isAnyLive ? <></> : Content}
      </div>
    </section>
  );
};

export default BreakOutRoom;
