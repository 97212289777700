import React, {useContext, useEffect, useMemo, useState} from "react";
import NotificationCenter from "../../../hooks/NotificationCenter";
import Participant from "./Participant";
import {Context as defaultContext} from "../../../context/DefaultSectionContext";
import {TwillioParticipant} from "../../../Utils/Utils";

const Room = ({ room }) => {

  const [videoEnable, setVideoEnable] = useState(true);
  const [audioEnable, setAudioEnable] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [participantsContainer, setParticipantsContainer] = useState({});

  const {
    state: {
      onlineUsers : {
        membersMap
      }
    },
  } = useContext(defaultContext);

  useEffect(() => {
    if (!room || !room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');

    }
    room.localParticipant.videoTracks.forEach(
      publication => { 
        if (videoEnable) {
           publication.track.enable()
        }
        else {
          publication.track.disable()
        }
      }
    );
  }, [videoEnable]);

  useEffect(() => {
    if (!room || !room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');

    }

    room.localParticipant.audioTracks.forEach(
      publication => {
      if (audioEnable) {
        publication.track.enable()
      }
      else {
        publication.track.disable()
      }
      }
    );

  }, [audioEnable]);


  useEffect(() => {
    NotificationCenter.sharedInstance().registerNotification("onVideoSwitchClick", (enable) => {
      setVideoEnable(enable);
    })

    NotificationCenter.sharedInstance().registerNotification("onAudioSwitchClick", (enable) => {
      setAudioEnable(enable)
    })


  }, [])
  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => {
        setTimeout(() => {
          NotificationCenter.sharedInstance().broadcastNotification("participantConnected", [...participants, participant])
        }, 1000)
        return [...prevParticipants, participant]
      });
    };

    const participantDisconnected = (participant) => {
      const _participant = {...participant}
       setParticipants((prevParticipants) => {
        let parts = prevParticipants.filter((p) => p !== participant)
             setTimeout(() => {
               NotificationCenter.sharedInstance().broadcastNotification("participantDisconnected", {participants: parts, disconnected: _participant})
             }, 1000);
        return parts;
      }
      );

    };
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);


  let self = useMemo(() => {
    return <Participant
        className="self"
        key={room.localParticipant.sid}
        participant={room.localParticipant}
        isSelf={true}
        audioEnable={audioEnable}
        videoEnable={videoEnable}
    />
  }, [room.localParticipant.sid, audioEnable, videoEnable])

  let remoteParticipants = participants.map(participant => {
    if (!participantsContainer[participant.sid]){
      const id = TwillioParticipant(participant).getUserID()
      participantsContainer[participant.sid] =  <Participant details={membersMap[id]} key={participant.sid} participant={participant} />
    }
    return participantsContainer[participant.sid]
  });
  remoteParticipants.push(self);
  const isGroupCall = remoteParticipants.length > 2;

  return (
    <div className="room">
      {/* <h2>Room: {roomName}</h2> */}
      {/* <button onClick={handleLogout}>Log out</button> */}
      <div className={`grid-chat ${!isGroupCall ? "grid-chat1" : ""}`}>{remoteParticipants}</div>

      {/*{!isGroupCall && <div className="remote-participants">*/}
      {/*  {room ? self : (*/}
      {/*    ""*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*}*/}
       {/*<h3>Remote Participants</h3> */}
    </div>
  );
};

export default Room;
