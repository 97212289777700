import Pusher from "pusher-js";
import React, { Component, useContext, useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { baseUrl } from "../../../../../../constants/url/urlConstants";
import {
  OnAirRating_Channel,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  RatingEvent,
} from "../../../../../../constants/Pusher/PusherConstants";
import toast from "react-hot-toast";
import DBNetwork from "../../../../../../api/DBNetwork";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Context as defaultContext,
  Context as DefaultSectionContext
} from "../../../../../../context/DefaultSectionContext";
import getAuthDetails from "../../../../../../hooks/getAuthDetails";
import { PusherEvent } from "../../../../../../hooks/PusherEvent";
import toastProp from "../../../../../../data/toastProp";

const RatingMenu = () => {
  const {
    onSendRating,
    state: {
      eventsPageData: {
        user,
        page_data: {
          rating,
          event,
          isRatingAvail
        },
        translations
      },
      pusherEvent
    },
  } = useContext(defaultContext);


  useEffect(() => {
    if ((pusherEvent?.action === PusherEvent.ONAIR_RATING_UPDATE || pusherEvent?.action === PusherEvent.ONAIR_RATING) && pusherEvent?.data) {
      if (
        rating.length >= 1 &&
        !window.$("#rating-box-wrapper").hasClass("show")
      ) {
        window.$(".box-wrap-modal").removeClass("show");
        window.$(".inner-video-wrap").removeClass("toggle-active");
        window.$(".gs-box-wrap-modal").collapse("hide");
        window.$(".full-width-box").removeClass("active");
        window.$("#rating-box-wrapper").collapse("show");
      }
    }
  }, [pusherEvent]);

  const systemId = localStorage.getItem("system_id");;
  const color = event?.color;
  const eventId = event?.id;
  const user_id = getAuthDetails(user?.id, systemId);
  const [selectedRatingMap, setSelectedRatingMap] = useState({})

  const ratingChanged = (newRating, queId, index) => {
    let _rating = { ...rating[index] };
    let _selectedRatingMap = { ...selectedRatingMap };
    if (!_selectedRatingMap[_rating.id]) {
      _selectedRatingMap[_rating.id] = { ..._rating, given_star: newRating };
    }
    setSelectedRatingMap(_selectedRatingMap)
  };

  const onSubmitRating = async (e, queId, index) => {
    e.preventDefault();
    const obj = { ...rating[index] };
    if (!selectedRatingMap[obj.id] || selectedRatingMap[obj.id].given_star === 0) {
      toast(`your rated 0 stars.`);
      return
    }
    const given_star = selectedRatingMap[obj.id].given_star;
    const payload = {
      event_id: eventId,
      q_id: queId,
      star: given_star,
      system_id: systemId,
      // review: review
    };
    onSendRating(payload, (data) => {
      if (data?.status) {
        toast(`your rated ${given_star} stars.`);
      }
    })
  };
let ratingQue;
  let anyRatingLive = rating?.find((x) => x.live !== 0);

  if (rating?.length > 0) {
    ratingQue = rating?.filter((t) => t?.live === 1).map((rating, index) => {

      return (
        <div key={index} className="card popup-card-wrapper">
          <div
            id={`headingRating-default${index}`}
            className="card-header py-2 px-0"
          >
            <h3 className="mb-0 d-flex justify-content-between">
              <a
                type="button"
                data-toggle="collapse"
                data-target={`#collapseRating-default${index}`}
                aria-expanded={index === 0 ? true : false}
                // aria-expanded={index === 0 ? 'true' : "false"}
                aria-controls={`collapseRating-default${index}`}
                className={`btn btn-link ${index === 0 ? '' : 'collapsed'}`}
              >
                {rating?.question}
              </a>
            </h3>
          </div>
          <div
            id={`collapseRating-default${index}`}
            aria-labelledby={`headingRating-default${index}`}
            data-parent={`#accordionRating`}
            className={`${index === 0 ? "collapse show" : "collapse"}`}
          >
            <div className="card-body">
              {rating.given_star !== 0 ? (
                <>
                  {" "}
                  <ReactStars
                    count={
                      rating.given_star
                        ? rating.given_star
                        : selectedRatingMap[rating.id]?.given_star
                    }
                    size={30}
                    value={rating.given_star}
                    color={color}
                    activeColor={color}
                  />
                  <div className="submitted-ans-wrapper">
                    <div className="poll-answer-wrap polling-answer">
                      <h5>
                        {" "}
                        {translations?.rating?.submit_success_response_text} :{" "}
                        {rating.given_star
                            ? rating.given_star
                            : 0}{" "}
                        Star
                      </h5>
                    </div>
                  </div>
                  {/* {rating.review && <div> Review : {rating.review ? rating.review
                                    : (rating.review || '')}
                                </div>} */}
                </>
              ) : (
                <form key={index} action="" className="voting-form">
                  <div className="db-radio-check ">
                    <ReactStars
                      count={rating?.star}
                      onChange={(newRating) =>
                        ratingChanged(newRating, rating.id, index)
                      }
                      size={45}
                      // isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      // classNames={props.faIConMainContainer}
                      activeColor={color}
                      color="#212529"
                    />
                  </div>
                  <div key={index} className="text-center mt-4">
                    <a
                      onClick={(e) =>
                        onSubmitRating(e, rating?.id, index)
                      }
                      className="btn btn-sm vw-btn vw-btn-black mt-3"
                      href="#"
                      id="sav-review"
                    >
                      {translations?.rating?.send_buton_text}{" "}
                      <i
                        className="fa fa-paper-plane-o"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  return (
    <div
      className="card box-wrap-card cmn-border-color"
      style={{ border: `2px solid ${color}` }}
    >
      <div className="card-header bg-white p-2">
        <div className="card-actions-wrap d-flex justify-content-end align-items-center">
          <a
            className="ml-2 rcc-menu"
            href=""
            data-target="rating-box-wrapper"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div className="card-body pt-0">
        <div
          id={`accordionRating`}
          className="accordion event-info-accordion QA-accordion"
        >
          {ratingQue}
        </div>
      </div>
    </div>
  );
}

export default RatingMenu;
