import React from 'react'


const SocialUrl = ({ faIcon, url }) => {
    return <div className="col-12 col-md-6">
        <div className="profile-group">
            <div className="profile-data">
                <div className="social-links">
                    <a href={url}><i className={faIcon} aria-hidden="true"></i>
                        {url}</a>
                </div>
            </div>
        </div>
    </div>
}

export default function UserInformation({ src, firstName, lastName,
    emailId, organization,
    registrationType,
    jobTitle,
    socialUrl, LinkedInURL, TwitterURL, FacebookURL }) {
    return (
        <div className="modal-body gs-modal-body">
            <div className="profile-page-wrap">
                <div className="profile-image-wrap">
                    <div className="profile-imageframe">
                        <img src={src} alt="" className="img-responsive img-fluid rounded-circle" />
                    </div>
                </div>
                <div className="profile-info-wrap">
                    <div className="profile-information">
                        <div className="row">
                            {firstName && <div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>First Name</label>
                                        <p>{firstName}</p>
                                    </div>
                                </div>
                            </div>}
                            {lastName && <div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>Last Name</label>
                                        <p>{lastName}</p>
                                    </div>
                                </div>
                            </div>}
                            <div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>Email Address</label>
                                        <p>{emailId}</p>
                                    </div>
                                </div>
                            </div>
                            {organization && <div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>Compant/Organisation</label>
                                        <p>{organization}</p>
                                    </div>
                                </div>
                            </div>}
                            {registrationType && <div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>Registration Type</label>
                                        <p>{registrationType}</p>
                                    </div>
                                </div>
                            </div>}
                            {jobTitle && <div div className="col-12 col-md-6">
                                <div className="profile-group">
                                    <div className="profile-data">
                                        <label>Job Title</label>
                                        <p>{jobTitle}</p>
                                    </div>
                                </div>
                            </div>}
                            {LinkedInURL && <SocialUrl
                                faIcon='fa fa-linkedin'
                                url={LinkedInURL} />}
                            {TwitterURL && <SocialUrl
                                faIcon='fa fa-twitter'
                                url={TwitterURL} />}
                            {FacebookURL && <SocialUrl
                                faIcon='fa fa-twitter'
                                url={FacebookURL} />}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
