import React, { useContext, useState } from 'react'
import { Link } from "react-router-dom";
import placeholder from '../../../assets/user/avatarplaceholder.png';
import Img from '../../components/defaultLayout/Img';
import { Context as networkingToolContext } from '../../../context/NetworkTollContext';

import { useToasts } from "react-toast-notifications";
import {Error} from "../../../Utils/Utils";
import {ErrorOption} from "../../components/ToastOptions";

export const ListProfile = ({ user }) => {
  const {
    addUserToVideoChat,
    state: {
      videoChat
    }

  } = useContext(networkingToolContext);
  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const addUserToVideoChatRequest = () => {
    if (isLoading) return;
    
    setIsLoading(true)
    addUserToVideoChat(videoChat?.match_id, user?.id, (response, error) => {
      if (error) {
        addToast(Error(error).getResponseError(), ErrorOption)
      }
      setIsLoading(false)
    });
  }
  return (
    <div className="dft-client-info-wrap">
      <div className="dft-client-img-wrap">
        <Img src={user?.avatar} className="img-fluid" placeholder={placeholder} />
      </div>
      <div className="dft-client-details-wrap">
        <h3>{user?.name + ' ' + user.last_name}</h3>
        <div className="dft-appoint-action-wrap">
          {
            user?.LinkedInURL &&
            <Link to={user?.LinkedInURL}>
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
            </Link>
          }
          {
            user?.TwitterURL &&
            <Link to={user?.TwitterURL}>
              <i className="fa fa-twitter-square" aria-hidden="true"></i>
            </Link>
          }
          {
            user?.FacebookURL &&
            <Link to={user?.FacebookURL}>
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
            </Link>
          }
          
          <Link to="#" disabled={isLoading} onClick={(e) => {
            e.preventDefault();
            addUserToVideoChatRequest();
          }} className="btn btn-join">
            <i className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-plus" }`} aria-hidden="true"></i> {!isLoading ? "Join" : ""}
          </Link>
        </div>
      </div>
    </div>
  );
}
