import React, { useContext } from "react";
import { Context as AuthContext } from "../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";


export const BackToRegistration = (props) => {

  const history = useHistory();

    const {
      state: {
        registrationPageData: { event },
      },
    } = useContext(AuthContext);
    
    const backToRegister = (e) => {
      e.preventDefault();
      localStorage.removeItem("defaultLogin");    
      window.location.reload();  
    };

    return (
      <div className="row">
        <div className="col-12 text-center">
          <div className="d-flex justify-content-center my-3">
            <a
              type="submit"
              href="#"
              style={{ color: event?.color }}
              onClick={(e) => backToRegister(e)}
              className={`btn vw-btn register-btn vw-btn-white w-25 cmn-text-color`}
            >
              <i className="fa fa-arrow-left"> Back</i>
            </a>
          </div>
        </div>
      </div>
    );
}
