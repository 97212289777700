import React from "react";
import ChatThumb from "../../chatMenu/ChatThumb/ChatThumb";

const QandAModal = ({ onPinQandA, children, isThumbLoding }) => {
  return (
    <div
      id="QA-box-wrapper"
      className="QA-box-wrap box-wrap-modal full-width-box"
    >
      <div className="card box-wrap-card cmn-border-color">
        <div className="card-header bg-white p-2">
          <div className="card-actions-wrap d-flex justify-content-between align-items-center">
            <ChatThumb
              isThumbLoding={isThumbLoding}
              linkClassName="QA-box-wrapper"
              onPinChat={onPinQandA}
            />
            <a
              href="#"
              className="ml-2 small-iframe"
              data-target="QA-box-wrapper"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default QandAModal;
