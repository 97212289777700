import React, { Component, useContext, useEffect, useState } from "react";

import profilePlaceholder from "../../../assets/user/avatarplaceholder.png";
import { Context as networkingToolContext } from "../../../context/NetworkTollContext";
import Img from "../../components/defaultLayout/Img";
import UserProfileImage from "../../components/defaultLayout/UserProfileImage";
import { ErrorOption } from "../../components/ToastOptions";
import { useToasts } from "react-toast-notifications";
import { Context as DefaultSectionContext } from "../../../context/DefaultSectionContext";
import toast from 'react-hot-toast';
import { Toast } from "./Toast";
import { ToastType } from "./ToastType";
// import { baseUrl } from "../../../../../constants/url/urlConstants";

// import VideoChat from "./VideoChat/VideoChat.js";

// import Pusher from "pusher-js";
// import {
//   PUSHER_APP_KEY,
//   PUSHER_CLUSTER,
// } from "../../../../../constants/Pusher/Pusher";

const min = 1;
const max = 20;

const randomMin = 1;
const randomMax = 6;

const Dice = ({ disabled }) => {
  const {
    state: {
      eventsPageData: {
        page_data
      },
    },
  } = useContext(DefaultSectionContext);

  const diceList = page_data?.networking_tool?.interests ?? {};
  const checked_interests = page_data?.user_interests ?? {};

  const [rand1, setRand1] = useState({ rand1: 0, rand2: 0 });
  const [rand2, setRand2] = useState({ rand1: 0, rand2: 0 });
  const [accepted, setAccepted] = useState(false);
  const [joined, setJoined] = useState(false);
  const [diceChecked, setDiceChecked] = useState({ checked: [], initial: true });


  const [timer_interval, setTimer_interval] = useState(null);
  const [timer_sec, setTimer_sec] = useState(0);
  const { addToast } = useToasts();

  /*
  constructor(props) {
    super(props);

    state = {
      rand1: 0,
      rand2: 0,
      accepted: false,
      accessToken: '',
      audio: true,
      countdown3Min: 180,
      countdown10Min: 10,
      joined: false,
      match: false,
      participants: [],
      pusher: null,
      videoChatRoom: null,
      video: true,

      timer_interval: null,
      timer_show: false,
      timer_min: 3,
      timer_sec: 0,
    };

    rollDice = rollDice.bind(this);
    getRandom = getRandom.bind(this);
  }

  componentDidMount() {
    console.log('MOUNTED DICES');
    console.log(joined);
    console.log(props.user);
  }

  */

  const {
    rollDice,
    videoConnect,
    rollDiceInterestUpdate,
    state: { details, dice_matched },
  } = useContext(networkingToolContext);

  const [diceMatched, setDiceMatched] = useState(dice_matched)

  useEffect(() => {
    if (dice_matched?.user?.id !== diceMatched?.user?.id) {
      setDiceMatched(dice_matched)
      setRand2(rand1)
    }
    if (!diceMatched) {
      if (rand1.rand1 === rand2.rand1 && rand1.rand2 === rand2.rand2) {
        setRand2({ rand1: rand1.rand2, rand2: rand1.rand1 })
      }
    }
  }, [dice_matched])
  const rollingDice = async () => {
    setRand1({ rand1: getRandom(), rand2: getRandom() });
    setRand2({ rand1: getRandom(), rand2: getRandom() });
    setDiceMatched(null)
    setJoined(true);
    rollDice(details?.event, (response, error) => {
      if (response) {
        Toast("Matching interest is found, "+`${response?.data?.matched_on}`, ToastType.INFO);
      } else if (error) {
        Toast(error.response.data.message);
      }
      setJoined(false);
      setTimer_sec(0);
    });
    // setState({
    // 	rand1: random1,
    // 	rand2: random2,
    // 	joined: true
    // });

    // try {
    // 	const url = baseUrl("api/video-chat/join/" + props.event.id);
    // 	const response = await DBNetwork.get(url);
    // 	console.log(response.data.data.match_id);
    // 	if(response.data.data.match_id) {
    // 		setState({
    // 			match: true,
    // 		});
    // 		props.setMatchId(response.data.data.match_id);
    // 		gotMatch(response.data.data.match_id);
    // 	}
    // 	else {
    // 		if (pusher == null) {
    // 			let pusher = new Pusher(PUSHER_APP_KEY, {
    // 				cluster: PUSHER_CLUSTER,
    // 				encrypted: false
    // 			});
    // 			pusher = pusher;

    // 			pusher.connection.bind('error', function (err) {
    // 				if (err?.code === 404) {
    // 					console.log('Over limit!');
    // 				}
    // 			});
    // 			const channel = pusher.subscribe('videochat.user.' + props.user.id);
    // 			channel.bind("App\\Events\\VideoChatUserFoundEvent", data => {
    // 				// setState({
    // 				// 	match: true
    // 				// });
    // 				props.setMatchId(data.match_id);
    // 				gotMatch(data.match_id);
    // 			});
    // 		}
    // 	}

    // } catch (error) {
    // 	console.log("error");
    // 	if(error.response) {
    // 		console.log(error.response.data);
    // 		if(error.response.data.code == "VC_MEETING_PROGRESS" && error.response.data.data.match_id) {
    // 			console.log('reconnecting');
    // 			// setState({
    // 			// 	match: true
    // 			// });
    // 			props.setMatchId(error.response.data.data.match_id);
    // 			gotMatch(error.response.data.data.match_id);
    // 			props.acceptMatch();
    // 		}
    // 	}
    // }

    setTimer_sec(15);
  };

  useEffect(() => {
    if (timer_sec > 0) {
      startTimer();
    } else {
    }
  }, [timer_sec]);

  const startTimer = () => {
    if (timer_sec > 0) {
      setTimeout(() => setTimer_sec((prev) => prev - 1), 1000);
      setRand1({ rand1: getRandom(), rand2: getRandom() });
      setRand2({ rand1: getRandom(), rand2: getRandom() });
    } else {
      setJoined(false);
      clearInterval(timer_interval);
    }
  };

  const gotMatch = async (match_id) => {
    // try {
    // 	const url = baseUrl(`api/video-chat/match/${match_id}`);
    // 	const response = await DBNetwork.get(url);
    // 	props.setFOI(response.data.data.foi);
    // 	props.setMatchId(match_id);
    // 	props.listenForVCEvents();
    // 	props.setMatchedUser(response.data.data.matched_users[0]);
    // } catch (error) {
    // 	console.log("Error");
    // 	console.error(error);
    // }
  };

  const getRandom = () => {
    return (Math.floor(Math.random() * (max - min)) + min) * 90;
  };

  const connect = () => {
    videoConnect(details?.event, dice_matched?.user, dice_matched?.matched_on, (response, error) => {
      // setIsLoading(false)
      if (error) {
        addToast(error.response.data.message, ErrorOption);
      }
    });
  };

  const stylePlayer1 = {
    transform: "rotateX(" + rand1.rand1 + "deg) rotateY(" + rand1.rand2 + "deg)",
  };

  const stylePlayer2 = {
    transform: "rotateX(" + rand2.rand1 + "deg) rotateY(" + rand2.rand2 + "deg)",
  };

  useEffect(() => {

    if (diceChecked?.initial == true) {
      if (page_data?.user_interests) {
        const checked_interests = page_data?.user_interests?.map(d => d.id)
        setDiceChecked(prev => {
          return {
            ...prev,
            checked: checked_interests,
          }

        })
      }

      return;
    }
  }, [page_data?.user_interests])

  const getCurrentChecked = () => diceChecked?.checked
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (diceChecked?.initial == true) return;
      rollDiceInterestUpdate(details?.event, getCurrentChecked(), (response, error) => {
        if (response) {
          console.log("[rollingDice] " + JSON.stringify(response));
        } else if (error) {
          addToast(error.response.data.message, ErrorOption);
        }
      });
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [diceChecked]);

  const handleChange = (event) => {
    const { value, checked } = event.target;
    let checkedOptions = [...diceChecked.checked]
    if (checked == true && !checkedOptions.includes(value)) {
      checkedOptions.push(Number(value));
    } else {
      const index = checkedOptions.indexOf(Number(value));
      if (index > -1) {
        checkedOptions.splice(index, 1);
      }
    }
    setDiceChecked(prev => {
      return { ...prev, checked: checkedOptions, initial: false }
    });
  }

  return (    
  <>
    {!accepted && (
      <div className="dice-wrap dice-pairing-wrap pt-5">
        <div className="peers-wrapper">
          {joined && (
            <div className="match-text ">
              <h2 className="match-blue-text">Looking for a match</h2>
              <h2 className="match-blue-text">{timer_sec}</h2>
            </div>
          )}
          {!joined && (
            <div className="match-text ">
              {dice_matched ? <div><h2 className="match-blue-text">We have a match!</h2> <h4 style={{ color: "rgb(193, 18, 56)" }}>Matching interest: {dice_matched?.matched_on}</h4> </div>
                : <h2 className="match-blue-text">Let's find a match</h2>}
            </div>
          )}
          <div className="row align-items-center my-5">
            <div className=" col-6 col-sm-6 col-md-3 col-lg-3">
              {details?.user && (
                <div className="player player1">
                  <div className="player-imageframe text-center">
                    <UserProfileImage
                      src={
                        details?.user?.avtar
                          ? details?.user.avtar
                          : profilePlaceholder
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="player-name text-center">
                    <h4 className="match-blue-text">
                      {`${details.user?.name ?? ""} ${details.user?.last_name ?? ""
                        }`.capitalize()}
                    </h4>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <section className="dice-container">
                <div id="player1" className="cube-box" style={stylePlayer1}>
                  <div className="front">
                    <span className="dot dot1"></span>
                  </div>
                  <div className="back">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                  </div>
                  <div className="right">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                  </div>
                  <div className="left">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                  </div>
                  <div className="top">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                    <span className="dot dot5"></span>
                  </div>
                  <div className="bottom">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                    <span className="dot dot5"></span>
                    <span className="dot dot6"></span>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <section className="dice-container">
                <div id="player2" className="cube-box" style={stylePlayer2}>
                  <div className="front">
                    <span className="dot dot1"></span>
                  </div>
                  <div className="back">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                  </div>
                  <div className="right">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                  </div>
                  <div className="left">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                  </div>
                  <div className="top">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                    <span className="dot dot5"></span>
                  </div>
                  <div className="bottom">
                    <span className="dot dot1"></span>
                    <span className="dot dot2"></span>
                    <span className="dot dot3"></span>
                    <span className="dot dot4"></span>
                    <span className="dot dot5"></span>
                    <span className="dot dot6"></span>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              {diceMatched && (
                <div className="player player2">
                  <div className="player-imageframe text-center">
                    <UserProfileImage
                      src={dice_matched?.user?.avtar}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="player-name text-center">
                    <h4 className="match-blue-text">
                      {dice_matched
                        ? `${dice_matched?.user?.name ?? ""} ${dice_matched?.user?.last_name ?? ""
                          }`.capitalize()
                        : ""}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
          {details?.networking_tool?.config?.nt_match_on == 'interest' && (
            <div className="rolling_dice_checkbox">
              <form action="#">
                <ul>
                  {diceList.map(dice_item => {
                    return (
                      <li key={dice_item.id}>
                        <input
                          type="checkbox"
                          id={dice_item.interest}
                          name={dice_item.interest}
                          value={dice_item.id}
                          checked={diceChecked.checked.includes(dice_item.id)}
                          onChange={handleChange}
                        />
                        <label for={dice_item.interest}>{`${dice_item?.interest}`.capitalize()}</label>
                      </li>
                    )
                  })}
                </ul>
              </form>
            </div>
          )}

          {diceMatched && (
            <div id="rollingDice" className="dice-btn-wrap">
              <button
                type="button"
                className="btn btn-flat btn-dice mb-2"
                onClick={connect}
                disabled={disabled}
              >
                Let's Connect !
              </button>
            </div>
          )}
          {!joined && (
            <div id="rollingDice" className="dice-btn-wrap">
              <button
                type="button"
                className="btn btn-flat btn-dice mb-5"
                onClick={rollingDice}
                disabled={disabled}
              >
                {`Roll The Dice ${diceMatched ? "Again" : ""}!`}
              </button>
            </div>
          )}
        </div>
      </div>
    )}
  </>
  );
};

export default Dice;
