import toast from 'react-hot-toast'
import { ToastType } from "./ToastType";

const ErrorToastWrapper = (message) => {
    return {
        jsx: <>
            <div className="content">
                <div className="icon">
                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
                <div className="details">

                    <p>{message}</p>
                </div>
            </div>
            <div className="close-icon" onClick={() => toast.dismiss()}>
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </>,
        className: 'error_toast'
    }
}

const SuccessToastWrapper = (message) => {
    return {
        jsx: <>
                <div className="content">
                    <div className="icon">
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i></div>
                    <div className="details">

                        <p>{message}</p>
                    </div>
                </div>
                <div className="close-icon" onClick={() => toast.dismiss()}>
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
        </>,
        className: 'success_toast'
    }
}

const InfoToastWrapper = (message) => {
    return {
    jsx: <>
        <div className="content">
            <div className="icon">
                <i class="fa fa-info-circle" aria-hidden="true"></i></div>
            <div className="details">

                <p>{message}</p>
            </div>
        </div>
        <div className="close-icon" onClick={() => toast.dismiss()}>
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </>,
        className: 'info_toast'
    }


}

export const Toast = (message, type) => {
    let content = InfoToastWrapper(message)

    if (type === ToastType.ERROR) {
        content = ErrorToastWrapper(message)

    } else if (type === ToastType.SUCCESS) {
        content = SuccessToastWrapper(message)
    }
    const {jsx, className} = content

    toast((t) => jsx, {style: {margin: 10}, className:`toast_all ${className}`});
}