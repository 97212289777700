import React from 'react'
import { Link } from "react-router-dom";


export const LetterPagination = (props) => {
  // const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  // const alphabets = alpha.map((x) => String.fromCharCode(x));

  const pagination =  Array.from(new Set(props.alphabets ?? [])).map((c, index) => {
    return (
      <Link
        key={index}
        to="#"
        onClick={() => props.setCurrentLatter(c.toLowerCase())}
        className={`filter-items ${
          props.currentLatter === c.toLowerCase() ? "active" : ""
        }`}
      >
        {c}
      </Link>
    );
  })
  return (
    <div className="filter-pagination-wrap">
      <div className="filter-wrapper">
        <div className="filter-item-list">
          <Link
            to="#"
            onClick={() => props.setCurrentLatter('all')}
            className={`filter-items ${
              props.currentLatter === 'all' ? "active" : ""
            }`}
          >
            All
          </Link>
          {pagination}
        </div>
      </div>
    </div>
  );
}
