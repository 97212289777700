import React, {useContext, useState} from "react";
import user_img from "../../../../assets/images/user1-128x128.jpg";
import UserProfileImage from "../../../components/defaultLayout/UserProfileImage";
import {Appointment, Error} from "../../../../Utils/Utils";
import {OptionConstants} from "../../../../constants/Constants";
import {Context as networkingToolContext} from "../../../../context/NetworkTollContext";
import {useToasts} from "react-toast-notifications";
import {ErrorOption, SuccessOption} from "../../../components/ToastOptions";
import NotificationCenter from "../../../../hooks/NotificationCenter";


export const Pending = ({appointment}) => {
  const appointmentModel = Appointment(appointment);

  const [loading, setLoading] = useState({isLoading: false, type: null})
  const { addToast } = useToasts();

  const {
    updateAppointment,
    state: { details },
  } = useContext(networkingToolContext);

  const callApi = (type) => {
    setLoading( { isLoading: true, type: type })
    updateAppointment({status: type, ...appointmentModel.getRequestPayload()}, (res, err) => {
      if (res){
        addToast(res.data.message, SuccessOption);
      }else if (err) {
        addToast(Error(err).getResponseError(), ErrorOption);
      }
      setLoading({ isLoading: false, type: null })
    })
  }
  const updateAppointmentRequest = () => {
    console.log(JSON.stringify(appointment))
    NotificationCenter.sharedInstance().broadcastNotification("setUpdateAppointmentUserDetails", {userDetails: {...appointment, event: {id: appointment?.event_id}}, appointment: appointment});
    // alert("asdasd")
    window.$("#appointmentModal").modal("show");
    // setAppointmentUser(user_id);
  }
  const Loading =  <i
      aria-hidden="true"
      className={`fa fa-spinner fa-spin`}
  ></i>

  const isLoading = (type) => (loading.isLoading && loading.type === type)
  return (
    <div className="dft-appointment-acceptedlist dft-appointment-pandinglist">
      <div className="row no-gutters">
        <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="acceptedlist-img">
            {((details?.user?.id !== appointment.host_id)) ?
            <UserProfileImage src={appointmentModel.getHostAvatar()} />
            : <UserProfileImage src={appointmentModel.getAvatar()} /> }

          </div>
        </div>
        <div className="col-md-8 col-lg-8 col-xl-9">
          <div className="acceptedlist-info">
            <div className="accepted_infobox">
              {/*<h6>11.00-12.00 sat 10/.07</h6>*/}
              <h6>{appointmentModel.getFormatedDate()}</h6>
              {((details?.user?.id  !== appointment.host_id)) ? <h5>{appointmentModel.getHostFullName()}</h5> : <h5>{appointmentModel.getFullName()}</h5> }
              {((details?.user?.id  !== appointment.host_id)) ? <p>{appointmentModel.getHostOrganization()}</p> : <p>{appointmentModel.getOrganization()}</p> }
            </div>
            <div className="accepted-icon-btn">
              { (details?.user?.id !== appointment.host_id) && <button className="btn check" disabled={loading.isLoading} onClick={() => callApi(OptionConstants.ACCEPTED)}>
                {isLoading(OptionConstants.ACCEPTED) ? Loading : <i class="fa fa-check" aria-hidden="true" /> }
              </button> }
              <button className="btn" disabled={loading.isLoading} onClick={() => updateAppointmentRequest()}>
                {isLoading(OptionConstants.PENDING) ? Loading :<i class="fa fa-calendar-plus-o" aria-hidden="true"></i>}
              </button>
              <button className="btn cross" disabled={loading.isLoading} onClick={() => callApi(OptionConstants.DECLINE)}>
                {isLoading(OptionConstants.DECLINE) ? Loading : <i class="fa fa-times" aria-hidden="true"></i>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
