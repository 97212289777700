import Pusher from "pusher-js";
import React, {Component, useContext, useEffect, useState} from "react";
import { baseUrl } from "../../../../../../constants/url/urlConstants";
import {
    PUSHER_APP_KEY,
    PUSHER_CLUSTER,
    QAAnswerEvent,
    QA_Answer_Channel,
} from "../../../../../../constants/Pusher/PusherConstants";
import QandAChat from "../../../../../../UIComponets/QandAChat/QandAChat";
import DBNetwork from "../../../../../../api/DBNetwork";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import QandAInput from "./QandAInput/QandAInput";
import QandAModal from "./QandAModal/QandAModal";
import QandAComponet from "./QandAComponent/QandAComponet";
import toastProp from "../../../../../../data/toastProp";
import { changeColor } from "../../../../../../utlis/changeColor";
import {DefaultContext} from "react-icons";
import {
    Context as defaultContext,
    Context as DefaultSectionContext
} from "../../../../../../context/DefaultSectionContext";
import {PusherEvent} from "../../../../../../hooks/PusherEvent";
import getAuthDetails from "../../../../../../hooks/getAuthDetails";

const QandA = (props) =>  {

    const {
        sendQnA,
        state: {
            eventsPageData: {
                user,
                page_data : {
                    chat,
                    event,
                    question_answer
                },
                translations
            },
            pusherEvent
        },
    } = useContext(defaultContext);


    useEffect(() => {
        setQ_and_A(question_answer ?? [])
    }, [question_answer])

    const userId = user?.id;
    const color= event?.color;
    const systemId = localStorage.getItem("system_id");
    const uniqueId = getAuthDetails(user?.id, systemId);
    const eventId= event?.id;

    const chatContainer = React.createRef();
    const inputRef = React.createRef();
    const [q_and_a, setQ_and_A] = useState(question_answer)
    const [queText, setQueText] = useState('');
    const [ansText, setAnsText] = useState({});
    const [isQueLoading, setIsQueLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onTextChangeInput = (value, index, queId) => {
        setAnswer(queId, value)
    };
    const setAnswer = (question_id, text) => {
        setAnsText({...ansText, [question_id]: text})
    }

    const setUser = (userName) => {
        this.setState((prevState, props) => ({
            ...prevState,
            user: userName,
        }));
    };
    changeColor(props.color);
    useEffect(() => {
        scrollToMyRef()
        if (pusherEvent?.action === PusherEvent.ANSWER_REC && pusherEvent?.data){
            const data = pusherEvent?.data
            toast(
                `New answer received for ${data?.qandAQuestion?.question}`,
                toastProp
            );
            if (

                !window.$("#QA-box-wrapper").hasClass("active")
            ) {
                window.$('.fixed-menu-item[data-target="QA-box-wrapper"]')?.click()
            }
            const index = question_answer.findIndex(i => {
                return data?.qandAQuestion?.id === i?.id
            })
            if (index !== -1) {
                const id = "#collapseQAOneDefault" + index
                const selector = `.btn.collapsed[data-target="${id}"]`;
                window.$(selector)?.click()
                const containerID = id + " .direct-chat-messages"
                const container = window.$(containerID);
                const lastElement = window.$(containerID + " .direct-chat-msg").last()
                container?.scrollTop(lastElement.offset().top + container.scrollTop());
            }
        }
    }, [pusherEvent]);

    // ********************** On Que send Function. **********************
    const onSendQueEnter = (e) => {
        if (e.key === "Enter" && queText.length > 0) {
            onSendQue()
        }
    };
    const onSendQue = (e) => {
        e?.preventDefault();
        if (queText.length > 0) {
            e?.currentTarget.setAttribute("disabled", "disabled");
            setIsQueLoading(true)
            const payload = {
                username: user?.name,
                question: queText,
                event_id: eventId,
                system_id: systemId,
            };
            sendQnA({ ...payload }, (data) => {
                setQueText("");
                setIsQueLoading(false);
                e?.currentTarget?.removeAttribute("disabled");
            })
        }
    };
    // ********************** On Send Message Function. **********************

    const onSendMessage = (e, question_id, index) => {
        e.preventDefault();
        if (
            ansText[question_id] &&
            ansText[question_id].length > 0
        ) {
            setIsLoading(true)
            let currentTarget = e.currentTarget;
            currentTarget?.setAttribute("disabled", "disabled");

            const url = baseUrl("api/send-answer");
            // const username = window.prompt('Username: ');

            const payload = {
                username: user?.name,
                question_id,
                event_id: eventId,
                answer: ansText[question_id],
                system_id: systemId,
            };
            DBNetwork.post(url, payload).then((response) => {
                setIsLoading(false)
                currentTarget?.removeAttribute("disabled");
                setAnswer(question_id, '')
            });
        }
    };

    const onSendMessageEnter = (e, question_id, index) => {
        if (e.key === "Enter" && ansText[question_id] &&
            ansText[question_id].length > 0) {
            setIsLoading(true)
            const url = baseUrl("api/send-answer");
            // const username = window.prompt('Username: ');
            const payload = {
                username: user?.name,
                question_id,
                event_id: eventId,
                answer:  ansText[question_id],
                system_id: systemId,
            };
            DBNetwork.post(url, { ...payload }).then((response) => {
                setIsLoading(false)
                setAnswer(question_id, '')
            });
        }
    };

    const scrollToMyRef = () => {
        if (chatContainer.current) {
            const scroll =
                chatContainer.current.scrollHeight -
                chatContainer.current.clientHeight;
            chatContainer.current.scrollTo(0, scroll);
        }
        if (inputRef.current) {
            const scroll =
                inputRef.current.scrollHeight - inputRef.current.clientHeight;
            inputRef.current.scrollTo(0, scroll);
        }
    };


    // *********** Variable declarations. ***********
    let questions;
    const question = [...q_and_a];
    // ********************** Data Render. **********************

    if (question?.length > 0) {
        questions = question?.map((que, i) => {
            return (
                <React.Fragment key={i}>
                    <div className="card popup-card-wrapper">
                        <div className="card-header p-0" id={`headingQADefault${i}`}>
                            <h3 className="mb-0 d-flex justify-content-between">
                                <a
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseQAOneDefault${i}`}
                                    aria-expanded={i === 0 ? "true" : "false"}
                                    aria-controls={`collapseQAOneDefault${i}`}
                                >
                                    {que?.question}
                                    <div className="d-flex QA-meta-text ">
                                        <div
                                            className="time "
                                            style={{ color: color }}
                                        >
                                            {que?.time}
                                        </div>
                                        <div className="name">{que?.user_name}</div>
                                    </div>
                                </a>
                            </h3>
                        </div>
                        <div ref={inputRef}>
                            <QandAChat
                                index={i}
                                attributes={{
                                    id: `collapseQAOneDefault${i}`,
                                    class: "collapse",
                                    "aria-labelledby": `#headingQADefault${i}`,
                                    "data-parent": `#accordionQA`,
                                }}
                                chatMessages={que?.answers}
                                color={color}
                                userDetails={
                                    userId ? userId : systemId
                                }
                            >
                                <div className="input-group mb-2">
                                    <input
                                        value={ansText[que?.id] ? ansText[que?.id] : ""}
                                        onChange={(e) =>
                                            onTextChangeInput(e.target.value, i, que.id)
                                        }
                                        type="text"
                                        className="form-control fix-rounded-right cmn-focus-border-color"
                                        placeholder={translations?.qa?.answer_placeholder}
                                        onKeyDown={(e) => onSendMessageEnter(e, que.id, i)}
                                    />
                                    <div className="input-group-append">
                      <span className="input-group-text bg-white">
                        <Link
                            onClick={(e) => onSendMessage(e, que.id, i)}
                            to="#"
                        >
                          <i
                              className={`fa ${
                                  isLoading
                                      ? "fa-spinner fa-spin"
                                      : "fa-paper-plane-o"
                              }`}
                              aria-hidden="true"
                          ></i>
                        </Link>
                      </span>
                                    </div>
                                </div>
                            </QandAChat>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
    }

    // ********************* Main Returned JSX code *****************

    return (
        <>
            {/* Que modal Show */}
            {!props.isPinQandA && (
                <QandAModal
                    isThumbLoding={props.isThumbLoding}
                    onPinQandA={props.onPinQandA}
                >
                    <QandAInput
                        que={queText}
                        setQueText={setQueText}
                        onSendQueEnter={onSendQueEnter}
                        isQueLoading={isQueLoading}
                        onSendQue={onSendQue}
                    />
                    <div
                        ref={chatContainer}
                        className="card-body df-card-body-scrollbar px-0 my-2 mx-3"
                    >
                        <div
                            className="accordion event-info-accordion QA-accordion"
                            id={"accordionQA"}
                        >
                            {questions}
                        </div>
                    </div>
                </QandAModal>
            )}

            {/* On Pin Q and A section */}
            {props.isPinQandA && (
                <QandAComponet
                    isQAThumbLoding={props.isQAThumbLoding}
                    onPinQandA={props.onPinQandA}
                >
                    <QandAInput
                        que={queText}
                        setQueText={setQueText}
                        onSendQueEnter={onSendQueEnter}
                        onSendQue={onSendQue}
                        isQueLoading={isQueLoading}
                    />
                    {/* <div className="card-body px-1"> */}
                    <div
                        className="accordion event-info-accordion QA-accordion"
                        id={"accordionQA"}
                    >
                        {questions}
                    </div>
                    {/* </div> */}
                </QandAComponet>
            )}
        </>
    );
}

export default QandA;