
import { baseUrl } from "../constants/url/urlConstants";
import createDataContext from "./createDataContext";
import {
    GS1DATA, GS1SUB_CHANNEL, ISVALID, GS1SUB_UPDATE_HAPPENING_NOW_DATA,
    GS1SUB_UPDATE_SESSION_DATA, GS1SUB_UPDATE_LIVE_SESSION_DATA,
    RATING_UPDATED, VOTE_UPDATED, POLL_UPDATED,
    UPDATE_ALL_USER_RATINGS, UPDATE_ALL_USER_VOTES, UPDATE_ALL_USER_POLLS,
    FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE
} from '../constants/ActionsConstants/actiontypes';
import DBNetwork from "../api/DBNetwork";

const Gs1Reducer = (state, action) => {
    switch (action.type) {
        case GS1DATA: return {
            ...state,
            GS1Data: action.payload,
            isLoadingGS1Data: action.isDataLoaded
        }
        case ISVALID: return {
            ...state,
            isValid: action.payload
        }
        case GS1SUB_CHANNEL:
            let _sub_chstate = { ...state }
            let _subpage_data = { ..._sub_chstate.GS1Data.page_data };
            let _selected_channel = { ..._subpage_data.happening_now.selected_channel };
            _selected_channel = action.payload.happening_now?.selected_channel;
            _subpage_data.happening_now.selected_channel = _selected_channel;
            _subpage_data.happening_now.main_channel.islive = action.payload.happening_now?.main_channel?.islive;
            _subpage_data.happening_now.subchannels = action.payload.happening_now?.subchannels;
            _subpage_data.happening_now.main_channel = action.payload.happening_now?.main_channel;
            // _subpage_data.happening_now.selected_channel =  _selected_channel;
            _sub_chstate.GS1Data.page_data.happening_now = _subpage_data.happening_now;

            return {
                ..._sub_chstate,
                // subChannel: action.payload,
                isDataLoaded: action.isDataLoaded,
                isSubChannelDataRequest: action.isSubChannelDataRequest

            }
        case GS1SUB_UPDATE_HAPPENING_NOW_DATA:
            let _state = { ...state }
            let page_data = { ..._state.GS1Data.page_data };

            page_data.happening_now = action.payload.happening_now;
            _state.GS1Data.page_data = page_data;

            return {
                ..._state,
                // isSubChannelDataRequest: true
            }
        case GS1SUB_UPDATE_SESSION_DATA:
            let _state_up = { ...state }
            let cevent_data = _state_up.GS1Data.page_data.cevent_data;
            let mySessions = cevent_data.mySessions;
            let allSessions = cevent_data.sessions;
            let map = action.payload;
            //session loop
            for (let i = 0; i < allSessions.length; i++) {
                let session = allSessions[i];
                let category_name = session.category;
                for (const [key, value] of Object.entries(session.items)) {
                    const day = map.day;
                    value.forEach((item, index) => {
                        if (item.id === map.id) {
                            let item = session.items[key][index];
                            item.is_live = map.is_live;
                            session.items[day][index] = item;
                        }

                    });
                }
                allSessions[i] = session;
            }

            for (let i = 0; i < mySessions.length; i++) {
                let session = mySessions[i];
                let category_name = session.category;
                for (const [key, value] of Object.entries(session.items)) {
                    const day = map.day;
                    value.forEach((item, index) => {
                        if (item.id === map.id) {
                            let item = session.items[key][index];
                            item.is_live = map.is_live;
                            session.items[day][index] = item;
                        }

                    });
                }
                mySessions[i] = session;
            }
            cevent_data.mySessions = mySessions;
            cevent_data.sessions = allSessions;
            _state_up.GS1Data.page_data.cevent_data = cevent_data;

            return {
                ..._state_up
            }

        case GS1SUB_UPDATE_LIVE_SESSION_DATA:
            let _state_liveSession = { ...state };
            let happening_now = _state_liveSession.GS1Data.page_data.happening_now;
            let livesessions = happening_now?.livesessions;
            let index = happening_now?.livesessions?.findIndex(session => session?.id === action.payload?.id);
            if (index !== -1 && livesessions) {
                livesessions[index].is_live = action.payload?.is_live;
            } else {

            }
            livesessions = livesessions
            return {
                ..._state_liveSession
            }
        case RATING_UPDATED:
            let ratingState = { ...state }
            let ismainChannelLive = ratingState.GS1Data.page_data.happening_now.main_channel.islive;
            let _options = [...ratingState.GS1Data.page_data.happening_now.selected_channel.options];
            let _rating = [...ratingState.GS1Data.page_data.happening_now.selected_channel.rating];

            let ratingIndex = _rating.findIndex(rating => rating.id === action.payload.id);
            if (ratingIndex !== -1) {
                _rating[ratingIndex].live = action.payload.live
            } else {
                _rating.push(action.payload);
            }

            ratingState.GS1Data.page_data.happening_now.selected_channel.options = _options;
            ratingState.GS1Data.page_data.happening_now.selected_channel.rating = _rating;
            return { ...ratingState }

        case UPDATE_ALL_USER_RATINGS:
            let ratingsState = { ...state }
            let ratingSelected_channel = { ...ratingsState.GS1Data.page_data.happening_now.selected_channel };
            ratingSelected_channel.rating = [...action.payload];
            ratingsState.GS1Data.page_data.happening_now.selected_channel = ratingSelected_channel;
            return { ...ratingsState }

        case UPDATE_ALL_USER_VOTES:
            let votesState = { ...state }
            let voteSelected_channel = { ...votesState.GS1Data.page_data.happening_now.selected_channel };
            voteSelected_channel.vote = [...action.payload];
            votesState.GS1Data.page_data.happening_now.selected_channel = voteSelected_channel;
            return { ...votesState }

        case VOTE_UPDATED:
            let voteState = { ...state }
            let _optionsVote = [...voteState.GS1Data.page_data.happening_now.selected_channel.options];
            let _vote = [...voteState.GS1Data.page_data.happening_now.selected_channel.vote];

            let voteIndex = _vote.findIndex(vote => vote.id === action.payload.id);
            if (voteIndex !== -1) {
                _vote[voteIndex].live = action.payload.live
            }

            voteState.GS1Data.page_data.happening_now.selected_channel.options = _optionsVote;
            voteState.GS1Data.page_data.happening_now.selected_channel.vote = _vote;
            return { ...voteState }

        case POLL_UPDATED:
            let pollState = { ...state }
            let selected_channel = { ...pollState.GS1Data.page_data.happening_now.selected_channel };
            selected_channel.poll = action.payload;
            pollState.GS1Data.page_data.happening_now.selected_channel = selected_channel;
            return { ...pollState }

        case UPDATE_ALL_USER_POLLS:
            let _pollState = { ...state }
            let __selected_channel = { ..._pollState.GS1Data.page_data.happening_now.selected_channel };
            __selected_channel.poll = action.payload;
            _pollState.GS1Data.page_data.happening_now.selected_channel = __selected_channel;
            return { ..._pollState }

        case FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE:
            let __state = { ...state }
            let __page_data = { ...__state.GS1Data.page_data };

            __page_data.happening_now = action.payload.page_data.happening_now;
            __state.GS1Data.page_data = __page_data;
            return { ...__state }

        default:
            return state;
    }
}

const fetchGS1Data = dispatch => async (slug, callback) => {
    const url = baseUrl(slug);
    // const headers = { Authorization: JSON.parse(localStorage.setItem('GS1_User'))?.accessToken }
    try {
        const response = await DBNetwork.get(url,);
        callback(response.status)
        dispatch({ type: GS1DATA, payload: response.data, isLoadingGS1Data: true })
    } catch (err) {
        console.log(err);
        callback(err.message);
        dispatch({ type: ISVALID, payload: false });

    }
}


//  ******************** For update Session in TimeTable sections **********************

const updateSessionData = dispatch => data => {
    dispatch({ type: GS1SUB_UPDATE_SESSION_DATA, payload: data });
}

//  ******************** For update Session in TimeTable sections **********************

const updateLiveSessionData = dispatch => data => {
    dispatch({ type: GS1SUB_UPDATE_LIVE_SESSION_DATA, payload: data });

}

const fetchEventsSubChannelData = dispatch => async (slug, type) => {

    try {
        const response = await DBNetwork.get(`api/${slug}`);
        dispatch({ type: GS1SUB_CHANNEL, payload: response.data, isDataLoaded: true, isSubChannelDataRequest: false });
        if (!localStorage.getItem('system_id')) {
            const system_id = JSON.stringify(response.data?.system_id)
            // localStorage.setItem('system_id', system_id === "" ? null : system_id);
        }

    } catch (err) {
        console.log(err);
        dispatch({ type: ISVALID, payload: false });

    }
}


const fetchSubChannelWhenMainUnlive = dispatch => async (slug) => {
    const url = baseUrl(slug);
    alert()
    // const headers = { Authorization: JSON.parse(localStorage.setItem('GS1_User'))?.accessToken }
    try {
        const response = await DBNetwork.get(url,);
        // callback(response.status)
        dispatch({ type: FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE, payload: response.data })
    } catch (err) {
        console.log(err);
        // dispatch({ type: ISVALID, payload: false });

    }
}


const ratingUpdated = dispatch => data => {
    dispatch({ type: RATING_UPDATED, payload: data });

}
const updateAllUserRatings = dispatch => data => {
    dispatch({ type: UPDATE_ALL_USER_RATINGS, payload: data });

}
const updateAllUserVotes = dispatch => data => {
    dispatch({ type: UPDATE_ALL_USER_VOTES, payload: data });

}
const updateAllUserPolls = dispatch => data => {
    dispatch({ type: UPDATE_ALL_USER_POLLS, payload: data });

}
const updateAllPolls = dispatch => data => {
    dispatch({ type: UPDATE_ALL_USER_POLLS, payload: data });

}
const voteUpdated = dispatch => data => {
    dispatch({ type: VOTE_UPDATED, payload: data });

}
const pollUpdated = dispatch => data => {
    dispatch({ type: POLL_UPDATED, payload: data });

}

const attendingEvent = dispatch => async (id) => {
    try {
        const response = await console.log('')
    } catch (e) {
        console.error()
    }
}

const joinEvent = dispatch => async (id) => {
    try {
        const response = await console.log('')
    } catch (e) {
        console.error()
    }
}
export const { Context, Provider } = createDataContext(
    Gs1Reducer,
    {
        fetchGS1Data,
        attendingEvent,
        joinEvent,
        fetchEventsSubChannelData, updateLiveSessionData,
        updateSessionData, ratingUpdated, voteUpdated, pollUpdated,
        updateAllUserRatings, updateAllUserVotes, updateAllUserPolls, updateAllPolls, fetchSubChannelWhenMainUnlive
    } // functions
    , {
        GS1Data: {},
        isValid: true, subChannel: {}, isDataLoaded: false,
        isSubChannelDataRequest: true, isLoadingGS1Data: false,
        openModal: false
    } //initial State
)