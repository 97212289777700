import { useContext, useEffect } from "react";
import { Context as defaultContext, Context as DefaultContext } from "./DefaultSectionContext";
import { Context as networkingToolContext } from "./NetworkTollContext";

const NetworkAuthDefaultContext = (props) => {

  const {
    state: {
      eventsPageData: { user, page_data,
        translations
      },
      pusherEvent
    },
  } = useContext(defaultContext);

  const {
    state: { details },
    init
  } = useContext(networkingToolContext)

  const eventCallback = (data) => {

  }
  useEffect(() => {
    if (user && page_data?.event) {
      init(
        {
          event: page_data?.event,
          user: user,
          networking_tool: page_data?.networking_tool
        },
        eventCallback
      );
    }
  }, [user])

  return <>
    {props.children}
  </>
}
export default NetworkAuthDefaultContext;