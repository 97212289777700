import { imageBaseUrlForCanvas } from "../../../constants/url/urlConstants";

// var menusdsd = JSON.parse('{"widget_one":[{"title":"Programm","image":"/web/img/gs1_index/program.jpg","types":"program_modal","live":false,"overlay_color":"rgb(104, 155, 237, .4)","position":1},{"title":"Happening now","image":"/web/img/gs1_index/happening_now.jpg","types":"happening_now_modal","live":true,"overlay_color":"rgb(218, 139, 240, .4)","position":2},{"title":"Social media","image":"/web/img/gs1_index/social_media.jpg","types":"social_media_modal","live":false,"overlay_color":"rgb(104, 155, 237, .4)","position":3},{"title":"Daily trivia","image":"/web/img/gs1_index/daily_trivia.jpg","types":"daily_trivia_modal","live":true,"overlay_color":"rgb(218, 139, 240, .4)","position":4}],"widget_two":[{"title":"Speakers","types":"speakers_modal","position":1},{"title":"Meet GS1 peers","types":"meet_gs1_peers_modal","position":2},{"title":"Video vault","types":"video_vault_modal","position":3},{"title":"Sponsors","types":"sponsers_modal","position":4}]}');
let menus = undefined;

var VP = [14, 12.5, 12, 11.5, 11, 10.5, 10, 9.5, 9];
var HP = [0, 20, 23, 25, 27, 30, 35, 40, 45, 60];
var HL = [];
var VL = [];
var POINTS = [];
var wMap = {};

var isLoaded = false;
var locationHash = window.location.hash;
var DRUS = ['#timetable', '#socialwall', '#DailyTrivia', '#speakers', '#VOD', '#Sponsors', '#myaccount', '#meetgs1peers', '#HappeningNow'];
var isShowingTimeTable = false;

var showTimeTable = function (video, ac, nav, navBg, svgImgContainer) {
    if (isShowingTimeTable) {
        return;
    }
    isShowingTimeTable = true;
    video.classList.toggle('fade');
    ac.classList.toggle('fade');
    nav && nav.classList.toggle('fade');
    navBg.classList.toggle('fade');
    svgImgContainer.classList.toggle('fade');
    if (!DRUS.includes(locationHash)) {
        setTimeout(function () {
            video.classList.toggle('none');
            video.parentNode.classList.toggle('none');
        }, 2000);
    } else {
        video.classList.toggle('none');
        video.parentNode.classList.toggle('none');
    }
}
const getFontSize = function (font) {
    var newFont = (window.innerHeight / 575) * font;
    if (newFont < 10) {
        return 10;
    }
    return newFont;
}
const setEleHeights = (width, height) => {
    var nav = document.getElementById("nav");
    var ac = document.getElementById("anim-canvas");
    var video = document.getElementById("video");
    var navBg = document.getElementById("nav-bg");
    var svgImgContainer = document.getElementById("svg-img-container");

    if (nav == null || ac == null || video == null ||
        navBg == null || svgImgContainer == null) {
        return false;
    }

    if (!isLoaded) {
        isLoaded = true;
        if (!DRUS.includes(locationHash)) {
            video.addEventListener("ended", function () {
                init();
                showTimeTable(video, ac, nav, navBg, svgImgContainer);
                setTimeout(function () {
                    init();
                }, 200);
                const interval = setInterval(function () {
                    if (window.OpenFirstVideoModal) {
                        clearInterval(interval);
                        window.OpenFirstVideoModal();
                    }
                }, 5000);
            });

            var videoSource = document.getElementById("videoSource");
            videoSource.setAttribute('src', '/script/gs1/videos/GS1_LandingPage_Animation v09_Tekst.mp4');
            video.load();
            video.play();

        } else {
            // setTimeout(function () {
            //     window.dispatchEvent(new Event('resize'));
            // }, 2200)
            showTimeTable(video, ac, nav, navBg, svgImgContainer);
            let modalIndex = DRUS.indexOf(locationHash);

            const interval = setInterval(function () {
                if (window.OpenDirectModal) {
                    clearInterval(interval);
                    window.OpenDirectModal(modalIndex);
                }
            }, 500);
        }
    }

    var navH = height * VP[0] / 100;
    navBg.setAttribute('style', 'height:' + navH + 'px');
    nav && nav.setAttribute('style', 'height:' + navH + 'px');
    return true;
}

var drawImage = function () {
    return;
    // var imgDiv = document.getElementById("img-container");
    // var widgets = menus.widget_one;
    // for (var i = 0; i < menus.widget_one.length; i++) {
    //     var widget = widgets[i];
    //     widget.image = 'http://vdb.pixelstudio.site/web/img/gs1_index/daily_trivia.jpg';
    //     widget['wii'] = "widget-img-" + i;
    //     widget['wci'] = "widget_one_click_" + i;
    //     var img = document.getElementById(widget.wii);
    //     wMap[widget.wci] = widget;
    //     if (!img) {
    //         img = document.createElement("img");
    //         img.setAttribute('id', widget.wii);
    //         img.setAttribute('src', widget.image);
    //         img.className = 'img';
    //         var block = widget.block;
    //         var imhH = Math.abs(block[0][1] - block[3][1]);
    //         var imhW = Math.abs(block[0][0] - block[1][0]);
    //         img.style.height = imhH;
    //         imgDiv.appendChild(img);
    //     }
    // }
}

var createSVGImage = function (w, h) {
    var svg = document.getElementById("svg-img-container");
    svg.setAttribute('viewBox', '0 0 ' + w + ' ' + h + '');

    var defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");//document.getElementById("svg-img-container-defs");
    svg.appendChild(defs)
    const createImage = (widget) => {
        widget.image = imageBaseUrlForCanvas + widget.image;
        var ele = document.createElementNS("http://www.w3.org/2000/svg", "image");
        ele.setAttribute('href', widget.image);
        ele.setAttribute('height', "1.5");
        ele.setAttribute('width', "1");
        ele.setAttribute('preserveAspectRatio', "none");
        return ele;
    }
    const createPattern = (widget) => {
        var ele = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
        ele.setAttribute('patternContentUnits', 'objectBoundingBox');
        ele.setAttribute('id', widget.wpii);
        ele.setAttribute('height', '100%');
        ele.setAttribute('width', '100%');
        return ele;
    }
    const createPolygon = (widget) => {
        var block = widget.block;
        var ele = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
        ele.setAttribute('id', widget.wppi);
        svg.appendChild(ele);
        return ele;
    }
    menus.widget_one.forEach((widget, index) => {

        widget['wpii'] = 'wpii' + index;//widget pattern image id
        widget['wppi'] = 'wppi' + index;//widget pattern polygon id

        var pattern = document.getElementById(widget.wpii);
        if (pattern == null) {
            pattern = createPattern(widget);
            defs.appendChild(pattern);
            var image = createImage(widget);
            pattern.appendChild(image);
        }
        var polygon = document.getElementById(widget.wppi);
        if (polygon == null) {
            polygon = createPolygon(widget);
        }
        polygon.setAttribute('fill', 'url(#' + widget.wpii + ')');
        polygon.setAttribute('points', widget.block.join(' '));
    });
}
var initClickEvent = function (w, h) {
    var svg = document.getElementById("svg-container");
    svg.setAttribute('viewBox', '0 0 ' + w + ' ' + h + '');

    const addClickEvent = function (widget) {
        var block = widget.block;
        var polygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
        polygon.setAttribute('id', widget.wci);
        // polygon.onclick = function (event) {
        // if (widgetClickEvent != undefined && !!widgetClickEvent) {
        //     widgetClickEvent(widget);
        // };
        // };

        svg.appendChild(polygon);
        polygon.setAttribute('fill-opacity', "0");
        return polygon;
    }
    menus.widget_one.forEach((widget, i) => {
        widget['wci'] = "widget_one_click_" + i;
        wMap[widget.wci] = widget;
        var polygon = document.getElementById(widget.wci);
        if (polygon == null) {
            polygon = addClickEvent(widget);
        }
        polygon.setAttribute('points', widget.block.join(' '));
        polygon.setAttribute('data-toggle', 'modal');
        polygon.setAttribute('data-target', '#' + widget.types);
    });
    menus.widget_two.forEach((widget, i) => {
        widget['wci'] = "widget_two_click_" + i;
        wMap[widget.wci] = widget;
        var polygon = document.getElementById(widget.wci);
        // var polygon = addClickEvent(widget)
        if (polygon == null) {
            
            polygon = addClickEvent(widget);
        }
        polygon.setAttribute('points', widget.block.join(' '));
        polygon.setAttribute('data-toggle', 'modal');
        polygon.setAttribute('data-target', '#' + widget.types);
    });

}
var init = function () {
    if (!window.menus || !window.menus.widget_one || !window.menus.widget_two) {
        return
    }
    menus = window.menus;
    var outerWidth = window.innerWidth;
    var outerHeight = window.innerHeight;
    const seh = setEleHeights(outerWidth, outerHeight);
    if (!seh) {
        return;
    }
    var container = document.getElementById("mobile-landscape");
    var mobilePortrait = document.getElementById("mobile-portrait");

    if (outerHeight > outerWidth) {
        mobilePortrait.style.display = 'block';
        container.style.display = 'none';
        return;
    }
    mobilePortrait.style.display = 'none';
    container.style.display = 'block';

    var c = document.getElementById("anim-canvas");
    var ctx = c.getContext("2d");

    c.height = outerHeight;
    c.width = outerWidth;

    var H = outerHeight, W = outerWidth; // 1323, 741


    var CPX = W, CPY = -(H * 30 / 100);

    HL = []; //[x1,y1,x2,y2]
    VL = []; //[x1,y1,x2,y2]
    POINTS = [];
    var percent = 0;
    var lineWidth = 3;
    var strokeStyle = "#fff";//"#f6fafe"
    for (var i = 0; i < VP.length; i++) {
        percent = percent + (H * VP[i] / 100);
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.lineCap = 'round';
        ctx.strokeStyle = strokeStyle;
        ctx.moveTo(0, percent);
        ctx.lineTo(W, percent);
        ctx.stroke();
        HL.push([0, percent, W, percent]);
    }

    var percent = W;
    for (var i = 0; i < HP.length; i++) {
        percent = percent - (W * HP[i] / 100);
        VL.push([CPX, CPY, percent, H]);
        if (i != HP.length - 1) {
            ctx.beginPath();
            ctx.lineWidth = lineWidth;
            ctx.lineCap = 'round';
            ctx.strokeStyle = strokeStyle;
            ctx.moveTo(CPX, CPY);
            ctx.lineTo(percent, H);
            ctx.stroke();
            if (i > 0 && i < 7) {
                var newPont = lineLineIntersection([CPX, CPY], [percent, H], [HL[0][0], HL[0][1]], [HL[0][2], HL[0][3]]);
                ctx.beginPath();
                ctx.lineWidth = lineWidth;
                ctx.lineCap = 'round';
                ctx.strokeStyle = "#c4e0f7";
                ctx.moveTo(CPX, CPY);
                ctx.lineTo(newPont[0], newPont[1]);
                ctx.stroke();
            }
        }
    }

    for (var iHL = 0; iHL < HL.length; iHL++) {
        var RP = [];
        for (var iVL = 0; iVL < VL.length; iVL++) {
            //getting all intersections
            var newPont = lineLineIntersection(
                [HL[iHL][0], HL[iHL][1]],
                [HL[iHL][2], HL[iHL][3]],
                [VL[iVL][0], VL[iVL][1]],
                [VL[iVL][2], VL[iVL][3]]
            );
            RP.push(newPont);
        }
        POINTS.push(RP);
    }

    var wtc = ['#ecb668', '#d5afbc', '#d4cf65', '#cea7b2'];
    for (var i = 0; i < menus.widget_two.length; i++) {
        var block = [POINTS[5][i + 1], POINTS[5][i], POINTS[6][i], POINTS[6][i + 1]];
        var index = menus.widget_two.length - (i + 1);
        var widget = menus.widget_two[index];
        widget.color = wtc[index];
        widget.block = block;
        linearWidget(ctx, widget, block)
    }

    var woTemp = menus.widget_one[0];
    woTemp.block = [POINTS[2][6], POINTS[2][5], POINTS[3][5], POINTS[3][6]];
    woTemp.cercle = POINTS[2][6];

    var woTemp = menus.widget_one[1];
    woTemp.block = [POINTS[3][4], POINTS[3][3], POINTS[4][3], POINTS[4][4]];
    woTemp.cercle = POINTS[4][3];

    var woTemp = menus.widget_one[2];
    woTemp.block = [POINTS[1][4], POINTS[1][3], POINTS[2][3], POINTS[2][4]];
    woTemp.cercle = POINTS[1][4];

    var woTemp = menus.widget_one[3];
    woTemp.block = [POINTS[2][2], POINTS[2][1], POINTS[3][1], POINTS[3][2]];
    woTemp.cercle = POINTS[3][1];

    drawImage();
    createSVGImage(W, H);
    for (var i = 0; i < menus.widget_one.length; i++) {
        var woTemp = menus.widget_one[i];
        woTemp.cercleRadious = H / 30;
        mainWidget(ctx, woTemp, woTemp.block, woTemp.cercle)
    }
    initClickEvent(W, H);
}

/**
 * block [TL_POINT, TR_POINT, BR_POINT, BL_POINT]
 * widget
 */
var mainWidget = function (ctx, widget, block, cercle) {
    var colorBlock = block;

    var textPoint = centerPoint(colorBlock[0], colorBlock[1]);
    // ctx.font = "bold 16px Gotham-Bold";
    ctx.font = "bold " + getFontSize(14) + "px Gotham-Bold";
    ctx.fillStyle = "#143170";
    ctx.textAlign = "center";
    // ctx.textBaseline = "middle";
    ctx.fillText(widget.title, textPoint[0], textPoint[1] - 12);


    // var img = document.getElementById(widget.wii);
    // var pat = ctx.createPattern(img, 'no-repeat');

    // const image = new Image();
    // image.src = widget.image;
    // var pat = ctx.createPattern(image, 'no-repeat');

    ctx.beginPath();
    ctx.lineWidth = 5;
    ctx.lineCap = 'round';
    ctx.moveTo(colorBlock[0][0], colorBlock[0][1]);
    for (var i = 1; i < block.length; i++) {
        ctx.lineTo(colorBlock[i][0], colorBlock[i][1]);
    }
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = 'rgb(0, 0, 0, 0)'; //widget.overlay_color;
    ctx.fill();


    // ctx.beginPath();
    // ctx.arc(cercle[0], cercle[1], widget.cercleRadious, 0, 2 * Math.PI, false);
    // ctx.fillStyle = '#88aadb';
    // ctx.fill();
    // ctx.lineWidth = 0;
    // ctx.strokeStyle = '#00003300';
    // ctx.stroke();

    // // ctx.font = "18px Gotham";
    // ctx.font = getFontSize(18) + "px Gotham";
    // ctx.fillStyle = "#fff";
    // ctx.textAlign = "center";
    // ctx.textBaseline = "middle";
    // ctx.fillText('0' + widget.position, cercle[0], cercle[1] + 2);

    return;
    // var traInterval = setInterval(function () {
    //     var img = document.getElementById(widget.wii);
    //     if (img != null) {
    //         clearInterval(traInterval);
    //         transform2d(img, block[0][0], block[0][1], block[1][0], block[1][1], block[3][0], block[3][1], block[2][0], block[2][1]);
    //         // transform2d(img, 100, 100, 200, 100, 50, 300, 300, 300);
    //     }
    // }, 500);

}
/**
 * block [TL_POINT, TR_POINT, BR_POINT, BL_POINT]
 * widget
 */
var linearWidget = function (ctx, widget, block) {
    var colorBlock = [
        platePoint(block[0], block[3]),
        platePoint(block[1], block[2]),
        block[2],
        block[3]
    ];

    var textPoint = centerPoint(colorBlock[0], colorBlock[1]);
    // ctx.font = "bold 16px Gotham-Bold";
    ctx.font = "bold " + getFontSize(14) + "px Gotham-Bold";
    ctx.fillStyle = "#143170";
    ctx.textAlign = "center";
    // ctx.fillText(widget.title, textPoint[0], textPoint[1]);
    ctx.fillText(widget.title, textPoint[0], textPoint[1] - 8);

    ctx.beginPath();
    ctx.lineWidth = 0;
    ctx.lineCap = 'round';
    ctx.moveTo(colorBlock[0][0], colorBlock[0][1]);
    for (var i = 1; i < block.length; i++) {
        ctx.lineTo(colorBlock[i][0], colorBlock[i][1]);
    }
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = widget.color ? widget.color : "red";
    ctx.fill();

    const image = new Image();
    image.src = '/script/gs1/pyramid/pyramid icons_Tekengebied 1-0' + widget.position + '.svg';
    image.onload = () => {
        var ph = Math.abs(colorBlock[0][1] - colorBlock[3][1])
        var phu = ph / 2;
        ph = ph + phu;
        ctx.drawImage(image, colorBlock[0][0], colorBlock[0][1] - phu, ph, ph);
    }

}
var centerPoint = function (a, b) {
    var x = (a[0] + b[0]) / 2;
    var y = (a[1] + b[1]) / 2;
    return [x, y];
}
var platePoint = function (a, b) {
    var dx = (a[0] - b[0]) / 7;
    var dy = (a[1] - b[1]) / 7;
    var x = ((a[0] + b[0]) / 2) - Math.abs(dx);
    var y = ((a[1] + b[1]) / 2) + Math.abs(dy);
    return [x, y];
}
var lineLineIntersection = function (A, B, C, D) {
    // Line AB represented as a1x + b1y = c1
    var a1 = B[1] - A[1];
    var b1 = A[0] - B[0];
    var c1 = a1 * (A[0]) + b1 * (A[1]);

    // Line CD represented as a2x + b2y = c2
    var a2 = D[1] - C[1];
    var b2 = C[0] - D[0];
    var c2 = a2 * (C[0]) + b2 * (C[1]);

    var determinant = a1 * b2 - a2 * b1;

    var x = (b2 * c1 - b1 * c2) / determinant;
    var y = (a1 * c2 - a2 * c1) / determinant;
    return [x, y];
}

document.addEventListener('readystatechange', event => {
    if (event.target.readyState === "complete") {
        init();
    }
})
window.addEventListener("orientationchange", function () {
    init();
}, false);

window.addEventListener('resize', function () {
    init();
});


console.log(menus);

export default init;
