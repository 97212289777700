import React from 'react'
import { Link } from 'react-router-dom'


export default function Sponsors({ img, description, title, link }) {
    return (
      <a target="_blank" href={link}>
        <div key={description} className="sponsors-box-wrap">
          <div className="sponsors-imageframe">
            <img src={img} alt="" className="img-fluid" />
          </div>
          <div className="sponsors-desc">
            <span>{description}</span>
          </div>
          <div className="title-label">
            <h6>{title}</h6>
            {link ? <div className="spnsr-link">
              <a href={link}>
                <i className="fa fa-link"></i>
              </a>
            </div>
            : ''}
          </div>
        </div>
      </a>
    );
}
