import { extend } from 'jquery';
import React, { Component } from 'react';
import DBNetwork from '../../../../../api/DBNetwork';
import { baseUrl } from '../../../../../constants/url/urlConstants';
import profilePlaceholder from '../../../../../assets/user/avatarplaceholder.png';
import { useContext } from "react";
import { Context as networkingToolContext } from "./../../../../../context/NetworkTollContext";
const Profile = (props) => {
	const {
		videoConnect,
		state: { details: { event, user } }

	} = useContext(networkingToolContext)
	/*
	constructor(props) {
		super(props);

	}

	connect = async () => {


		console.log("CLICK CONNECT");
		try {

			const url = baseUrl(`api/video-chat/global/${props.event.id}/${props.user.id}}`);
			const response = await DBNetwork.get(url);
			console.log("response");
			console.log(response);
			console.log(response.data.data.match_id);
			if (response.data.data.match_id) {
				await props.gotMatch(response.data.data.match_id);
				await props.acceptMatch();
				props.listenForVCEvents();
			}

		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	gotMatch = async (match_id) => {
		try {
			const url = baseUrl(`api/video-chat/match/${match_id}`);
			const response = await DBNetwork.get(url);
			props.setFOI(response.data.data.foi);
			props.setMatchId(match_id);
			props.listenForVCEvents();

			props.setMatchedUser(response.data.data.matched_users[0]);


		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	acceptMatch = async () => {
		try {
			const url = baseUrl(`api/video-chat/accept/${props.match_id}`);
			const response = await DBNetwork.get(url);


			props.setTwilioRoom(response.data.data.twilio_room);
			props.setTwilioToken(response.data.data.twilio_token);

			props.setActiveVideoChat(true);
			// setState({
			// 	accepted: true
			// });

			console.log("RESPONSE");
			console.log(response);

		} catch (error) {
			console.log("Error");
			console.error(error);
		}

	}
*/

	return (
		<div className="card profile-card-wrapper">
			<div className="card-header">
				<div className="postion-wrap">
					<p>{props.user.title}</p>
					<i className="fa fa-circle status-on"></i>
				</div>
			</div>
			<div className="card-body">
				<div className="employee-wrapper">
					<div className="employee-info">
						<div className="employee-details-wrap">
							<div className="working-place">
								<h5>{props.user.company}</h5>
							</div>
							<div className="employee-name">
								<h2>{props.user.name} {props.user.last_name}</h2>
							</div>
						</div>
						<div className="employee-image-wrap">
							<div className="emp-image-frame">
								<img src={props.user ? (props.user.avatar ? props.user.avatar : profilePlaceholder) : profilePlaceholder} className="img-fluid" alt="profilepicture"></img>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div className="card-footer">
				<div className="emp-contect-info">
					<div className="emp-social-wrap">
						{props.user.LinkedInURL &&
							<a target="_blank" href={props.user.LinkedInURL}><i className="fa fa-linkedin" aria-hidden="true"></i></a>}
						{props.user.TwitterURL &&
							<a target="_blank" href={props.user.TwitterURL}><i className="fa fa-twitter" aria-hidden="true"></i></a>}
					</div>
					<div className="emp-video-profile">
						<a href="#" onClick={() => videoConnect(event, user)}>
							<i className="fa fa-video" aria-hidden="true"></i>
							Connect
						</a>
						<a data-toggle="modal" data-target="#modalAppointment">
							<i className="fa fa-plus" aria-hidden="true"></i>
							Appointment
						</a>
					</div>
				</div>
			</div>
		</div>
	)

}


export default Profile;
