import React, { Component, useContext } from 'react';
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

import ProfileList from './ProfileList/ProfileList';

import "../style/css/GlobalChat.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { baseUrl } from '../../../../constants/url/urlConstants';

import DBNetwork from '../../../../api/DBNetwork';
import { Context as networkingToolContext } from '../../../../context/NetworkTollContext'


const GlobalChat = () => {
	/*
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			isLoading: true,
			current_page: 1,
			next_page: null,
			prev_page: null,
			current_letter: 'all',
		}
	}

	componentDidMount = () => {
		this.fetchOnlineUsers(current_letter, current_page);
	}

	load_nextPage = () => {
		if (next_page)
			this.fetchOnlineUsers(current_letter, next_page);
	}

	load_prevPage = () => {
		if (prev_page) {
			this.fetchOnlineUsers(current_letter, prev_page);
		}
	}

	fetchOnlineUsers = async (letter, page = 1) => {
		console.log(`Fetching for letter ${letter}`);

		this.setState({
			isLoading: true,
			current_letter: letter,
		});

		try {
			const url = baseUrl(`api/video-chat/users/${this.props.event.id}/${letter}?page=${page}`);
			const response = await DBNetwork.get(url);

			const next_page = response.data.data.current_page + 1 <= response.data.data.last_page ?
				response.data.data.current_page + 1 : null;
			const prev_page = response.data.data.current_page - 1 > 0 ?
				response.data.data.current_page - 1 : null;

			this.setState({
				current_page: response.data.data.current_page,
				users: response.data.data.data,
				next_page: next_page,
				prev_page: prev_page,
			});

		} catch (error) {
			console.log("ERROR");
		}


		this.setState({
			isLoading: false,
		});
	}
	*/

	const {
		fetchOnlineUsers,
		loadPreviousOnlineUsers,
		loadNextOnlineUsers,
		state: {
			details,
			onlineUsers
			// : {
			// 	isLoading,
			// 	prev_page,
			// 	next_page,
			// 	current_letter,
			// 	users
			// }
		}
	} = useContext(networkingToolContext);


	const alphabeth = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

	return (
		<>
			<section className="networking-wrapper">
				<div className="networking-section">
					<div className="container-fluid">
						<div className="heading-wrap">
							<span>Networking tools</span>
						</div>
						<div className="df-networking-wrap">
							<div className="networking-wrap">
								<div className="filter-pagination-wrap">
									<div className="filter-wrapper">
										<div className="filter-item-list">
											<a onClick={() => fetchOnlineUsers(details?.event, 'all')} className="filter-items">ALL</a>
											{alphabeth.map((item, index) => {
												return <a key={index} onClick={() => fetchOnlineUsers(details?.event, item)}
													className="filter-items">{item}</a>
											})}
										</div>
									</div>
								</div>

								{onlineUsers?.isLoading &&
									<div className="globalChatLoader">
										<FontAwesomeIcon size="5x" icon={faSpinner} pulse />
									</div>
								}

								{!onlineUsers?.isLoading &&
									<ProfileList />}

								{onlineUsers?.users.length == 0 && !onlineUsers.isLoading &&
									<div className="globalChatNoMatch">
										<h2 className="match-blue-text">No users online for {onlineUsers?.current_letter}</h2>
									</div>
								}

								<div className="filter-pagination-wrap">
									<div className="filter-wrapper">
										<div className="filter-item-list">
											{onlineUsers?.prev_page != null &&
												<a className="filter-items" onClick={loadPreviousOnlineUsers}>Previous Page</a>}
											{onlineUsers?.next_page !== null &&
												<a className="filter-items" onClick={loadNextOnlineUsers}>Next Page</a>}
										</div>
									</div>
								</div>


							</div>
							<div className="dft-calling-wrap">
								<div className="emp-calling">
									<h1 className="calling-title">calling...</h1>
									<a href="" className="hang-up-btn">
										<i className="fa fa-phone" aria-hidden="true"></i> Hang up
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="one_on_one_wrap">
				<div className="container-fluid">
					<div className="start_one_on_one paired-chat-warp">
						<div className="row">
							<div className="col-12 col-md-9 col-lg-10">
								<div className="player-chat-room">
									<div className="row">
										<div className="col-1 ">
											<div className="player1-chat chat-player host-player">
												<div className="player-imgframe">
													<img src="/WebbleUp_img/Networking/nt-10.png" className="img-fluid rounded-circle" alt="Player-Image" />
												</div>
												<div className="chat-player-name player1-name text-center pt-3">
													<h6 className="player_name">Wendy Janssens</h6>
													<p className="player_designation">Host</p>
												</div>
											</div>
										</div>
										<div className="col-6">
											<div className="video-chatting-wrap">
												<div className="video-chat-wrapper">
													<div className="host-video-wrap">
														<div className="video-responsive video-responsive-16by9">
															<img src="/WebbleUp_img/Networking/nt-10.png" className="img-fluid video-responsive-item video-big-imgframe" alt="" />
														</div>
													</div>
													<div className="host-partner-video-wrap">
														<img src="/WebbleUp_img/Networking/nt-9.png" className="img-fluid video-small-imgframe" alt="" />
													</div>
													{/* <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen=""></iframe>
                                                    </div> */}
												</div>
											</div>
											<div className="video-actions-wrapper d-flex align-items-center justify-content-center">
												<div className="video-action-wrap">
													<div className="video-option-icon">
														<i className="fa fa-video-camera" aria-hidden="true"></i>
													</div>
													<div className="custom-control custom-switch dft-custom-switch">
														<input type="checkbox" className="custom-control-input" id="customSwitch1" />
														<label className="custom-control-label" htmlFor="customSwitch1"></label>
													</div>
												</div>
												<div className="video-action-wrap">
													<div className="video-option-icon">
														<i className="fa fa-microphone" aria-hidden="true"></i>
													</div>
													<div className="custom-control custom-switch dft-custom-switch">
														<input type="checkbox" className="custom-control-input" id="customSwitch2" />
														<label className="custom-control-label" htmlFor="customSwitch2"></label>
													</div>
												</div>
												<div className="video-action-wrap">
													<div className="video-disconnet-btn">
														<a href="#" className="video-disconnet-btn">
															<i className="fa fa-phone" aria-hidden="true"></i>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div className="player-chat-wrap chat-modal">
												<div className="oneto1-player-chat">
													<div className="card box-wrap-card">
														<div className="card-header border-0">
															<div className="input-group">
																<input type="text" placeholder="Antwoord" required="required" className="form-control fix-rounded-right" />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<a type="button">
																			<i aria-hidden="true" className="fa fa-search"></i>
																		</a>
																	</span>
																</div>
															</div>
														</div>
														<div className="card-body dft-card-body-scrollbar p-0 m-3">
															<div className="direct-chat-messages">
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">08:33</span>
																		<span className="direct-chat-name">MichaelSteurs</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			ok
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">08:33</span>
																		<span className="direct-chat-name">MichaelSteurs</span>
																	</div>
																</div>
																<div className="direct-chat-msg right">
																	<div className="direct-chat-text">
																		<span>
																			testing vinod
																		</span>
																	</div>
																	<div className="direct-chat-infos">
																		<span className="direct-chat-timestamp">08:42</span>
																		<span className="direct-chat-name">You</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test Dimi
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:54</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test fallback or not
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:55</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test 2
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:55</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="card-footer border-0">
															<div className="input-group mb-2">
																<input type="text" placeholder="Antwoord" required="required" className="form-control fix-rounded-right" />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<a type="button">
																			<i aria-hidden="true" className="fa fa-paper-plane-o"></i>
																		</a>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-1">
											<div className="player2-chat chat-player">
												<div className="player-imgframe">
													<img src="/WebbleUp_img/Networking/nt-9.png" className="img-fluid rounded-circle" alt="Player-Image" />
												</div>
												<div className="chat-player-name player2-name text-center pt-3">
													<h6 className="player_name">Peter Pan (Me)</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-3 col-lg-2">
								<div className="dft-appointment-wrapper">
									<div className="dft-appointment-listing">
										<div className="dft-appoints-wrap">
											<div className="dft-appoint-client-wrap">
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Multiple screen */}
			<section className="one_on_one_wrap">
				<div className="container-fluid">
					<div className="start_one_on_one paired-chat-warp">
						<div className="row">
							<div className="col-12 col-md-9 col-lg-10">
								<div className="player-chat-room">
									<div className="row">
										<div className="col-8">
											<div className="video-chatting-wrap">
												<div className="video-chat-wrapper">
													<div className="multiple-video-chat-room">
														<div className="multiple-video multiple-grid">
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-1.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-2.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-3.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-4.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-5.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-6.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-7.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-8.png" alt="" className="img-fluid" />
																</Link>
															</div>
															<div className="multiple-video-items multiple-grid-items">
																<Link to="#" className="multiple-host">
																	<img src="/WebbleUp_img/Networking/nt-9.png" alt="" className="img-fluid" />
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="video-actions-wrapper d-flex align-items-center justify-content-center">
												<div className="video-action-wrap">
													<div className="video-option-icon">
														<i className="fa fa-video-camera" aria-hidden="true"></i>
													</div>
													<div className="custom-control custom-switch dft-custom-switch">
														<input type="checkbox" className="custom-control-input" id="customSwitch3" />
														<label className="custom-control-label" htmlFor="customSwitch3"></label>
													</div>
												</div>
												<div className="video-action-wrap">
													<div className="video-option-icon">
														<i className="fa fa-microphone" aria-hidden="true"></i>
													</div>
													<div className="custom-control custom-switch dft-custom-switch">
														<input type="checkbox" className="custom-control-input" id="customSwitch4" />
														<label className="custom-control-label" htmlFor="customSwitch4"></label>
													</div>
												</div>
												<div className="video-action-wrap">
													<div className="video-disconnet-btn">
														<a href="#" className="video-disconnet-btn">
															<i className="fa fa-phone" aria-hidden="true"></i>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div className="player-chat-wrap chat-modal">
												<div className="oneto1-player-chat">
													<div className="card box-wrap-card">
														<div className="card-header border-0">
															<div className="input-group">
																<input type="text" placeholder="Antwoord" required="required" className="form-control fix-rounded-right" />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<a type="button">
																			<i aria-hidden="true" className="fa fa-search"></i>
																		</a>
																	</span>
																</div>
															</div>
														</div>
														<div className="card-body dft-card-body-scrollbar p-0 m-3">
															<div className="direct-chat-messages">
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">08:33</span>
																		<span className="direct-chat-name">MichaelSteurs</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			ok
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">08:33</span>
																		<span className="direct-chat-name">MichaelSteurs</span>
																	</div>
																</div>
																<div className="direct-chat-msg right">
																	<div className="direct-chat-text">
																		<span>
																			testing vinod
																		</span>
																	</div>
																	<div className="direct-chat-infos">
																		<span className="direct-chat-timestamp">08:42</span>
																		<span className="direct-chat-name">You</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test Dimi
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:54</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test fallback or not
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:55</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
																<div className="direct-chat-msg">
																	<div className="direct-chat-text">
																		<span>
																			test 2
																		</span>
																	</div>
																	<div className="direct-chat-infos clearfix">
																		<span className="direct-chat-timestamp">13:55</span>
																		<span className="direct-chat-name">ArdMer</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="card-footer border-0">
															<div className="input-group mb-2">
																<input type="text" placeholder="Antwoord" required="required" className="form-control fix-rounded-right" />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<a type="button">
																			<i aria-hidden="true" className="fa fa-paper-plane-o"></i>
																		</a>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-3 col-lg-2">
								<div className="dft-appointment-wrapper">
									<div className="dft-appointment-listing">
										<div className="dft-appoints-wrap">
											<div className="dft-appoint-client-wrap">
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Multiple screen */}
			<section className="one_on_one_wrap">
				<div className="container-fluid">
					<div className="start_one_on_one paired-chat-warp">
						<div className="row">
							<div className="col-12 col-md-9 col-lg-10">
								<div className="player-chat-room">
									<div className="row">
										<div className="col-1 ">
											<div className="player1-chat chat-player host-player">
												<div className="player-imgframe">
													<img src="/WebbleUp_img/Networking/nt-10.png" className="img-fluid rounded-circle" alt="Player-Image" />
												</div>
												<div className="chat-player-name player1-name text-center pt-3">
													<h6 className="player_name">Wendy Janssens</h6>
													<p className="player_designation">Host</p>
												</div>
											</div>
										</div>
										<div className="col-11">
											<div className="meeting-welcome-note">
												<div className="meeting-welcome-wrap">
													<div className="meeting-start-soon">
														<h5>Please wait for connection, this meeting will start soon</h5>
														<h5>Start: 2:00 pm</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-3 col-lg-2">
								<div className="dft-appointment-wrapper">
									<div className="dft-appointment-listing">
										<div className="dft-appoints-wrap">
											<div className="dft-appoint-client-wrap">
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
												<div className="dft-client-info-wrap">
													<div className="dft-client-img-wrap">
														<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
													</div>
													<div className="dft-client-details-wrap">
														<h3>Josy jones</h3>
														<div className="dft-appoint-action-wrap">
															<a href="#">
																<i className="fa fa-linkedin-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-twitter-square" aria-hidden="true"></i>
															</a>
															<a href="#">
																<i className="fa fa-facebook-square" aria-hidden="true"></i>
															</a>
															<a className="btn btn-join" href="#">
																<i className="fa fa-plus" aria-hidden="true"></i>add
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default GlobalChat;
