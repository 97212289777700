import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PusherWrapper from './PusherWrapper';

class Socket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pusher: null
        }

    }
    componentWillUnmount() {
        if (this.props.list?.length > 0) {
            PusherWrapper.sharedInstance().removeChannels(this.props.list);
        }
    }
    componentDidMount() {

        const { id } = this.props;
        console.log(this.props.channel + id)

        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
            pusher.log = (msg) => {
                console.log("Pusher[log] " + msg);
            };
            const channel = pusher.subscribe(this.props.channel + id);
            channel.bind(this.props.event, data => {
                console.log(data);
                if (!process.env.production) {
                    // console.log("HappeningNowSocket[componentDidMount > ChannelUpdateEvent] " + JSON.stringify(data));
                }
                this.props.onSocketUpdate(data);
            });
        }
    }
    render() {
        return (
            <div>

            </div>
        );
    }
}



export default Socket;