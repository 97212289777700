import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import telent from "../../../../assets/images/telenet_owler.png";
const TelnetHeader = ({ logo, title }) => {
  useEffect(() => {
    if (title) document.title = title;
    else document.title = "Webble Up ";
  }, [title]);
  return (
    <section className="upcoming-top-bar">
      <div className="container-fluid">
        <div className="upcmng-top-bar">
          <div className="upcoming-logo-wrapper">
            <img src={logo} alt="" className="img-fluid" />
            <h1>{title}</h1>
          </div>
          <div className="upcmng-admin-wrap">
            <a
              target="_blank"
              href="https://admin.Webble-Up.com/admin/login"
              className="btn cmn-bg-color"
            >
              <i className="fa fa-sign-out" aria-hidden="true"></i>Admin Login
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TelnetHeader;
