import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import DBNetwork from '../../api/DBNetwork';
import { baseUrl } from '../../constants/url/urlConstants';
import { formReducer, FORM_INPUT_UPDATE, RESET_STATE } from '../../hooks/TextInputReducer';
import TextComponent from '../../UIComponets/TextComponent';
import toastProp from '../../data/toastProp';


class ContactUsC extends Component {
    constructor() {
        super();
        this.state = {
            contactUs: {
                company_name: '',
                first_name: '',
                email: '',
                message: '',
                last_name: '',
            },
        };
    }

    onContactUs = async (e) => {
        e.preventDefault();
        const url = baseUrl('api/contact');
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.contactUs.email !== '') {
            if (this.state.contactUs.first_name !== '') {
                if (this.state.contactUs.message !== '') {
                    const response = await DBNetwork.post(url, { ... this.state.contactUs });
                    let contactUs = {
                        company_name: '',
                        first_name: '',
                        email: '',
                        message: '',
                        last_name: '',
                    }
                    if (response.data.status) {
                        toast.success(response.data.message, toastProp);
                        this.setState((prevState, props) => ({
                            ...prevState, contactUs,
                        }));
                    }
                } else {
                    toast.error(this.props.selectedLanguage === 'nl' ? 'bericht vereist' : 'Message Required', toastProp);
                }
            } else {
                toast.error(this.props.selectedLanguage === 'nl' ? 'Naam (vereist)' : 'Name Required', toastProp);
            }
        }
        else {
            toast.error(this.props.selectedLanguage === 'nl' ? 'Email(verplicht' : 'Email required', toastProp);
        }
    }
    textChangeHandler = (e, type) => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passRegex = /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,15})$/
        let isValid = true;

        if (type = "required" && e.target.value.trim().length === 0) {
            isValid = false;
        }
        if (type = 'email') {
            isValid = false;
        }

        const { name, value } = e.target;
        let contact_us = { ...this.state.contactUs };
        contact_us[name] = value;


        this.setState((prevState, props) => ({
            ...prevState, contactUs: contact_us, isValid
        }));
    };
    render() {
        return <section className="wb-contact-wrapper" id="Contact_Us">
            <div className="wp-footer-bg">
                <img src="script/LandingPage/images/footer-bg.png" alt="" />
            </div>
            <div className="container-fluid">
                <div className="wb-evnt-heading wb-evnt-heading-left">
                    <div className="wb-title-before"></div>
                    <h5>Contact us</h5>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mr-auto">
                        <div className="wb-contact-form-wrap">
                            <form action="" onSubmit={this.onContactUs}>
                                <div className="form-group">
                                    <input name={"company_name"}
                                        value={this.state.contactUs.company_name}
                                        onChange={(e) => this.textChangeHandler(e, 'required')}
                                        type="text" className="form-control"
                                        placeholder={this.props.selectedLanguage === 'nl' ? 'Bedrijfsnaam' : "Company Name"} />
                                </div>
                                <div className="form-group">
                                    <input name={"first_name"}
                                        value={this.state.contactUs.first_name}
                                        onChange={(e) => this.textChangeHandler(e, 'required')}
                                        type="text" className="form-control"
                                        placeholder={this.props.selectedLanguage === 'nl' ? 'naam' : "Name"} />
                                </div>
                                <div className="form-group">
                                    <input onChange={(e) => this.textChangeHandler(e, 'required')}
                                        name={"email"}
                                        type="text"
                                        className="form-control"
                                        value={this.state.contactUs.email}
                                        placeholder={this.props.selectedLanguage === 'nl' ? 'E-mailadres' : "email Address"} />
                                </div>
                                <div className="form-group">
                                    <textarea onChange={(e) => this.textChangeHandler(e)}
                                        type="text"
                                        name={"message"}
                                        className="form-control"
                                        value={this.state.contactUs.message}
                                        placeholder={this.props.selectedLanguage === 'nl' ? "Boodschap" : "Message"} ></textarea>
                                </div>



                                <div className="wb-btn-wrap wb-submit-wrap">
                                    <div className="wb-btn-group">
                                        <Link onClick={this.onContactUs} to="#"
                                            type="submit"
                                            className={`btn animated-button wb-btn `}>{this.props.selectedLanguage === 'nl' ? 'verstuur' : 'Send'}</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="wb-footer-wrapper">
                <div className="container-fluid">
                    <div className="wb-footer-credit">
                        <h6 className="wb-grn-txt">copyright &copy; DB Video</h6>
                    </div>
                </div>
            </footer>
        </section>
    }
}
export default ContactUsC;