import React, { useState } from "react";
import { Link } from "react-router-dom";

const ChatThumb = ({ onPinChat, linkClassName, isThumbLoding }) => {
  const [className, setClassName] = useState("");
  const pinChat = () => {
    onPinChat();
    setTimeout(() => {
      setClassName("small-iframe");
    }, 200);
  };
  return (
    <>
      {isThumbLoding ? (
        <Link to="#">
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </Link>
      ) : (
        <div onClick={pinChat}>
          <Link className={className} data-target={linkClassName} to="#">
            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
          </Link>
        </div>
      )}
    </>
  );
};

export default ChatThumb;
