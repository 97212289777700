
export const baseUrl = url => `${process.env.REACT_APP_API_HOST}/` + url;
export const imageBaseUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}/`
export const imageBaseUrlForCanvas = `${process.env.REACT_APP_IMAGE_BASE_URL}`
export const imageWithStorage = `${process.env.REACT_APP_IMAGE_WITH_STORAGE}/`




// export const ADD_SESSION_LINK = `${process.env.ADD_SESSION_LINK}`
export const ADD_SESSION_LINK = 'https://web.cvent.com/event/1a19c141-8b27-4345-80fc-0b73cde42ea4/'
