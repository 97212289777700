import React from 'react';
import { Line } from 'react-chartjs-2';

const state = {
    labels: ['January', 'February', 'March',
        'April', 'May'],
    datasets: [
        {

            label: 'View',
            backgroundColor: 'transparent',
            borderColor: '#00A65A',
            pointColor: '#00A65A',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: '#00A65A',
            data: [50, 55, 100, 130, 140, 90],
            fillColor: "#fff",
            pointStrokeColor: "#fff",
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 2
        },
        {
            label: 'Interactions',
            backgroundColor: 'transparent',
            borderColor: '#3C8DBC',
            pointColor: '#3C8DBC',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: '#3C8DBC',
            data: [90, 65, 90, 190, 120, 100],
            fillColor: "#fff",
            pointStrokeColor: "#fff",
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 2

        },
    ]
}




const Chart = props => {
    return (
        <div>
            <Line
                data={state}
                options={{
                    title: {
                        display: true,
                        text: 'Users Activity',
                        fontSize: 20

                    },
                    legend: {
                        display: true,
                        position: 'right'
                    }
                }}
            />
        </div>
    )
}

export default Chart;