import React, { Component, useContext } from 'react';
import { Item } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { Context as networkingToolContext } from '../../../../../context/NetworkTollContext'


import Profile from './Profile';

const ProfileList = (props) => {

	const {
		state: {
			onlineUsers
		}
	} = useContext(networkingToolContext);

	if (!onlineUsers?.users) {
		return <></>;
	}
	return (
		<div className="profile-card-wrap">
			<div className="networking-card-wrap">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-3">
						<div className="dft-appointment-wrapper">
							<div className="dft-appointment-heading">
								<h5>My Appointments</h5>
							</div>
							<div className="dft-appointment-listing">
								<div className="dft-appoints-wrap">
									<div className="dft-appoint-date-wrap">
										<h5>
											<span className="dft-week-day">Tue</span> <span className="dft-date-day">15/04/21</span>
										</h5>
									</div>
									<div className="dft-appoint-client-wrap">
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="dft-appoints-wrap">
									<div className="dft-appoint-date-wrap">
										<h5><span className="dft-week-day">Tue</span> <span className="dft-date-day">15/04/21</span></h5>
									</div>
									<div className="dft-appoint-client-wrap">
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-1.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-2.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
										<div className="dft-client-info-wrap">
											<div className="dft-client-img-wrap">
												<img src="../../WebbleUp_img/Networking/ap-3.png" alt="" className="img-fluid" />
											</div>
											<div className="dft-client-details-wrap">
												<h3>Josy jones</h3>
												<div className="dft-appoint-action-wrap">
													<Link to="#"><i className="fa fa-linkedin-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></Link>
													<Link to="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></Link>
													<Link to="#" className="btn btn-join"><i className="fa fa-plus" aria-hidden="true"></i>Connect</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-12 col-md-12 col-lg-9">
						<div className="networking-cards-list scrollbar">
							{onlineUsers?.users?.map((item, index) => {
								return (
									<>
										<Profile
											key={index}
											user={item}
										/>
									</>



								)

							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default ProfileList;