import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NotificationCenter from '../../../hooks/NotificationCenter';
import UserProfileImage from '../../components/defaultLayout/UserProfileImage';
import { CardProfile } from './CardProfile';
import {Context as networkingToolContext, updateAppointment} from '../../../context/NetworkTollContext';
import { useToasts } from "react-toast-notifications";

import { ErrorOption, SuccessOption } from '../../../Default/components/ToastOptions'
import {OptionConstants} from "../../../constants/Constants";
import {Context as defaultContext} from "../../../context/DefaultSectionContext";
import Img from "../../components/defaultLayout/Img";
import placeholder from "../../../assets/user/avatarplaceholder.png";
import { DatePicker } from 'antd';
import '../../../../node_modules/antd/dist/antd.css';

import moment from 'moment-timezone';
moment.tz.setDefault("Europe/Brussels");

export const AppointmentModal = () => {

  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointment, setAppointment] = useState(null)

  const {
    state: {
      eventsPageData: { page_data },
    },
  } = useContext(defaultContext);

  const {
    makeAppointment,
    updateAppointment
  } = useContext(networkingToolContext)

  useEffect(() => {
    NotificationCenter.sharedInstance().registerNotification("setAppointmentUserDetails", (details) => {
      setUserDetails(details);
      setAppointment(null)
    });
    NotificationCenter.sharedInstance().registerNotification("setUpdateAppointmentUserDetails", ({userDetails, appointment}) => {
      setUserDetails(userDetails);
      setAppointment(appointment)
      // console.log(moment(appointment.date).format('YYYY-MM-DDTHH:MM'))
    });

  }, [])

  useEffect(() => {
    setAppointmentDate("");
    setIsLoading(false)
  }, [userDetails])
  const selectDateTime = (value, dateString) => {
    //e.preventDefault();
    setAppointmentDate(moment(dateString));
  }
  const makeAppointmentRequest = (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (!userDetails.user?.id) {
      addToast("User ID Required!", ErrorOption)
      return;
    }

    if (!appointmentDate) {
      addToast("Select Appointment Date!", ErrorOption)
      return;
    }

    if (!userDetails?.event?.id) {
      addToast("Event ID Required!", ErrorOption)
      return;
    }
    if (appointment && !appointment?.id){
      addToast("Appointment ID Required!", ErrorOption)
      return;
    }

    let data = { user_id: userDetails.user?.id, date: moment(appointmentDate).format("YYYY-MM-DD HH:mm:ss"), event_id: userDetails?.event?.id}
    if (appointment){
      data.id = appointment.id;
      data.status = OptionConstants.PENDING;
    }
    setIsLoading(true)
    const method = appointment  ? updateAppointment : makeAppointment;

    method(data, (response, error) => {
      if (error) {
        addToast(error.response?.data?.message, ErrorOption)
      } else {
        window.$("#appointmentModal").modal("hide");
        addToast("Appointment Added Successfully", SuccessOption)
      }
      setIsLoading(false)
    })
  }


  return (
    <div
      className="modal dft-modal fade fade-scale show"
      id="appointmentModal"
      tabIndex="-1"
      aria-labelledby="modalAppointmentLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content dft-modal-content">
          <div className="modal-header dft-modal-header">
            <h2 className="dft-modal-title text-uppercase">
              { appointment ? "Update Appointment" : "Make Appointment"}
            </h2>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">
                <i className="material-icons">close</i>
              </span>
            </button>
          </div>
          <div className="modal-body dft-modal-body">
            <div className="dft-appoint-wrap">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="dft-appointment-card">
                    <div className="card profile-card-wrapper">
                      <div className="card-header">
                        <div className="dft-position-wrap">
                          <p className="cmpny-function">{userDetails?.user?.info?.function}</p>
                          <p className="cmpny-name">{userDetails?.user?.info?.company}</p>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="dft-employee-wrapper">
                          <div className="dft-employee-info">
                            <div className="employee-details-wrap">
                              <div className="employee-name">
                                <h2>{`${userDetails?.user?.name ?? ""} ${userDetails?.user?.last_name ?? ""}`.capitalize()}</h2>
                                {Boolean.parse(page_data?.networking_tool?.config?.nt_show_email ?? "false")  && <p>
                                 {`${userDetails?.user?.email ?? ""}`}
                                </p>}
                              </div>
                            </div>
                            <div className="employee-image-wrap">
                              { Boolean.parse(page_data?.networking_tool?.config?.nt_show_avatar ?? "false") && <div className="employee-image-wrap">
                                <div className="emp-image-frame">
                                  <UserProfileImage src={userDetails?.user?.avatar} className="img-fluid" />
                                </div>
                              </div> }
                            </div>
                          </div>
                        </div>
                      </div>
                      { Boolean.parse(page_data?.networking_tool?.config?.nt_show_social_links ?? "false") && <div className="card-footer">
                        <div className="dft-emp-contect-info">
                          <div className="emp-social-wrap">
                            {userDetails?.user?.info?.linkedin_link && (
                                <a href={userDetails?.info?.linkedin_link}>
                                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                </a>
                            )}
                            {userDetails?.user?.info?.twitter_link && (
                                <a to={userDetails?.info?.twitter_link}>
                                  <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                </a>
                            )}
                            {userDetails?.user?.info?.facebook_link && (
                                <a to={userDetails?.info?.facebook_link}>
                                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a>
                            )}
                          </div>
                        </div>
                      </div> }
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="dft-appointment-time-wrap">
                    <form className="appointment-form">
                      <div className="form-group">
                        <label htmlFor="appointmentDateTime">
                          date + time
                        </label>
                        <DatePicker
                            className="form-control" 
                            id="appointmentDateTime"  
                            showTime
                            defaultValue={moment()}
                            onChange={selectDateTime} 
                            value={appointmentDate}
                        />
                        {/* <input
                          type="datetime-local"
                          className="form-control test2"
                          id="appointmentDateTime"
                          onChange={selectDateTime}
                          value={appointmentDate}
                        /> */}
                      </div>
                      <div className="form-group mt-5 mb-0">

                        <button disable={isLoading} className="btn dft-btn-black" onClick={makeAppointmentRequest}>

                          {!isLoading ? "Send invite" : <i
                            aria-hidden="true"
                            className={`fa fa-spinner fa-spin`}
                          ></i>}
                        </button>

                      </div>
                      <div className="form-group">
                        <div className="dft-success-message">
                          {/* <h4>Your invitation is successfully sent</h4> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
