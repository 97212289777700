import React from 'react'
import Speaker from './Speaker'


export default function SpeakersList({ speakers }) {
    let speakerList;

    if (speakers?.length > 0) {
        speakerList = speakers?.map((speaker, index) => {
            return <Speaker key={index} speaker={speaker} />
        })
    }

    return (
        <div data-hash='#speakers'
            className="modal gs-modal fade fade-scale"
            id="speakers_modal"
            tabIndex="-1"
            aria-labelledby="modalSpeakersLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header">
                        <div className="page-heading">
                            <h4 className="modal-title page-title">Speakers</h4>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true"><i className="material-icons">close</i></span>
                        </button>
                    </div>
                    <div className="modal-body gs-modal-body">
                        <div className="speakers-wrapper">
                            <div className="speakers-box-wrapper">
                                {speakerList}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
