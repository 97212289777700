import React, { Component } from 'react';
import PusherWrapper from '../../../../hooks/PusherWrapper';

class DefaultSectionSocket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pusher: null
        }
    }
    componentWillUnmount() {
        if (this.props.eventId) {
            const id = this.props.eventId;
            const list = ['ChannelUpdate.' + id];
            PusherWrapper.sharedInstance().removeChannels(list);
        }
    }


    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
            pusher.log = (msg) => {
                console.log("Pusher[log] " + msg);
            };
            //  console.log('eventID'+ this.props.eventId);
            //  pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe('ChannelUpdate.' + this.props.eventId);
            channel.bind("App\\Events\\ChannelUpdateEvent", data => {
                console.log(data);
                if (!process.env.production) {
                    // console.log("HappeningNowSocket[componentDidMount > ChannelUpdateEvent] " + JSON.stringify(data));
                }
                this.props.channel(data);
            });
        }
    }
    render() {
        // console.log("eventID" + this.props.eventId);
        return <> </>
    }
}
export default DefaultSectionSocket;