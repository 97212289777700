import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context as networkingToolContext } from './../../../context/NetworkTollContext';
import { Context as defaultContext } from './../../../context/DefaultSectionContext';
import ChatMessage from './Chat/ChatMessage';
import { useToasts } from "react-toast-notifications";
import NotificationCenter from '../../../hooks/NotificationCenter';

const options = {
  position: 'top-right',
  style: {
    backgroundColor: 'midnightblue',
    border: '2px solid lightgreen',
    color: 'lightblue',
    fontFamily: 'Menlo, monospace',
    fontSize: '20px',
    textAlign: 'center',
  },
  closeStyle: {
    color: 'lightcoral',
    fontSize: '16px',
  },
}

export const PlayerChat = () => {
  const {
    sendChat,

    state: {
      videoChat,
      chatMessages
    }
  } = useContext(networkingToolContext);

  const {
    state: {
      eventsPageData: { translations }
    },
  } = useContext(defaultContext);

  const [searchText, setSearchText] = useState("");
  const [chatMessage, setChatMessage] = useState("");
  const [isMessageSending, setIsMessageSending] = useState(false);
  const { addToast } = useToasts();
  const chatContainer = useRef(null);
  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight -
      chatContainer.current.clientHeight;
    chatContainer.current.scrollTo(0, scroll);
  };

  const onChatInputChange = e => {
    e.preventDefault();
    const message = e.target.value
    setChatMessage(message);
  }
  const onMessageSend = () => {
    setIsMessageSending(true)
    sendChat({
      match_id: videoChat?.match_id,
      message: chatMessage,
    }, (response, error) => {
      if (error) {
        addToast(error.message, {
          appearance: "error",
          autoDismiss: true,
        })
      } else {
        setChatMessage("")
        scrollToMyRef();
      }
      setIsMessageSending(false)
    })
  }

  const chatTranslation = translations?.chat;

  useEffect(() => {

    NotificationCenter.sharedInstance().registerNotification("receivedMessages", (msg) => {
      scrollToMyRef()
    })

  }, [])

  return (
    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
      <div className="player-chat-wrap chat-modal">
        <div className="oneto1-player-chat">
          <div className="card box-wrap-card">
            <div className="card-header border-0">
              <div className="input-group">
                <input
                  type="text"
                  placeholder={chatTranslation?.search_placeholder}
                  required="required"
                  className="form-control fix-rounded-right"
                  onChange={(e) => setSearchText(e.currentTarget.value)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <a type="button">
                      <i aria-hidden="true" className="fa fa-search"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="card-body dft-card-body-scrollbar p-0 m-3" ref={chatContainer} >
              <div className="direct-chat-messages" >
                {chatMessages?.filter((msg) => {
                  if (searchText.length > 0) {
                    return msg.message?.includes(searchText);
                  }
                  return true;
                }).map((m) => <ChatMessage {...m} />)}
              </div>
            </div>
            <div className="card-footer border-0">
              <div className="input-group mb-2">
                <input
                  disabled={isMessageSending}
                  type="text"
                  placeholder={chatTranslation?.answer_placeholder}
                  required="required"
                  className="form-control fix-rounded-right"
                  value={chatMessage}
                  onChange={(e) => onChatInputChange(e)}
                  onKeyPress={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                      // Do code here
                      ev.preventDefault();
                      onMessageSend()
                    }
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <a type="button" onClick={(e) => onMessageSend()}  >
                      <i
                        aria-hidden="true"
                        className={`fa ${isMessageSending ? "fa-spinner fa-spin" : "fa-paper-plane-o"}`}
                      ></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
