import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import useStyle from '../../hooks/cssLoad';
import { formReducer, FORM_INPUT_UPDATE } from '../../hooks/TextInputReducer';
import TextComponent from '../../UIComponets/TextComponent';
import placeholder from '../../assets/user/avatarplaceholder.png';
import toast from 'react-hot-toast';
import { Context as AuthContext } from '../../context/AuthContext';
import Location from '../../hooks/Location';
import LoaderDefaultSection from '../../UIComponets/LoaderDefaultSection';
import { imageWithStorage } from '../../constants/url/urlConstants';
import ErrorPageDefault from '../../Route/ErrorPageDefault';
import { useHistory } from 'react-router-dom';
const toastProp = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
        border: '1px solid #713200',
        padding: '16px',
        zIndex: 1,
        color: '#713200',
    }
}


const ResetPassword = () => {
    useStyle('/script/default/defaultStyle.css');
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [_, token] = Location();
    const [isLoading, setIsLoading] = useState(false);
    // const [userToken, setUserToken] = useState(null);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPasswordMatched, setIsPasswordMatched] = useState(false);
    // const [token, setToken] = useState(null);
    const { resetPassword, validateToken,
        state: { resetPasswordData: { status, data, message } } } = useContext(AuthContext);
    const url = `api/reset-password/${token}`;

    const validToken = async () => {
        setIsLoading(true);
        try {
            await validateToken(url, response => {
                if (response.status) {
                    // setUserToken(token);
                }
            });
            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        validToken();
    }, []);



    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            password: '',
            confirm_password: '',
        },
        inputValidities: {
            password: false,
            confirm_password: false,
        },
        formIsValid: false
    });
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue, inputValidity) => {
            dispatchFormState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                isValid: inputValidity,
                input: inputIdentifier
            });
        },
        [dispatchFormState]
    );
    const toShowPassword = useCallback((show, type) => {
        if (type === 'password') setShowPassword(show);
        else if (type === 'confirmPassword') setShowConfirmPassword(show)
    }, [setShowPassword, setShowConfirmPassword]);




    const onResetPassword = (e) => {
        e.preventDefault();
        if (formState.inputValues.password !== formState.inputValues.confirm_password) {
            setIsPasswordMatched(true);
            toast.error('password does not match', toastProp);
        } else {
            setIsLoading(true);
            setIsPasswordMatched(false);
            const url = `api/reset-password/${token}`
            try {
                resetPassword(url, { ...formState.inputValues }, (response) => {
                    if (response?.status) {
                        setIsLoading(false)
                        toast.success(response?.message, toastProp);
                        history.push(`/`)
                    } else {
                        setIsLoading(false)
                        toast.success(response?.message, toastProp);
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }
        // const url = `api/reset-password/${token}`;

    }

    if (isLoading) return <LoaderDefaultSection />
    if (!status) return < ErrorPageDefault />
    return (
        <section className="login-fixed-wrapper">
            <div className="container">
                <div className="login-logo-top">
                    <img src="" alt="" className="img-fluid" />
                </div>
                <div className="login-box register-box reset-box">
                    <div className="login-form-wrap vw-form-wrap">
                        <form method="POST" className="login-form">
                            <input type="hidden" name="token" value="{{ $token }}" />
                            <div className="row text-center">
                                <div className="col-12 col-md-4">
                                    <div className="form-logo-wrap reset-logo-wrap">
                                        <div className="logo-img-frame">
                                            <img src={imageWithStorage + data?.user?.avatar}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = placeholder
                                                }} className="img-fluid" alt="" />
                                            <div className="user-email-reset">
                                                <span>{data?.user?.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <h1 className="text-white reset-title">Reset Password</h1>
                                    {/* <div className="alert alert-success"></div> */}
                                    {isPasswordMatched && <div className="bg-danger text-white py-2 px-4 m-3">
                                        <i className="fa fa-times" aria-hidden="true">
                                        </i>&nbsp;
                                       Password does not matched.
                                    </div>
                                    }
                                    <TextComponent
                                        id='password'
                                        password
                                        errorMessageContainerStyle='errorMessageContainerStyle'
                                        required
                                        resetPassword
                                        minLength={6}
                                        inputType='password'
                                        errorText="Password Must Contains 6 characters."
                                        onInputChange={inputChangeHandler}
                                        placeholder='Password (Required)*'
                                        inputType={showPassword ? 'text' : 'password'}
                                        spanId="inputPassword"
                                        faIcon='fa fa-lock'
                                        areaHidden={"true"}
                                        errorText="Password Must Contains 6 characters."
                                        faEyeContainer="input-group-append"
                                        faEyeSpanClassName="input-group-text db-background"
                                        faEyeAriaHidden={'true'}
                                        faEyeIcon={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}
                                        passwordType='password'
                                        // onEyeClick={ }
                                        toShowPassword={toShowPassword}
                                        onInputChange={inputChangeHandler}
                                    />
                                    <TextComponent
                                        id='confirm_password'
                                        password
                                        errorMessageContainerStyle='errorMessageContainerStyle'
                                        required
                                        minLength={6}
                                        resetPassword
                                        inputType='password'
                                        errorText="Password Must Contains 6 characters."
                                        onInputChange={inputChangeHandler}
                                        placeholder='Confirm Password (Required)*'
                                        inputType={showConfirmPassword ? 'text' : 'password'}
                                        spanId="inputPassword"
                                        faIcon='fa fa-lock'
                                        areaHidden={"true"}
                                        errorText="Password Must Contains 6 characters."
                                        faEyeContainer="input-group-append"
                                        faEyeSpanClassName="input-group-text db-background"
                                        faEyeAriaHidden={'true'}
                                        faEyeIcon={showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}
                                        passwordType='confirmPassword'
                                        // onEyeClick={ }
                                        toShowPassword={toShowPassword}
                                        onInputChange={inputChangeHandler}
                                    />

                                    <button
                                        disabled={!formState.formIsValid}
                                        onClick={onResetPassword}
                                        className="btn btn-block mt-5 vw-btn reset-btn vw-btn-yellow mb-3 db-background">
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ResetPassword;