import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../constants/url/urlConstants';
import { Context as GS1Context } from '../../../../context/GS1Context';
import Channel from '../../../../Default/components/defaultLayout/body/channel/Channel';
import Loader from '../../../../UIComponets/Loader';
import Channels from './Components/Channels';
import Wrapper from '../Wrapper/Wrapper'
import HappeningEventDetails from './HappeningEvent/HappeningEventDetails';
import HappeningEventHeader from './HappeningEvent/HappeningEventHeader';
import HappeningNowSocket from './HappeningNowSocket/HappeningNowSocket';
let isDataLoaded = true;

export default function HappeningNow({ happeningNow,
    pageData, children,
    userDetails,
    userId, toShowModel,
    showModal, eventId }) {

    const { fetchEventsSubChannelData, updateLiveSessionData,
        fetchSubChannelWhenMainUnlive,
        state: {
            isSubChannelDataRequest } }
        = useContext(GS1Context);

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isNestedDataLoading, setIsNestedDataLoading] = useState(false);
    const [channelSelected, setChannelSelected] = useState(happeningNow?.main_channel?.islive ? happeningNow?.selected_channel?.id : '');

    const isMainLive = happeningNow?.main_channel?.islive;
    const isSubchannelExists = happeningNow && happeningNow?.subchannels?.length > 0;
    useEffect(() => {
        // setChannelSelected(happeningNow?.selected_channel?.id)
        if (isMainLive) {
            const mainChannelID = happeningNow?.selected_channel?.id;
            setChannelSelected(mainChannelID)
        } else if (!isMainLive) {
            const isSubchannelExists = happeningNow?.subchannels?.length > 0;
            if (isSubchannelExists) {
                // setChannelSelected(happeningNow?.selected_channel?.id)

                const event = happeningNow?.subchannels[0].slug
                const organization = happeningNow?.subchannels[0].organization_slug;
                const url = `${organization}/${event}/happening-now`;
                const fetchSubChannel = async (url) => {
                    setIsDataLoading(true)

                    // isDataLoaded = false
                    try {
                        await fetchEventsSubChannelData(url);
                        setIsDataLoading(false)
                        setChannelSelected(happeningNow?.subchannels[0]?.event_id);
                    } catch (e) {
                        console.error(e)
                    }

                    setIsDataLoading(false)
                    // setChannelSelected(happeningNow?.subchannels[0]?.event_id)
                }
                fetchSubChannel(url);
            }
        }
    }, [isMainLive]);

    const onChannelSelect = async (e, index, organization, event, channelId) => {
        if (e) {
            e.preventDefault();
        }
        setIsDataLoading(true)

        const url = `${organization}/${event}/happening-now`

        isDataLoaded = false
        try {
            await fetchEventsSubChannelData(url);
            setIsDataLoading(false)
            setChannelSelected(channelId);
        } catch (e) {
            console.error(e)
        }

        setIsDataLoading(false)
    }


    const happeningNowData = async (data, hNow, type) => {

        const url = `${hNow?.main_channel.organization_slug}/${data?.slug}/happening-now`
        if (type) {
            if (hNow?.livesessions) {
                await updateLiveSessionData(data);
            }
        } else {
            setIsDataLoading(true)
            isDataLoaded = false
            try {
                await fetchEventsSubChannelData(url, 'type');
                if (!isMainLive) {
                    const isSubchannelExists = happeningNow?.subchannels?.length > 0;
                    if (isSubchannelExists) {
                        // let channelId = happeningNow?.subchannels?.find(channel => channel.slug === data?.slug)?.event_id
                        setChannelSelected(happeningNow?.subchannels[0].event_id)
                        const event = happeningNow?.subchannels[0].slug
                        const organization = happeningNow?.subchannels[0].organization_slug;
                        const url = `${organization}/${event}/happening-now`;

                        setIsNestedDataLoading(true)

                        // isDataLoaded = false
                        try {
                            await fetchEventsSubChannelData(url);
                            setIsNestedDataLoading(false)
                            setChannelSelected(happeningNow?.subchannels[0].event_id);
                        } catch (e) {
                            console.error(e)
                        }

                        setIsNestedDataLoading(false)
                        // setChannelSelected(happeningNow?.subchannels[0]?.event_id)
                    }
                    setIsNestedDataLoading(false);
                }
            } catch (e) {
                console.error(e);
                setIsDataLoading(false)

            }

            setIsDataLoading(false)
        }
    }

    if (!isMainLive) {
        if (isDataLoaded) {
            if (isSubchannelExists) {
                // const [subChannel] = happeningNow?.subchannels[0]
                const event = happeningNow?.subchannels[0].slug
                const organization = happeningNow?.subchannels[0].organization_slug;
                const url = `${organization}/${event}/happening-now`

                isDataLoaded = false
                fetchEventsSubChannelData(url);
                setChannelSelected(happeningNow?.subchannels[0]?.event_id)
            }

        }
    }

    let liveSessions;
    if (!isSubchannelExists && !isMainLive) {
        liveSessions = happeningNow?.livesessions?.filter(t => t.is_live === 1)?.map((sessions, index) => {

            let [, endTime] = sessions.end !== null ? sessions.end?.split(' ') : ['', '']
            const startTime = sessions.end !== null ? sessions?.time?.split(':').splice(0, 2)?.join(':') : null
            const month = moment(sessions?.date).format('MMM');
            let date = moment(sessions?.date).format('D');
            const day = moment(sessions?.date).format('ddd');
            const timeZone = moment().tz(sessions?.timezone).zoneAbbr();
            let time;
            if (endTime) {
                endTime = endTime?.split(':').splice(0, 2)?.join(':');
                time = `${startTime} - ${endTime} (${timeZone})`
            }
            const category = JSON.parse(sessions?.category);
            return <div className="card tt-card">
                <div className="card-header tt-card-header" id={`ttHappeningNowOne${index}`}>
                    <HappeningEventHeader
                        dataTarget={`#collapseHappeningNow${index}`}
                        areaExpanded={false}
                        ariaControls={`collapseHappeningNow${index}`}
                        time={time}
                        day={day}
                        date={date}
                        month={month}
                        title={sessions?.title}
                        is_live={sessions?.is_live}
                        highlight={category?.name}
                        attended={sessions?.attended}
                        link={sessions?.link}

                    />
                </div>
                <HappeningEventDetails
                    id={`collapseHappeningNow${index}`}
                    data={sessions}
                    dataParent={'#liveSessions-accordion'}
                    description={sessions?.description}
                    data={sessions?.speakers}
                />

            </div>

        })
    }



    let data;
    let nothingLive;
    let event_id;
    data = happeningNow?.selected_channel;
    event_id = happeningNow?.selected_channel?.id


    if (!happeningNow?.main_channel?.islive && happeningNow?.subchannels.length === 0 && !happeningNow?.livesessions?.find(session => session.is_live !== 0)) {
        nothingLive = <div class="happining-no-data">
            <div class="data-not-found">
                <h4>At this moment we have no live session.</h4>
                <a data-tab="tt-all-tab"
                    data-toggle="modal"
                    data-target="#program_modal"
                    class="btn datanotfound-btn "
                    type="button">
                    Click to view the time table
                 </a>

            </div>
        </div>
    }


    return (
        <>
            <div data-hash="#HappeningNow" className="modal gs-modal fade fade-scale"
                id="happening_now_modal" tabIndex="-1"
                aria-labelledby="modalHappiningLabel"
                aria-hidden="true" >
                {pageData && happeningNow?.selected_channel ? <HappeningNowSocket eventId={event_id}
                    happeningNowData={(data, type) => happeningNowData(data, happeningNow, type)}
                    happeningNow={happeningNow?.selected_channel}

                /> : null}
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header gs-modal-header faq-modal-header">
                            <div className="page-heading d-flex">
                                <h4 className="modal-title page-title">Happening Now</h4>
                                <div class="faq-heading">
                                    {(happeningNow?.main_channel?.islive && happeningNow?.subchannels?.length <= 0) ?
                                        <h4 class="faq-title">
                                            {happeningNow?.main_channel?.title}
                                        </h4> : null}
                                    {(!isMainLive && happeningNow?.subchannels?.length === 1) ?
                                        <h4 class="faq-title">
                                            {happeningNow?.selected_channel?.title}
                                        </h4> : null}
                                </div>
                            </div>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true"><i className="material-icons">close</i></span>
                            </button>
                        </div>
                        {nothingLive ? nothingLive : <div className="modal-body gs-modal-body">
                            <Wrapper id={'liveSessions-accordion'}>
                                {liveSessions}
                            </Wrapper>
                            {(isDataLoading || isNestedDataLoading) || !pageData ? <Loader /> : <>
                                <section className="channel-button-wrapper">
                                    <div className="container-fluid p-0">
                                        <div className="channel-btn-group">
                                            {(happeningNow?.main_channel?.islive
                                                && !happeningNow?.subchannels?.length <= 0)
                                                ? <Link
                                                    onClick={channelSelected !== happeningNow?.main_channel?.id ? (e) => onChannelSelect(e, 1,
                                                        happeningNow?.main_channel?.organization_slug,
                                                        happeningNow?.main_channel?.slug,
                                                        happeningNow?.main_channel?.id
                                                    ) : (e) => e.preventDefault()}
                                                    to=""
                                                    href="#"
                                                    className={`btn ${channelSelected === happeningNow?.main_channel?.id ? 'active' : ''}`}
                                                    type="button">
                                                    {happeningNow?.main_channel?.title}
                                                </Link> : null}
                                            {(!happeningNow?.main_channel?.islive && happeningNow?.subchannels?.length === 1) ? null :
                                                <>
                                                    {
                                                        (happeningNow?.subchannels?.length > 0) ?
                                                            happeningNow?.subchannels?.map((channel, index) => {
                                                                return <Link key={index}
                                                                    onClick={channelSelected !== channel?.event_id ? (e) => onChannelSelect(e,
                                                                        index,
                                                                        channel?.organization_slug,
                                                                        channel?.slug,
                                                                        channel?.event_id) : (e) => e.preventDefault()}
                                                                    to="" href="#"
                                                                    className={`btn ${channelSelected === channel?.event_id ? 'active' : ''}`}
                                                                    type="button">
                                                                    {channel?.name}
                                                                </Link>
                                                            }) : null
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </section>
                                {liveSessions ? <></> : <Channels iframe={data?.iframe}
                                                                  userId={userId}
                                                                  chat={happeningNow?.selected_channel?.chat}
                                                                  eventId={happeningNow?.selected_channel?.id}
                                                                  q_and_a={happeningNow?.selected_channel?.question_answer}
                                                                  rating={happeningNow?.selected_channel?.rating}
                                                                  poll={happeningNow?.selected_channel?.poll}
                                                                  userDetails={userDetails}
                                                                  vote={happeningNow?.selected_channel?.vote}
                                                                  options={happeningNow?.selected_channel?.options}
                                                                  tickers={happeningNow?.selected_channel?.tickers}
                                />}


                            </>
                            }
                        </div>}
                    </div>
                </div>
            </div >
        </>
    )
}


