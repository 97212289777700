import React, { useContext, useEffect, useState } from "react";
import "./login.css";
import "./chatboot.css";
import GS1Logo from "../../assets/Gs1/1200px-Logo_GS1.svg.png";
import useScript from "../../hooks/useScript";
import useStyle from "../../hooks/cssLoad";
import SupportMenu from "./SupportMenu";
import { Context as AuthContext } from "../../context/AuthContext";
import Location from "../../hooks/Location";
import ErrorPage from "../../Route/ErrorPage";
import Loader from "../../UIComponets/Loader";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import FAQ from "../components/NeedHelp/NeedHelp";

const LoginInput = ({ type, placeholder, onChange }) => {
  return (
    <input
      onChange={(e) => onChange(e.target.value)}
      type={type}
      className="form-control"
      placeholder={placeholder}
    />
  );
};

const GS1Login = () => {
  //************************* *  Append Script *************************
  useScript("/js/GS1/custom.js");
  useScript("/js/GS1/messengerChat.js");

  // useStyle('/script/GS1/css/chatboot.css');
  // useStyle('/script/GS1/css/login.css');
  useStyle("/script/gs1/css/gs1-style.css");
  useStyle("/script/buttonLoader.css");
  useStyle("/script/gs1/css/anim.css");
  useStyle("/script/gs1/css/GS1.css");
  // useStyle('/script/gs1/css/login.css');
  useStyle("/script/gs1/css/orientation.css");
  useStyle("/script/gs1/css/profile-page.css");
  useStyle("/script/gs1/css/social-wall.css");
  useStyle("/script/gs1/css/speakers.css");
  useStyle("/script/gs1/css/sponsors.css");
  useStyle("/script/gs1/css/gs1-style.css");
  useStyle("/script/gs1/css/time-table.css");
  useStyle("/script/gs1/css/trivia.css");
  useStyle("/script/gs1/css/video-vault.css");
  useStyle("/script/gs1/css/chatboot.css");
  useStyle("/script/gs1/css/faq.css");
  const [client, event] = Location();
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [onError, setOnError] = useState("");

  const {
    GS1Login,
    GS1Authentication,
    state: { isAuthenticate, isLogin },
  } = useContext(AuthContext);

  //************************** React Hooks *************************

  useEffect(() => {
    document.title = "GS1 Login | Global forum 2021";
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const onAuthentication = async () => {
      const url = `api/${client}/${event}`;
      try {
        await GS1Authentication(url);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    onAuthentication();
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = GS1Logo;
      //   favicon.href = imageBaseUrl + event?.logo;
    }
  }, [event?.logo]);

  if (isLoading) return <Loader />;
  if (!isAuthenticate) return <ErrorPage />;

  const onLogin = async (e) => {
    e.preventDefault();
    const url = `api/${client}/${event}`;
    setIsLoggedIn(true);

    await GS1Authentication(url);
    GS1Login(email, password, url, (response) => {
      if (response.status) {
        // expires_at
        localStorage.setItem("GS1_User", JSON.stringify(response));
        setIsLoggedIn(false);
        setTimeout(() => (document.location.href = `/${client}/${event}`), 250);
        // window.location = `/${client}/${event}`;
      } else {
        setOnError("Incorrect email or password");
        setIsLoggedIn(false);
        addToast("Client not found", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });

    setIsLoggedIn(false);
  };
  return (
    <main className="login-form-body">
      <section className="login-form-wrapper">
        <div className="login-section">
          <div className="gs-login">
            <div className="gs-logo-section">
              <div className="logo-img-wrap">
                <img
                  src={GS1Logo}
                  className="img-fluid img-responsive"
                  alt=""
                />
              </div>
              <h1>Global Forum 2021</h1>
            </div>

            <form action="" className="login_form">
              {onError && <div class="notice-component warning">{onError}</div>}
              <div className="form-group">
                <LoginInput
                  type="email"
                  placeholder="Email Id"
                  onChange={setEmail}
                />
              </div>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                  </div>
                </div>
                <LoginInput
                  type="password"
                  placeholder="Confirmation Code"
                  onChange={setPassword}
                />
              </div>
              <div className="form-btn-wrap">
                <button
                  onClick={onLogin}
                  type="submit"
                  className={"btn btn-gs-login btn-block"}
                >
                  Log in
                  {isLoggedIn && (
                    <div
                      class="btn-spinner spinner-border text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* <!-- Contact Box --> */}
          <div
            onClick={() => setIsClicked(!isClicked)}
            id="contact-px-chat-box"
            className="faq-contact-chat-btn"
          >
            <div className="contact-px-chat-box-iconwrapper contact-px-chat-box-js-toggler fadeIn">
              <div className="contact-px-chat-box-icon">
                <a data-toggle="modal" data-target="#modalFAQ">
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FAQ />
    </main>
  );
};

export default GS1Login;
