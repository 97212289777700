import React, { useContext } from "react";
import { imageBaseUrl } from "../../constants/url/urlConstants";
import { Context as AuthContext } from "../../context/AuthContext";
import ReactHtmlParser from "react-html-parser";
import { BackToRegistration } from "./BackToRegistration";

export const PaymentOpen = () => {

  let payment_url = "";  

    const {
      state: {
        registrationPageData: { event, payment, translations, dbvideo },
      },
    } = useContext(AuthContext);

    if (payment?.item?._links?.checkout?.href) {
      payment_url = payment?.item?._links?.checkout?.href;
    }
    return (
      <div className="wb-splash-reg-wrapper">
        <div className="wb-splash-inner">
          <div className="form-logo-wrap mb-0">
            <div className="logo-img-frame">
              <img
                // src={event?.logo}
                src={imageBaseUrl + event?.logo}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="logo-brand-name ">
              <h2 style={{ color: "#000" }}>{event?.title}</h2>
              <span className="cmn-bg-color">Event</span>
            </div>
          </div>
          {dbvideo?.payment_open ? (
            ReactHtmlParser(
              dbvideo?.payment_open.replace("[[payment_url]]", payment_url)
            )
          ) : (
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="font-weight-bold my-5">Payment Is pending</h1>
              </div>
            </div>
          )}
          <BackToRegistration />
        </div>
      </div>
    );
}
