import React, { useEffect, useRef } from 'react';

const Support = ({ script }) => {
    const scriptRef = useRef(null);

    function removeTags(str) {
        if (script) {
            const ele = script.replace(/(<([^>]+)>)/ig, '');

            let scriptTag = document.getElementById('script');
            if (!scriptTag) {
                scriptTag = document.createElement('script');
                scriptTag.id = 'supportScript';
                scriptTag.innerHTML = ele;
                document.body.appendChild(scriptTag);
            }
        }
    }

    useEffect(() => {
        removeTags(script);
    }, []);
    return (
        <div ref={scriptRef}>
        </div>
    )
};

export default Support;