import React from 'react'

export default function RadioButton(props) {
    return (
        <label className={props.labelClassName}> {props.title}
            <input
                name={props.name}
                style={props.style}
                value={props.value}
                checked={props.checked}
                onChange={(e) => props.onSelection(e.target.value)}
                type="radio"

            />
            <span className={props.spanClassName} ></span>
        </label>
    )
}
