import React, {useContext, useEffect, useState} from "react";
import LoaderDefaultSection from "../../../../../../UIComponets/LoaderDefaultSection";
import { Context as DefaultSectionContext } from "../../../../../../context/DefaultSectionContext"
const VODModal = ({ src, setVideoId, color, title, id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    vodView
  } = useContext(DefaultSectionContext);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    if (id) {
      vodView(id, (response) => {
        console.log("[VOD VIEW]" + JSON.stringify(response))
      });
    }

  }, [src]);

  return (
    <div
      className="modal fade"
      id="vodModal"
      aria-labelledby="vodModalLabel"
      aria-hidden="true"
      data-backdrop="static"
      // onClick={() => setVideoId(null)}
    >
      <div
        // onClick={(e) => e.preventDefault()}

        className="modal-dialog modal-lg modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="modal-header border-0">
            <div
              // onClick={(e) => e.preventDefault()}
              className="heading-wrap-vod"
            >
              <span style={{ color }}>{title}</span>
            </div>
            <button
              onClick={() => setVideoId(null)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa fa-times fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="embed-responsive embed-responsive-16by9 height_0">
              {isLoading ? (
                <LoaderDefaultSection />
              ) : (
                <iframe
                  className="embed-responsive-item"
                  src={`https://player.vimeo.com/video/${src}`}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  style={{ color }}
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
          {/* <div className="modal-footer">
            <button
              onClick={() => setVideoId(null)}
              type="button"
              className="btn vw-btn vw-btn-black "
              data-dismiss="modal"
            >
              Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VODModal;
