
export enum OptionConstants {
    NETWORKINGTOOL = "networking_tool",
    SHOW_SOCIAL_LINKS = "show_social_links",
    NT_SHOW_SOCIAL_LINKS = "nt_show_social_links",
    NT_SHOW_AVATAR = "nt_show_avatar",
    NT_SHOW_EMAIL = "nt_show_email",
    PENDING = "pending",
    ACCEPTED = "accepted",
    DECLINE = "declined",
}