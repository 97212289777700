
import { imageWithStorage } from "../../../constants/url/urlConstants";
import placeholder from '../../../assets/user/avatarplaceholder.png';

const UserProfileImage = (props) => {
    return <img
        dSrc={imageWithStorage + props.src}
        src={imageWithStorage + props.src}
        onError={(e) => {
            e.target.onerror = null;
            if (props.placeholder) e.target.src = props.placeholder;
            else e.target.src = placeholder;
        }}
        alt="" className={props.className} />
}

export default UserProfileImage;
