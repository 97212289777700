import moment from "moment";
const ChatMessages = (props) => {
    let current_date = new Date();
    return (
        <div className={`direct-chat-msg ${props.right ? "right" : ""}`}>
            <div className="direct-chat-text">
                <span>{props.message}</span>
            </div>
            <div className="direct-chat-infos clearfix">
                <span className="direct-chat-timestamp">{moment(props.time, ["HH:mm"]).format("HH:mm")}</span>
                <span className="direct-chat-name">{props.username}</span>
            </div>
        </div>
    )
}

export default ChatMessages;