import React, {Component, useContext} from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./PartnersSection.css";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
    Sponsor_Channel,
    Sponsor_Event,
} from "../../../../../../constants/Pusher/PusherConstants";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";

const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 4 },
};

const PartnersSection = () => {
    const {
        state: {
            eventsPageData: {
                page_data : {sponser},
                translations
            }
        },
    } = useContext(defaultContext);

    let sponsers = sponser;
    let items;
    let mainPartner;
    if (sponsers?.length > 0) {
        mainPartner = sponsers?.find((main) => main?.main === 1);
        const partners = sponsers?.filter((a) => a?.main !== 1);
        items = partners?.map((item, index) => {
            return (
                <div key={index} className="item">
                    <div className="partners-frame">
                        <a href={item?.link} target={item?.target}>
                            <img src={imageWithStorage + item?.logo} alt={item?.name} />
                        </a>
                    </div>
                </div>
            );
        });
    } else {
        return <></>;
    }

    const mainPartnerLogo = [
        <a href={mainPartner?.link} target="_blank">
            <img src={imageWithStorage + mainPartner?.logo} alt="" />
        </a>,
    ];

    // console.log(items);
    return (
        <section className="partners-wrapper">
            <div className="container-fluid">
                <div className="row">
                    {mainPartner && (
                        <div className="col-12 col-md-2">
                            <div className="main-partner-wrap">
                                <div className=" main-partners-carousel">
                                    {/* <div className="owl-carousel main-partners-carousel"> */}
                                    <div className="item">
                                        <div className="partners-frame">
                                            <AliceCarousel
                                                disableDotsControls={true}
                                                // mouseTracking
                                                items={mainPartnerLogo}
                                                // disableButtonsControls
                                                // autoPlayInterval={2000}
                                                // responsive={responsive}
                                            />

                                            <div className="partner-label">
                                                <span>{translations?.sponsers?.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {items.length > 0 && (
                        <div
                            className={
                                mainPartner
                                    ? "col-12 col-md-10 px-5"
                                    : "col-12 col-md-12 px-5"
                            }
                        >
                            <div className="partners-slider-wrap">
                                <div className="partners-carousel">
                                    <AliceCarousel
                                        disableDotsControls={true}
                                        mouseTracking
                                        items={items}
                                        infinite
                                        autoPlay
                                        // disableButtonsControls
                                        autoPlayInterval={2000}
                                        responsive={responsive}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default PartnersSection;
