const ProgrammeToggle = ({ onClick, id, href, ariaControls, ariaSelected, title, day, date, className }) => <a key={id}
    className={className}
    id={id}
    onClick={onClick}
    data-toggle={'tab'}
    href={href}
    role="tab"
    aria-controls={ariaControls}
    aria-selected={ariaSelected} >
    {title}
    <span className="tt-day" >{day}</span >
    <span className="tt-date" >{date}</span >
</a >

export default ProgrammeToggle;