import Pusher from 'pusher-js';
import React, { Component } from 'react'
import { PUSHER_APP_KEY, PUSHER_CLUSTER, Tickers_Channel, Ticker_Event } from '../../../../../../constants/Pusher/PusherConstants';
import PusherWrapper from '../../../../../../hooks/PusherWrapper';
export default class HappeningNowTicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickers: props.tickers || [],
            pusher: null
        }
    }

    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;
          
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(Tickers_Channel + this.props.eventId);
            channel.bind(Ticker_Event, data => {
                this.setState((prevState, props) => ({
                    ...prevState, tickers: data.tickers

                }));
            });
        }
    }

    render() {
        let tickersList;
        let tickers = [...this.state.tickers]
        if (tickers?.length > 0) {
            tickersList = tickers?.map((ticker, index) => {
                return <li key={index}>{ticker?.name}</li>
            })
        } else {
            return <> </>
        }
        return (
            <section className="highlight-wrapper inner-highlight-wrap">
                <div className="marquee-text-wrapper">
                    <div className="d-flex">
                        <p className="highlight-heading">HighLights</p>
                        <marquee behavior="" direction="" className="marquee-text">
                            <ul>
                                {tickersList}
                            </ul>
                        </marquee>
                    </div>

                </div>
            </section>
        )
    }
}
