import React from 'react'
import useScript from '../hooks/useScript';
import './css/spinner.css';

export default function LoaderDefaultSection() {
    useScript('/js/loaderDefault.js');
    return (
        // <div>Loader..</div>
        // <div id="baseLoader" className="df-loader-wrapper default-loader">
        //     <div className="load-wrapper">
        //         <div className="loader-inner"></div>
        //     </div>
        // </div>

        <div class="reverse-loader" id="baseLoader">
            <div class="reverse-spinner"></div>
        </div>
    )
}
