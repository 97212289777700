import React, { useEffect, useState } from "react";
import LoaderDefaultSection from "../../UIComponets/LoaderDefaultSection";

const HeaderVideo = ({ video, setVideo, isVideoClose, setIsVideoClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, [video]);
  return (
    <div
      className="modal fade wb-modal"
      id="videoModal"
      tabIndex="-1"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
      onClick={() => setVideo(null)}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              onClick={() => setVideo(null)}
              className="close wb-modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="fa fa-times fa-2x" aria-hidden="true"></i>
              </span>
            </button>
            <div className="wb-video-modal-wrap">
              <div className="wb-modal-content">
                <div className="embed-responsive embed-responsive-16by9">
                  {isLoading ? (
                    <LoaderDefaultSection />
                  ) : (
                    <iframe
                      src={`https://player.vimeo.com/video/${video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                      width="640"
                      height="360"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      title="Webble-Up Explainer"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderVideo;
