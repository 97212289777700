import React, { useState } from 'react';
import HappeningEventDetails from '../HappeningNow/HappeningEvent/HappeningEventDetails';
import HappeningEventHeader from '../HappeningNow/HappeningEvent/HappeningEventHeader';
import ProgrammeToggle from '../Programme/ProgrammeToggle';
import Wrapper from '../Wrapper/Wrapper';
import UserInformation from './UserInformation';
import profilePlaceholder from '../../../../assets/user/avatarplaceholder.png';
import MySession from '../Programme/MySessions';


export default function Profile({ user, mySessions }) {



    return (
        <div data-hash="#myaccount"
            className="modal gs-modal fade fade-scale"
            id="myprofilemodal" tabIndex="-1"
            aria-labelledby="myprofilemodalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header">
                        <div className="page-heading">
                            <h4 className="modal-title page-title">My Page</h4>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true"><i className="material-icons">close</i></span>
                        </button>
                    </div>
                    <div className="modal-body gs-modal-body">
                        <div className="row">
                            <div className="col-12">
                                <UserInformation
                                    firstName={user?.FirstName}
                                    middleName={user?.MiddleName}
                                    lastName={user?.LastName}
                                    emailId={user?.EmailAddress}
                                    organization={user?.organization}
                                    // registrationType={user?.organization}
                                    jobTitle={user?.Title}
                                    TwitterURL={user?.TwitterURL}
                                    FacebookURL={user?.FacebookURL}
                                    LinkedInURL={user?.LinkedInURL}
                                    src={user?.ImageURL || profilePlaceholder}
                                />
                            </div>
                            <div className="col-12">
                                <div className="profile-session-wrap">
                                    <div className="inner-heading-wrap">
                                        <div className="modal-header gs-modal-header">
                                            <div className="page-heading">
                                                <h4 className="modal-title page-title">My Registered Sessions</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="all-tt-content">
                                    <div className="tab-content" id="nav-tabContent-tt">
                                        <div className="tab-pane fade show active"
                                            id="nav1" role="tabpanel"
                                            aria-labelledby="nav1-tab">
                                            <div className="timetable-content">
                                                <MySession mySession={mySessions}
                                                    navId='inner-nav-tab'
                                                    contentId="inner-tabContent-tt"
                                                    fromWhere={'profile'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}