import React from 'react';
import Post from './Post';
import PostWrapper from './PostWrapper'
import ReactHtmlParser from 'react-html-parser'

const SocialMediaHeading = ({ id, ariaSelected, icon, title, href, ariaControls }) => {
    return (
        <li className="nav-item ">
            <a className="nav-link" id={id} data-toggle="tab" href={href}
                role="tab" aria-controls={ariaControls} aria-selected={ariaSelected}><i
                    className={icon}></i>
                <span>{title}</span> </a>
        </li>)

}


export default function SocialMedia({ socialWall }) {
    return (
        <div data-hash="#socialwall"
            className="modal gs-modal fade fade-scale"
            id="social_media_modal"
            tabIndex="-1"
            aria-labelledby="modalSocialWallLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header">
                        <div className="page-heading">
                            <h4 className="modal-title page-title">Social Wall</h4>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true"><i className="material-icons">close</i></span>
                        </button>
                    </div>
                    <div className="modal-body gs-modal-body">
                        <section className="social-wal-wrapper">
                            <div id="gs-masonry">
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="social-filter-wrap">
                                            <ul className="nav nav-tabs gs-so-wal-tabs" id="gsSocialWalTabs" role="tablist">
                                                <SocialMediaHeading
                                                    id='"gsSocialWalTabs"'
                                                    href='#allSocial'
                                                    ariaControls='allSocial'
                                                    ariaSelected={true}
                                                    icon='fa fa-users'
                                                    title='All'
                                                />
                                                <SocialMediaHeading
                                                    id="flickr-tab"
                                                    href='#flickr'
                                                    ariaControls='flickr'
                                                    ariaSelected={false}
                                                    icon='fa fa-flickr'
                                                    title='Flicker'
                                                />
                                                <SocialMediaHeading
                                                    id="facebook-tab"
                                                    href='#facebook'
                                                    ariaControls='facebook'
                                                    ariaSelected={false}
                                                    icon='fa fa-facebook'
                                                    title='Facebook'
                                                />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="gs-post-wrap" className="gs-main-wrapper gs-post-wrapper fb-post-wrap">
                                    <div className="tab-content" id="gsSocialWal">
                                        <PostWrapper
                                            id='allSocial'
                                            className="tab-pane fade show active"
                                            ariaLabelledBy='allSocial-tab'

                                        >
                                            <Post
                                                titleSrc=''
                                                title='Juicer'
                                                updateAgo='15h'
                                                src=''
                                                alt=''
                                                description=''
                                                descriptionLink='Social Media should be a strength in your next
                                                        fundraising
                                                        campaign, not a weakness 💪.
                                                Read more: '
                                                likesCount='5'
                                                socialMediaIcon='fa fa-facebook'

                                            />
                                        </PostWrapper>
                                        <PostWrapper
                                            id='flickr'
                                            className="tab-pane fade show"
                                            ariaLabelledBy='flickr-tab'

                                        >
                                            <Post
                                                titleSrc=''
                                                title='Juicer'
                                                updateAgo='15h'
                                                src=''
                                                alt=''
                                                description=''
                                                descriptionLink='Social Media should be a strength in your next
                                                        fundraising
                                                        campaign, not a weakness 💪.
                                                Read more: '
                                                likesCount='5'
                                                socialMediaIcon='fa fa-facebook'

                                            />
                                        </PostWrapper>
                                        <PostWrapper
                                            id='facebook'
                                            className="tab-pane fade show"
                                            ariaLabelledBy='facebook-tab'

                                        >
                                            <Post
                                                titleSrc=''
                                                title='Juicer'
                                                updateAgo='15h'
                                                src=''
                                                alt=''
                                                description=''
                                                descriptionLink='Social Media should be a strength in your next
                                                        fundraising
                                                        campaign, not a weakness 💪.
                                                Read more: '
                                                likesCount='5'
                                                socialMediaIcon='fa fa-facebook'

                                            />
                                        </PostWrapper>


                                    </div>
                                </div> */}
                                {ReactHtmlParser(socialWall)}

                            </div>

                        </section>

                    </div>
                </div>
            </div>
        </div>
    )
}
