
import React, {useEffect} from 'react'
import SupportMenu from './SupportMenu'
import logout from "../../hooks/logout";

export default function VersionValidator() {
    useEffect(() => {
       let version = localStorage.getItem('version');
       if (!version || version !== process.env.REACT_APP_BUILD_VERSION){
           logout();
           localStorage.setItem('version', process.env.REACT_APP_BUILD_VERSION);
           return;
       }
    }, []);
    return (<></>)
}