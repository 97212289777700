import React from 'react'

export default function SpeakerSessions({ id, sessionDay, sessionDate, sessionTopic, sessionEndTime, sessionStartTime, speakerSessionDescription, sessionTimezone, index }) {
    const random = Math.floor(Math.random() * 10000)
    return (

        <div className="card">
            <div className="card-header" id={`headingOne${random}`}>
                <h2 className="mb-0">
                    <a className="btn btn-link btn-block text-left collapsed"
                        type="button" data-toggle="collapse"
                        data-target={`#collapseOne${random}`} aria-expanded="true"
                        aria-controls={`collapseOne${random}`}>
                        <span className="session-day">{sessionDay}</span>
                       , <span className="session-date">{sessionDate}</span>
                       , <span className="session-time">{sessionStartTime}</span> - <span className="session-time">{sessionEndTime}</span> (CET)

                        , <p>{sessionTopic}</p>

                    </a>
                </h2>
            </div>

        </div>


    )
}
