import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cssLoad from "../hooks/cssLoad";
import AboutUs from "./aboutUs/Aboutus";
import ContactUs from "./contactUs/ContactUs";
import ContactUsC from "./contactUs/ContactUsC";
import Experience from "./experince/Experience";
import Features from "./features/Features";
import Header from "./Header/Header";
import Powered from "./Powered/Powered";
const LandingPage = () => {
  const [language, setLanguage] = useState("nl");
  const history = useHistory();
  useEffect(() => {
    const loc = window.location.search;
    document.title = "Webble Up - Online eventplatform";

    if (loc.includes("nl")) {
      setLanguage("nl");
    } else if (loc.includes("en")) {
      setLanguage("en");
    }
  });

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = "/script/LandingPage/images/main-logo.png";
    }
  }, []);

  useEffect(() => {
    let metaTag1 = document.getElementById("description");
    let metaTag2 = document.getElementById("keywords");
    let metaTag3 = document.getElementById("robots");
    let metaTag4 = document.getElementById("og:title");
    let metaHead = document.getElementById("metaHead");
    let metaNL;
    let metaNL1;
    let metaNL2;
    let metaNL3;
    let h1;

    if (language === "nl") {
      document.title = "Webble Up - Online eventplatform";
    } else {
      document.title = "The Webble Up online event platform";
    }

    if (!metaTag1) {
      metaNL = document.createElement("meta");
      // meta.href = path + url;
      metaNL.id = "description";
      metaNL.name = "description";
      metaNL.content =
        language === "nl"
          ? "Online event organiseren? Zet je hybride event op de rails met online eventplatform Webble Up. Internationaal bereik, maximale beleving. Let’s get digital."
          : "Organising an online event? Get your hybrid event on track with the online event platform Webble Up. International reach, maximum experience. Let's get digital.";
      document.head.appendChild(metaNL);
    }

    if (!metaTag2) {
      metaNL1 = document.createElement("meta");
      // meta.href = path + url;
      metaNL1.id = "keywords";
      metaNL1.name = "keywords";
      metaNL1.content =
        language === "nl" ? "Online eventplatform" : "Online Event Platform ";
      document.head.appendChild(metaNL1);
    }
    if (!metaTag3) {
      metaNL2 = document.createElement("meta");
      // meta.href = path + url;
      metaNL2.id = "og:title";
      metaNL2.name = "og:title";
      metaNL2.property = "og:title";
      metaNL2.content =
        language === "nl"
          ? "Wil je een prikkelend online evenement organiseren? "
          : "Do you want to organise a stimulating online event?";
      document.head.appendChild(metaNL2);
    }
    if (!metaTag4) {
      metaNL3 = document.createElement("meta");
      // meta.href = path + url;
      metaNL3.id = "robots";
      metaNL3.name = "robots";
      //  metaNL3.property = "robots";
      metaNL3.content = "index, follow";
      document.head.appendChild(metaNL3);
    }
    if (!metaHead) {
      h1 = document.createElement("head");
      // meta.href = path + url;
      h1.id = "metaHead";
      h1.title =
        language === "nl"
          ? "Webble Up - Online eventplatform "
          : "The Webble Up online event platform";
      //  head.property = "robots";
      document.head.appendChild(h1);
    }

    return () => {
      if (metaNL) document.head.removeChild(metaNL);
      if (metaNL1) document.head.removeChild(metaNL1);
      if (metaNL2) document.head.removeChild(metaNL2);
      if (metaNL3) document.head.removeChild(metaNL3);
      if (h1) document.head.removeChild(h1);
    };
  }, [language]);

  cssLoad("/script/LandingPage/css/header.css");
  cssLoad("/script/LandingPage/css/style.css");
  cssLoad("/script/LandingPage/css/animate.min.css");
  cssLoad("/script/LandingPage/css/contact.css");
  cssLoad("/script/LandingPage/css/event.css");
  cssLoad("/script/LandingPage/css/featured.css");
  cssLoad("/script/LandingPage/css/font-awesome-all.css");
  cssLoad("/script/LandingPage/css/normalize.css");
  cssLoad("/script/LandingPage/css/powered.css");
  cssLoad("/js/font-awesome.js");

  return (
    <>
      <Header setLanguage={setLanguage} selectedLanguage={language} />
      <AboutUs selectedLanguage={language} />
      <Experience selectedLanguage={language} />
      <Features selectedLanguage={language} />
      <Powered selectedLanguage={language} />
      {/* <ContactUs selectedLanguage={language} /> */}
      <ContactUsC selectedLanguage={language} />
    </>
  );
};

export default LandingPage;
