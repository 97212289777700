
export enum PusherEvent {
    INSERT_CHAT = "insert_chat",
    DELETE_CHAT = "delete_chat",
    SEND_ANSWER = "send_answer",
    ANSWER_REC = "ANSWER_REC",

    ONAIR_RATING = "on_air_rating",
    ONAIR_RATING_DELETE = "ONAIR_RATING_DELETE",
    ONAIR_RATING_UPDATE = "ONAIR_RATING_UPDATE",

    ONAIR_VOTE = "ONAIR_VOTE",
    ONAIR_VOTE_DELETE = "ONAIR_VOTE_DELETE",
    ONAIR_VOTE_UPDATE = "ONAIR_VOTE_UPDATE",

    ONAIR_POLL = "ONAIR_POLL",
    OFFAIR_POLL = "OFFAIR_POLL",
    ONAIR_POLL_DELETE = "ONAIR_POLL_DELETE",
    ONAIR_POLL_UPDATE = "ONAIR_POLL_UPDATE"
}