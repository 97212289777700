import React, { Component, useEffect, useState } from 'react';
import IFrame from '../../../../../UIComponets/iframe/IFrame'
import Chat from './Chat/Chat';
import QueHeader from './QandAModal/QueHeader';
import Rating from './Rating/Rating';
import ReactHtmlParser from 'react-html-parser';
import QandAModal from './QandAModal/QandAModal';
import QandAChat from '../../../../../UIComponets/QandAChat/QandAChat';
import ChatInput from '../../../../../UIComponets/ChatInput';
import Pusher from 'pusher-js';
import { useToasts } from 'react-toast-notifications';

import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../../constants/Pusher/Pusher';
import useScript from '../../../../../hooks/useScript';
import Poll from './Poll/Poll';
import { baseUrl } from '../../../../../constants/url/urlConstants';
import Vote from './Vote/Vote';
import DBNetwork from "../../../../../api/DBNetwork";
import HappeningNowTicker from './Ticker/HappeningNowTicker';
import PusherWrapper from '../../../../../hooks/PusherWrapper';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';


const ToggleMenu = ({ dataTarget, ariaExpanded, ariaControls, title, className, onClick, isclicked, dataToggle }) => {

    return <a onClick={isclicked ? onClick : null} href="" className={className}
        data-toggle={dataToggle} data-target={dataTarget}
        aria-expanded='false' aria-controls={ariaControls}>{title}</a>
}

class Channels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            user: '',
            chats: this.props.chat ? this.props.chat : [],
            q_and_a: props.q_and_a ? props.q_and_a : [],
            rating: this.props.rating ? this.props.rating : [],
            isComponentMounted: false,
            eventId: props.eventId,
            options: this.props.options,
            pusher: null,
            token: props.token ? props.token : null,
            isLoading: false,
            ratingNull: true,
            pollNull: true,
            voteNull: true,
            showPoll: true,
            hasMore: true,
        }

    }
    chatContainer = React.createRef();
    setMessage = (message) => {
        this.setState((prevState, props) => ({
            ...prevState, message: message
        }));
    };
    setUser = (userName) => {
        this.setState((prevState, props) => ({
            ...prevState, user: userName
        }));
    };
    setChats = (chat) => {
        this.setState((prevState, props) => ({
            ...prevState, chats: [...this.state.chats, chat]
        }), () => this.scrollToMyRef());
    };
    setsetIsComponentMounted = (isComponentMounted) => {
        this.setState((prevState, props) => ({
            ...prevState, isComponentMounted: isComponentMounted
        }));
    };

    componentDidMount() {
        console.log(PUSHER_APP_KEY)
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;

            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');

                    if (PUSHER_APP_KEY === 'a89915b17a80dc1ad5ba') console.log('Over limit!');
                }
            });
            pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe('chat.' + this.props.eventId);
            channel.bind("App\\Events\\ChatEvent", data => {
                console.log(this.props.userDetails)
                if (data?.message) {
                    if (data?.message?.id !== 0 ? (data?.message?.id === this.props.userDetails?.id) : '') {
                        toast(`new message received from ${data?.message?.user_name}`);
                    }
                    this.setChats(data.message);
                }
            });
        }
    }

    onSendMessageEnter = (e) => {
        const url = baseUrl('api/send-chat-message');
        if (e.key === 'Enter' && this.state.message?.length > 0) {
            this.setState((prevState, props) => ({
                ...prevState, isLoading: true,

            }));
            const headers = {
                Authorization: ` Bearer ${this.props.token?.access_token}
            ` }
            const payload = {
                username: this.state.username,
                message: this.state.message,
                event_id: this.props.eventId

            };

            DBNetwork.post(url, payload
            ).then(response => {
                if (response.data) {
                    this.setMessage('');
                    this.setState((prevState, props) => ({
                        ...prevState, isLoading: false,

                    }));
                }
            });

        }
    }
    onSendMessage = (e, type) => {
        e.preventDefault();

        if (this.state.message?.length > 0) {
            this.setState((prevState, props) => ({
                ...prevState, isLoading: true,

            }));
            e?.currentTarget?.setAttribute('disabled', 'disabled');
            const url = baseUrl('api/send-chat-message');

            const payload = {
                username: this.state.username,
                message: this.state.message,
                event_id: this.state.eventId
            };

            DBNetwork.post(url, payload
            ).then(response => {
                if (response.data) {
                    this.setMessage('');
                    this.setState((prevState, props) => ({
                        ...prevState, isLoading: false,
                    }));
                    e?.currentTarget?.removeAttribute('disabled');

                }

            });
        }

    }

    scrollToMyRef = () => {
        const scroll =
            this.chatContainer.current.scrollHeight -
            this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
    };

    isAnyFeatureIsEmpty = (value, type) => {
        let options = [...this.state.options];


        // if (type === 'pollNull') {
        //     if (value) {
        //         this.setState((prevState, props) => ({
        //             ...prevState, showPoll: true,
        //         }));
        //     } else {
        //         this.setState((prevState, props) => ({
        //             ...prevState, showPoll: false,
        //         }));
        //     }

    }
    //     if (value) {
    //         const index = options.indexOf('poll')
    //         if (index === -1) {
    //             options.push('poll');
    //             this.setState((prevState, props) => ({
    //                 ...prevState, showPoll: true,
    //             }));
    //         }
    //     } else {
    //         const index = options.indexOf('poll')
    //         options.splice(index, 1);
    //     }
    //     this.setState((prevState, props) => ({
    //         ...prevState, showPoll: false
    //     }));
    // }


    fetchMoreData = () => {
        // const page = this.state.pagenumber + 1;
        // let url = "/api/look-book?page=" + page;
        // axios.get(url).then(
        //     Response => {
        //         if (Response.data.page_data.looks < 12) {
        //             this.setState((pre, next) => {
        //                 return {
        //                     ...pre,
        //                     hasMore: false
        //                 }
        //             });
        //             return;
        //         }
        //         this.setState(previousState => ({
        //             pagenumber: page,
        //             look_book: [...previousState.look_book, ...Response.data.page_data.looks]
        //         }));

        //     }

        // );

    }

    render() {
        console.log('this' + this)
        const _iframe = this.props.iframe && this.props.iframe?.replaceAll('allowfullscreen', 'allowFullscreen');
        const iframe = _iframe && _iframe?.replace('frameborder', 'frameBorder');
        const isRatingAvail = this.props.rating?.filter(r => r.live === 1).length > 0;
        // const isPollAvail = this.props.poll?.live;
        const isPollAvail = true
        let isVoteAvail
        if (this.props.vote?.length > 0) isVoteAvail = this.props.vote.filter(r => r.live === 1).length > 0;
        return <>
            <section className="header-wrapper hp-video-wrap">
                <div className="hp-video-wrapper">
                    <div className="inner-video-wrap gs-inner-video-wrap">
                        <div className="collapsabel-width collapsabel-width-wrapper">
                            <div id='embedIframe'
                                className='embed-responsive embed-responsive-16by9'>
                                {ReactHtmlParser(iframe)}
                            </div>
                            <HappeningNowTicker
                                tickers={this.props.tickers}
                                eventId={this.state.eventId}
                            />
                            <div className="menu-wrapper">
                                {/* <HappeningNow /> */}
                                <div
                                    className="user-details-fix-wrap fixed-menu-list gs-fixed-menu-list">
                                    {this.state.options?.includes('q_and_a') && <ToggleMenu
                                        className='fixed-menu-item qa-menu smallIframe'
                                        dataTarget='#QA-box-wrapper'
                                        ariaControls='QA-box-wrapper'
                                        title='Q&A'
                                    />}

                                    {this.state.options?.includes('chat') && <ToggleMenu
                                        className='fixed-menu-item chat-menu smallIframe'
                                        dataTarget='#chat-box-wrapper'
                                        ariaControls='chat-box-wrapper'
                                        title='Chat'
                                    />
                                    }
                                    {this.state.options?.includes('rating') && <ToggleMenu
                                        className='fixed-menu-item rating-menu rcc-menu'
                                        dataTarget='#rating-box-wrapper'
                                        ariaControls='rating-box-wrapper'
                                        title='Rating'
                                        dataToggle='collapse'
                                    />}
                                    {this.state.options?.includes('poll') && <ToggleMenu
                                        className={`fixed-menu-item poll-menu rcc-menu `}
                                        dataTarget='#collapsePoll'
                                        ariaControls='collapsePoll'
                                        title='poll'
                                        dataToggle='collapse'
                                    />}
                                    {this.state.options?.includes('vote') && <ToggleMenu
                                        className='fixed-menu-item vote-menu rcc-menu'
                                        dataTarget='#collapseVote'
                                        ariaControls='collapseVote'
                                        title='vote'
                                        dataToggle='collapse'
                                    />}
                                </div>
                                <div className="menu-content">
                                    <QandAModal q_and_a={this.props.q_and_a}
                                        userId={this.props.userId}
                                        eventId={this.props.eventId}

                                        token={this.props.token}
                                        userDetails={this.props.userDetails}
                                    />
                                    <div id="chat-box-wrapper"
                                        aria-labelledby=""
                                        className="chat-box-wrap gs-box-wrap-modal full-width-box rounded-0">
                                        <div className="card box-wrap-card">
                                            <div className="card-header bg-white border-0 p-2">
                                                <div className="card-actions-wrap d-flex justify-content-end align-items-center">
                                                    <a href="" data-target="#chat-box-wrapper"
                                                        className="smallIframe">
                                                        <i aria-hidden="true"
                                                            className="fa fa-times">
                                                        </i>
                                                    </a>
                                                </div>
                                            </div>
                                            {/* <InfiniteScroll
                                                dataLength={this.state.chats.length}
                                                next={this.fetchMoreData}
                                                hasMore={this.state.hasMore}
                                                loader={<div className="snippet" data-title=".dot-typing">
                                                    <div className="stage">
                                                        <div className="dot-typing"></div>
                                                    </div>
                                                </div>
                                                }
                                                endMessage={
                                                    <p className="scrollendMsg" style={{ textAlign: "center" }}>
                                                        <b>No older Message</b>
                                                    </p>

                                                }
                                            > */}
                                            <div ref={this.chatContainer} className="card-body gs-card-body-scrollbar p-0 m-3">
                                                <Chat
                                                    userDetails={this.props.userId}
                                                    chatData={this.state.chats}
                                                    token={this.props.token}
                                                />
                                            </div>
                                            {/* </InfiniteScroll> */}
                                            <div className="card-footer">
                                                <div className="input-group mb-2">
                                                    <input type="text"
                                                        placeholder="Type Message"
                                                        value={this.state.message}
                                                        onChange={(e) => this.setMessage(e.target.value)}
                                                        onKeyDown={this.onSendMessageEnter}
                                                        required="required"
                                                        className="form-control fix-rounded-right" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bg-white">
                                                            <a onClick={this.onSendMessage} href="#">
                                                                <i aria-hidden="true"
                                                                    className={`fa ${this.state.isLoading ? 'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}>
                                                                </i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {<Rating isRatingEmpty={this.isAnyFeatureIsEmpty}
                                        isRatingAvail={isRatingAvail}
                                        rating={this.state.rating}
                                        eventId={this.state.eventId}
                                        token={this.props.token}
                                        userDetails={this.props.userDetails}
                                    />}
                                    <Poll isPollEmpty={this.isAnyFeatureIsEmpty}
                                        isPollAvail={isPollAvail}
                                        poll={this.props.poll}

                                        eventId={this.state.eventId}
                                        token={this.props.token}
                                        show={this.state.showPoll}
                                        userDetails={this.props.userDetails}
                                    />
                                    {<Vote isVoteEmpty={this.isAnyFeatureIsEmpty}
                                        isVoteAvail={isVoteAvail}
                                        vote={this.props.vote}
                                        eventId={this.state.eventId}
                                        headers={this.state.headers}
                                        token={this.props.token}

                                        userDetails={this.props.userDetails}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    }
}

export default Channels;
