import React, { useContext, useState } from 'react'
import { Link } from "react-router-dom";
import { Context as networkingToolContext } from '../../../context/NetworkTollContext';
import { Context as defaultContext } from '../../../context/DefaultSectionContext';
import Img from '../../components/defaultLayout/Img';
import placeholder from '../../../assets/user/avatarplaceholder.png';
import NotificationCenter from '../../../hooks/NotificationCenter';
import { useToasts } from "react-toast-notifications";
import { ErrorOption } from '../../components/ToastOptions';
import {OptionConstants} from "../../../constants/Constants";
import { changeColor } from '../../../utlis/changeColor';

export const CardProfile = ({ event, user, setAppointmentUser }) => {
  const {
    state: {
      eventsPageData: { page_data },
    },
  } = useContext(defaultContext);
  const { videoConnect } = useContext(networkingToolContext);
  const { addToast } = useToasts();

  const makeAppointment = (user_id) => {
    NotificationCenter.sharedInstance().broadcastNotification("setAppointmentUserDetails", { user: user, event: event });
    // alert("asdasd")
    window.$("#appointmentModal").modal("show");
    // setAppointmentUser(user_id);
  }
  const [isLoading, setIsLoading] = useState(false)
  const dice_matched ="";
  const videoConntectRequest = () => {
    if (isLoading) { return }
    setIsLoading(true)
    videoConnect(event, user, dice_matched, (response, error) => {
      setIsLoading(false)
      if (error){
        addToast(error.response.data.message, ErrorOption)
      }
    })
  }

  const updateColor = () => {    
    const colorCode = event?.color;      
    changeColor(
      colorCode,
      `.df-networking-wrap .profile-card-wrapper .dft-emp-contect-info .emp-video-profile a`,
      `{background: ${colorCode}!important;color:#ffffff!important}`
    );

    changeColor(
      colorCode,
      `.df-networking-wrap .profile-card-wrapper`,
      `{border-color: ${colorCode}!important;color:#ffffff!important}`
    );   
  }

  updateColor()
  return (
    <div className="card profile-card-wrapper">
      <div className="card-header">
        <div className="dft-position-wrap">
          <p className="cmpny-function">{user?.info?.function}</p>
          <p className="cmpny-name">{user?.info?.company}</p>
        </div>
      </div>
      <div className="card-body">
        <div className="dft-employee-wrapper">
          <div className="dft-employee-info">
            <div className="employee-details-wrap">
              <div className="employee-name">
                <h2>
                  <Link to="#">{`${user?.name ?? ""} ${user?.last_name ?? ""}`.capitalize()}</Link>
                </h2>
                { Boolean.parse(page_data?.networking_tool?.config?.nt_show_email ?? "false") && <p>
                  {`${user?.email ?? ""}`}
                </p>}
              </div>
            </div>
            { Boolean.parse(page_data?.networking_tool?.config?.nt_show_avatar ?? "false") && <div className="employee-image-wrap">
              <div className="emp-image-frame">
                <Img
                  src={user?.avatar}
                  className="img-fluid"
                  placeholder={placeholder}
                />
              </div>
            </div> }
          </div>
        </div>
      </div>
       <div className="card-footer">
        <div className="dft-emp-contect-info">
          {Boolean.parse(page_data?.networking_tool?.config?.nt_show_social_links ?? "false") &&
          <div className="emp-social-wrap">
            {user?.info?.linkedin_link && (
                <a href={user?.info?.linkedin_link}>
                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
            )}
            {user?.info?.twitter_link && (
                <a to={user?.info?.twitter_link}>
                  <i className="fa fa-twitter-square" aria-hidden="true"></i>
                </a>
            )}
            {user?.info?.facebook_link && (
                <a to={user?.info?.facebook_link}>
                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
            )}
          </div>
          }
          <div className="emp-video-profile">
            <Link to="#" onClick={(e) => {
              e.preventDefault();
              videoConntectRequest()

            }}>
              <i className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-video"}`} aria-hidden="true"></i> {!isLoading ? "Connect" : ""}

            </Link>
            <Link onClick={() => makeAppointment(user?.id)} to="#">
              <i className="fa fa-plus" aria-hidden="true"></i>Appointment
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
