import React, {Component} from 'react';
import DBNetwork from '../../../../api/DBNetwork';

import profilePlaceholder from '../../../../assets/user/avatarplaceholder.png';
import { baseUrl } from '../../../../constants/url/urlConstants';

import VideoChat from './VideoChat/VideoChat.js';

import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../constants/Pusher/Pusher';

const min = 1;
const max = 20;

const randomMin = 1;
const randomMax = 6;

class Dice extends Component {

	constructor(props) {
		super(props);

		this.state = {
			rand1: 0,
			rand2: 0,
			accepted: false,
			accessToken: '',
			audio: true,
			countdown3Min: 180,
			countdown10Min: 10,
			joined: false,
			match: false,
			participants: [],
			pusher: null,
			videoChatRoom: null,
			video: true,

			timer_interval: null,
			timer_show: false,
			timer_min: 3,
			timer_sec: 0,
		};

		this.rollDice = this.rollDice.bind(this);
		this.getRandom = this.getRandom.bind(this);
	}

	componentDidMount() {
		console.log('MOUNTED DICES');
		console.log(this.state.joined);
		console.log(this.props.user);
	}

	rollDice = async () => {
        let random1 = this.getRandom();
        let random2 = this.getRandom();

		this.setState({
			rand1: random1,
			rand2: random2,
			joined: true
		});

		try {
			const url = baseUrl("api/video-chat/join/" + this.props.event.id);
			const response = await DBNetwork.get(url);
			console.log(response.data.data.match_id);
			if(response.data.data.match_id) {
				this.setState({
					match: true,
				});
				this.props.setMatchId(response.data.data.match_id);
				this.gotMatch(response.data.data.match_id);
			}
			else {
				if (this.state.pusher == null) {
					let pusher = new Pusher(PUSHER_APP_KEY, {
						cluster: PUSHER_CLUSTER,
						encrypted: false
					});
					this.state.pusher = pusher;

					pusher.connection.bind('error', function (err) {
						if (err?.code === 404) {
							console.log('Over limit!');
						}
					});
					const channel = pusher.subscribe('videochat.user.' + this.props.user.id);
					channel.bind("App\\Events\\VideoChatUserFoundEvent", data => {
						/*this.setState({
							match: true
						});*/
						this.props.setMatchId(data.match_id);
						this.gotMatch(data.match_id);
					});
				}
			}

		} catch (error) {
			console.log("error");
			if(error.response) {
				console.log(error.response.data);
				if(error.response.data.code == "VC_MEETING_PROGRESS" && error.response.data.data.match_id) {
					console.log('reconnecting');
					/*this.setState({
						match: true
					});*/

					this.props.setMatchId(error.response.data.data.match_id);
					this.gotMatch(error.response.data.data.match_id);
					this.props.acceptMatch();
				}
			}
		}

		this.startTimer();

	}

	startTimer = () => {
		this.state.timer_interval = setInterval(() => {

			if (this.state.timer_sec == 0 && this.state.timer_min == 0) {
				// todo show snackbar
				this.setState({
					joined: false,
					timer_min: 3,
					timer_sec: 0,
					pusher: null,
				});
				clearInterval(this.state.timer_interval);
			}
			else if (this.state.timer_sec == 0) {
				this.setState({
					timer_min: this.state.timer_min - 1,
					timer_sec: 59
				});
			}
			else {
				this.setState({
					timer_sec: this.state.timer_sec - 1,
				});
			}
		}, 1000);


	}

	gotMatch = async (match_id) =>{
		try {
			const url = baseUrl(`api/video-chat/match/${match_id}`);
			const response = await DBNetwork.get(url);
			this.props.setFOI(response.data.data.foi);
			this.props.setMatchId(match_id);
			this.props.listenForVCEvents();

			this.props.setMatchedUser(response.data.data.matched_users[0]);


		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	getRandom = () => {
		return (Math.floor(Math.random() * (max - min)) + min) * 90;
	}

	render() {
		const stylePlayer1 = {
			transform: 'rotateX(' + this.state.rand1 + 'deg) rotateY(' + this.state.rand2 + 'deg)',
		};
		const stylePlayer2 = {
			transform: 'rotateX(' + this.state.rand1 + 'deg) rotateY(' + this.state.rand2 + 'deg)',
		};
		return (

			<div className="container">
				{!this.state.accepted &&
					<div className="dice-wrap dice-pairing-wrap">
						<div className="peers-wrapper">
							{this.state.joined &&
								<div className="match-text ">
									<h2 className="match-blue-text">Looking for a match</h2>
									<h2 className="match-blue-text">
										0{this.state.timer_min}:{this.state.timer_sec <= 9 ? 0 : ""}{this.state.timer_sec}</h2>
								</div>
							}
							{!this.state.joined &&
								<div className="match-text ">
									<h2 className="match-blue-text">Let's find a match</h2>
								</div>
							}
							<div className="row align-items-center my-5 py-5">
								<div className=" col-6 col-sm-6 col-md-6 col-lg-3">
									<div className="player player1">
										<div className="player-imageframe text-center">
											<img src={this.props.user.avtar ? this.props.user.avtar : profilePlaceholder} className="img-fluid" alt="" />
										</div>
										<div className="player-name text-center d-none">
											<h4 className="match-blue-text">{this.props.user.name + ' ' + this.props.user.last_name}</h4>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-6 col-md-6 col-lg-3">
									<section className="dice-container">
										<div id="player1" className="cube-box" style={stylePlayer1}>
											<div className="front">
												<span className="dot dot1"></span>
											</div>
											<div className="back">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
											</div>
											<div className="right">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
											</div>
											<div className="left">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
											</div>
											<div className="top">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
												<span className="dot dot5"></span>
											</div>
											<div className="bottom">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
												<span className="dot dot5"></span>
												<span className="dot dot6"></span>
											</div>
										</div>
									</section>
								</div>
								<div className="col-6 col-sm-6 col-md-6 col-lg-3">
									<section className="dice-container">
										<div id="player2" className="cube-box" style={stylePlayer2}>
											<div className="front">
												<span className="dot dot1"></span>
											</div>
											<div className="back">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
											</div>
											<div className="right">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
											</div>
											<div className="left">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
											</div>
											<div className="top">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
												<span className="dot dot5"></span>
											</div>
											<div className="bottom">
												<span className="dot dot1"></span>
												<span className="dot dot2"></span>
												<span className="dot dot3"></span>
												<span className="dot dot4"></span>
												<span className="dot dot5"></span>
												<span className="dot dot6"></span>
											</div>
										</div>
									</section>
								</div>
								<div className="col-6 col-sm-6 col-md-6 col-lg-3">
									<div className="player player2">
										<div className="player-imageframe text-center">
											<img src={this.props.matched_user ? (this.props.matched_user.avatar ? this.props.matched_user.avatar : profilePlaceholder) : profilePlaceholder} className="img-fluid" alt="" />
										</div>
										{this.state.match &&
											<div className="player-name text-center d-none">
												<h4 className="match-blue-text">{this.props.matched_user ? this.props.matched_user.name : ''}</h4>
											</div>
										}
									</div>
								</div>
							</div>

							{!this.state.joined &&
								<div id="rollingDice" className="dice-btn-wrap">
									<button type="button" className="btn btn-flat btn-dice" onClick={this.rollDice}>Roll The Dice !</button>
								</div>
							}
						</div>
					</div>
				}

			</div>
		)
	}
}

export default Dice;
