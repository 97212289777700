import React, { Component } from 'react'
import Sponsors from './Sponsors';
import { imageWithStorage } from '../../../../constants/url/urlConstants';
import { PUSHER_APP_KEY, PUSHER_CLUSTER, Sponsor_Channel, Sponsor_Event } from '../../../../constants/Pusher/PusherConstants';
import Pusher from 'pusher-js';
import PusherWrapper from '../../../../hooks/PusherWrapper';

export default class SponsorsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsors: props.sponsors || [],
            pusher: null
        }
    };
    componentWillUnmount() {
        if (this.props.eventId) {
            const eventId = this.props.eventId;
            const list = [Sponsor_Channel + eventId];
            PusherWrapper.sharedInstance().removeChannels(list);
        }

    }

    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
            
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(Sponsor_Channel + this.props.eventId);
            channel.bind(Sponsor_Event, data => {
                // console.log(data)
                this.setState((prevState, props) => ({
                    ...prevState, sponsors: data?.sponser,

                }));
            });
        }
    }

    render() {
        let sponsorsList;
        let sponsors = [...this.state.sponsors]
        if (sponsors?.length > 0) {
            sponsorsList = sponsors?.map((sponsor, index) => {
                return <Sponsors title={sponsor?.name}
                    link={sponsor?.link}
                    description={sponsor?.description}
                    img={imageWithStorage + sponsor?.logo} />
            });
        }
        return (
            <div data-hash="#Sponsors"
                className="modal gs-modal fade fade-scale"
                id="sponsers_modal"
                tabIndex="-1"
                aria-labelledby="modalSponsorsLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header gs-modal-header">
                            <div className="page-heading">
                                <h4 className="modal-title page-title">Slide Decks</h4>
                            </div>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true"><i className="material-icons">close</i></span>
                            </button>
                        </div>
                        <div className="modal-body gs-modal-body">
                            <div className="sponsors-wrapper">
                                <div className="sponsors-box-wrapper">
                                    {sponsorsList}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}
