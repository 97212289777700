import React, { useEffect } from 'react'
import logo from '../../../assets/Gs1/1200px-Logo_GS1.svg.png';
import useBodyClass from '../../../hooks/BodyClass';

const MobileOrientation = (props) => {
    useBodyClass('mobile-orientation-body');

    return (
        <main>
            {/* <!-- Mobile Orientation --> */}
            <section className="mobile-orientation-wrapper orientation-portrait-active">
                <div className="mobile-rotate-wrapper">
                    <div className="rotating-content">
                        <div className="container-fluid">
                            <div className="rotating-content-wrap">
                                <div className="rotating-mobile-content">
                                    <h5>This website is optimized for 16:9 resolution please turn your device</h5>
                                    <div className="rotating-image-wrap mobile">
                                        <img src="/img/mobile.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>

                                <div className="rotating-desk-content">
                                    <h5>This website is optimized for 16:9 resolution Please scale horizontally</h5>
                                    <div className="rotating-image-wrap desktop">
                                        <img src="/img/desk.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                <div className="rotating-logo-wrap">
                                    <img src={logo} className="img-fluid" alt=""/>
                                    <h1>Global Forum 2021</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="mobile-orientation-wrapper orientation-landscape-active">
                <div class="mobile-rotate-wrapper">
                    <div class="rotating-content">
                        <div class="container-fluid">
                            <div class="rotating-content-wrap">
                                <h5>This webiste is optimized for 16:9 resolution Please scale horizontally</h5>
                                <div class="rotating-image-wrap desktop">
                                    <img src="/img/desk.png" class="img-fluid" alt="" />
                                </div>
                                <div class="rotating-logo-wrap">
                                    <img src={logo} class="img-fluid" alt="" />
                                    <h1>Globle Forum 2021</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </main>
    )

}

export default MobileOrientation