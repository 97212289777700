import React, { useState } from 'react'

export default function SplashScreen({ src }) {
    const [closeVideo, setCloseVideo] = useState(false);
    return (
        <div class="modal gs-modal gs-modal-video gs-welcome-video fade fade-scale"
            data-backdrop="static"
            data-keyboard="false"
            id="modalFirstVideo"
            tabindex="-1"
            aria-labelledby="modalSingelVideoLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body gs-modal-body">
                        <div class="video-popup welcome-popup">
                            <div class="video-title">
                                <h4></h4>
                                <button onclick="intro_video_close()" onClick={() => setCloseVideo(!closeVideo)} type="button" class="close" data-dismiss="modal">
                                    <span aria-hidden="true">
                                        <i class="material-icons">
                                            close
                                        </i>
                                    </span>
                                </button>
                            </div>
                            <div class="video-iframe-wrap">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe src={`'https://player.vimeo.com/video/${closeVideo ? closeVideo : src}?autoplay=1`}
                                        width="640"
                                        height="360"
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
