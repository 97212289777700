import axios from 'axios';
import React, { useContext } from 'react'
import DBNetwork from '../../api/DBNetwork';
import { imageBaseUrl } from '../../constants/url/urlConstants';
import { Context as AuthContext } from "../../context/AuthContext";

import Location from "../../hooks/Location";
import { BackToRegistration } from './BackToRegistration';

export const ShowPaymentMethods = ({ userDetails, backToRegister }) => {

    const [clientName, eventName] = Location();

    let redirectUrl = window.location.origin + "/" + clientName + "/" + eventName + "/register";

    const {
        state: {
            registrationPageData: { event, payment },
        },
    } = useContext(AuthContext);

    const createPayment = (object, e) => {
        e.preventDefault();
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${userDetails.access_token}`
        // }

        DBNetwork.post(
          `${process.env.REACT_APP_API_HOST}/api/mollie/create-payment/${event.id}`,
          { payment_method: object.method_id, redirect_url: `${redirectUrl}` }
        ).then((response) => {
          window.open(response.data.data.checkout_url, "_self");
          // alert(JSON.stringify(response));
        });
    }
    let methods = payment?.methods.map((method, index) => {
        let images = JSON.parse(method?.image);

        return (
            <a key={index} onClick={(e) => createPayment(method, e)} href="#">
                <li className="row align-items-center py-2">
                    <div className="col-xs-2 col-sm-3 col-md-3 col-lg-3">
                        <img src={images.size2x} alt={method?.description} />
                    </div>
                    <div className="col-xs-10 col-sm-7 col-md-7 col-lg-7 d-flex justify-content-between">
                        <span>{method?.description}</span>
                        <span className="fa fa-angle-right"></span>
                    </div>
                </li>
            </a>
        );
    })

    return (
      <div className="wb-splash-reg-wrapper">
        <div className="wb-splash-inner">
          <div className="form-logo-wrap">
            <div className="logo-img-frame">
              <img
                // src={event?.logo}
                src={imageBaseUrl + event?.logo}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="logo-brand-name ">
              <h2 style={{ color: "#000" }}>{event?.title}</h2>
              <span
                className="cmn-bg-color"
                style={{ backgroundColor: event?.color }}
              >
                Event
              </span>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 text-center">
              <h1 className="font-weight-bold">
                Pay
                <br />
                <span style={{ color: event?.color }}>
                  {" "}
                  &euro; {parseFloat(payment?.amount).toFixed(2)}
                </span>
              </h1>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <ul className="list-group">{methods}</ul>
            </div>
          </div>
          <BackToRegistration />
        </div>
      </div>
    );
}
