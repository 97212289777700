import React, { useState, useContext } from 'react'
import ChatMenu from '../chatMenu/Chats';
import Links from '../components/Links';
import PollMenu from '../PollMenu/PollMenu';
import QandA from '../QandA/QandA';
import RatingMenu from '../ratingMenu/RatingMenu';
import VoteMenu from '../voteMenu/VoteMenu';
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";


const SideMenu = ({ options, chat, q_and_a, polls, onClick, vote, rating, children,
    isPinChat,
    isPinQandA,
    isPollAvail,
    isRatingAvail,
    isVoteAvail,
    dbvideo
}) => {
    const {
        state: {
            eventsPageData: {
                translations
            }
        },
    } = useContext(defaultContext);
    const getStyleObject = (type) => {

        let obj = {};
        const btnColorKey = type + '_btn_color';
        if (dbvideo[btnColorKey]) {
            obj.backgroundColor = dbvideo[btnColorKey];
        }
        const btnTextColorKey = type + '_btn_text_color';
        if (dbvideo[btnTextColorKey]) {
            obj.color = dbvideo[btnTextColorKey];
        }
        return obj;
    }

    const [pinQandA, setPinQandA] = useState(false)
    return (
        <>
            <div className="menu-wrapper">
                <div className="user-details-fix-wrap fixed-menu-list">
                    {options?.includes('q_and_a') && !isPinQandA ? < Links
                        link='#'
                        className="fixed-menu-item qa-menu small-iframe"
                        dataTarget="QA-box-wrapper"
                        heading={translations?.qa?.title}
                        style={getStyleObject('qa')}
                    /> : null}

                    {options?.includes('chat') && !isPinChat ? <Links
                        link='#'
                        className="fixed-menu-item chat-menu small-iframe"
                        dataTarget="chat-box-wrapper"
                        heading={translations?.chat?.title}
                        style={getStyleObject('chat')}
                    /> : null}
                    {options?.includes('rating') && isRatingAvail ? <Links
                        link='#'
                        className="fixed-menu-item ration-menu rcc-menu"
                        dataTarget="rating-box-wrapper"
                        heading={translations?.rating?.title}
                        style={getStyleObject('rating')}
                    /> : null}
                    {options?.includes('poll') ? < Links
                        link='#'
                        className="fixed-menu-item poll-menu rcc-menu"
                        dataTarget="collapsePoll"
                        heading={translations?.poll?.title}
                        style={getStyleObject('poll')}
                    /> : null}
                    {options?.includes('vote') && isVoteAvail ? <Links
                        link='#'
                        className="fixed-menu-item vote-menu rcc-menu"
                        dataTarget="collapseVote"
                        heading={translations?.vote?.title}
                        style={getStyleObject('vote')}
                    /> : null}
                </div>
                <div className="menu-content">
                    {children}
                </div>
            </div>
        </>
    )
};

export default SideMenu;





