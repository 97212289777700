import React, { useContext } from "react";
import { imageBaseUrl } from "../../constants/url/urlConstants";
import { Context as AuthContext } from "../../context/AuthContext";
import { BackToRegistration } from "./BackToRegistration";
import ReactHtmlParser from "react-html-parser";


export const PaymentCanceled = () => {
  const {
    state: {
      registrationPageData: { event, payment, translations, dbvideo },
    },
  } = useContext(AuthContext);

  return (
    <>
      <div className="wb-splash-reg-wrapper">
        <div className="wb-splash-inner">
          <div className="form-logo-wrap mb-0">
            <div className="logo-img-frame">
              <img
                // src={event?.logo}
                src={imageBaseUrl + event?.logo}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="logo-brand-name ">
              <h2 style={{ color: "#000" }}>{event?.title}</h2>
              <span className="cmn-bg-color">Event</span>
            </div>
          </div>
          {dbvideo?.payment_canceled ? (
            ReactHtmlParser(dbvideo?.payment_canceled)
          ) : (
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="font-weight-bold my-5">
                  Payment has been cancelled
                </h1>
              </div>
            </div>
          )}
          <BackToRegistration />
        </div>
      </div>
    </>
  );
};
