import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { changeColor } from "../../../../../../../utlis/changeColor";
import ChatThumb from "../../chatMenu/ChatThumb/ChatThumb";
import { Context as defaultContext } from "../../../../../../../context/DefaultSectionContext";

const QandAComponet = ({ children, onPinQandA, isQAThumbLoding }) => {
  const [isClicked, setIsClicked] = useState(false);
  const onCancel = () => {
    onPinQandA();
    setIsClicked(!isClicked);
  };

  const {
    state: {
      eventsPageData: { page_data },
    },
  } = useContext(defaultContext);

  changeColor(page_data?.event?.color);

  return (
    <div className="col-12 col-md-6 pinned-height">
      <div className="moderator-qa-wrapper">
        <div className="box-panel-common qa-box-panel">
          <div className="card box-common-card cmn-border-color">
            <div className="card-header box-common-card-header">
              <div className="box-header-wrap">
                <div className="box-title-wrap ">
                  <a href="#" className="box-title cmn-bg-color">
                    Q&A
                  </a>
                </div>
                {isQAThumbLoding ? (
                  <Link to="#">
                    <i
                      className="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      style={{ color: "black" }}
                    ></i>
                  </Link>
                ) : (
                  <div onClick={onPinQandA} className="box-actions-wrap">
                    <Link to="#">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QandAComponet;
