

export const base64Encode = file => {
    // const [fileBase64String, setFileBase64String] = useState("");
    let encodedFile;
    var reader = new FileReader();
    if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
            var Base64 = reader.result;
            console.log(Base64);
            encodedFile = Base64;
        };
        reader.onerror = (error) => {
            console.log("error: ", error);
        };
    }

    return encodedFile
};

