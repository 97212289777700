import React from 'react';
import placeholder from '../../../../../assets/user/avatarplaceholder.png';

export default function Speaker({
  speakerImage,
  speakerName,
  speakerDesignation,
  speakerCompanyName,
}) {
  return (
    <div className="speaker-profile-panel">
      <div className="spkr-imgfrm">
        <img
          src={speakerImage}
     onError={(e) => { e.target.onerror = null; e.target.src = placeholder }}
          alt="speaker Image"
          className="img-fluid img-responsive rounded-circle"
        />
      </div>
      <div className="spkr-info">
        <h5 className="spkr-name">{speakerName}</h5>
        <p className="spkr-designation">{speakerDesignation}</p>
        <p className="spkr-designation" style={{ color: "#f28500" }}>
          {speakerCompanyName}
        </p>
      </div>
    </div>
  );

}

