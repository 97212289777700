import { useContext } from "react";
import { dispatch } from "react-hot-toast";
import DBNetwork from "../api/DBNetwork";
import {
  NET_TOLL_VIDEO_CONNECT,
  NET_TOLL_DETAILS,
  NET_CHEK_FOR_DICE_OPEN,
  RESET_VC_STATE,
  GOT_MATCH,
  VIDEO_CHAT_ACCEPT,
  FETCH_ONLINE_USERS,
  SET_METCH_ID,
  SHOW_INCOMING_CALL,
  CLEAR_INCOMING_CALL,
  DECLINE_CALLBACK,
  ENDCHAT_CALLBACK,
  DECLINE_CALLBACK_TRIGGER,
  ENDCHAT_CALLBACK_TRIGGER,
  SET_VIDEO_CHAT_STATE,
  VIDEO_CHAT_CONNECTING,
  VIDEO_CHAT_MESSAGE_RECIEVED,
  FETCH_MATCHED,
  ADD_APPOINTMENT, DICE_MATCHEDED, DICE_MATCHEDED_CLEAR, UPDATE_APPOINTMENT, PUSHER_APPOINTMENT_UPDATE,
} from "../constants/ActionsConstants/actiontypes";
import { baseUrl } from "../constants/url/urlConstants";
import Twilio from "../Default/NetworkingTool/Twilio";
import NotificationCenter from "../hooks/NotificationCenter";
import PusherWrapper from "../hooks/PusherWrapper";
import { VideoChatState } from "../hooks/VideoChatState";
import createDataContext from "./createDataContext";
import OnlineUsersModel from "./Model/OnlineUsersModel";
import { VideoChatModel } from "./Model/VideoChatModel";
import {OptionConstants} from "../constants/Constants";
import {PusherObject} from "../Utils/Utils";

const Reducer = (state, action) => {
  let _videoChat = { ...state.videoChat };
  let _onlineUsers = { ...state.onlineUsers };

  switch (action.type) {
    case NET_TOLL_DETAILS:
      return {
        ...state,
        details: { ...action.payload },
      };
    case NET_CHEK_FOR_DICE_OPEN:
      return { ...state };

    case RESET_VC_STATE: {
      let object = { videoChat: null, incomingCall: null };
      if (action.payload.videoChatState) {
        object["videoChatState"] = action.payload.videoChatState;
        Twilio.sharedInstance().setVideoChatState(
          action.payload.videoChatState
        );
        return { ...state, ...object, chatMessages: [] };
      }
      return { ...state, videoChat: null, chatMessages: [] };
    }

    case SET_METCH_ID:
      _videoChat.match_id = action.payload.match_id;
      if (action.payload.requestedChat) {
        _videoChat.requestedChat = action.payload.requestedChat;
      }
      return { ...state, videoChat: _videoChat };

    case GOT_MATCH:
      _videoChat = {};
      _videoChat.match_id = action.payload.match_id;
      _videoChat.foi = action.payload.data.foi;
      _videoChat.matched_users = action.payload.data.matched_users[0];
      return { ...state, videoChat: _videoChat };

    case VIDEO_CHAT_ACCEPT:
      _videoChat.twilio_room = action.payload.data.twilio_room;
      _videoChat.twilio_token = action.payload.data.twilio_token;
      _videoChat.twilio_identity = action.payload.data.twilio_identity;
      _videoChat.vc_created_at = action.payload.data.vc_created_at;
      _videoChat.active_videochat = true;
      Twilio.sharedInstance().setVideoChatState(VideoChatState.CONNECTED);
      return {
        ...state,
        videoChat: _videoChat,
        videoChatState: VideoChatState.CONNECTED,
      };

    case VIDEO_CHAT_CONNECTING:
      Twilio.sharedInstance().setVideoChatState(VideoChatState.CALLING);
      return {
        ...state,
        videoChat: _videoChat,
        videoChatState: VideoChatState.CALLING,
      };
    case FETCH_ONLINE_USERS:
      _onlineUsers.next_page = action.payload.data.current_page + 1;
      _onlineUsers.prev_page = action.payload.data.current_current_page - 1;
      _onlineUsers.users = action.payload.data.data;
      _onlineUsers.current_page = action.payload.data.current_current_page;
      _onlineUsers.isLoading = false;
      return { ...state, onlineUsers: _onlineUsers };
    case SHOW_INCOMING_CALL:
      Twilio.sharedInstance().setVideoChatState(VideoChatState.RINGING);
      return {
        ...state,
        incomingCall: action.payload,
        videoChatState: VideoChatState.RINGING,
      };
    case CLEAR_INCOMING_CALL:
      return { ...state, incomingCall: null };
    case SET_VIDEO_CHAT_STATE:
      Twilio.sharedInstance().setVideoChatState(action.payload);
      return { ...state, videoChatState: action.payload };
    case VIDEO_CHAT_MESSAGE_RECIEVED:
      const send_by_me = state?.details.user.id === action.payload.sender_id;
      var d = new Date();
      var time = d.getHours() + ":" + d.getMinutes();
      const msgObj = {
        message: action.payload.message,
        username: action.payload.username,
        right: send_by_me,
        time: time,
      };
      const chatMessages = [...(state.chatMessages ?? []), msgObj];
      return { ...state, chatMessages: chatMessages };

    case FETCH_MATCHED:
      const connectedUser = action.payload.matched_users;
      return { ...state, connectedUser: connectedUser };

    case ADD_APPOINTMENT:
      const appointment = {...action.payload?.appoinment};
      let networking_tool = state?.details?.networking_tool ?? {};
      networking_tool.appointments = [ ...(networking_tool?.appointments ?? []), appointment];
      state.details.networking_tool = networking_tool;
      return { ...state };

    case UPDATE_APPOINTMENT:
      const updatedAppointment = action.payload?.appoinment ?? [];
      let update_networking_tool = state?.details?.networking_tool ?? {};
      let list_appointments = update_networking_tool?.appointments;
      const index = list_appointments.findIndex((t) => t.id == updatedAppointment.id);
      if (index !== -1){
        list_appointments[index] = updatedAppointment;
      }
      update_networking_tool.appointments = list_appointments;
      state.details.networking_tool = update_networking_tool;
      return { ...state };

    case PUSHER_APPOINTMENT_UPDATE:
      const pusherAppointment = action.payload?.appoinment ?? [];
      let p_update_networking_tool = state?.details?.networking_tool ?? {};
      let p_list_appointments = p_update_networking_tool?.appointments;
      const p_index = p_list_appointments.findIndex((t) => t.id == pusherAppointment.id);
      if (p_index !== -1){
        p_list_appointments[p_index] = pusherAppointment;
      }else {
        p_list_appointments.push(pusherAppointment)
      }
      p_update_networking_tool.appointments = p_list_appointments;
      state.details.networking_tool = p_update_networking_tool;
      return { ...state };

    case DICE_MATCHEDED:
      return {...state, dice_matched: action.payload?.data}

    case DICE_MATCHEDED_CLEAR:
      return {...state, dice_matched: null}
    default:
      return state;
  }
};

export const setVideoState = (dispatch) => async (state) => {
  dispatch({ type: SET_VIDEO_CHAT_STATE, payload: state });
};
export const videoConnect = (dispatch) => async (event, user, dice_matched, callback) => {
  try {
    setVideoState(dispatch)(VideoChatState.CALLING);
    const url = baseUrl(`api/video-chat/global/${event.id}/${user.id}?match=${dice_matched}`);
    const response = await DBNetwork.get(url);
    const match_id = response?.data?.data?.match_id;
    if (match_id) {
      await gotMatch(dispatch)(match_id);
      await videoChatAccept(dispatch)(match_id);
      await registerPuserEvents(dispatch)(match_id);
      if (callback) {
        callback(response, null);
      }
    }
  } catch (error) {
    dispatch({
      type: SET_VIDEO_CHAT_STATE,
      payload: VideoChatState.DISCONNECTED,
    });

    callback(null, error);
  }

  // await dispatch({ type: NET_TOLL_VIDEO_CONNECT, payload: langauge });
};

export const commonUserAppointmentEvent = (dispatch) => async (data) => {
  const model = PusherObject(data);
  if (model.isAppointment()){
    dispatch({type: PUSHER_APPOINTMENT_UPDATE, payload: model.getObject()});
  }
};
export const init = (dispatch) => async (details, currentState) => {
  PusherWrapper.shared.unregisterNetworkToolChannels();
  await dispatch({ type: NET_TOLL_DETAILS, payload: details });
  PusherWrapper.shared.registerGlobalChatEvets(details, {
    videoChatGlobalRequestEvent: (data) =>
      videoChatGlobalRequestEvent(dispatch)(data, details, currentState),
    commonUserAppointmentEvent: (data) => commonUserAppointmentEvent(dispatch)( data, details)
  });
};

export const check_for_dice_open = (dispatch) => async () => {
  const {
    state: { details },
  } = useContext(Context);
  const url = baseUrl("api/video-chat/dice/authorised/" + details?.event?.id);
  const response = await DBNetwork.get(url);
  dispatch({ type: NET_CHEK_FOR_DICE_OPEN, payload: response });
};

export const endChat = (dispatch) => async (match_id, callback) => {
  const currentState = Twilio.sharedInstance().getVideoChatState();
  const isDisconnectedOrDisconnecting =  currentState === VideoChatState.DISCONNECTED || currentState === VideoChatState.DISCONNECTING
  if (isDisconnectedOrDisconnecting) return;

  setVideoState(dispatch)(VideoChatState.DISCONNECTING);
  const url = baseUrl("api/video-chat/end-chat/" + match_id);
  const response = await DBNetwork.get(url);
  NotificationCenter.sharedInstance().broadcastNotification(
    "endChatRequest",
    response.data
  );
  resetVCState(dispatch)(VideoChatState.DISCONNECTED);
  PusherWrapper.sharedInstance().unregisterNetworkToolChannels();
  callback && callback(response)
};

export const resetVCState = (dispatch) => async (videoChatState) => {
  dispatch({
    type: RESET_VC_STATE,
    payload: { videoChatState: videoChatState },
  });
};

export const declineMatch = (dispatch) => async (match_id, callback) => {
  try {
    const url = baseUrl("api/video-chat/decline/" + match_id);
    const respone = await DBNetwork.get(url);
    resetVCState(dispatch)(VideoChatState.DISCONNECTED);
    callback && callback(respone, null)
  }catch (error) {
    callback && callback(null, error)
  }
};

const videoChatMessageEvent = (dispatch) => async (data) => {
  NotificationCenter.sharedInstance().broadcastNotification(
    "receivedMessages",
    data
  );
  dispatch({ type: VIDEO_CHAT_MESSAGE_RECIEVED, payload: data });
};
const registerPuserEvents = (dispatch) => async (match_id) => {
  PusherWrapper.shared.unregisterNetworkToolChannels();

  PusherWrapper.shared.registerNetworkingTool(match_id, {
    videoChatDeclineEvent: (data) => videoChatDeclineEvent(dispatch)(data),
    videoChatEndEvent: (data) => videoChatEndEvent(dispatch)(data),
    videoChatMessageEvent: (data) => videoChatMessageEvent(dispatch)(data),
  });
};

export const fetchMatchedUser = (dispatch) => async (match_id, callback) => {
  const url = baseUrl(`api/video-chat/match/${match_id}`);
  const response = await DBNetwork.get(url);
  dispatch({ type: FETCH_MATCHED, payload: { ...response.data.data } });
  callback(response);
};
export const gotMatch = (dispatch) => async (match_id) => {
  const url = baseUrl(`api/video-chat/match/${match_id}`);
  const response = await DBNetwork.get(url);
  PusherWrapper.shared.unregisterNetworkToolChannels();
  dispatch({
    type: GOT_MATCH,
    payload: { ...response.data, match_id: match_id },
  });
  // resetVCState(dispatch)();
  // dispatch({ type: SET_METCH_ID, payload: { match_id: match_id } })
  registerPuserEvents(dispatch)(match_id);
  /*
    this.setFOI(response.data.data.foi);
    this.listenForVCEvents();
  
    this.setMatchedUser(response.data.data.matched_users[0]);
  */
};
export const videoChatDeclineEvent = (dispatch) => async (data) => {
  // this.props.openSnackbar("Meeting was declined by " + data.username);
  NotificationCenter.sharedInstance().broadcastNotification(
    "videoChatDeclineEvent",
    data
  );
  endChat(dispatch)(data.match_id);
  // resetVCState(dispatch)();
};

export const videoChatEndEvent = (dispatch) => async (data) => {
  // this.props.openSnackbar("Meeting was ended by " + data.username);
  // resetVCState(dispatch)();
  endChat(dispatch)(data.match_id);
};

export const videoChatGlobalRequestEvent =
  (dispatch) => async (data, details, snackbarCallback) => {
    // this.props.openSnackbar("Meeting was ended by " + data.username);
    // const {
    //   state: { videoChat: { match_id } },
    // } = useContext(Context)

    const currentState = Twilio.sharedInstance().getVideoChatState();
    if (
      data.match_id !== null &&
      currentState === VideoChatState.DISCONNECTED
    ) {
      // console.log(data);
      //this.props.openSnackbar("Meeting was requested by " + data.requesting_username);
      // window.$('.modal').modal('hide');

      // dispatch({ type: SET_METCH_ID, payload: { match_id: data?.match_id, requestedChat: true } })
      await gotMatch(dispatch)(data?.match_id);
      dispatch({
        type: SHOW_INCOMING_CALL,
        payload: { ...data, match_id: data?.match_id, requestedChat: true },
      });
      // snackbarCallback(data)
      // await videoChatAccept(dispatch)(data?.match_id);
    }
  };
export const clearIncommingCall = (dispatch) => async () => {
  dispatch({ type: CLEAR_INCOMING_CALL });
};

export const videoChatAccept = (dispatch) => async (match_id, callback) => {
  try {
    const url = baseUrl(`api/video-chat/accept/${match_id}`);
    const response = await DBNetwork.get(url);
    dispatch({ type: VIDEO_CHAT_ACCEPT, payload: response.data });
    callback && callback(response, null)
  } catch (error) {
    console.log("Error - Accept Match");
    console.error(error);
    callback && callback(null, error)
    if (!error.response) return;

    if (error.response.data.data.code === "VC_MEETING_DECLINED") {
      resetVCState(dispatch)();
    }
  }
};

export const fetchOnlineUsers =
  (dispatch) =>
  async (event, letter, page = 1, callback) => {
    const url = baseUrl(
      `api/video-chat/users/${event.id}/${letter}?page=${page}`
    );
    const response = await DBNetwork.get(url);
    if (callback) callback(response);
    dispatch({
      type: FETCH_ONLINE_USERS,
      payload: { ...response.data, current_letter: letter },
    });
  };

export const loadPreviousOnlineUsers = (dispatch) => async (letter) => {
  const {
    state: { onlineUsers },
  } = useContext(Context);

  fetchOnlineUsers(dispatch)(
    onlineUsers.current_letter,
    onlineUsers.current_page - 1
  );
};

export const loadNextOnlineUsers = (dispatch) => async (letter) => {
  const {
    state: { onlineUsers },
  } = useContext(Context);

  fetchOnlineUsers(dispatch)(
    onlineUsers.current_letter,
    onlineUsers.current_page + 1
  );
};

export const sendChat = (dispatch) => async (msg, callback) => {
  if (msg !== "") {
    console.log("SENDING THE MSG");
    try {
      const url = baseUrl("api/video-chat/chat/" + msg?.match_id);
      const response = await DBNetwork.post(url, { msg: msg.message });
      callback(response, null);
    } catch (error) {
      console.log("Error");
      callback(null, error);
    }
  }
};

export const makeAppointment = (dispatch) => async (appointment, callback) => {
  try {
    const url = baseUrl(
      "api/video-chat/" + appointment.event_id + "/make-appointment"
    );
    const response = await DBNetwork.post(url, {
      user_id: appointment.user_id,
      date: appointment.date,
    });
    dispatch({ type: ADD_APPOINTMENT, payload: response?.data?.data });
    callback(response, null);
  } catch (error) {
    console.log("Error");
    callback(null, error);
  }
};
export const updateAppointment = (dispatch) => async (appointment, callback) => {
  try {
    const url = baseUrl(
        "api/video-chat/" + appointment.event_id + "/update-appointment/" + appointment?.id
    );
    const response = await DBNetwork.post(url, {
      user_id: appointment.user_id,
      date: appointment.date,
      status: appointment.status
    });
    dispatch({ type: UPDATE_APPOINTMENT, payload: response?.data?.data });
    callback(response, null);
  } catch (error) {
    console.log("Error");
    callback(null, error);
  }
};

export const addUserToVideoChat =
  (dispatch) => async (match_id, userId, callback) => {
    try {
      const url = baseUrl(`api/video-chat/add-user/${match_id}/${userId}`);
      const response = await DBNetwork.get(url);
      callback(response, null);
    } catch (error) {
      console.log("Error");
      callback(null, error);
    }
  };

export const rollDice = (dispatch) => async (event, callback) => {
  try {
    dispatch({type: DICE_MATCHEDED_CLEAR})
    const url = baseUrl(`api/video-chat/roll-dice/${event.id}`);
    const response = await DBNetwork.get(url);
    dispatch({type: DICE_MATCHEDED, payload: response})
    callback && callback(response, null)
  } catch (error) {
    dispatch({type: DICE_MATCHEDED_CLEAR})
    callback && callback(null, error)
  }

  // await dispatch({ type: NET_TOLL_VIDEO_CONNECT, payload: langauge });
};

export const rollDiceInterestUpdate = (dispatch) => async (event, dice_list, callback) => {
  try {
    const url = baseUrl(`api/video-chat/${event.id}/users/interests`);
    const response = await DBNetwork.post(url,{"interests":dice_list});
    callback && callback(response, null)
  } catch (error) {
    callback && callback(null, error)
  }

  // await dispatch({ type: NET_TOLL_VIDEO_CONNECT, payload: langauge });
};

export const { Context, Provider } = createDataContext(
  Reducer,
  {
    init,
    gotMatch,
    declineMatch,
    videoConnect,
    videoChatAccept,
    check_for_dice_open,
    fetchOnlineUsers,
    loadPreviousOnlineUsers,
    loadNextOnlineUsers,
    sendChat,
    clearIncommingCall,
    endChat,
    setVideoState,
    makeAppointment,
    updateAppointment,
    addUserToVideoChat,
    fetchMatchedUser,
    rollDice,
    rollDiceInterestUpdate
  },
  {
    videoChat: null,
    onlineUsers: null,
    videoChatState: VideoChatState.DISCONNECTED,
  }
);
