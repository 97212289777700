import {ErrorOption} from "../Default/components/ToastOptions";
import _ from 'lodash';
import moment from 'moment';


export  const getLanQueryParam = (language) => {
    return {locale: language};
}

export const Appointment = (appointment) => {
    const _appointment = {...appointment}
    const _user = _appointment?.user;
    const _info = _appointment?.info;
    const _host_user = _appointment?.host_user;
    const _host_info = _appointment?.host_info;

    const getId = () => _appointment?.id;
    const getEventId = () => _appointment?.event_id;
    const getUserId = () => _user?.id;
    const getFullName = () => {return `${_user.name ?? ""} ${_user.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.company ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar}`};
    const getFormatedDate = () => { return moment(`${_appointment?.date}`).format("ddd DD/MM HH:mm") }
    const getRequestPayload = () => { return {id: getId(), user_id: getUserId(), event_id: getEventId()}}
    //sandeep added
    const getHostUserId = () => _host_user?.id;
    const getHostFullName = () => {return `${_host_user.name ?? ""} ${_host_user.last_name ?? ""}`.capitalize()};
    const getHostOrganization = () => {return `${_host_info?.company ?? ""}`.capitalize() }
    const getHostAvatar =  () => { return `${_host_user?.avtar}`};


    return {
        getId : () => getId(),
        getEventId : () =>  getEventId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
        getFormatedDate: () => getFormatedDate(),
        getRequestPayload: () => getRequestPayload(),
        getHostUserId: () => getHostUserId(),
        getHostFullName: () => getHostFullName(),
        getHostOrganization: () => getHostOrganization(),
        getHostAvatar: () => getHostAvatar()
    }
}

export const Error = (error) => {
    const _error = error;

    return {
        getResponseError: () =>  _error.response?.data?.message,
    }
}
export const TwillioParticipant = (participant) => {
    const _participant = {...participant}
    const _identity = _participant?.identity;

    const getUserID = () => {
        if (_identity){
            return _.split( _identity, '-')?.first()
        }
        return null
    }

    return {
        getUserID: () => getUserID()
    }
}

export const OnlineUser = (userdetails) => {
    const _user = {...userdetails}
    const _info = _user?.info;

    const getId = () => _user?.id;
    const getUserId = () => _user?.id;
    const getFullName = () => {return `${_user?.name ?? ""} ${_user?.last_name ?? ""}`.capitalize()};
    const getOrganization = () => {return `${_info?.Organization ?? ""}`.capitalize() }
    const getAvatar =  () => { return `${_user?.avtar}`};

    return {
        getId : () => getId(),
        getUserId : () => getUserId(),
        getFullName: () => getFullName(),
        getOrganization : () => getOrganization(),
        getAvatar: () => getAvatar(),
    }
}

export const PusherObject = (appointment) => {
    const _appointmentModel = {...appointment}

    const isAppointment = () => {
        return _appointmentModel?.type === "appointment"
    }

    const getObject = () => {
        return isAppointment() ? _appointmentModel?.data : null
    }

    return {
        isAppointment : () => isAppointment(),
        getObject: () => getObject()
    }
}
