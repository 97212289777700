import React from 'react';

const Experience = ({ selectedLanguage }) => {
    return <section className="wb-experience-wrap">
        <div className="container-fluid">
            <div className="wb-exp-wrap">
                {selectedLanguage === 'nl' && <h1>klaar voor een verbluffende online ervaring</h1>}
                {selectedLanguage === 'en' && <h1>ready for a stunning online experience</h1>}
                <div className="wb-btn-wrap">
                    <div className="wb-btn-group">
                        <a href="#Contact_Us" className="btn animated-button wb-btn">{selectedLanguage === 'nl' ? 'Let’s get digital!' : "Let's get digital!"}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

};

export default Experience;