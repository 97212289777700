import React from 'react'
import './css/loader.css';
export default function Loader() {

    return (
        <>
            <div className="loader-wrapper loader">
                <div className="load-wrapper">
                    <div className="loader-inner"></div>
                </div>
            </div>
        </>
    )
}
