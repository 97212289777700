import React, { useContext, useState } from "react";
import { Context as networkingToolContext } from "../../../context/NetworkTollContext";
export const Calling = () => {
  const {
    state: { videoChat },
  } = useContext(networkingToolContext);

  const [isLoading, setIsLoading] = useState(false);

  const endCall = (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (videoChat?.match_id) {
      setIsLoading(true);
      // alert("endcall")
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);

    }
  };
  return (
    <div className="dft-calling-wrap">
      <div className="emp-calling" onClick={(e) => endCall(e)}>
        <h1 className="calling-title">calling...</h1>
        {/* <a href className="hang-up-btn">
          <i className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-phone"}`} aria-hidden="true"></i> {!isLoading ? "Hang up" : ""}
        </a> */}
      </div>
    </div>
  );
};
