import React, { useContext, useEffect, useState } from "react";
import telnet from "../../../../assets/images/telenet_owler.png";
import userImagePlaceholder from "../../../../assets/user/avatarplaceholder.png";
import eventHeader from "../../../../assets/images/header.png";
import AccountModal from "./AccountModal";
import { Link } from "react-router-dom";
import HeaderLogo from "../../HeaderLogo/HeaderLogo";
import {
  imageBaseUrl,
  imageWithStorage,
} from "../../../../constants/url/urlConstants";
import logout from "../../../../hooks/logout";
import { useHistory } from "react-router";
import Location from "../../../../hooks/Location";
import {
  Context as defaultContext,
  Context as DefaultSectionContext,
} from "../../../../context/DefaultSectionContext";
import { Context as languageContext } from "../../../../context/LanguageContext";

import usePrevious from "../../../../hooks/HooksPropsChanges";
import DropDown from "../../../../UIComponets/DropDown/DropDown";
import { changeLangauge } from "../../../../context/LanguageContext";

export default function Header(props) {
  const {
    header,
    firstName,
    lastName,
    profilePic,
    user,
    setIsLoginRequired,
    event,
    security,
    options,
    dbvideo,
  } = props;

  const {
    languageChange,
    state: {
      eventsPageData: { languages, translations },
    },
  } = useContext(defaultContext);

  const {
    changeLangauge,
    state: { langauge },
  } = useContext(languageContext);
  const isPasswordSecurity = () => security === "password";
  const isPuplic = () => security === "public";
  const isRegistration = () => security === "registration";
  const isCustomHeader = () => options?.includes("custom_header");
  const [render, setReRender] = useState(false);
  const history = useHistory();
  const { onUserLoggedIn } = useContext(DefaultSectionContext);
  const [clientName, eventName] = Location();
  const onLogout = async (e) => {
    e.preventDefault();
    const pass = `${clientName}+${eventName}`;

    if (security === "password") {
      localStorage.removeItem(pass);
      history.replace(`/${clientName}/${eventName}`);
    } else {
      localStorage.removeItem(pass);
      logout();
      if (security === "registration") {
        history.replace(`/${clientName}/${eventName}/register`);
      } else {
        history.replace(`/${clientName}/${eventName}`);
      }
    }

    // await fetchEventsPage(url);
    // window.location.href = `/${client}/${event}`
    setIsLoginRequired(true);
    await onUserLoggedIn({});
    // history.replace(`/${client}/${event}`);
  };

  const onLogoutViaPass = async (e) => {
    e.preventDefault();
    // alert("Logout pass");
    // logout();
    // history.push(`/${clientName}/${eventName}/login`);
    const pass = `${clientName}+${eventName}`;
    if (security === "password" && user) {
      logout();
      history.push(`/${clientName}/${eventName}/login`);
      localStorage.removeItem(pass);
    }

    setIsLoginRequired(true);
    await onUserLoggedIn({});
  };

  const onLogin = (e) => {
    e.preventDefault();
    setIsLoginRequired(true);
    const pass = `${clientName}+${eventName}`;

    if (security === "password" && user) {
      logout();
      history.push(`/${clientName}/${eventName}`);
      localStorage.removeItem(pass);
    }
    // history.push(`/${clientName}/${eventName}/login`);
  };
  const isUser =
    user && security === "registration" ? Object.keys(user).length > 0 : null;
  const fName = firstName ? firstName : "";
  const lName = lastName ? lastName : "";
  let name;
  const LanguageMenu =
    isPasswordSecurity() || isPuplic() ? (
      <div className="db-language-wrap ml-auto">
        <div className="form-goup">
          <DropDown
            classAdd={"custom-select"}
            items={languages}
            selected={langauge}
            onChange={(data) => changeLangauge(data)}
          />
        </div>
      </div>
    ) : (
      ""
    );
  if (security === "public" && !isUser) {
    name = "Guest";
  } else {
    name = fName + " " + lName;
  }

  if (options && options.includes("hide_title")) {
    return (
      <section className="main-navigation hide">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            {isUser && security === "registration" && (
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                data-trigger="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            )}

            {security === "password" && window.innerWidth <= 767 && (
              <div className="mobile-log-out">
                <Link to={"#"} onClick={onLogoutViaPass}>
                  <i
                    className="fa fa-sign-out"
                    style={{ color: "black" }}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            )}

            {isUser ? (
              <div className="user-details-wrapper">
                <div className="user-profile-pic">
                  <img
                    src={
                      profilePic
                        ? imageWithStorage + profilePic
                        : userImagePlaceholder
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userImagePlaceholder;
                    }}
                    className="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <div className="user-detail-wrap text-center">
                  <p className="user-name text_color cmn-text-color">
                    {user?.id ? name : "Guest"}
                  </p>
                  <div className="profile-actions">
                    {isUser && (
                      <Link to={"#"} onClick={onLogout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    {security === "password" && (
                      <Link to={"#"} onClick={onLogoutViaPass}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    <div className="dropdown setting-dropdown">
                      {isUser ? (
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </button>
                      ) : null}
                      {security === "password" && window.innerWidth <= 767 && (
                        <Link to={"#"} onClick={onLogoutViaPass}>
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                        </Link>
                      )}

                      <div
                        className="dropdown-menu "
                        style={{ background: "2px solid" + event?.color }}
                        aria-labelledby="dropdownMenu2"
                      >
                        <a
                          className="dropdown-item cmn-hover-color "
                          type="button"
                          data-toggle="modal"
                          data-target="#accountModal"
                        >
                          {translations?.account?.myaccount_title}
                        </a>
                        {/* <a className="dropdown-item" type="button">My Events</a> */}
                        <a
                          href={"#"}
                          className="dropdown-item"
                          type="button"
                          onClick={onLogout}
                        >
                          {translations?.account?.logout_label}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isRegistration() && LanguageMenu}

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="offcanvas-header">
                <button className="btn btn-close float-right">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
                <a className="navbar-brand" href="#">
                  <div className="navbar-brand-image">
                    <img
                      src={
                        profilePic
                          ? imageWithStorage + profilePic
                          : userImagePlaceholder
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = userImagePlaceholder;
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              </div>
              {isUser ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#accountModal"
                    >
                      {translations?.account?.myaccount_title}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={onLogout}>
                      {translations?.account?.logout_label}
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </nav>
        </div>
      </section>
    );
  } else if (options && options.includes("custom_header")) {
    return (
      <section className="main-navigation">
        <div className="container-fluid headerUserEdit">
          <nav className="navbar navbar-expand-lg navbar-light">
            <img
              src={
                dbvideo && dbvideo["event_header"]
                  ? imageWithStorage + dbvideo["event_header"]
                  : ""
              }
              alt=""
              className="img-fluid image-headerUserEdit"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = eventHeader;
              }}
            />

            {isUser && security === "registration" && (
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                data-trigger="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            )}

            {security === "password" && window.innerWidth <= 767 && (
              <div className="mobile-log-out">
                <Link to={"#"} onClick={onLogoutViaPass}>
                  <i
                    className="fa fa-sign-out"
                    style={{ color: "black" }}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            )}

            {isUser ? (
              <div className="user-details-wrapper">
                <div className="user-profile-pic">
                  <img
                    src={
                      profilePic
                        ? imageWithStorage + profilePic
                        : userImagePlaceholder
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userImagePlaceholder;
                    }}
                    className="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <div className="user-detail-wrap text-center">
                  <p className="user-name text_color cmn-text-color">
                    {user?.id ? name : "Guest"}
                  </p>
                  <div className="profile-actions">
                    {isUser && (
                      <Link to={"#"} onClick={onLogout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    {security === "password" && (
                      <Link to={"#"} onClick={onLogoutViaPass}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    <div className="dropdown setting-dropdown">
                      {isUser ? (
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </button>
                      ) : null}
                      {security === "password" && window.innerWidth <= 767 && (
                        <Link to={"#"} onClick={onLogoutViaPass}>
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                        </Link>
                      )}

                      <div
                        className="dropdown-menu "
                        style={{ background: "2px solid" + event?.color }}
                        aria-labelledby="dropdownMenu2"
                      >
                        <a
                          className="dropdown-item cmn-hover-color "
                          type="button"
                          data-toggle="modal"
                          data-target="#accountModal"
                        >
                          {translations?.account?.myaccount_title}
                        </a>
                        {/* <a className="dropdown-item" type="button">My Events</a> */}
                        <a
                          href={"#"}
                          className="dropdown-item"
                          type="button"
                          onClick={onLogout}
                        >
                          {translations?.account?.logout_label}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isRegistration() && LanguageMenu}

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="offcanvas-header">
                <button className="btn btn-close float-right">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
                <a className="navbar-brand" href="#">
                  <div className="navbar-brand-image">
                    <img
                      src={
                        profilePic
                          ? imageWithStorage + profilePic
                          : userImagePlaceholder
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = userImagePlaceholder;
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              </div>
              {isUser ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#accountModal"
                    >
                      {translations?.account?.myaccount_title}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={onLogout}>
                      {translations?.account?.logout_label}
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </nav>
        </div>
      </section>
    );
  }
  return (
    <>
      <section className="main-navigation">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="navbar-brand" href="#">
              <HeaderLogo
                container="navbar-brand-image"
                src={imageBaseUrl + header?.logo}
                imageClassName="img-fluid"
              />

              <h2 className="navbar-brand-name">{header?.event_title}</h2>
            </div>
            {isUser && security === "registration" && (
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                data-trigger="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            )}

            {security === "password" && window.innerWidth <= 767 && (
              <div className="mobile-log-out">
                <Link to={"#"} onClick={onLogoutViaPass}>
                  <i
                    className="fa fa-sign-out"
                    style={{ color: "black" }}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            )}

            {isUser ? (
              <div className="user-details-wrapper">
                <div className="user-profile-pic">
                  <img
                    src={
                      profilePic
                        ? imageWithStorage + profilePic
                        : userImagePlaceholder
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userImagePlaceholder;
                    }}
                    className="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <div className="user-detail-wrap text-center">
                  <p className="user-name text_color cmn-text-color">
                    {user?.id ? name : "Guest"}
                  </p>
                  <div className="profile-actions">
                    {isUser && (
                      <Link to={"#"} onClick={onLogout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    {security === "password" && (
                      <Link to={"#"} onClick={onLogoutViaPass}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                      </Link>
                    )}

                    <div className="dropdown setting-dropdown">
                      {isUser ? (
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </button>
                      ) : null}
                      {security === "password" && window.innerWidth <= 767 && (
                        <Link to={"#"} onClick={onLogoutViaPass}>
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                        </Link>
                      )}

                      <div
                        className="dropdown-menu "
                        style={{ background: "2px solid" + event?.color }}
                        aria-labelledby="dropdownMenu2"
                      >
                        <a
                          className="dropdown-item cmn-hover-color "
                          type="button"
                          data-toggle="modal"
                          data-target="#accountModal"
                        >
                          {translations?.account?.myaccount_title}
                        </a>
                        {/* <a className="dropdown-item" type="button">My Events</a> */}
                        <a
                          href={"#"}
                          className="dropdown-item"
                          type="button"
                          onClick={onLogout}
                        >
                          {translations?.account?.logout_label}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {LanguageMenu}

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="offcanvas-header">
                <button className="btn btn-close float-right">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
                <a className="navbar-brand" href="#">
                  <div className="navbar-brand-image">
                    <img
                      src={
                        profilePic
                          ? imageWithStorage + profilePic
                          : userImagePlaceholder
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = userImagePlaceholder;
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              </div>
              {isUser ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#accountModal"
                    >
                      {translations?.account?.myaccount_title}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={onLogout}>
                      {translations?.account?.logout_label}
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </nav>
        </div>
      </section>
    </>
  );
}
