import React, { useContext } from 'react';
import { Context as defaultContext } from "../../../../../../../context/DefaultSectionContext";

const QandAInput = ({ que, setQueText, onSendQueEnter, onSendQue, isQueLoading, question_placeholder }) => {
    const {
        state: {
            eventsPageData: {
                translations: {
                    qa
                }
            }
        },
    } = useContext(defaultContext);
    return (
        <div className="input-group px-4 mb-2 ">
            <input type="text"
                placeholder={qa?.question_placeholder}
                value={que}
                onChange={(e) => setQueText(e.target.value)}
                onKeyDown={onSendQueEnter}
                required="required"
                className="form-control fix-rounded-right cmn-focus-border-color" />
            <div onClick={onSendQue}
                className="input-group-append">
                <span className="input-group-text bg-white">
                    <a href="#">
                        <i aria-hidden="true"
                            className={`fa ${isQueLoading ?
                                'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}>
                        </i>
                    </a>
                </span>
            </div>
        </div>
    );
};

export default QandAInput;