import React, { useContext, useState } from "react";
import { Context as networkingToolContext } from "../../../context/NetworkTollContext";
export const Disconnecting = () => {

  return (
    <div className="dft-calling-wrap">
      <div className="emp-calling">
        <h1 className="calling-title">Disconnecting...</h1>
        {/* <a href className="hang-up-btn">
          <i className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-phone"}`} aria-hidden="true"></i> {!isLoading ? "Hang up" : ""}
        </a> */}
      </div>
    </div>
  );
};
