import React, { useContext } from "react";
import { Context as AuthContext } from "../../context/AuthContext";
import ReactHtmlParser from "react-html-parser";

export const ShowEventFee = (props) => {
  const {
    state: {
      registrationPageData: { payment, dbvideo },
    },
  } = useContext(AuthContext);

  return (
    <div className="wb-splash-reg-wrapper">
      <div className="wb-splash-inner">
        {dbvideo?.payment_show ? (
          ReactHtmlParser(
            dbvideo?.payment_show.replace("[[price]]", payment.amount)
          )
        ) : (
          <div className="text-center">
            <h4 className="font-weight-bold">
              This event is only accessible after payment
            </h4>
            <p>In the next step you will be asked to fulfill the payment</p>
            <h4 className="font-weight-bold">
              Event fee: &euro; {payment.amount}
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};
