import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Context as AuthContext } from "../../context/AuthContext";
import useStyle from "../../hooks/cssLoad";
import { formReducer, FORM_INPUT_UPDATE } from "../../hooks/TextInputReducer";
import TextComponent from "../../UIComponets/TextComponent";
import dbLogo from "../../assets/images/powered.png";
import toast from "react-hot-toast";
import toastProp from "../../data/toastProp";

import { changeColor } from "../../utlis/changeColor";
const ForgetPassword = () => {
  useStyle("/script/default/defaultStyle.css");
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const buttonRef = useRef();
  const {
    forgetPassword,
    state: {
      forgetPasswordData: { status, message, event },
    },
  } = useContext(AuthContext);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: "",
    },
    inputValidities: {
      email: false,
    },
    formIsValid: false,
  });
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  const setEmailAddress = (e) => {
    if (e.key === "Enter") {
      if (buttonRef) {
        buttonRef.current.focus();
      }
    }
    setEmail(e.target.value);
  };

  const onForgetPassword = async (e) => {
    e.preventDefault();

    if (email === "") {
      toast.error(`Enter Email ID`, { toastProp });
      return;
    }
    const url = `api/forget-password`;
    setIsLoading(true);
    // let email = formState.inputValues.email;
    await forgetPassword(url, email, (response) => {
      if (response.status) {
        setIsLoading(false);
        toast.success(`Email send to ${email}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            border: "1px solid #713200",
            padding: "16px",
            zIndex: 1,
            color: "#713200",
          },
        });
      } else {
        setError(response.message);
        setIsLoading(false);
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            border: "1px solid #713200",
            padding: "16px",
            zIndex: 1,
            color: "#713200",
          },
        });
      }
    });
  };
  return (
      <section className="login-fixed-wrapper">
        <div className="container">
          <div className="login-logo-top">
            <img src={dbLogo} alt="" className="img-fluid"/>
          </div>
          <div className="login-box register-box reset-box">
            <div className="login-form-wrap vw-form-wrap">
              <form className="login-form text-center">
                {status ? (
                    <div className="alert alert-success alert-dismissible fade show">
                      <i className="fa fa-check" aria-hidden="true"></i> We have e-mail
                      your password reset link to <strong>{email}</strong>!
                    </div>
                ) : (
                    <>
                      {" "}
                      <div className="input-group mb-4 justify-content-center">
                        <div className="input-group-prepend">
                          {/* <span
                        className="input-group-text db-background"
                        id="inputUsername"
                      >
                        <i className="fa fa-lock" aria-hidden="true"></i>
                      </span> */}
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                        <span
                            //   style={{ backgroundColor: event?.color }}
                            className="input-group-text"
                            id="input-email"
                        >
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </span>
                          </div>
                          <input
                              type={"text"}
                              className="form-control"
                              value={email}
                              placeholder="Email*"
                              aria-label="Password"
                              onChange={(e) => setEmailAddress(e)}
                              aria-describedby="input-email"
                          />
                        </div>
                        {/* <TextComponent
                      id="email"
                      email
                      errorMessageContainerStyle="errorMessageContainerStyle"
                      required
                      forgetPassword
                      inputType="text"
                      errorText="Invalid Email Address!"
                      onInputChange={inputChangeHandler}
                      placeholder="Email(Required)*"
                    /> */}
                      </div>
                      {!isLoading ? (
                          <button
                              ref={buttonRef}
                              //   disabled={!formState.formIsValid}
                              onClick={onForgetPassword}
                              type="submit"
                              className={`btn btn-block my-4 vw-btn reset-btn vw-btn-yellow db-background`}
                          >
                            Reset Password
                          </button>
                      ) : (
                          <a
                              href="#"
                              className="btn btn-block my-4 vw-btn reset-btn vw-btn-yellow db-background"
                          >
                            <i
                                aria-hidden="true"
                                className={"fa fa-spinner fa-spin"}
                            ></i>
                          </a>
                      )}
                    </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
  );
};

export default ForgetPassword;
