import React, { useEffect, useState } from "react";
import Main from "../Default/components/Main/Main";
import {
  Route,
  Link,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import UserInvitation from "../Auth/userInvitation/UserInvitation";
import Streaming from "../Default/components/streaming/Streaming";
import ModeratorScreen from "../Default/components/ModeratorScreen/ModeratorScreen";
import ViewerPublicEvent from "../Default/components/PublicEvent/ViewerPublicEvent/ViewerPublicEvent";
import LoginC from "../Auth/Login/LoginC";
import Register from "../Auth/Register/Register";
import MagicLinkValidator from "../Auth/MagicLink/MagicLinkValidator";
import GS1Home from "../GS1/components/Home";
import Home from "../Default/components/Telnet/home";
import Location from "../hooks/Location";
import GS1Login from "../GS1/Login/GS1Login";
import ErrorPage from "./ErrorPage";
import ForgetPassword from "../Auth/ForgetPassword/ForgetPassword";
import ResetPassword from "../Auth/ForgetPassword/ResetPassword";
import LandingPage from "../LandingPage/LandingPage";
import LoginViaPass from "../Auth/Login/LoginViaPass";

export default function Navigator() {
  //*******************  React States And Context and varibale declaratio *******************
  const history = useHistory();
  const [isGS1, setISGS1] = useState(false);
  const [client, event] = Location();
  let currentClient;

  //*******************  React Hooks  *******************

  useEffect(() => {
    client.toLowerCase();
    if (client.toLowerCase().includes("gs1")) {
      setISGS1(true);
      currentClient = "GS1";
    }
    if (client === "login") history.push("/login");
    if (client === "register") history.push("/register");
    // if (client === 'GS1' || 'gs1') history.push('/GS1');
  }, [client]);

  //*******************  Function Declaration  *******************

  const getLayoutWrapper = (Page, props) => {
    //to render all others components except header and footer

    return <Page {...props} />;
  };

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => getLayoutWrapper(LandingPage, props)}
      />

      {/*GS1 Page*/}
      {
        <Route
          exact
          path={`/GS1/:${event}`}
          render={(props) => getLayoutWrapper(GS1Home, props)}
        />
      }
      {
        <Route
          exact
          path={`/GS1/:${event}/login`}
          render={(props) => getLayoutWrapper(GS1Login, props)}
        />
      }
      {/* Reset-Password */}
      {
        <Route
          exact
          path={`/forget-password`}
          render={(props) => getLayoutWrapper(ForgetPassword, props)}
        />
      }
      {
        <Route
          exact
          path={`/reset-password/:slug`}
          render={(props) => getLayoutWrapper(ResetPassword, props)}
        />
      }
      {/*  Standard Events*/}
      {
        <Route
          exact
          path={`/:${client}/:${event}`}
          render={(props) => getLayoutWrapper(Main, props)}
        />
      }
      <Route
        exact
        path={`/:${client}`}
        render={(props) => getLayoutWrapper(Home, props)}
      />
      <Route
        exact
        path={`/:${client}/:${event}/register`}
        render={(props) => getLayoutWrapper(Register, props)}
      />
      {/* <Route exact path={`/:${client}/:${event}/login`} render={(props) => getLayoutWrapper(Login, props)} /> */}
      {/* <Route
        exact
        path={`/:${client}/:${event}/login`}
        render={(props) => getLayoutWrapper(LoginC, props)}
      /> */}
      <Route
        exact
        path={`/:${client}/:${event}/login`}
        render={(props) => getLayoutWrapper(LoginViaPass, props)}
      />
        {/* Magic Link*/}
        {
            <Route
                exact
                path={`/:${client}/:${event}/:passcode`}
                render={(props) => getLayoutWrapper(Main, props)}
            />
        }
      <Redirect
        to={`/:${client}`}
        render={(props) => getLayoutWrapper(Main, props)}
      />
    </Switch>
  );
}
