import { Link } from "react-router-dom";
import React, { Component, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { baseUrl } from "../../../../../../constants/url/urlConstants";
import {
  OffAirPollEvent,
  OffAirPoll_Channel,
  OnAirPollEvent,
  OnAirPoll_Channel,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
} from "../../../../../../constants/Pusher/PusherConstants";
import toast from "react-hot-toast";
import DBNetwork from "../../../../../../api/DBNetwork";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Context as defaultContext,
  Context as DefaultSectionContext
} from "../../../../../../context/DefaultSectionContext";
import toastProp from "../../../../../../data/toastProp";
import { PusherEvent } from "../../../../../../hooks/PusherEvent";

const PollMenu = (props) => {
  const {
    submitPoll,
    state: {
      eventsPageData: {
        user,
        page_data: {
          poll,
          event,
          isPollAvail
        },
        translations
      },
      pusherEvent
    },
  } = useContext(defaultContext);

  useEffect(() => {
    if ((pusherEvent?.action === PusherEvent.ONAIR_POLL_UPDATE || pusherEvent?.action === PusherEvent.ONAIR_POLL) && pusherEvent?.data) {
      if (
        poll.live &&
        !window.$("#collapsePoll").hasClass("show")
      ) {
        window.$(".box-wrap-modal").removeClass("show");
        window.$(".inner-video-wrap").removeClass("toggle-active");
        window.$(".gs-box-wrap-modal").collapse("hide");
        window.$(".full-width-box").removeClass("active");
        window.$("#collapsePoll").collapse("show");
      }
    }else if (pusherEvent?.action === PusherEvent.OFFAIR_POLL){
      window.$("#collapsePoll").collapse("hide")
    }
  }, [pusherEvent]);

  const [isLoading, setIsLoading] = useState(false)
  const [pollMap, setPollMap] = useState({})
  // const [poll, setPoll] = useState(page_data?.poll)
  const [selectedId, setSelectedId] = useState(pollMap[poll.id]?.answer_id ?? pollMap[poll.id]?._answer_id);
  const userDetails = user?.id;
  const systemId = localStorage.getItem("system_id");;
  const color = event?.color;
  const eventId = event?.id;

  const onOptionSelection = (e, id) => {
    // e.preventDefault();
    let _poll = { ...poll };
    _poll._answer_id = id;
    let _pollMap = { ...pollMap };
    if (!_pollMap[_poll.id]) {
      _pollMap[_poll.id] = _poll;
    }
    _pollMap[_poll.id]._answer_id = id;
    setPollMap(_pollMap)
    setSelectedId(id)
  };

  const onSubmitPoll = async () => {
    setIsLoading(true)
    const question_id = poll?.id;
    const option_id = selectedId;
    const event_id = eventId;
    const system_id = systemId;

    if (option_id) {
      submitPoll({
        question_id,
        option_id,
        event_id,
        system_id,
      }, (response) => {
        const answer = poll?.options?.find(
            (option) => option.id === parseInt(option_id)
        )?.option;
        if (response.status) {
          toast.success(
              `🤙🏻You submitted option ${
                  // poll?.options?.find(
                  //   (x) => x.id === parseInt(poll?._answer_id)
                  // )?.option
                  answer
              }`,
              toastProp
          );
          let _poll = { ...poll };
          _poll.answer_id = option_id;

          let _pollMap = { ...pollMap };
          _pollMap[_poll.id] = { ..._poll, answer_id: option_id, _answer_id: null };
          setPollMap(_pollMap)
          setIsLoading(false)
        }
      });
    } else {
      toast(`Please Select a Value `, toastProp);
    }
  };

  let pollQuestion;
  let pollOptions;

  if (poll) {
    pollQuestion = poll?.question;
    if (poll.options?.length > 0) {
      pollOptions = poll?.options?.map((option, index) => {
        return (
            <label
                onChange={(e) => onOptionSelection(e, option.id)}
                className="checkcontainer"
                key={index}
            >
              {option?.option}
              <input
                  name="poll"
                  type="radio"
                  value={option?.id}
                  id={`rememberMe${index}`}
              />
              <span className="radiobtn"></span>
            </label>
        );
      });
    }
  }
  const Poll = poll?.answer_id !== 0 && pollQuestion ? (
      <>
        <div className="submitted-ans-wrapper">
          <div className="poll-question-wrap polling-question">
            <h4>Asked Question : {pollQuestion}</h4>
          </div>
          <div className="poll-answer-wrap polling-answer">
            <h5>
              Submitted Answer:{" "}
              {poll?.answer_id !== 0 &&
              poll?.options?.find(
                  (x) => x.id === parseInt(poll?.answer_id)
              )?.option}
            </h5>
          </div>
        </div>
      </>
  ) : (
      <form action="" className="voting-form">
        <p className="text-center">
          <b>{pollQuestion}</b>
        </p>
        <div className="db-radio-check w-50 m-auto">{pollOptions}</div>
        <div className="text-center mt-4">
          {!isLoading ? (
              <Link
                  onClick={onSubmitPoll}
                  to="#"
                  className="btn vw-btn vw-btn-black"
              >
                {translations?.poll?.send_button_text}
              </Link>
          ) : (
              <a href="#" className="btn vw-btn vw-btn-black">
                <i
                    aria-hidden="true"
                    className={"fa fa-spinner fa-spin"}
                ></i>
              </a>
          )}
        </div>
      </form>
  )
  const NoPoll = (<p className="text-center"> {translations?.poll?.noPoll ?? "No Poll Available at the moment"}</p>)
  return (
    <div className="card box-wrap-card cmn-border-color"  style={{ borderColor: color }}>
      <div className="card-header bg-white py-1 px-2">
        <div className="card-actions-wrap d-flex justify-content-end align-items-center">
          <a
            className="rcc-menu"
            href=""
            data-toggle="collapse"
            data-target="collapsePoll"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div className="card-body pt-0">
        {isPollAvail ? Poll : NoPoll}
      </div>
    </div>
  );
}

export default PollMenu;
