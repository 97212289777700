import React from 'react';
import { Link } from 'react-router-dom';

const LoaderInfiniteScroll = () => {
    return <div className="load-more-wrapper">
        <Link to="#" className="load-more-link">Loading More..</Link>
        <div className="sm-loading">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
};

export default LoaderInfiniteScroll;