import React from 'react'

export default function VideoIframe({ src, description, onCloseModal }) {
    return (<>
        {<div className="modal gs-modal gs-modal-video fade fade-scale video-modal" id="modalSingelVideo" tabIndex="-1"
            aria-labelledby="modalSingelVideoLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body gs-modal-body p-0">
                        <div className="video-popup">
                            <button type="button" onClick={onCloseModal} className="close video-pop-close" data-dismiss="modal">
                                <span aria-hidden="true">
                                    <i className="material-icons">close</i>
                                </span>
                            </button>
                            <div className="video-iframe-wrap">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe title="vimeo-player"
                                        src={src}
                                        width="640"
                                        height="360"
                                        frameBorder="0"
                                        allowFullScreen="allowFullScreen"
                                        className="embed-responsive-item">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
    )
}
