import React, { Component } from 'react';
import Pusher from 'pusher-js';
import DBNetwork from "../../../api/DBNetwork";

class TPusher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            username: '',
            chats: [],
            pusher: null
        };

    }

    componentDidMount() {
        const username = window.prompt('Username: ', 'Anonymous');
        this.setState({ username });
        let pusher = new Pusher('a6cba9d89f9b3f634590', {
            cluster: 'eu',
            encrypted: false
        });
        this.state.pusher = pusher;
        
        pusher.connection.bind('error', function (err) {
            if (err?.code === 404) {
                console.log('Over limit!');
            }
        });
        pusher.allChannels().forEach(channel => console.log(channel.name));
        const channel = pusher.subscribe('chat.' + this.props.eventId);
        channel.bind('ChatEvent', data => {
            console.log("chatEvent[DATA] " + JSON.stringify(data));
            // this.setState({ chats: [...this.state.chats, {"message": data.message}], test: '' });
        });
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleTextChange(e) {
        if (e.keyCode === 13) {

            const payload = {
                username: this.state.username,
                message: this.state.text,
                event_id: this.props.eventId
            };
            // const channel = this.state.pusher.subscribe('chat.31');
            // channel.emit('chatEvent', payload );
            DBNetwork.post('http://localhost:3000/api/send-chat-message', payload);
            // axios.post('http://localhost:5000/message', payload);
        } else {
            this.setState({ text: e.target.value });
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">

                    <h1 className="App-title">Welcome to React-Pusher Chat</h1>
                </header>

            </div>
        );
    }
}

export default TPusher;
