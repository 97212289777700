import React, { useContext } from 'react';
import { Context as defaultContext } from "../../../../../../../context/DefaultSectionContext";

const ChatSearch = () => {
    const {
        state: {
            eventsPageData: {
                translations: {
                    chat
                }
            }
        },
    } = useContext(defaultContext);
    return (
        <form className="form-inline" action="#">
            <div className="input-group input-group-sm">
                <input className="form-control "
                    type="search"
                    placeholder={chat?.search_placeholder}
                    aria-label="Search" />
                <div className="input-group-append ">
                    <button className="btn btn-navbar border" type="button">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ChatSearch;