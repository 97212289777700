import React from 'react'
import QandAccordian from '../../../../../../UIComponets/QandAaccordian/QandAaccordian'
import Chat from '../Chat/Chat'
import Pusher from 'pusher-js'
import DBNetwork from "../../../../../../api/DBNetwork";
import { PUSHER_APP_KEY, PUSHER_CLUSTER, QAAnswerEvent, QA_Answer_Channel } from '../../../../../../constants/Pusher/PusherConstants';
import { baseUrl } from '../../../../../../constants/url/urlConstants';
import QandAChat from '../../../../../../UIComponets/QandAChat/QandAChat';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import PusherWrapper from '../../../../../../hooks/PusherWrapper';
class QandAModal extends React.Component {
    chatContainer = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            que: '',
            user: '',
            q_and_a: props.q_and_a ? props.q_and_a : [],
            eventId: props.eventId,
            pusher: null,
            isLoading: false,
            isQueLoading: false,
        }
        // this.onTextChangeInput = this.onTextChangeInput.bind(this);
        // this.onKeyDown = this.onKeyDown.bind(this);
    }

    setQueText = (message) => {
        this.setState((prevState, props) => ({
            ...prevState, que: message,
        }));
    };

    onTextChangeInput = (value, index, queId) => {
        const q_and_aList = [...this.state.q_and_a];
        let answer = { ...this.state.q_and_a[index] };
        answer.answer = value;
        q_and_aList[index] = answer;
        this.setState((prevState, props) => ({
            ...prevState, q_and_a: q_and_aList,
        }));

    }


    setUser = (userName) => {
        this.setState((prevState, props) => ({
            ...prevState, user: userName
        }));
    };

    setQue = (que) => {

        this.setState(prevState => ({
            ...prevState,
            q_and_a: [...prevState.q_and_a, que]
        }))
        // this.setState((prevState, props) => ({
        //     ...prevState, q_and_a:this.state.q_and_a, que]
        // }));
    };
    componentWillUnmount() {
        if (this.props.eventId && QA_Answer_Channel && this.props.userId) {
            const channel = this.props.eventId + QA_Answer_Channel + this.props.userId;
            const list = [channel];
            PusherWrapper.sharedInstance().removeChannels(list);
        }

    }

    componentDidMount() {

        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;
        
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(this.props.eventId + QA_Answer_Channel + this.props.userId);
            channel.bind(QAAnswerEvent, data => {
                const index = this.state.q_and_a.findIndex(x => x.id === data.qandAQuestion.id);
                if (index !== -1) {
                    let list = [...this.state.q_and_a]
                    let answer = [...this.state.q_and_a[index].answers || [], data?.qandAAnswer]
                    list[index].answers = answer
                    this.setState((prevState, props) => ({
                        ...prevState, q_and_a: list,
                    }), () => this.scrollToMyRef()
                    );
                    toast(`New answer received for ${this.state.q_and_a[index].question}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }
    }


    // ********************** On Que send Function. **********************

    onSendQueEnter = (e) => {

        if (e.key === 'Enter' && this.state.que.length > 0) {
            const url = baseUrl('api/send-question');
            this.setState((prevState, props) => ({
                ...prevState, isQueLoading: true,
            }));

            const payload = {
                username: this.state.username,
                question: this.state.que,
                event_id: this.props.eventId
            };
            DBNetwork.post(url, payload
            ).then(response => {
                // this.setQue(response.data.message);
                this.setQueText('');
                this.setState(prevState => ({
                    ...prevState,
                    q_and_a: [response.data, ...prevState.q_and_a],
                    isQueLoading: false
                }))

            });

        }
    }
    onSendQue = (e) => {
        e.preventDefault();
        if (this.state.que.length > 0) {
            e.currentTarget.setAttribute('disabled', 'disabled');
            this.setState((prevState, props) => ({
                ...prevState, isQueLoading: true,
            }));
            const url = baseUrl('api/send-question');
            this.setState((prevState, props) => ({
                ...prevState, isQueLoading: true,
            }));

            const payload = {
                username: this.state.username,
                question: this.state.que,
                event_id: this.props.eventId
            };

            DBNetwork.post(url, { ...payload }
            ).then(response => {
                this.setQueText('');
                this.setState(prevState => ({
                    ...prevState,
                    q_and_a: [response.data, ...prevState.q_and_a],
                    isQueLoading: false
                }));
                e.currentTarget?.removeAttribute('disabled');
            });
        }
    }
    // ********************** On Send Message Function. **********************

    onSendMessage = (e, question_id, index) => {
        e.preventDefault();
        if (this.state.q_and_a[index]?.answer && this.state.q_and_a[index].answer?.length > 0) {
            this.setState((prevState, props) => ({
                ...prevState, isLoading: true,
            }));
            let currentTarget = e.currentTarget;
            currentTarget?.setAttribute('disabled', 'disabled');

            const url = baseUrl('api/send-answer');
            // const username = window.prompt('Username: ');
            this.setUser('Harshal');
            const payload = {
                username: this.state.username,
                question_id,
                event_id: this.props.eventId,
                answer: this.state.q_and_a[index].answer,

            };
            DBNetwork.post(url, payload
            ).then(response => {
                // this.setChats(response.data.message);
                if (response.data) {
                    let list = [...this.state.q_and_a];
                    list[index].answer = ''
                    if (response.data) {
                        let list = [...this.state.q_and_a];
                        list[index].answer = ''
                        if (response.data) {
                            this.state.q_and_a[index].answer = '';
                            this.setState((prevState, props) => ({
                                ...prevState, isLoading: false,
                                q_and_a: list
                            }));
                            currentTarget?.removeAttribute('disabled');

                        }
                    }
                }

            });
        }
    }


    onSendMessageEnter = (e, question_id, index) => {
        if (e.key === 'Enter') {

            this.setState((prevState, props) => ({
                ...prevState, isLoading: true,
            }));
            const url = baseUrl('api/send-answer');
            // const username = window.prompt('Username: ');
            this.setUser('Harshal');
            const payload = {
                username: this.state.username,
                question_id,
                event_id: this.props.eventId,
                answer: this.state.q_and_a[index].answer,

            };
            DBNetwork.post(url, { ...payload }
            ).then(response => {
                // this.setChats(response.data.message);
                let list = [...this.state.q_and_a];
                list[index].answer = ''
                if (response.data) {
                    this.state.q_and_a[index].answer = '';
                    this.setState((prevState, props) => ({
                        ...prevState, isLoading: false,
                        q_and_a: list
                    }));
                }

            });
        }


    }

    scrollToMyRef = () => {
        const scroll =
            this.chatContainer.current.scrollHeight -
            this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
    };
    render() {

        // *********** Variable declarations. ***********
        let questions;
        const question = [...this.state?.q_and_a];

        // ********************** Data Render. **********************

        if (question?.length > 0) {
            questions = question?.map((que, i) => {
                return <React.Fragment key={i}>
                    <div className="card popup-card-wrapper">
                        <div className="card-header p-0" id={`headingQAChatGS1${i}`}>
                            <h3 className="mb-0 d-flex justify-content-between">
                                <a className="btn btn-link collapsed" type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseQAChatGS1${i}`}
                                    aria-expanded="false"
                                    aria-controls={`collapseQAChatGS1${i}`}>
                                    {que?.question}
                                    <div className="d-flex QA-meta-text">
                                        <div className="time">{que?.time}</div>
                                        <div className="name">{que?.user_name}
                                        </div>
                                    </div>
                                </a>
                            </h3>
                            <div></div>
                            <div ref={this.chatContainer}>
                                <QandAChat index={i}
                                    attributes={{
                                        id: `collapseQAChatGS1${i}`,
                                        class: "collapse",
                                        'aria-labelledby': `#headingQAChatGS1${i}`,
                                        'data-parent': `#accordionQA`
                                    }}
                                    chatMessages={que?.answers}
                                    userDetails={this.props.userId}  >
                                    <div className="input-group mb-2">
                                        <input value={que.answer ? que.answer : ''}
                                            onChange={(e) => this.onTextChangeInput(e.target.value, i, que.id)}
                                            type="text"
                                            className="form-control fix-rounded-right"
                                            placeholder="Type Message"
                                            onKeyDown={(e) => this.onSendMessageEnter(e, que.id, i)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text bg-white">
                                                <Link onClick={(e) => this.onSendMessage(e, que.id, i)}
                                                    to="#">
                                                    <i className={`fa ${this.state.isLoading ? 'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}
                                                        aria-hidden="true">
                                                    </i>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </QandAChat >
                            </div>
                        </div>
                    </div>
                </React.Fragment >

            })
        }

        // ********************* Main Returned JSX code *****************
        return (
            <div id="QA-box-wrapper" aria-labelledby=""
                className="QA-box-wrap gs-box-wrap-modal full-width-box rounded-0">
                <div className="card box-wrap-card">
                    <div className="card-header bg-white p-2 border-0">
                        <div
                            className="card-actions-wrap d-flex justify-content-end align-items-center">
                            <a href="" data-target="#QA-box-wrapper"
                                className="smallIframe">
                                <i aria-hidden="true"
                                    className="fa fa-times"></i></a></div>
                    </div>
                    <div className="input-group px-4 mb-2">
                        <input type="text"
                            placeholder="Ask your question"
                            value={this.state.que}
                            onChange={(e) => this.setQueText(e.target.value)}
                            onKeyDown={this.onSendQueEnter}
                            required="required"
                            className="form-control fix-rounded-right"
                        />
                        <div className="input-group-append">
                            <span className="input-group-text bg-white">
                                <a onClick={this.onSendQue} href="#">
                                    <i aria-hidden="true" className={`fa ${this.state.isQueLoading ? 'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}>
                                    </i>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="card-body gs-card-body-scrollbar p-0 m-3">
                        <div id="accordionQA" className="accordion event-info-accordion QA-accordion">
                            <div className="card popup-card-wrapper">
                                {questions}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default QandAModal;
