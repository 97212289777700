import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import renderHTML from 'react-render-html';
import useScript from '../../../../hooks/useScript';
export default function DailyTrivia({ iframe }) {

    (function () { var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName, id = "typef_orm", b = "https://embed.typeform.com/"; if (!gi.call(d, id)) { js = ce.call(d, "script"); js.id = id; js.src = b + "embed.js"; q = gt.call(d, "script")[0]; q.parentNode.insertBefore(js, q) } })()
    return (
        <div data-hash='#DailyTrivia' className="modal gs-modal fade fade-scale"
             id="daily_trivia_modal"
             tabIndex="-1" aria-labelledby="modalTriviaLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header">
                        <div className="page-heading d-flex">
                            <h4 className="modal-title page-title text-capitalize">Daily Trivia</h4>
                        </div>
                        <button type="button" data-dismiss="modal" className="close"><span aria-hidden="true"><i
                            className="material-icons">close</i></span></button>
                    </div>
                    <div className="modal-body gs-modal-body dailyTri-modal-body">
                        {/* {renderHTML('<div class="typeform-widget" data-url="https://form.typeform.com/to/KaDqNZyU?typeform-medium=embed-snippet" data-transparency="50" data-hide-headers="true" data-hide-footer="true" style="width: 100%; height: 100%;"></div> ')} */}

                        <div className="typeform-widget"
                             data-url="https://form.typeform.com/to/VOfnWFtP?typeform-medium=embed-snippet"
                             data-transparency="50"
                             data-hide-headers="true"
                             style={{width: '100%', height: "90%"}}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
