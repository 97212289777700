import React, { useContext, useEffect, useState } from 'react'
import { Calling } from './Calling'
import { CardProfile } from './CardProfile'
import { Link } from 'react-router-dom'
import { LetterPagination } from './LetterPagination'
import { OneDayAppointment } from './OneDayAppointment'
import { IncommingCall } from './IncommingCall'
import { Context as networkingToolContext } from './../../../context/NetworkTollContext';
import { Context as defaultContext, Context as defaultSectionContext } from './../../../context/DefaultSectionContext';
import VideoChat from '../VideoChat/VideoChat'
import { PlayerChat } from './PlayerChat'
import { ListProfile } from './ListProfile'
import { AppointmentModal } from './AppointmentModal'
import NotificationCenter from '../../../hooks/NotificationCenter'
import { OneToOne } from './OneToOne'
import { VideoChatState } from '../../../hooks/VideoChatState'
import OnlineUsers from './OnlineUsers'
import Dice from "./Dice";
import { Disconnecting } from "./Disconnecting";
export const Base = () => {
  const {
    state: {
      eventsPageData: { page_data },
    },
  } = useContext(defaultContext);

  const {
    fetchOnlineUsers,
    state: {
      details,
      onlineUsers,
      videoChatState
    }
  } = useContext(networkingToolContext);

  const [currentLatter, setCurrentLatter] = useState("all");
  const [appointmentUser, setAppointmentUser] = useState(null);


  useEffect(() => {
    if (details) {
      fetchOnlineUsers(details?.event, currentLatter);
    }
  }, [details])

  const onlineUsersEl = onlineUsers?.users?.map((user, index) => {
    return (<CardProfile
      setAppointmentUser={setAppointmentUser}
      // key={index}
      event={details?.event}
      user={user}
    />
    );
  })

  const nt_rolling_dice = page_data?.networking_tool?.config?.nt_rolling_dice ?? "false";

  const isOnlineUser = () => videoChatState === VideoChatState.DISCONNECTED || videoChatState === VideoChatState.RINGING || videoChatState === VideoChatState.CALLING
  const isConnected = () => videoChatState === VideoChatState.CONNECTED || videoChatState === VideoChatState.DISCONNECTING
  const isDisconnecting = () => videoChatState === VideoChatState.DISCONNECTING
  const isCalling = () => videoChatState === VideoChatState.CALLING
  const isRollingDiceEnable = () => Boolean.parse(nt_rolling_dice)
  const isRollingDice = () => isRollingDiceEnable() && (videoChatState === VideoChatState.DISCONNECTED || videoChatState === VideoChatState.RINGING || videoChatState === VideoChatState.CALLING)

  if (isRollingDice())
    return (
      <section className="networking-wrapper" id="video-chat-section">
        <div className="networking-section">
          <div className="container-fluid">
            <div className="heading-wrap">
              <span>{details?.networking_tool?.config?.nt_title}</span>
            </div>
            <div className="df-networking-wrap" style={{backgroundColor:details?.networking_tool?.config?.nt_bg_color}}>
              <Dice disabled={isCalling()} />
              <IncommingCall />
              {isCalling() && <Calling />}
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="networking-wrapper" id="video-chat-section">
      <div className="networking-section">
        <div className="container-fluid">
          <div className="heading-wrap">
            <span>{details?.networking_tool?.config?.nt_title}</span>
          </div>
          <div className="df-networking-wrap" style={{backgroundColor:details?.networking_tool?.config?.nt_bg_color}}>
            {isOnlineUser() && <OnlineUsers />}
            <AppointmentModal />
            {/* Start 1 on 1 Section */}
            {isCalling() && <Calling />}
            {isDisconnecting() && <Disconnecting />}
            {/* <Calling /> */}
            <IncommingCall />
            {isConnected() && <OneToOne />}
          </div>
        </div>
      </div>
    </section>
  );
}
