import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context as networkingToolContext } from "../../../../../../src/context/NetworkTollContext";
import { useToasts } from "react-toast-notifications";
import Img from "../../Img";
import placeholder from "../../../../../assets/user/avatarplaceholder.png";

export const AppointmentProfile = ({ user }) => {
  const {
    addUserToVideoChat,
    state: { videoChat },
  } = useContext(networkingToolContext);
  const { addToast } = useToasts();

  const addUserToVideoChatRequest = () => {
    addUserToVideoChat(videoChat?.match_id, user?.id, (response, error) => {
      if (error) {
        console.error(error);
      }
    });
  };
  return (
    <div className="dft-client-info-wrap">
      <div className="dft-client-img-wrap">
        <Img
          src={user?.avatar}
          className="img-fluid"
          placeholder={placeholder}
        />
        <h3>{user?.name + " " + user.last_name}</h3>
      </div>
      <div className="dft-client-details-wrap">
        <div className="dft-appoint-action-wrap">
          {user?.info?.linkedin_link && (
            <Link to={user?.info?.linkedin_link}>
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
            </Link>
          )}
          {user?.info?.twitter_link && (
            <Link to={user?.info?.twitter_link}>
              <i className="fa fa-twitter-square" aria-hidden="true"></i>
            </Link>
          )}
          {user?.info?.facebook_link && (
            <Link to={user?.info?.facebook_link}>
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
            </Link>
          )}
        </div>
        <div className="appoint_action_btn">
          <Link to="#" className="btn btn-join">
            <i className="fa fa-plus" aria-hidden="true"></i>Join
          </Link>
        </div>
      </div>
    </div>
  );
};
