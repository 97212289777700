import React, { Component } from "react";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import image from "../../../../../../assets/images/breakout-room.png";
import {
  Breakout_Channel,
  Breakout_Event,
} from "../../../../../../constants/Pusher/PusherConstants";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import { Context as DefaultSectionContext } from "../../../../../../context/DefaultSectionContext";
import LoaderInfiniteScroll from "../../../../../../UIComponets/LoaderInfiniteScroll/LoaderInfiniteScroll";
const infiniteScroll = {
  height: "auto",
  overflowY: "auto",
  overflowX: "hidden",
};
class BreakOutRoomMobile extends Component {
  static contextType = DefaultSectionContext;
  constructor(props) {
    super(props);
    this.state = {
      rooms: props.rooms || [],
      hasMore: true,
      eventsId: props.eventId,
    };
  }

  componentWillUnmount() {
    if (this.props.eventId) {
      const eventId = this.props.eventId;
      const list = [Breakout_Channel + eventId];
      PusherWrapper.sharedInstance().removeChannels(list);
    }
  }

  componentDidMount() {
    // window.$(".mobile-carousel").owlCarousel({
    //   loop: true,
    //   nav: false,
    //   autoplay: true,
    //   dots: false,
    //   navText: [
    //     '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    //     '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    //   ],
    //   autoplayTimeout: 6000,
    //   items: 1,
    // });

    if (this.state.pusher == null) {
      let pusher = PusherWrapper.sharedInstance().pusher();

      this.state.pusher = pusher;
      pusher.log = (msg) => {
        // console.log("Pusher[log] " + msg);
      };
      pusher.connection.bind("error", function (err) {
        if (err?.code === 404) {
          console.log("Over limit!");
        }
      });
      // pusher.allChannels().forEach(channel => console.log(channel.name));
      const channel = pusher.subscribe(Breakout_Channel + this.props.eventId);
      channel.bind(Breakout_Event, (data) => {
        console.log(data);
        let rooms = [...this.state.rooms];
        let breakOutRoom = { ...data.brackoutroom };
        const index = this.state.rooms.findIndex(
          (x) => x.id === breakOutRoom.id
        );
        if (index !== -1) {
          let updatedRoom = { ...rooms[index] };

          if (breakOutRoom?.deleted) {
            rooms.splice(index, 1);
          } else {
            updatedRoom.thumbnail = breakOutRoom?.thumbnail;
            updatedRoom.name = breakOutRoom?.name;
            updatedRoom.link = breakOutRoom?.link;
            updatedRoom.file = breakOutRoom?.file;
            updatedRoom.status = breakOutRoom?.status;
            updatedRoom.url_link = breakOutRoom?.url_link;
            updatedRoom.position = breakOutRoom.position;
            rooms[index] = { ...updatedRoom };
          }

          // updatedRoom.category = breakOutRoom.category;

          // if (this.props.setIsBreakoutRoomLive) {
          //   this.props.setIsBreakoutRoomsLive(
          //     updatedRoom.filter((rooms) => rooms?.status === 1)?.length
          //   );
          //   console.log("setIsBreakoutRoomLive");
          // }
          this.setState((prevState, props) => ({
            ...prevState,
            rooms: rooms,
          }));
          this.props.setIsBreakoutRoomsLive(
            rooms.filter((rooms) => rooms?.status === 1)?.length
          );
        } else {
          let updatedRoom = [breakOutRoom, ...this.state.rooms];
          this.setState((prevState, props) => ({
            ...prevState,
            rooms: updatedRoom,
          }));
          if (this.props.setIsBreakoutRoomLive) {
            this.props.setIsBreakoutRoomsLive(
              updatedRoom.filter((rooms) => rooms?.status === 1)?.length
            );
          }
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rooms: nextProps.rooms || [] });
  }
  fetchMoreData = async () => {
    const {
      fetchBreakoutRoomData,
      state: {
        eventsPageData: {
          page_data: { event },
        },
      },
    } = this.context;
    const payload = {
      event_id: event?.id,
      length: this.state.rooms?.length,
    };
    try {
      await fetchBreakoutRoomData(payload, (response) => {
        // console.log(response)
        if (response?.length < 6) {
          this.setState((pre, next) => {
            return {
              ...pre,
              hasMore: false,
            };
          });
          return;
        }
        if (response) {
          this.setState((previousState) => ({
            ...previousState,
            rooms: [...previousState.rooms, ...response],
          }));
        }
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  render() {
    let rooms = [...this.state.rooms];
    let breakoutRooms;
    if (this.props.socketOn) {
      return <></>;
    }
    let position = rooms?.sort((a, b) => a.position - b.position);

    let size = 2;
    let chunkedBreakoutRooms = [];
    for (let i = 0; i < position.length; i += size) {
      chunkedBreakoutRooms.push(position.slice(i, i + size));
    }
    if (chunkedBreakoutRooms?.length > 0) {
      breakoutRooms = chunkedBreakoutRooms?.map((video_items, index) => {
        return (
            <div key={index} className="item">
              {video_items.map((room, i) => {
                if (!room.status) return;
                return (
                    <div key={i} className="breakout-rooms-item">
                      <div className="card border-0 rounded-0">
                        <div className="card-body border-0">
                          <a
                              target={room?.target}
                              href={room?.link}
                          >
                            <div className="brk-room-image-frame">
                              <img
                                  src={imageWithStorage + room?.thumbnail}
                                  className="img-fluid"
                                  alt={room?.name}
                              />
                            </div>
                          </a>
                          <div className="brk-room-details-wrap">
                            <div className="brk-room-chapter">
                              {room?.file && (
                                  <a
                                      className="mr-2"
                                      href={imageWithStorage + room?.file}
                                      download
                                      target="_blank"
                                  >
                                    <i className="fa fa-copy" aria-hidden="true"></i>
                                  </a>
                              )}
                              <a
                                  href={room?.link ? room?.link : "#"}
                                  target={room?.target}
                              >
                                {room?.name}
                              </a>
                            </div>
                            {room?.url_link && (
                                <div className="brk-room-links">
                                  <a
                                      target={room?.target}
                                      href={room?.url_link ? room?.url_link : "#"}
                                  >
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                  </a>
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })}
            </div>
        );
      });
    } else {
      return <div></div>;
    }

    let isAnyLive = rooms?.filter((room) => room.status === 1).length;
    return (
        <section className="breakout-rooms-wrapper">
          <div className="breakout-rooms-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="owl-carousel owl-theme breakout-carousel mobile-carousel">
                    {!isAnyLive ? (
                        <></>
                    ) : (
                        breakoutRooms
                        // <InfiniteScroll
                        //   dataLength={this.state.rooms?.length}
                        //   next={this.fetchMoreData}
                        //   hasMore={this.state.hasMore}
                        //   style={infiniteScroll}
                        //   loader={<LoaderInfiniteScroll />}
                        //   // endMessage={
                        //   //   <p
                        //   //     className="scrollendMsg"
                        //   //     style={{ textAlign: "center" }}
                        //   //   ></p>
                        //   // }
                        // >
                        //   <div className="row"></div>
                        // </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default BreakOutRoomMobile;
