import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  formReducer,
  FORM_INPUT_UPDATE,
} from "../../../../hooks/TextInputReducer";
import { useHistory } from "react-router";
import Select from "react-dropdown-select";
import contries from "../../../../data/contries";
import RadioButton from "../../../../UIComponets/radioButton/RadioButton";
import TextComponent from "../../../../UIComponets/TextComponent";
import userImage from "../../../../assets/images/user1-128x128.jpg";
import { Context as AuthContext } from "../../../../context/AuthContext";
import {
  Context as defaultContext,
  Context as DefaultSectionContext,
} from "../../../../context/DefaultSectionContext";
import ProfilePicPlaceholder from "../../../../assets/user/avatarplaceholder.png";
import logout from "../../../../hooks/logout";
import Location from "../../../../hooks/Location";
import { base64Encode } from "../../../../hooks/Base64Encode";
import { useToasts } from "react-toast-notifications";
import toast from "react-hot-toast";
import toastProp from "../../../../data/toastProp";
import { changeColor } from "../../../../utlis/changeColor";
import DropDown from "../../../../UIComponets/DropDown/DropDown";
import {
  changeLangauge,
  Context as languageContext,
} from "../../../../context/LanguageContext";
import { imageWithStorage } from "../../../../constants/url/urlConstants";
import { Context as networkingToolContext } from "./../../../../context/NetworkTollContext";
import { AppointmentList } from "./Appointment/AppointmentList";

const AccountModal = ({ user, event, setIsLoginRequired, security }) => {
  const [client, eventSlug] = Location();
  const history = useHistory();
  const [country, setCountry] = useState(
    user?.country_code ? user.country_code : "Belgium"
  );
  const [radioButton, setRadioButton] = useState(user?.gender);

  const [profilePicName, setProfilePicName] = useState(null);
  const [fileBase64String, setFileBase64String] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToasts();

  const { updateProfile } = useContext(AuthContext);
  const {
    onUserLoggedIn,
    updateUserDetails,
    state: {
      eventsPageData: { languages, translations },
    },
  } = useContext(DefaultSectionContext);
  const {
    changeLangauge,
    state: { langauge },
  } = useContext(languageContext);
  const [currentLanguage, setCurrentLanguage] = useState(langauge);

  const {
    state: { details },
  } = useContext(networkingToolContext);

  const appointments = details?.networking_tool?.appointments.map((a,i) => {
    return <AppointmentList key={i} appointment={a} />;
  });

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      name: "",
      last_name: "",
      // email: '',
      phone: "",
      // password: '',
    },
    inputValidities: {
      name: false,
      last_name: false,
      // email: false,
      phone: false,
      // password: false,
    },
    formIsValid: false,
  });
  const [formStateExtra, dispatchFormStateExtra] = useReducer(formReducer, {
    inputValues: {
      facebook_link: "",
      linkednin_link: "",
      twitter_link: "",
      company: "",
      function: "",
    },
  });
  const [profilePic, setProfilePic] = useState(
    user?.avtar ? imageWithStorage + user?.avtar : null
  );
  const accountTranslations = translations?.account;
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      }); 
    },
    [dispatchFormState]
  );

  const inputExtraChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormStateExtra({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormStateExtra]
  );

  useEffect(() => {
    setRadioButton(user?.gender);
    setCountry(user?.country_code ? user.country_code : "Belgium");
  }, []);

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        // console.log(Base64);
        setFileBase64String(Base64);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  const setProfileImage = (e) => {
    e.preventDefault();
    encodeFileBase64(e.target.files[0]);
    setProfilePic(URL.createObjectURL(e.target.files[0]));
    setProfilePicName(e.target.files[0].name);
  };
  const countriesList = contries.map((country, i) => (
    <option key={i}>{country.name}</option>
  ));

  const onUpdateProfile = async (e) => {
    const url = `/${client}/${event?.slug}/user-update`;
    let data = Object.fromEntries(
      Object.entries(formState.inputValues).filter(
        ([_, second]) => second != ""
      )
    ); // see below for description.
    data.gender = radioButton;
    data.avatar = fileBase64String;
    data.user_id = user?.id;
    data.country = country;
    if (currentLanguage) {
      data.language = currentLanguage;
    }
    setIsLoading(true);
    try {
      await updateProfile(url, data, (response) => {
        // console.log(response)
        if (response.data.status) {
          toast.success(response?.data.message, toastProp);
          updateUserDetails(response?.data?.user);
          setIsLoading(false);
          window.$("#model_button").click();
          setTimeout(() => {
            changeLangauge(currentLanguage);
          }, 1000);
        } else {
          toast.success(response?.data.message, toastProp);
          setIsLoading(false);
        }
      });
    } catch (err) {
      addToast("Something went wrong! try again", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsLoading(false);
    }
  };

  const onUpdateExtraDetails = async (e) => {
    const url = `/${client}/${event?.slug}/user-extra-update`;
    setIsLoading(true);
    try {
      await updateProfile(
        url,
        { userMeta: formStateExtra.inputValues },
        (response) => {
          // console.log(response)
          if (response.data.status) {
            toast.success(response?.data.message, toastProp);
            updateUserDetails(response?.data?.user);
            setIsLoading(false);
            window.$("#model_button").click();
            setTimeout(() => {
              changeLangauge(currentLanguage);
            }, 1000);
          } else {
            toast.success(response?.data.message, toastProp);
            setIsLoading(false);
          }
        }
      );
    } catch (err) {
      addToast("Something went wrong! try again", {
        appearance: "error",
        autoDismiss: true,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    changeColor(
      event?.color,
      `.checkcontainer input:checked ~ .radiobtn`,
      `{border:${event?.color}!important;}`
    );
  }, []);
  const onLogout = async (e) => {
    e.preventDefault();
    logout();
    setIsLoginRequired(true);
    await onUserLoggedIn({});
  };
  if (security === "public" && !user) {
    return <></>;
  }
  return (
    <>
      <div
        className="modal fade profile-modal"
        id="accountModal"
        tabIndex="-1"
        aria-labelledby="accountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h3 className="modal-title cmn-text-color" id="accountModalLabel">
                {accountTranslations?.myaccount_title}
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="model_button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="account-modal-wrap">
                <div className="poped-account">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link cmn-border-color active"
                          id="myAccount-tab"
                          data-toggle="pill"
                          href="#myAccount"
                          role="tab"
                          aria-controls="myAccount"
                          aria-selected="true"
                        >
                          Account Details
                        </a>
                        <a
                          className="nav-link"
                          id="network-profile-tab"
                          data-toggle="pill"
                          href="#network-profile"
                          role="tab"
                          aria-controls="network-profile"
                          aria-selected="false"
                        >
                          {accountTranslations?.network_profile_label}
                        </a>
                        <a
                          className="nav-link"
                          id="my-appointments-tab"
                          data-toggle="pill"
                          href="#my-appointments-content"
                          role="tab"
                          aria-controls="my-appointments-content"
                          aria-selected="false"
                        >
                          {accountTranslations?.my_appointments_label}
                        </a>
                        <a
                          onClick={onLogout}
                          className="nav-link"
                          id="logout-profile-tab"
                          data-toggle="pill"
                          href="#logout-profile"
                          role="tab"
                          aria-controls="logout-profile"
                          aria-selected="false"
                        >
                          {accountTranslations?.logout_label}
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="myAccount"
                          role="tabpanel"
                          aria-labelledby="myAccount-tab"
                        >
                          <div className="account-from-wrap">
                            <form action="" method="post">
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-12 col-md-3">
                                  <div className="image-frame">
                                    {!profilePic && (
                                      <img
                                        src={
                                          user?.avtar
                                            ? user?.avtar
                                            : ProfilePicPlaceholder
                                        }
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = user?.avtar;
                                        }}
                                        className="img-responsive img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                    {profilePic && (
                                      <img
                                        src={profilePic}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = ProfilePicPlaceholder;
                                        }}
                                        className="img-responsive img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* {fileBase64String} */}
                                </div>
                                <div className="col-12 col-sm-12 col-md-9">
                                  <div className="account-form-div">
                                    <TextComponent
                                      id="name"
                                      containerStyle="form-goup form-row "
                                      labelStyle="col-4"
                                      inputStyle="col-8"
                                      labelName={
                                        accountTranslations?.first_name_label
                                      }
                                      // initiallyValid={formState.inputValues.firstName === ''}
                                      label
                                      required
                                      inputType="text"
                                      errorText="*Please enter First Name."
                                      initialValue={user?.name}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputChangeHandler}
                                      placeholder=""
                                    />
                                    <TextComponent
                                      id="last_name"
                                      containerStyle="form-goup form-row "
                                      labelStyle="col-4"
                                      inputStyle="col-8"
                                      labelName={
                                        accountTranslations?.last_name_label
                                      }
                                      // initiallyValid={formState.inputValues.lastName === ''}
                                      label
                                      required
                                      inputType="text"
                                      errorText="*Please enter Last Name."
                                      initialValue={user?.last_name}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputChangeHandler}
                                      placeholder=""
                                    />
                                    <div className="form-goup form-row">
                                      <label className="col-4">
                                        {accountTranslations?.country_label}
                                      </label>
                                      <select
                                        value={country}
                                        onChange={(e) =>
                                          setCountry(e.target.value)
                                        }
                                        className="col-8"
                                      >
                                        {countriesList}
                                      </select>
                                    </div>
                                    <div className="form-goup form-row">
                                      <label className="col-4">
                                        {accountTranslations?.language_label}
                                      </label>
                                      <DropDown
                                        classAdd={"col-8"}
                                        items={languages}
                                        selected={currentLanguage}
                                        onChange={(data) =>
                                          setCurrentLanguage(data)
                                        }
                                      />
                                    </div>
                                    <TextComponent
                                      id="phone"
                                      containerStyle="form-goup form-row "
                                      labelStyle="col-4"
                                      inputStyle="col-8"
                                      labelName={
                                        accountTranslations?.phone_label
                                      }
                                      label
                                      required
                                      minLength={5}
                                      inputType="number"
                                      errorText="*Please valid phone number."
                                      initialValue={user?.phone}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputChangeHandler}
                                      placeholder=""
                                    />
                                    <div className="form-group form-row">
                                      <label className="col-4">
                                        {accountTranslations?.gender_label}
                                      </label>
                                      <div className="col-8 d-flex justify-content-around">
                                        <div className="db-radio-check">
                                          <RadioButton
                                            labelClassName="checkcontainer"
                                            title="Male"
                                            name="validation_type"
                                            value="male"
                                            style={{
                                              borderColor: event?.color,
                                            }}
                                            onSelection={setRadioButton}
                                            spanClassName="radiobtn "
                                            checked={radioButton === "male"}
                                          />
                                        </div>
                                        <div className="db-radio-check">
                                          <RadioButton
                                            labelClassName="checkcontainer"
                                            title="Female"
                                            value="female"
                                            style={{
                                              borderColor: event?.color,
                                            }}
                                            onSelection={setRadioButton}
                                            name="validation_type"
                                            checked={radioButton === "female"}
                                            spanClassName="radiobtn"
                                          />
                                        </div>
                                        <div className="db-radio-check">
                                          <RadioButton
                                            labelClassName="checkcontainer"
                                            title="other"
                                            style={{
                                              borderColor: event?.color,
                                            }}
                                            spanClassName="radiobtn"
                                            name="validation_type"
                                            value="other"
                                            checked={radioButton === "other"}
                                            onSelection={setRadioButton}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group db-custom-file-group form-row">
                                      <label className="col-4">
                                        {accountTranslations?.avatar_label}
                                      </label>
                                      <div className="col-8">
                                        <label className="custom-file-upload ">
                                          <input
                                            onChange={setProfileImage}
                                            type="file"
                                            name="excel_file"
                                          />
                                          {
                                            accountTranslations?.avatar_file_label
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 text-right">
                                  {isLoading ? (
                                    <a
                                      href="#"
                                      className="btn vw-btn vw-btn-black"
                                    >
                                      <i
                                        aria-hidden="true"
                                        className={"fa fa-spinner fa-spin"}
                                      ></i>
                                    </a>
                                  ) : (
                                    <button
                                      // disabled={formState.formIsValid}
                                      onClick={onUpdateProfile}
                                      type="button"
                                      className="btn modal-btn btn-flat"
                                    >
                                      {accountTranslations?.update_button_text}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="logout-profile"
                          role="tabpanel"
                          aria-labelledby="logout-profile-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="network-profile"
                          role="tabpanel"
                          aria-labelledby="network-profile-tab"
                        >
                          <div className="account-from-wrap">
                            <form action="" method="post">
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-12 col-md-9">
                                  <div className="account-form-div">
                                    <TextComponent
                                      id="facebook_link"
                                      containerStyle="form-goup form-row"
                                      labelStyle="col-3"
                                      inputStyle="col-9"
                                      labelName={
                                        accountTranslations?.facebook_label
                                      }
                                      label
                                      inputType="text"
                                      initialValue={user?.info?.facebook_link}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputExtraChangeHandler}
                                      placeholder=""
                                    />
                                    <TextComponent
                                      id="linkedin_link"
                                      containerStyle="form-goup form-row"
                                      labelStyle="col-3"
                                      inputStyle="col-9"
                                      labelName={
                                        accountTranslations?.linkedin_label
                                      }
                                      label
                                      inputType="text"
                                      errorText="*Please enter Last Name."
                                      initialValue={user?.info?.linkedin_link}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputExtraChangeHandler}
                                      placeholder=""
                                    />
                                    <TextComponent
                                      id="twitter_link"
                                      containerStyle="form-goup form-row"
                                      labelStyle="col-3"
                                      inputStyle="col-9"
                                      labelName={
                                        accountTranslations?.twitter_label
                                      }
                                      label
                                      inputType="text"
                                      errorText="*Please enter Last Name."
                                      initialValue={user?.info?.twitter_link}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputExtraChangeHandler}
                                      placeholder=""
                                    />
                                    <TextComponent
                                      id="company"
                                      containerStyle="form-goup form-row"
                                      labelStyle="col-3"
                                      inputStyle="col-9"
                                      labelName={
                                        accountTranslations?.company_label
                                      }
                                      label
                                      required
                                      inputType="text"
                                      errorText="*Please enter Last Name."
                                      initialValue={user?.info?.company}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputExtraChangeHandler}
                                      placeholder=""
                                    />
                                    <TextComponent
                                      id="function"
                                      containerStyle="form-goup form-row"
                                      labelStyle="col-3"
                                      inputStyle="col-9"
                                      labelName={
                                        accountTranslations?.function_label
                                      }
                                      label
                                      required
                                      inputType="text"
                                      errorText="*Please enter Last Name."
                                      initialValue={user?.info?.function}
                                      accountDetails
                                      errorMessageContainerStyle="errorMessageContainerStyle"
                                      onInputChange={inputExtraChangeHandler}
                                      placeholder=""
                                    />
                                  </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-3">
                                  <div className="image-frame">
                                    {!profilePic && (
                                      <img
                                        src={
                                          user?.avtar
                                            ? user?.avtar
                                            : ProfilePicPlaceholder
                                        }
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = user?.avtar;
                                        }}
                                        className="img-responsive img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                    {profilePic && (
                                      <img
                                        src={profilePic}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = ProfilePicPlaceholder;
                                        }}
                                        className="img-responsive img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  {/* {fileBase64String} */}
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-12 text-right">
                                  {isLoading ? (
                                    <a
                                      href="#"
                                      className="btn vw-btn vw-btn-black"
                                    >
                                      <i
                                        aria-hidden="true"
                                        className={"fa fa-spinner fa-spin"}
                                      ></i>
                                    </a>
                                  ) : (
                                    <button
                                      // disabled={formState.formIsValid}
                                      onClick={onUpdateExtraDetails}
                                      type="button"
                                      className="btn modal-btn btn-flat"
                                    >
                                      {accountTranslations?.update_button_text}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="my-appointments-content"
                          role="tabpanel"
                          aria-labelledby="my-appointments-tab"
                        >
                          <div className="account-from-wrap">
                            <form action="" method="post">
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="dft-appointment-wrapper my_appointment_date">
                                    <div className="dft-appointment-listing">
                                      {appointments}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountModal;

// `let data = Object.fromEntries(Object.entries(formState.inputValues).filter(([_, second]) => second != ""));`
//Object.entries covert object to key-pair value array.
//filter => used to filter out the property in this case it is value so index is ==1 thus we does not require to give a name for first index.
// Object.fromEntries=> convert key pairs array back into the object.
