import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ChatThumb from "../ChatThumb/ChatThumb";
import { Context as defaultContext } from "../../../../../../../context/DefaultSectionContext";

const ChatComponent = ({ children, onPinChat, isChatThumbLoding }) => {
  const {
    state: {
      eventsPageData: {
        translations: { chat },
      },
    },
  } = useContext(defaultContext);
  const [isClicked, setIsClicked] = useState(false);
  const onCancel = () => {
    setIsClicked(!isClicked);
    onPinChat();
  };
  return (
    <div className="col-12 col-md-6 pinned-height">
      <div className="moderator-chat-wrapper pinned-wrapper">
        <div className="box-panel-common chat-box-panel">
          <div className="card box-common-card cmn-border-color">
            <div className="card-header box-common-card-header">
              <div className="box-header-wrap">
                <div className="box-title-wrap ">
                  <a href="#" className="box-title cmn-bg-color">
                    {chat?.title}
                  </a>
                </div>
                {/* <ChatThumb onPinChat={onPinChat} /> */}
                {isChatThumbLoding ? (
                  <Link to="#">
                    <i
                      className="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      style={{ color: "black" }}
                    ></i>
                  </Link>
                ) : (
                  <div onClick={onCancel} className="box-actions-wrap">
                    {!isClicked && (
                      <Link to="#">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Link>
                    )}
                  </div>
                )}
                {/* <div onClick={onCancel} class="box-actions-wrap">
                  {!isClicked && (
                    <a>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </a>
                  )}
                </div> */}
              </div>
            </div>
            <div className="card-body pt-2">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
