export const changeColor = (color, className, property, change) => {
    if (!color) return
    const els = document.getElementsByClassName('cmn-text-color');
    if (els.length > 0) {
        for (let i of els) {
            i.style.color = color
        };
    }

    const iframe = document.getElementsByTagName('iframe');
    if (iframe) {
        for (let i of iframe) {
            i.style.borderColor = color
        };
    }
    const border = document.getElementsByClassName('cmn-border-color');
    if (border) {
        for (let i of border) {
            i.style.borderColor = color
        };
    }
    const bgColor = document.getElementsByClassName('cmn-bg-color');
    if (bgColor) {
        for (let i of bgColor) {
            i.style.backgroundColor = color;
        };
    }
    const onFocus = document.getElementsByClassName('cmn-focus-border-color');
    if (onFocus) {
        for (let i of onFocus) {
            i.style.borderColor = color;
        };
    }
    let styleElem = document.getElementById('changeColor');
    if (!styleElem) {
        styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.id = 'changeColor';
    } else {
        if (color && !change) {
            styleElem.innerHTML += className + property
            // styleElem.append.style.className = `${className} ${property}`;
        }
    }
}
