import React from "react";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";

export default function Events({
  eventTime,
  eventCount,
  eventDescription,
  color,
  file,
  target,
  link,
  vimeoId,
}) {
  return (
    <tr>
      <td>{eventTime}</td>
      {/* <td>{eventCount}</td> */}
      <td colSpan="3">{eventDescription}</td>
      <td>
          <div className="tt-action-btns">
          {/* <DownloadLink
                        label={<i className="far fa-copy ev-ylw-icon cmn-text-color" style={{ color }}></i>}
                        filename={imageWithStorage + file}
                        exportFile={() => Promise.resolve("cached data here …")}
                        tagName={'a'} /> */}
          {file && (
            <a target={target} href={imageWithStorage + file} download>
                <i
                    className="far fa-copy ev-ylw-icon cmn-text-color"
                    style={{color}}
                    aria-hidden="true"
                ></i>
            </a>
          )}
          {link && (
              <a target={target} href={link} className="copy-to-clipboard">
                  <i className="fa fa-link opacity-1" aria-hidden="true"></i>
              </a>
          )}
          {vimeoId && (
            <a target={target} href={`https://vimeo.com/${vimeoId}`}>
                <i
                    className="fa fa-video ev-ylw-icon cmn-text-color"
                    style={{color}}
                    aria-hidden="true"
                ></i>
            </a>
          )}
        </div>
      </td>
    </tr>
  );
}
