import { useEffect } from 'react';
import CacheVersion from "./cacheVersion";

const useScript = url => {
    const path = process.env.REACT_APP_STATIC_RESOURCE;
    const REACT_APP_CACHE_BUST = CacheVersion();
    useEffect(() => {
        const script = document.createElement('script');

        // script.src = path + url;
        script.src = url + `?version=${REACT_APP_CACHE_BUST}`;
        script.async = false;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export default useScript;