import { VideoChatState } from "../../hooks/VideoChatState";

class Twilio {
    static _shared = null;

    constructor() {
    }

    static sharedInstance() {
        if (Twilio._shared === null) {
            Twilio._shared = new Twilio();
        }
        return Twilio._shared;
    }

    setVideoChatState(currentState) {
        Twilio._shared.currentState = currentState;
    }
    getVideoChatState() {
        return Twilio._shared.currentState ?? VideoChatState.DISCONNECTED;
    }
    setRoom(room) {
        Twilio._shared.room = room;
    }

    getRoom() {
        return Twilio._shared.room;
    }
}
export default Twilio;
