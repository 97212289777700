import React, {useContext} from "react";
import advertisementLogo from "../../../../../../assets/images/web-development-add.jpg";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
import {Context as defaultContext} from "../../../../../../context/DefaultSectionContext";
const AdvertisementSection = () => {
  const {
    state: {
      eventsPageData: {page_data: {advertisement} }
    },
  } = useContext(defaultContext);

  if (!advertisement?.banner) return <></>;

  return (
    <section className="db-advertisement-wrapper">
      <div className="container">
        <div className="container-fluid">
          <div className="db-advertisement-banner">
            {/*<h6>Advertisement</h6>*/}
            <a href={advertisement?.link} target="_blank">
              <img
                src={imageWithStorage + advertisement?.banner}
                className="img-fluid"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvertisementSection;
