import Route from './Route/Route';
import { ToastProvider } from 'react-toast-notifications';

import { Provider as DefaultSectionProvider } from './context/DefaultSectionContext';
import { Context as DefaultSectionConsumer } from './context/DefaultSectionContext';
import { Provider as NetworkToolContext } from './context/NetworkTollContext';
import NetworkAuthDefaultContext from './context/NetworkAuthDefaultContext';
import { Provider as GS1Context } from './context/GS1Context';
import { Provider as AuthContext } from './context/AuthContext';
import { Provider as LanguageContext } from './context/LanguageContext';
import { CookiesProvider } from "react-cookie";
import toast, { Toaster } from 'react-hot-toast';
const ip = require("ip");



function App() {
  window['ipAddress'] = ip.address();
  return (
    <CookiesProvider>
      <ToastProvider>
        <LanguageContext>
          <AuthContext>

            <DefaultSectionProvider>
              <NetworkToolContext >
                <NetworkAuthDefaultContext>
                  <GS1Context>
                    <Toaster position="top-right"/>
                    <Route />
                  </GS1Context>
                </NetworkAuthDefaultContext>
               </NetworkToolContext>
            </DefaultSectionProvider>

          </AuthContext>
        </LanguageContext>
      </ToastProvider>
    </CookiesProvider>
  );
}

export default App;
