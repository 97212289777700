import React, {Component} from 'react';
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import ProfileList from './ProfileList/ProfileList';

import "../style/css/GlobalChat.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { baseUrl } from '../../../../../constants/url/urlConstants';
import DBNetwork from '../../../../../api/DBNetwork';



class GlobalChat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			isLoading: true,
			current_page: 1,
			next_page: null,
			prev_page: null,
			current_letter: 'all',
		}
	}

	componentDidMount = () => {
		this.fetchOnlineUsers(this.state.current_letter, this.state.current_page);
	}

	load_nextPage = () => {
		if (this.state.next_page)
			this.fetchOnlineUsers(this.state.current_letter, this.state.next_page);
	}

	load_prevPage = () => {
		if (this.state.prev_page) {
			this.fetchOnlineUsers(this.state.current_letter, this.state.prev_page);
		}
	}

	fetchOnlineUsers = async (letter, page = 1) => {
		console.log(`Fetching for letter ${letter}`);
		
		this.setState({
			isLoading: true,
			current_letter:letter,
		});

		try {
			const url = baseUrl(`api/video-chat/users/${this.props.event.id}/${letter}?page=${page}`);
			const response = await DBNetwork.get(url);

			const next_page = response.data.data.current_page + 1 <= response.data.data.last_page ?
				response.data.data.current_page + 1 : null;
			const prev_page = response.data.data.current_page - 1 > 0 ?
				response.data.data.current_page - 1 : null;

			this.setState({
				current_page: response.data.data.current_page,
				users: response.data.data.data,
				next_page: next_page,
				prev_page: prev_page,
			});
			
		} catch (error) {
			console.log("ERROR");
		}


		this.setState({
			isLoading: false,
		});
	}


	render() {
		const alphabeth = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

		return (
			<div className="meet-xyz-wrap mx-5 my-2">
				{/*<div class="connecting-heading">
					<h1 class="weather_sunday">Connecting People</h1>
			</div>*/}
				<div className="filter-pagination-wrap">
					<div className="filter-wrapper">
						<div className="filter-item-list">
							<a onClick={() => this.fetchOnlineUsers('all')} className="filter-items">ALL</a>
							{alphabeth.map((item, index) => {
								return <a key={index} onClick={() => this.fetchOnlineUsers(item)}
										  className="filter-items">{item}</a>
							})}
						</div>
					</div>
				</div>
					
					{this.state.isLoading &&
						<div className="globalChatLoader">
							<FontAwesomeIcon  size="5x" icon={faSpinner} pulse/>
						</div>
					}

					{!this.state.isLoading && 
					<ProfileList 
						setMatchId={this.props.setMatchId} 
						setTwilioToken={this.props.setTwilioToken} 
						setTwilioIdentity={this.props.setTwilioIdentity} 
						setTwilioRoom={this.props.setTwilioRoom}
						setMatchedUser={this.props.setMatchedUser}
						setVC_State={this.props.setVC_State}
						setActiveVideoChat={this.props.setActiveVideoChat}
						setFOI={this.props.setFOI}
						listenForVCEvents={this.props.listenForVCEvents}
						declineMatch={this.props.declineMatch}
						match_id={this.props.match_id} 
						twilio_room={this.props.twilio_room} 
						twilio_identity={this.props.twilio_identity} 
						twilio_token={this.props.twilio_token} 
						matched_user={this.props.matched_user} 
						foi={this.props.foi}
						event= {this.props.event} 
						users={this.state.users}
						gotMatch={this.props.gotMatch}
						acceptMatch={this.props.acceptMatch}
					/>}

					{this.state.users.length == 0 && !this.state.isLoading &&
						<div className="globalChatNoMatch">
							<h2 className="match-blue-text">No users online for {this.state.current_letter}</h2>
						</div>
					}

				<div className="filter-pagination-wrap">
					<div className="filter-wrapper">
						<div className="filter-item-list">
							{this.state.prev_page != null &&
							<a className="filter-items" onClick={this.load_prevPage}>Previous Page</a>}
							{this.state.next_page != null &&
							<a className="filter-items" onClick={this.load_nextPage}>Next Page</a>}
						</div>
					</div>
				</div>

							
			</div>
		);
	}
}

export default GlobalChat;