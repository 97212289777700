import React, {useEffect, useState} from 'react';
import ReactParse from 'react-html-parser';

export default function IFrame(props) {
    const [classname, setClassName] = useState(props.containerWrapper3 ?? '')
    useEffect(() => {
        let name = props.containerWrapper3;
        if (!props.isPrimaryOptions){
            name = name +  ' m-auto';
        }
        setClassName( name)
    }, [props.isPrimaryOptions])

    return (
        <>
            {/* {props.homeIFrame ? */}
            <div id={props.id} className={classname}>
                {ReactParse(props.iframe,{
                    transform: domNode => {
                        if (domNode.name === 'script') {
                            var script = document.createElement('script');
                            if(domNode.attribs.src)
                                script.src = domNode.attribs.src;
                            if(domNode.attribs.id)
                                script.id = domNode.attribs.id;
                            if(domNode.children.length > 0) {
                                script.innerHTML = domNode.children[0].data;
                            }
                            document.head.appendChild(script);
                          }
                    }
                })}
                {/* <iframe className={props.IframeClass}
                    src={props.src}
                    {...props.iframeProps}>
                </iframe> */}
            </div>
            {/* </div> : <div className={props.containerWrapper3}>
                {ReactParse(props.iframe)}
                <iframe className={props.IframeClass}
                    src={props.src}
                    {...props.iframeProps}>

                </iframe>
            </div>
            } */}

        </>
    );
}


