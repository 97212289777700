import React, { useContext, useEffect, useState } from "react";
import video from "../../../assets/Gs1/videos/GS1_Animation.mp4";
import { Context as GS1Context } from "../../../context/GS1Context";
//Components.
import useScript from "../../../hooks/useScript";
import HeaderComponent from "../../../Default/components/defaultLayout/Header";
import GS1Header from "./GS1Header/GS1Header";
import Programme from "../Models/Programme/Programme";
import SponsorsList from "../Models/Sponsors/SponsorsList";
import SocialMedia from "../Models/SocialMedia/SocialMedia";
import VideoVault from "../Models/VideoVault/VideoVault";
import SpeakersList from "../Models/SpeakerList/SpeakersList";
import DailyTrivia from "../Models/DailyTrivia/DailyTrivia";
import Profile from "../Models/Profile/Profile";
import needHelp from "../../../assets/Gs1/Need_help.png";
import useBodyClass from "../../../hooks/BodyClass";
import useStyle from "../../../hooks/cssLoad";
import { baseUrl } from "../../../constants/url/urlConstants";
import GS1Ticker from "./GS1Ticker/GS1Ticker";
import { appendScript } from "../../../utlis/appendScript";
import { removeScript } from "../../../utlis/removeScript";
import Location from "../../../hooks/Location";
import Loader from "../../../UIComponets/Loader";
import ErrorPage from "../../../Route/ErrorPage";
import TPusher from "./TPusher";
import { useHistory } from "react-router";
// import * as moment from "moment";
import moment from "moment-timezone";
import getAuthDetails from "../../../hooks/getAuthDetails";
import init from "./canvas";
import MeetGS1Peers from "../Models/MeetGS1Peers/MeetGS1Peers";
import SnackbarProvider from "react-simple-snackbar";
import SplashScreen from "../SplashScreen/SplashScren";
import FAQ from "../NeedHelp/NeedHelp";
import MobileOrientation from "./MobileOrientation";
import VersionValidator from "../../Login/VersionValidator";
import logout from "../../../hooks/logout";
import HappeningNow from "../Models/HappeningNow/HappeningNow";
import GS1Logo from "../../../assets/Gs1/1200px-Logo_GS1.svg.png";

//Modal.setAppElement('#root');

var GS1Home = () => {
  const [isClicked, setIsClicked] = useState(false);
  VersionValidator();
  //************************* *  Custom Hooks for Append JS *************************
  useScript("/js/GS1/openModel.js");
  useScript("/js/GS1/menus.js");
  useScript("/js/GS1/comput.js");
  useScript("/js/GS1/main.js");
  useScript("/js/GS1/custom.js");
  useScript("/js/GS1/messengerChat.js");
  useScript("/js/GS1/closeModel.js");

  useBodyClass("GS1-active");
  useStyle("/script/gs1/css/anim.css");
  useStyle("/script/gs1/css/GS1.css");
  // useStyle('/script/gs1/css/login.css');
  useStyle("/script/gs1/css/orientation.css");
  useStyle("/script/gs1/css/profile-page.css");
  useStyle("/script/gs1/css/social-wall.css");
  useStyle("/script/gs1/css/speakers.css");
  useStyle("/script/gs1/css/sponsors.css");
  useStyle("/script/gs1/css/gs1-style.css");
  useStyle("/script/gs1/css/time-table.css");
  useStyle("/script/gs1/css/trivia.css");
  useStyle("/script/gs1/css/video-vault.css");
  useStyle("/script/gs1/css/chatboot.css");
  useStyle("/script/gs1/css/faq.css");
  // useStyle('/script/GS1/css/login.css');
  //**************************Context and State *************************
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toShowPromotionalVideo, setToShowPromotionalVideo] = useState(false);
  const [isShowPromotionalVideo, setIsShowPromotionalVideo] = useState(true);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [clientName, event] = Location();
  const history = useHistory();
  var {
    fetchGS1Data,
    state: {
      GS1Data: { client, header, page_data, myaccount, user, status },
      isValid,
      isLoadingData,
      openModal,
    },
  } = useContext(GS1Context);
  const [meetGS1Key] = useState(0);

  //************************** React Hooks *************************
  useEffect(() => {
    document.title = `GS1 Home | Global forum ${new Date().getFullYear()}`;
  });

  var setOpenModal = (state) => {
    console.log(`IN SET OPEN MODAL ${openModal}`);
    console.log(`MODAL STATE ${state}`);
    setIsOpen(state);

    console.log(`OPEN MODAL AFTER ${openModal}`);
  };

  var closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const url = `api/${clientName}/${event}`;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        await fetchGS1Data(url, (response) => {
          if (response === "Request failed with status code 401") {
            setIsLoading(false);
            logout();
            // localStorage.clear();
            history.replace(`/${clientName}/${event}/login`);
          }
        });
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };
    fetchData();
    const authPayload = localStorage.getItem("GS1_User");

    if (!authPayload) {
      history.push(`/${clientName}/${event}/login`);
      return;
    }
    const details = JSON.parse(authPayload);
    if (!details.access_token || !details.expires_at) {
      history.push(`/${clientName}/${event}/login`);
      return;
    }

    const current = moment(new Date());
    const cDate = current.tz("Europe/Brussels").format("YYYY-MM-DD HH:mm:ss");

    const expireDate = new Date(details.expires_at);
    const eDate = moment(expireDate).format("YYYY-MM-DD HH:mm:ss");

    // console.log("AUTH [E Date]" + eDate);
    // console.log("AUTH [C Date]" + cDate);
    if (eDate < cDate) {
      localStorage.removeItem("GS1_User");
      history.push(`/${clientName}/${event}/login`);
      return;
    }
    var dateDiff = new Date(eDate) - new Date(cDate);
    setTimeout(() => {
      // console.log("AUTH [Logout Now]");
      // history.push(`/${clientName}/${event}/login`)
    }, dateDiff);

    // console.log("AUTH [Diff]" + dateDiff);
  }, []);

  useEffect(() => {
    if (page_data?.menu) {
      window["menus"] = page_data?.menu?.widgets;
      window["splash_show"] = page_data?.splash_show;
      window["splash"] = page_data?.splash;
    }
    if (init) {
      init();
    }
    if (window.modelInit) {
      window.modelInit();
    }
  });
  useEffect(() => {
    const current = moment(new Date());
    const cDate = current.tz("Europe/Brussels").format("YYYY-MM-DD HH:mm:ss");
    let today = new Date().toISOString().slice(0, 10);
    const expireDate = new Date(
      new Date(today).getTime() + 60 * 60 * 24 * 1000
    );
    const eDate = moment(expireDate).format("YYYY-MM-DD HH:mm:ss");

    const videoCookies = localStorage.getItem("video");
    if (!videoCookies) {
      setToShowPromotionalVideo(true);
    }
    if (eDate < cDate) {
      setToShowPromotionalVideo(true);
    }

    let dateDiff = new Date(eDate) - new Date(cDate);
    setTimeout(() => {
      setToShowPromotionalVideo(true);
      localStorage.removeItem("video");
    }, dateDiff);
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = GS1Logo;
      //   favicon.href = imageBaseUrl + event?.logo;
    }
  }, [event?.logo]);

  if (!isValid) {
    return <ErrorPage />;
  }
  let uniqueId;
  const token = localStorage.getItem("system_id");
  if (localStorage.getItem("system_id")) {
    uniqueId = getAuthDetails(token, localStorage.getItem("system_id"));
  }

  return (
    <SnackbarProvider>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="container-canvas" id="mobile-landscape">
              <div className="img-container" id="img-container"></div>
              <svg
                id="svg-img-container"
                viewBox="0 0 200 100"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
              <div id="nav-bg"></div>
              <canvas className="anim-canvas" id="anim-canvas">
                Your browser does not support the HTML5 canvas tag.
              </canvas>
              <svg
                id="svg-container"
                viewBox="0 0 200 100"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
              {/* GS1Header Section */}
              <GS1Header user={myaccount} />

              {/* GS1Ticker Section */}
              {page_data?.option?.includes("ticker") && (
                <GS1Ticker
                  tickers={page_data?.tickers}
                  eventId={page_data?.event?.id}
                />
              )}

              <div id="contact-px-chat-box" className="canvas-contact-chat-box">
                <div className="contact-px-chat-box-iconwrapper contact-px-chat-box-js-toggler fadeIn">
                  <div className="contact-px-chat-box-icon">
                    <p className="text-center">FAQ</p>
                    <img
                      data-toggle="modal"
                      data-target="#modalFAQ"
                      src={needHelp}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="video-container">
                <video id="video" autoPlay muted playsInline>
                  <source id="videoSource" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
            <div className="mobile-portrait" id="mobile-portrait"></div>

            {/* Profile Section */}
            <Profile
              user={myaccount}
              mySessions={page_data?.cevent_data?.mySessions}
            />

            {/* HappeningNow Section */}
            <HappeningNow
              showModal={() => setShowModal(!showModal)}
              pageData={page_data}
              eventId={page_data?.event?.id}
              userDetails={user}
              happeningNow={page_data?.happening_now}
              pageData={page_data?.happening_now?.selected_channel}
              userId={user?.id}
            ></HappeningNow>

            {/* Programme Section */}
            {page_data?.option?.includes("timetable") && (
              <Programme
                eventId={page_data?.event?.id}
                sessions={page_data?.cevent_data?.sessions}
                mySessions={page_data?.cevent_data?.mySessions}
                eventId={page_data?.event?.id}
              />
            )}

            {/* Sponsors Section */}
            {page_data?.option?.includes("sponsers") && (
              <SponsorsList
                sponsors={page_data?.cevent_data?.sponsers}
                eventId={page_data?.event?.id}
              />
            )}

            {/* SocialMedia Section */}
            {page_data?.option?.includes("social_wall") && (
              <SocialMedia
                socialWall={client?.social_wall_iframe}
                eventId={page_data?.event?.id}
              />
            )}

            {/* VideoVault Section */}
            {page_data?.option?.includes("vod") && (
              <VideoVault
                videos={page_data?.vods_categories}
                eventId={page_data?.event?.id}
              />
            )}

            {/* Speakers Section */}
            <SpeakersList speakers={page_data?.cevent_data?.speakers} />

            {/* DailyTrivia Section */}
            {page_data?.daily_trivia?.iframe && (
              <DailyTrivia iframe={page_data?.daily_trivia?.iframe} />
            )}

            {/* SplashScreen Section */}
            {toShowPromotionalVideo && (
              <SplashScreen src={page_data?.splash?.vimeo_id} />
            )}

            {/* MeetGS1Peers Section */}
            <MeetGS1Peers
              setOpenModal={setOpenModal}
              user={user}
              event={page_data?.event}
            ></MeetGS1Peers>

            {/* FAQ Section */}
            <FAQ />

            {/* MobileOrientation Component */}
            <MobileOrientation />
          </>
        )}
      </>
    </SnackbarProvider>
  );
};

export default GS1Home;
