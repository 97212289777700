import useStyle from '../hooks/cssLoad'
const ErrorPage = () => {
    useStyle('/script/gs1/css/error-404.css');

    return (
        <div className="error-wrap">
            <div className="notfound">
                <div className="notfound-logo-wrap">
                    <img src="http://vdb.pixelstudio.site/web/img/1200px-Logo_GS1.svg.png" className="img-fluid"
                         alt=""/>
                    <h1>Global forum 2021</h1>
                </div>
                <div className="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>Oops, The Page you are looking for can't be found!</h2>
                <a href="#"><span className="arrow"></span>Return To Homepage</a>
            </div>
        </div>
    )
}
export default ErrorPage;