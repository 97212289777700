import moment from 'moment';
import React, { useContext, useState } from 'react'
import HappeningEventDetails from '../HappeningNow/HappeningEvent/HappeningEventDetails'
import HappeningEventHeader from '../HappeningNow/HappeningEvent/HappeningEventHeader'
import Wrapper from '../Wrapper/Wrapper';
import ProgrammeToggle from './ProgrammeToggle';
import MySession from './MySessions'
import TestProg from './TestProg';
import { Context as GS1Context } from '../../../../context/GS1Context';
const CategoryTabs = ({ href, id, title, ariaControls, ariaSelected, className }) => {
    return <a key={id}
        className={className}
        id={id}
        data-toggle="tab"
        href={href}
        role="tab"
        aria-controls={ariaControls}
        aria-selected={ariaSelected}>
        {title}
    </a>
}


export default function Programme({ sessions, mySessions, eventId }) {
    let categoryList;
    let categoryListContent;
    let mySessionCategoryList;
    let mySessionCategoryListContent;
    const { updateSessionData } = useContext(GS1Context)
    // ****************************** for All Sessions ******************************

    if (sessions?.length > 0) {
        categoryList = sessions?.map((category, catIndex) => {
            let categoryListHref = `tt-all-cat-${catIndex}-content`;
            let categoryListID = `tt-all-cat-${catIndex}-tab`;

            return <CategoryTabs key={catIndex}
                className={catIndex === 0 ? 'nav-item nav-link active' : 'nav-item nav-link'}
                href={`#${categoryListHref}`}
                id={categoryListID}
                title={category?.category}
                ariaControls={categoryListHref}
                ariaSelected={catIndex === 0 ? true : false}
            />
        });

        // category List content Map

        categoryListContent = sessions?.map((category, catIndex) => {
            let categoryListHref = `tt-all-cat-${catIndex}-content`;
            let categoryListID = `tt-all-cat-${catIndex}-tab`;
            let items = category?.items
            return <div key={catIndex}
                className={`tab-pane fade ${catIndex === 0 ? ' show active' : ''}`}
                id={categoryListHref}
                role="tabpanel"
                aria-labelledby={categoryListID}>
                <div className="timetable-content">
                    <nav>
                        <div className="nav nav-tabs gs-nav-tabs"
                            id="inner-nav-tab"
                            role="tablist">

                            {/* session Day Loop */}

                            {Object.keys(items).map((dayKey, dayIndex) => {
                                let dayKeyHref = `tt-all-cat-${catIndex}-day-${dayIndex}-content`;
                                let dayKeyTab = `tt-all-cat-${catIndex}-day-${dayIndex}-tab`;
                                return <ProgrammeToggle key={dayIndex}
                                    className={`nav-link tt-nav-link ${dayIndex === 0 ? 'active' : ''}`}
                                    id={dayKeyTab}
                                    // data-toggle="tab"
                                    href={`#${dayKeyHref}`}
                                    ariaControls={dayKeyHref}
                                    ariaSelected={dayIndex === 0 ? true : false}
                                    title={dayKey}
                                />
                            })
                            }

                        </div>
                    </nav>
                    <div className="tab-content" id="inner-tabContent-tt">

                        {/* session Day content Loop */}

                        {Object.keys(items).map((dayKey, dayIndex) => {
                            let dayKeyHref = `tt-all-cat-${catIndex}-day-${dayIndex}-content`;
                            let dayKeyTab = `tt-all-cat-${catIndex}-day-${dayIndex}-tab`;
                            return <div key={dayIndex} className={`tab-pane fade ${dayIndex === 0 ? 'show active' : ''}`}
                                id={dayKeyHref}
                                role="tabpanel"
                                aria-labelledby={dayKeyTab}>
                                <Wrapper id={`accordionTT-cat-${catIndex}-day-${dayIndex}`}>

                                    {items[dayKey]?.map((session, sessionIndex) => {
                                        let categoryHighlight = JSON.parse(session?.category);

                                        // session details
                                        let sessionId = `tt-cat-${catIndex}-day-${dayIndex}-session-${sessionIndex}-tab`;
                                        let sessionHref = `tt-cat-${catIndex}-day-${dayIndex}-session-${sessionIndex}-content`;
                                        // let month = moment(session?.date).format('MMM');
                                        let month = moment(session?.date).format('MMM');
                                        let date = moment(session?.date).format('D');
                                        let day = moment(session?.date).format('ddd');
                                        let [, endTime] = session.end !== null ? session.end?.split(' ') : ['', '']
                                        let startTime = session.end !== null ? session?.time?.split(':').splice(0, 2)?.join(':') : null
                                        let timeZone = moment().tz(session?.timezone).zoneAbbr();

                                        let time;
                                        if (endTime) {
                                            endTime = endTime?.split(':').splice(0, 2)?.join(':');
                                            time = `${startTime} - ${endTime} (${timeZone})`
                                        }
                                        return <div key={sessionIndex} className="card tt-card">
                                            <div className="card-header tt-card-header"
                                                id={sessionId}>
                                                <HappeningEventHeader
                                                    dataTarget={`#${sessionHref}`}
                                                    areaExpanded={sessionIndex === 0 ? true : false}
                                                    ariaControls={sessionHref}
                                                    link={session?.link}
                                                    target={session?.target}
                                                    attended={session?.attended}
                                                    time={time}
                                                    day={day}
                                                    date={date}
                                                    is_live={session?.is_live}
                                                    month={month}
                                                    title={session?.title}
                                                    highlight={categoryHighlight?.name}
                                                />
                                            </div>
                                            <HappeningEventDetails
                                                id={sessionHref}
                                                data={session?.speakers}
                                                description={session?.description}
                                                dataParent={`#accordionTT-cat-${catIndex}-day-${dayIndex}`}
                                            />
                                        </div>
                                    })}
                                </Wrapper>
                            </div>

                        })
                        }
                    </div>
                </div>
            </div>


        });

    } else {
        categoryListContent = <div>No session Preset</div>
    }


    // ****************************** for All Sessions ******************************
    const sessionNowCallback = async (data) => {
        await updateSessionData(data)

    }


    return (<>
        <div data-hash="#timetable"
            className="modal gs-modal fade fade-scale"
            id="program_modal"
            tabIndex="-1"
            aria-labelledby="modalTimeTableLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header gs-modal-header-tt">
                        <div className="page-heading">
                            <h4 className="modal-title page-title">Time Table</h4>
                        </div>
                        <div className="tt-session-tabs-wrap">
                            <nav>
                                <div className="nav nav-tabs gs-nav-tabs gs-nav-tabs-tt" id="nav-tab-tt" role="tablist">
                                    <a
                                        className="nav-link active" id="tt-all-tab" data-toggle="tab" href="#tt-all-content" role="tab"
                                        aria-controls="tt-all-content" aria-selected="true">All Sessions</a>
                                    <a className="nav-link" id="tt-my-tab" data-toggle="tab" href="#tt-my-content" role="tab"
                                        aria-controls="tt-my-content" aria-selected="false">My Sessions</a>
                                </div>
                            </nav>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true"><i className="material-icons">close</i></span>
                        </button>
                    </div>
                    <div className="modal-body gs-modal-body">
                        <div className="all-tt-content">
                            <div className="tab-content" id="nav-tabContent-tt">
                                <div className="tab-pane fade active show"
                                    id='tt-all-content' role="tabpanel"
                                    aria-labelledby='tt-all-tab'>
                                    <div className="category-navtabs">

                                        <nav>
                                            <div className="nav nav-tabs gs-nav-tabs gs-cate-nav" id="category-nav-tab" role="tablist">
                                                {categoryList}
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            {categoryListContent}
                                        </div>
                                    </div>
                                </div>

                                {/* My sessions */}
                                <div className="tab-pane fade" id="tt-my-content"
                                    role="tabpanel"
                                    aria-labelledby="tt-my-tab">
                                    <div className="timetable-content">
                                        <MySession mySession={mySessions}
                                            navId='inner-nav-tab'
                                            contentId="inner-tabContent-tt"
                                            fromWhere={'timeTable'} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        <TestProg
            sessionNowCallback={sessionNowCallback}
            sessionNowData={sessions}
            eventId={eventId}
        />
    </>
    );
}