import React from 'react';

const MagicLinkValidator = (pops) => {

    return (
        <p>
            Validate Link
        </p>
    )
}

export default MagicLinkValidator;