import React, { Component } from 'react';
import Pusher from 'pusher-js';
import { Tickers_Channel, Ticker_Event } from '../../../../constants/Pusher/PusherConstants';
import PusherWrapper from '../../../../hooks/PusherWrapper';

export default class GS1Ticker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickers: props.tickers || [],
            pusher: null
        }
    }


    componentWillUnmount() {
        if (this.props.eventId) {
            const eventId = this.props.eventId;
            const list = [Tickers_Channel + this.props.eventId];
            PusherWrapper.sharedInstance().removeChannels(list);
        }

    }
    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;
          
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(Tickers_Channel + this.props.eventId);
            channel.bind(Ticker_Event, data => {
                console.log(data)
                this.setState((prevState, props) => ({
                    ...prevState, tickers: data.tickers

                }));
            });
        }
    }

    render() {
        let tickersList;
        let tickers = [...this.state.tickers]
        if (tickers?.length > 0) {
            let position = tickers?.sort((a, b) => a.position - b.position);
            tickersList = position?.map((ticker, index) => {
                return <span key={index} className="time-high mr-5">{ticker?.name}</span>
            })
        }
        return (
            <div className="hightlight-wrapper">
                <div className="highlight-info">
                    <h5>Happening now</h5>
                    <div className="highlight-time-table">
                        <div className="opening-highlights">
                            <marquee>
                                <p>
                                    {tickersList}
                                </p>
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}
