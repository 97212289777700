import React, { useContext, useEffect, useState } from 'react'
import { imageBaseUrl } from '../../constants/url/urlConstants';
import { Context as AuthContext } from "../../context/AuthContext";
import { PaymentState } from './PaymentState';
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from '../../UIComponets/CustomButton';
import successGif from "../Payment/img/success.gif";
import ReactHtmlParser from "react-html-parser";


export const PaymentSuccess = (props) => {  

    const history = useHistory();    

    const {
        state: {
            registrationPageData: { event, payment, translations, dbvideo},
        },
    } = useContext(AuthContext);

    const [redirectTimer, setRedirectTimer] = useState(10);

    let replaceTimer =
      '<span style="color: '+event?.color+'">' + redirectTimer +' </span>';

    const redirectToEvent = () => {
        history.push(`/${props.clientName}/${props.eventName}`);
    }

    if (payment?.state === PaymentState.PaymentSucessfull && payment?.seen === 0) {
        if (redirectTimer > 0) {
          setTimeout(() => setRedirectTimer(redirectTimer - 1), 1000);
        } else {
          redirectToEvent();
        }
    }

    useEffect(() => {
        setRedirectTimer(10);
    }, [payment])

    return (
      <>
        <div className="wb-splash-reg-wrapper">
          <div className="wb-splash-inner">
            <div className="form-logo-wrap mb-0">
              <div className="logo-img-frame">
                <img
                  // src={event?.logo}
                  src={imageBaseUrl + event?.logo}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="logo-brand-name ">
                <h2 style={{ color: "#000" }}>{event?.title}</h2>
                <span className="cmn-bg-color">Event</span>
              </div>
            </div>
            {dbvideo?.payment_success ? (
              ReactHtmlParser(
                dbvideo?.payment_success.replace("[[timer]]", replaceTimer)
              )
            ) : (
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="font-weight-bold my-5">
                    <img height="100" src={successGif} />
                    Payment successful
                  </h1>
                  <h5>
                    You will participating the event in{" "}
                    <span style={{ color: event?.color }}>{redirectTimer}</span>{" "}
                    seconds or click the button below
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="login--inner--wrap login-box register-box">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center my-3">
                <CustomButton
                  type="submit"
                  href="#"
                  style={{ color: event?.color }}
                  onClick={() => redirectToEvent()}
                  className={`btn vw-btn register-btn vw-btn-white mb-3 w-50 cmn-text-color`}
                  name={translations?.registration?.watch_now_button}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
