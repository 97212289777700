import React, { Component } from 'react'
import Video from './Video'
import VideoVaultWrapper from './VideoVaultWrapper'
import img from '../../../../assets/images/card.png';
import VideoIframe from './VideoIframe';
import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_CLUSTER, VOD_Channel, VOD_Event } from '../../../../constants/Pusher/PusherConstants';
import PusherWrapper from '../../../../hooks/PusherWrapper';


const VideosCategoryModal = ({ className, id, href, ariaControl, ariaSelected, title }) => {
    return <a key={id} className={className} id={id} data-toggle="tab" href={href} role="tab"
        aria-controls={ariaControl} aria-selected={ariaSelected}>{title}</a>
}
export default class VideoVault extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: props.videos || [],
            videoVimeoId: 0,
            isModalOpen: false
        }
    }
    onSetVideoVimeoId = videoVimeoId => {
        this.setState((prevState, props) => ({
            ...prevState, videoVimeoId: videoVimeoId,
            isModalOpen: !this.state.isModalOpen
        }));
    }

    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;

            // pusher.connection.bind('error', function (err) {
            //     if (err?.code === 404) {
            //         console.log('Over limit!');
            //     }
            // });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(VOD_Channel + this.props.eventId);
            channel.bind(VOD_Event, data => {
                this.setState((prevState, props) => ({
                    ...prevState, videos: data?.vods,

                }));
            });
        }
    }

    render() {
        let videosCategories;
        let videosCategoriesContent;
        let videos = { ...this.state.videos }
        if (videos) {
            videosCategories = Object.keys(videos).map((videosCategory, videosCategoryIndex) => {
                // if (videosCategory?.length > 0) {
                // console.log(videosCategory)
                let videosCategoryID = `category-nav-${videosCategoryIndex}-tab`;
                let videosCategoryHref = `category-nav-${videosCategoryIndex}-content`;
                return <VideosCategoryModal
                    className={`nav-link ${videosCategoryIndex === 0 ? 'active' : ''}`}
                    id={videosCategoryID} // ariaLabelled  by video wrapper
                    href={`#${videosCategoryHref}`}
                    ariaControl={`${videosCategoryHref}`}
                    ariaSelected={videosCategoryIndex === 0 ? true : false}
                    title={videosCategory} />
                // }
            });

            // video category content

            videosCategoriesContent = Object.keys(videos).map((videosCategory, videosCategoryIndex) => {

                let videosCategoryID = `category-nav-${videosCategoryIndex}-tab`;
                let videosCategoryHref = `category-nav-${videosCategoryIndex}-content`;
                return <VideoVaultWrapper
                    className={`tab-pane fade ${videosCategoryIndex === 0 ? 'active show' : ''}`}
                    id={videosCategoryHref}
                    ariaLabelledBy={videosCategoryID} >
                    {videos[videosCategory].length > 0 ?
                        videos[videosCategory]?.map((video, videoIndex) => {
                            //    console.log(vi)
                            return <Video
                                key={videoIndex}
                                onVideoClick={() => this.onSetVideoVimeoId(video?.vimeo_id)}
                                src={video?.thumbnail}
                                alt={video?.title}
                                title={video?.title}
                                description={video?.description} />

                        }) : null
                    }
                </VideoVaultWrapper>
            });

        }
        return (
            <>
                <div data-hash="#VOD"
                    className="modal gs-modal fade fade-scale video-modal"
                    id="video_vault_modal"
                    tabIndex="-1"
                    aria-labelledby="modalVideoVaultLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header gs-modal-header">
                                <div className="page-heading">
                                    <h4 className="modal-title page-title">Video Vault</h4>
                                </div>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true"><i className="material-icons">close</i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="video-vault-section">
                                    <nav>
                                        <div className="nav nav-tabs gs-nav-tabs" id="nav-tab" role="tablist">
                                            {videosCategories}
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        {videosCategoriesContent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VideoIframe onCloseModal={() => this.onSetVideoVimeoId(null)}
                    src={`https://player.vimeo.com/video/${this.state.videoVimeoId}`} />
            </>
        )
    }
}
