import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import Channel from "./channel/Channel";
import HighLightSection from "./DesktopView/HighlightSection/HighLightSection";
import PartnersSection from "./DesktopView/PartnersScection/PartnersSection";
import { appendScript } from "../../../../utlis/appendScript";
import { removeScript } from "../../../../utlis/removeScript";
import EventInfoSection from "./DesktopView/EventInfoSection.js/EventInfoSection";
import BreakOutRoom from "./DesktopView/BreakOutRoom/BreakOutRoom";
import SideMenu from "./DesktopView/SideMenuOptions/SideMenu";
import IFrame from "../../../../UIComponets/iframe/IFrame";
import ChatMenu from "./DesktopView/chatMenu/Chats";
import QandA from "./DesktopView/QandA/QandA";
import VoteMenu from "./DesktopView/voteMenu/VoteMenu";
import PollMenu from "./DesktopView/PollMenu/PollMenu";
import AdvertisementSection from "./DesktopView/AdvertismentSection/AdvertismentSection";
import RatingMenu from "./DesktopView/ratingMenu/RatingMenu";
import useScript from "../../../../hooks/useScript";
import MobileView from "../../defaultLayout/body/MobileView";
import {
  Context as defaultContext,
  Context as DefaultContext,
} from "../../../../context/DefaultSectionContext";
import { Context as networkingToolContext } from "../../../../context/NetworkTollContext";
import LoaderDefaultSection from "../../../../UIComponets/LoaderDefaultSection";
import { changeColor } from "../../../../utlis/changeColor";
import Support from "./DesktopView/Support/Support";
import SocialWall from "./DesktopView/SocialWall/socialWall";
import {
  imageWithStorage,
  imageBaseUrl,
} from "../../../../constants/url/urlConstants";
import placeholderBody from "../../../../assets/images/bg_body.jpg";
import placeholderPattern from "../../../../assets/images/body.png";
import Location from "../../../../hooks/Location";
import useBodyClass from "../../../../hooks/BodyClass";
import getAuthDetails from "../../../../hooks/getAuthDetails";
import MeetGS1Peers from "../../../../../src/Default/components/MeetGS1Peers/MeetGS1Peers";
import useStyle from "../../../../hooks/cssLoad";
import { Base } from "../../../NetworkingTool/UIComponents/Base";
import HtmlParser from "react-html-parser";

const VodSectionSlider = lazy(() =>
  import("./DesktopView/VodSection/VodSectionSlider")
);

const VodSection = lazy(() => import("./DesktopView/VodSection/VodSection"));
export default function Body() {
  const [clientName, eventName] = Location();
  const { pinnedItems } = useContext(DefaultContext);
  const [pinChat, setPinChat] = useState(false);
  const [pinQandA, setPinQandA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isThumbLoding, setIsThumbLoding] = useState(false);
  const [isChatThumbLoding, setIsChatThumbLoding] = useState(false);
  const [isQAThumbLoding, setIsQAThumbLoding] = useState(false);
  const [chatChanged, setChatChanged] = useState("");
  useBodyClass("default-active");
  useScript("/js/swiper.js");
  // useScript("/js/playerHeight.js");
  useStyle("/script/default/NetworkingStyle/networking.css");
  // useStyle("/script/gs1/css/gs1-style.css");
  useEffect(() => {
    if (page_data?.option?.includes("embed_player")) {
      window.$("body").unbind("click");
      window.$("body").on("click", ".small-iframe", function (e) {
        e.preventDefault();
        updateColor();
        window.repositionWidgetContainer();
        window.$(".box-wrap-modal").collapse("hide");
        if (!window.$("#" + window.$(this).data("target")).hasClass("active")) {
          if (
            window
              .$(this)
              .parents(".db-inner-video-wrap")
              .hasClass("toggle-active")
          ) {
            window.$(".full-width-box").removeClass("active");
            window.$("#" + window.$(this).data("target")).toggleClass("active");
            return;
          }
        }
        window.$("#" + window.$(this).data("target")).toggleClass("active");

        window
          .$(this)
          .parents(".db-inner-video-wrap")
          .toggleClass("toggle-active");
      });
      window.$("body").on("click", ".rcc-menu", function (e) {
        e.preventDefault();
        updateColor();
        window.repositionWidgetContainer();
        window.$(".db-inner-video-wrap").removeClass("toggle-active");
        window.$(".box-wrap-modal").collapse("hide");
        window.$(".full-width-box").removeClass("active");
        window.$("#" + window.$(this).data("target")).collapse("toggle");
      });
    }
    setTimeout(() => {
      window.updateTickerItems();
    }, 1000);
  }, [isLoading]);

  const {
    state: {
      eventsPageData: { user, page_data, translations },
      pusherEvent,
    },
  } = useContext(defaultContext);

  useEffect(() => {
    window.repositionWidgetContainer();
  }, [pusherEvent]);

  const system_id = localStorage.getItem("system_id");
  const uniqueId = getAuthDetails(user?.id, system_id);
  useEffect(() => {
    document.title = page_data?.event?.title;
  }, []);

  useEffect(() => {
    if (window.playerSetting && page_data?.event?.iframe) {
      window.playerSetting();
    }
  }, [isLoading]);

  useEffect(() => {
    appendScript("/js/owl.carousel.min.js");
    // appendScript("/js/arrageWidget.js");
    return () => removeScript("/js/arrageWidget.js");
  }, []);

  const pinnedChat = page_data?.pinned_chat;
  const pinnedQA = page_data?.pinned_qa;

  window.updateColor = () => {
    updateColor();
  };
  const updateColor = () => {
    const colorCode = page_data?.event?.color;
    changeColor(
      colorCode,
      `::-webkit-scrollbar-thumb`,
      `{background:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.direct-chat-messages .direct-chat-msg.right .direct-chat-text:before`,
      `{border-top-color:${colorCode}!important;}`
    );    
    changeColor(
      colorCode,
      `.box-wrap-modal:before`,
      `{border-color: ${colorCode} transparent transparent transparent !important;}`
    );
    changeColor(
      colorCode,
      `::-webkit-scrollbar-thumb`,
      `{background: ${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.event-info-accordion .card-header a.btn-link:before`,
      `{background:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.setting-dropdown .dropdown-menu .dropdown-item:hover`,
      `{color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.checkcontainer input:checked ~ .radiobtn:after`,
      `{background:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.checkcontainer input:checked ~ .radiobtn:after`,
      `{background:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.cmn-focus-border-color:focus`,
      `{border-color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.input-group .form-control:focus`,
      `{border-color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.mobile-carousel .owl-dots .owl-dot.active `,
      `{border-color:${colorCode}!important;background:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.mobile-carousel .owl-dots .owl-dot `,
      `{border-color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.alice-carousel__dots .alice-carousel__dots-item.__active `,
      `{background-color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      ".alice-carousel__dots-item:not(.__custom):hover",
      `{background-color:${colorCode}!important;}`
    );

    changeColor(
      colorCode,
      ".alice-carousel__dots-item:not(.__custom)",
      `{border-color:${colorCode}!important;}`
    );
    changeColor(
      colorCode,
      `.sm-loading span`,
      `{background-color:${colorCode}!important;}`
    );
    changeColor(
      page_data?.event?.color,
      `.checkcontainer input:checked ~ .radiobtn`,
      `{border: 2px solid ${page_data?.event?.color}!important;}`
    );
  };
  useEffect(() => {
    updateColor();
  }, [chatChanged, page_data?.event?.color, pinChat, pinQandA]);

  useEffect(() => {
    const scriptTag = document.getElementById("supportScript");
    if (!page_data?.option?.includes("support")) {
      if (scriptTag) {
        scriptTag.remove();
      }
    }
  }, [page_data?.option]);


  useEffect(() => {
    const bodyClass = document.querySelector(".default-active");
    let url;
    if (bodyClass) {
      if (page_data?.event?.background_type === "image") {
        if (page_data?.event?.background) {
          url = `url('${
            imageWithStorage + page_data?.event?.background
          }') center center no-repeat`;
        } else {
          url = `url('${placeholderBody}') center center no-repeat`;
        }
        bodyClass.style.background = url;
      } else if (page_data?.event?.background_type === "pattern") {
        if (page_data?.event?.background) {
          url = `background : url('${
            imageBaseUrl + page_data?.event?.background
          }' ) repeat; background-size:auto!important;`;
        } else {
          url = `background : url('${placeholderPattern}' ) repeat; background-size:auto!important;`;

          // bodyClass.style.backgroundSize = 'auto!important'
        }
        bodyClass.style.cssText = url;
      } else if (page_data?.event?.background_type === "color") {
        bodyClass.style.background = page_data?.event?.background;
      }
    }
  }, [page_data?.event]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      if (page_data?.event?.favicon) {
        favicon.href = imageWithStorage + page_data?.event?.favicon;
      } else if (page_data?.event?.logo) {
        favicon.href = imageWithStorage + page_data?.event?.logo;
      }
    }
  }, [page_data?.event?.logo]);

  const onPin = async (type) => {
    if (type === "chat") {
      setPinChat(!pinChat);
    }
    if (type === "qa") {
      setPinQandA(!pinQandA);
    }
    const data = {
      user_id: user?.id,
      event_id: page_data?.event?.id,
      type,
    };

    if (type === "chat") setIsChatThumbLoding(true);
    if (type === "qa") setIsQAThumbLoding(true);
    try {
      setIsThumbLoding(true);
      await pinnedItems(data, type, !pinChat, !pinQandA, (response) => {
        // console.log("response", response);
        if (response.status) {
          setIsThumbLoding(false);
          if (type === "chat") setIsChatThumbLoding(false);
          if (type === "qa") setIsQAThumbLoding(false);
        } else {
          setIsThumbLoding(false);
          if (type === "chat") setIsChatThumbLoding(false);
          if (type === "qa") setIsQAThumbLoding(false);
        }
      });
      if (window.$(".db-inner-video-wrap").hasClass("toggle-active")) {
        window.$(".db-inner-video-wrap").removeClass("toggle-active");
      }
    } catch (error) {
      setIsThumbLoding(false);
      if (type === "chat") setIsChatThumbLoding(false);
      if (type === "qa") setIsQAThumbLoding(false);
      console.log(error);
    }
  };

  // Componants
  const chaSecMargin = (
    <section className="channel-button-wrapper">
      <div className="container-fluid">
        <div className="channel-btn-group"></div>
      </div>
    </section>
  );
  let EventInfo = (
    <EventInfoSection key="EventInfo">
      <div className="heading-wrap">
        <span> {translations?.event_info?.title} </span>
      </div>
    </EventInfoSection>
  );
  let TickersComp = (
    <React.Fragment key="Tickers">
      {page_data?.option?.includes("ticker") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          <HighLightSection key="Tickers" />
        </>
      )}
    </React.Fragment>
  );
  let Partners = (
    <React.Fragment key="Partners">
      {page_data?.option?.includes("sponsers") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          <PartnersSection />
        </>
      )}
    </React.Fragment>
  );
  let Advertisement = (
    <React.Fragment key="Advertisement">
      {page_data?.option?.includes("banner") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          <AdvertisementSection />
        </>
      )}
    </React.Fragment>
  );

  let VOD = (
    <Suspense key="VOD" fallback={<div>Loading....</div>}>
      {page_data?.option?.includes("vod") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          {/* <VodSection
            vod={page_data?.vod}
            systemId={system_id}
            category={page_data?.category}
            eventId={page_data?.event?.id}
            color={page_data?.event?.color}
          /> */}
          <VodSectionSlider />
        </>
      )}
    </Suspense>
  );

  let BreakOut = (
    <React.Fragment key={"BreakOut"}>
      {page_data?.option?.includes("breakout_rooms") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          <BreakOutRoom>
            <div className="heading-wrap">
              <span> {translations?.breakout?.title} </span>
            </div>
          </BreakOutRoom>
        </>
      )}
    </React.Fragment>
  );
  let SupportComp = (
    <React.Fragment key="Support">
      {page_data?.option?.includes("support") && (
        <Support script={page_data?.event?.support} />
      )}
    </React.Fragment>
  );
  let SocialComp = (
    <React.Fragment key="Social">
      {page_data?.option?.includes("social_wall") && (
        <>
          {!page_data?.option?.includes("embed_player") && chaSecMargin}
          <SocialWall key={"Social"} />
        </>
      )}
    </React.Fragment>
  );
  const conClass = window.innerWidth >= 1920 ? "container-fluid" : "container";
  let Pinned = (
    <React.Fragment key="Pinned">
      {page_data && page_data?.option?.includes("embed_player") && (
        <>
          {pinnedChat || pinnedQA ? (
            <section className="pinned-widget-wrapper">
              <div className={conClass}>
                <div className="row">
                  {page_data?.option?.includes("chat") && pinnedChat ? (
                    <ChatMenu
                      onPinChat={() => onPin("chat")}
                      isPinChat={pinnedChat}
                      isChatThumbLoding={isChatThumbLoding}
                    />
                  ) : null}
                  {page_data?.option?.includes("q_and_a") && pinnedQA ? (
                    <QandA
                      onPinQandA={() => onPin("qa")}
                      isPinQandA={pinnedQA}
                      isQAThumbLoding={isQAThumbLoding}
                    />
                  ) : null}
                </div>
              </div>
            </section>
          ) : null}
        </>
      )}
    </React.Fragment>
  );

  let Hybrid =  HtmlParser(page_data?.dbvideo?.hybrid);
  const layout_map = {
    timetable: EventInfo,
    sponsors: Partners,
    breakout_room: BreakOut,
    banner: Advertisement,
    pinned_widget: Pinned,
    ticker: TickersComp,
    vod: VOD,
    social_wall: SocialComp,
    hybrid: Hybrid
  };

  // let components = [{ comp: TickersComp, name: "ticker", position: 0 }, { name: Partners, position: 1 }, { name: Advertisement, position: 2, name: "advertisement" }
  //     , { name: VOD, position: 3, name: "vod" }, { name: BreakOut, position: 4, name: "breakout_room" }, { name: SupportComp, position: 5 }, { name: SocialComp, position: 6 }];

  let components = [];

  if (
    page_data &&
    page_data?.arrangelayout &&
    page_data?.arrangelayout?.desktop.length > 0
  ) {
    let layouts = [...page_data?.arrangelayout.desktop];
    // layouts = layouts.sort((i1, i2) => {
    //   return i1.position < i2.position;
    // });
    layouts = layouts.sort((i1, i2) => i1.position - i2.position);
    let _layouts = [];
    layouts.forEach((item) => {
      if (layout_map[item.name]) {
        _layouts.push(layout_map[item.name]);
      }
    });
    // _layouts.push(SocialComp);
    _layouts.push(SupportComp);
    components = _layouts;
  } else {
    components = [
      TickersComp,
      Pinned,      
      Partners,
      Advertisement,
      EventInfo,
      VOD,
      BreakOut,
      SocialComp,
      SupportComp,
      Hybrid
    ];
  }
  {
    page_data?.option?.includes("networking_tool") &&
      components.push(<Base user={user} event={page_data?.event}></Base>);
  }
  let Layout = components;
  // ************* ****************** Sorting Logic  *******  ************************
  // let desktopArrangment = page_data?.arrangelayout?.desktop;
  // let Layout = components.sort(function (a, b) {
  //   return desktopArrangment.indexOf(a) - desktopArrangment.indexOf(b);
  // });
  const dbvideo = page_data?.dbvideo ?? {};
  const primary_options = ["chat", "poll", "q_and_a", "rating", "vote"];

  return (
    <>
      {
        <Channel
          mainChannel={page_data?.mainchannel}
          channels={page_data?.subchannels}
          channelSelected={page_data?.channelSelected}
          isLoading={setIsLoading}
          toShow={page_data?.option?.includes("embed_player")}
          eventId={page_data?.event?.id}
        />
      }
      {
        isLoading ? (
          <LoaderDefaultSection />
        ) : window.innerWidth <= 767 ? (
          <MobileView />
        ) : (
          <main className="desktop-view">
            {page_data?.option?.includes("embed_player") && (
              <section className="header-wrapper video-wrapper">
                <div className="container-fluid pr-0">
                  <div className="collapsabel-width height_1">
                    <div className="db-inner-video-wrap">
                      <IFrame
                        id="embedIframe"
                        containerWrapper3={`embed-responsive embed-responsive-16by9`}
                        isPrimaryOptions={page_data?.option?.containsAny(
                          primary_options
                        )}
                        iframe={page_data?.event?.iframe}
                      />
                      <SideMenu
                        isPinChat={pinnedChat}
                        isPinQandA={pinnedQA}
                        isPollAvail={page_data?.isPollAvail}
                        isRatingAvail={page_data?.isRatingAvail}
                        isVoteAvail={page_data?.isVoteAvail}
                        options={page_data?.option}
                        dbvideo={dbvideo}
                      >
                        {page_data?.option?.includes("q_and_a") && !pinnedQA ? (
                          <QandA
                            onPinQandA={() => onPin("qa")}
                            isPinQandA={pinnedQA}
                            isQAThumbLoding={isQAThumbLoding}
                          />
                        ) : null}
                        {page_data?.option?.includes("chat") && !pinnedChat ? (
                          <ChatMenu
                            onPinChat={() => onPin("chat")}
                            isPinChat={pinnedChat}
                            isThumbLoding={isThumbLoding}
                          />
                        ) : null}
                        {page_data?.option?.includes("poll") ? (
                          <div
                            id="collapsePoll"
                            className="collapse poll-box-wrap box-wrap-modal collapsepoll"
                          >
                            <PollMenu />
                          </div>
                        ) : null}
                        {page_data?.option?.includes("vote") &&
                        page_data?.isVoteAvail ? (
                          <div
                            id="collapseVote"
                            className="collapseVote vote-box-wrap box-wrap-modal collapse"
                          >
                            <VoteMenu />
                          </div>
                        ) : null}
                        {page_data?.option?.includes("rating") &&
                        page_data?.isRatingAvail ? (
                          <div
                            id="rating-box-wrapper"
                            className="collapse rating-box-wrap box-wrap-modal"
                          >
                            <RatingMenu />
                          </div>
                        ) : null}
                      </SideMenu>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* {page_data?.option?.includes('sponsers') && <PartnersSection sponsers={page_data?.sponser}
                    eventId={page_data?.event?.id} />} */}
            {Layout}
          </main>
        )
        // {window.innerWidth <= 767 && }
      }
    </>
  );
}
