import React from 'react'
import placeholder from '../../../../assets/user/avatarplaceholder.png';
export default function SpeakerProfile({ speakerProfilePic,
    speakerName,
    speakerTitle,
    speakerDescription,
    speakerCompany }) {
    return (
        <>
            <div className="col-12 col-sm-4 col-md-2 col-lg-2">
                <div className="speaker-imageframe">
                    <img
                        src={speakerProfilePic}
                        onError={(e) => { e.target.onerror = null; e.target.src = placeholder }}
                        alt="" className="img-fluid rounded-circle" />
                </div>
            </div>
            <div className="col-12 col-sm-8 col-md-6 col-lg-6">
                <div className="speaker-profile">
                    <div className="speaker-title-wrapper">
                        <div className="speaker-name">
                            <h3>{speakerName}</h3>
                        </div>
                        <div className="speaker-designation">
                            <h6>{speakerTitle} <span className="speaker-cmpny-name" style={{ color: ' #f28500' }}>{speakerCompany}</span></h6>
                        </div>
                    </div>
                    <div className="speaker-description">
                        <p>{speakerDescription}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
