import React from 'react'
import useStyle from '../../../../hooks/cssLoad';
import AccountModal from './AccountModal'
import Header from './Header';



export default function HeaderComponent({ header, user, event, isLoggedIn, security, options, dbvideo }) {
    // useStyle('/script/default/defaultStyle.css');

    return (
        <>
            <Header header={header}
                firstName={user?.name}
                lastName={user?.last_name}
                profilePic={user?.avtar}
                user={user}
                security={security}
                setIsLoginRequired={isLoggedIn}
                options={options}
                dbvideo={dbvideo}
            />
            <AccountModal user={user}
                event={event}
                security={security}
                setIsLoginRequired={isLoggedIn} />
        </>

    )
};
