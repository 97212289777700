import React, { useState, useCallback, useEffect, useContext } from "react";
import Video from "twilio-video";
import Lobby from "./Lobby";
import Room from "./Room";
import { Context as networkingToolContext } from './../../../context/NetworkTollContext';
import NotificationCenter from "../../../hooks/NotificationCenter";
import Twilio from "../Twilio";
import { useToasts } from "react-toast-notifications";
import { ErrorOption } from "../../components/ToastOptions";
import { Context as defaultContext } from './../../../context/DefaultSectionContext';


const VideoChat = () => {
  const {
    state: {
      eventsPageData: { user
      },
    }
  } = useContext(defaultContext)

  const {
    state: {
      videoChat
    },
    endChat,
  } = useContext(networkingToolContext);

  const { addToast } = useToasts();
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);


  useEffect(() => {
    const roomName = videoChat?.twilio_room;
    const userName = videoChat?.twilio_identity;
    const token = videoChat?.twilio_token;
    if (roomName && userName && token) {

      setConnecting(true);
      Video.connect(token, {
        name: roomName,
        audio: true,
        video: true,
      })
        .then((room) => {
          setConnecting(false);
          setRoom(room);
          Twilio.sharedInstance().setRoom(room);
        })
        .catch((err) => {
          console.error(err);
          setConnecting(false);
        });

        NotificationCenter.sharedInstance().registerNotification("videoChatDeclineEvent", (data) => {
          if (data?.username) {
            addToast(`${data?.user_id == user.id ? "You" : data?.username} Declined Call`, ErrorOption)
          }
         })
      NotificationCenter.sharedInstance().registerNotification("endChatRequest", (data) => {
        // console.debug("endChat")
        let __room = Twilio.sharedInstance().getRoom();
        __room?.localParticipant?.tracks?.forEach((trackPub) => {
          trackPub.track.stop();
        });
        __room?.disconnect();
        Twilio.sharedInstance().setRoom(null);
        setRoom(null);
      })
    }

  }, [videoChat]);

  useEffect(() => {

    // const callback = () => {
    //   setRoom((prevRoom) => {
    //     if (prevRoom) {
    //       prevRoom.localParticipant.tracks.forEach((trackPub) => {
    //         trackPub.track.stop();
    //       });
    //       prevRoom.disconnect();
    //     }
    //     return null;
    //   });
    // }
    // setDeclineCallback(callback);
    // setEndChatCallback(callback);
  })


  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render = <></>;
  if (room) {
    render = (
      <>
        <Room roomName={roomName} room={room} />
      </>
    );
  }
  return render;
};

export default VideoChat;
