import React, {Component} from 'react';
import DBNetwork from '../../../../api/DBNetwork';
import { baseUrl } from '../../../../constants/url/urlConstants';
import { withSnackbar } from 'react-simple-snackbar'
import Pusher from 'pusher-js';


import MeetGS1Body from './Components/MeetGS1Body';

import './MeetGS1Peers.scss';
import Modal from 'react-modal';
import MatchFound from './Components/Match/MatchFound';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../constants/Pusher/Pusher';


const options = {
	position: 'top-right',
	style: {
	  backgroundColor: 'midnightblue',
	  border: '2px solid lightgreen',
	  color: 'lightblue',
	  fontFamily: 'Menlo, monospace',
	  fontSize: '20px',
	  textAlign: 'center',
	},
	closeStyle: {
	  color: 'lightcoral',
	  fontSize: '16px',
	},
  }

const customStyles = {
	overlay: {zIndex: 1000}
};
  

class MeetGS1Peers extends Component {
	constructor(props) {
		super(props);
		console.log("PROPS MEET GS1 PEERS");
		console.log(this.props);
		this.close = this.close.bind(this);
		this.escFunction = this.escFunction.bind(this);
		

		this.state = {
			childKey: 0,
			match_id: null,
			pusher_global_chat: null,
			global_meeting: false,
			requestedChat: false,

			active_videochat : false,
			twilio_identity: '',
			twilio_room: '',
			twilio_token: null,
			vc_created_at: null,
			matched_user: null,
			foi: null,
			pusher: null,
		};

		this.setMatchId = this.setMatchId.bind(this);
		this.endChat = this.endChat.bind(this);

		document.addEventListener("keydown", this.escFunction, false);
	}

	/**
	 * Listen for Global request
	 */
	componentDidMount = () => {
		console.log("COMPONENT MOUNT MEET GS1 PEERS");

		if (this.state.pusher_global_chat == null && this.props.user) {	
			let pusher_global_chat = new Pusher(PUSHER_APP_KEY, {
				cluster: PUSHER_CLUSTER,
				encrypted: false
			});
			this.state.pusher_global_chat = pusher_global_chat;

			pusher_global_chat.connection.bind('error', function (err) {
				if (err?.code === 404) {
					console.log('Over limit!');
				}
			});
			
			const channel = pusher_global_chat.subscribe('videochat.globalrequest.' + this.props.user.id);
			channel.bind("App\\Events\\VideoChatGlobalRequestEvent", data => {
				console.log("INCOMMING REQUEST");
				console.log(data);
				if (this.state.match_id == null) {
					console.log(data);
					//this.props.openSnackbar("Meeting was requested by " + data.requesting_username);
					window.$('.modal').modal('hide');

					this.setState({
						requestedChat: true,
						match_id: data.match_id,
					});

					this.gotMatch(this.state.match_id);
				}
			});
		}

	}

	closeModal = () => {
		this.setState({
			requestedChat: false,
		});
	}

	setMatchId = (match_id) => {
		this.setState({
			match_id: match_id
		});
	}

	escFunction(event) {
		if(event.keyCode === 27) {
			console.log("ESC pressed");
			this.close();
		}
	}

	endChat = async () => {
		console.log("ENDING CHAT");
		if (this.state.match_id) {
			const match_id = this.state.match_id;

			this.setState({
				match_id: null
			});

			try {
				const url = baseUrl("api/video-chat/end-chat/" + match_id);
				const response = await DBNetwork.get(url);
			} catch (error) {
				console.log("Error");
				console.error(error);
			}
		}
	}

	close()  {
		console.log("CLOSE CHAT");
		this.endChat();
		this.resetVCState();
		
		console.log("CLOSE");
		this.setState({
			childKey : this.state.childKey + 1
		});
		
	}


	/***
	 * VIDEO CHAT SPECIFIC FUNCTIONS
	 */

	setActiveVideoChat = (state) => {
		this.setState({
			active_videochat: state,
		});
	}

	setVC_State = (state) => {
		this.setState({
			active_videochat: state,
		});
	}

	resetVCState = () => {
		this.setState({
			match_id: null,
			active_videochat: false,
			twilio_identity: '',
			twilio_room: '',
			twilio_token: null,
			vc_created_at: null,
			matched_user: null,
			foi: null,
		});
	}

	declineMatch = async () => {

		try {
			const url = baseUrl("api/video-chat/decline/" + this.state.match_id);
			const respone = await DBNetwork.get(url);

			this.resetVCState();

			this.closeModal();
		} catch (error) {
			console.log("ERROR");
			console.error(error);
		}
	}

	gotMatch = async (match_id) =>{
		try {
			const url = baseUrl(`api/video-chat/match/${match_id}`);
			const response = await DBNetwork.get(url);
			this.setFOI(response.data.data.foi);
			this.setMatchId(match_id);
			this.listenForVCEvents();
			
			this.setMatchedUser(response.data.data.matched_users[0]);

			
		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	acceptMatch = async (inModal) => {
		console.log("IN ACCEPT MATCH");
		try {
			const url = baseUrl(`api/video-chat/accept/${this.state.match_id}`);
			const response = await DBNetwork.get(url);

			console.log("RESPONSE");
			console.log(response);

			this.setTwilioRoom(response.data.data.twilio_room);
			this.setTwilioToken(response.data.data.twilio_token);

			console.log("ACCEPT");
			const date = new Date(response.data.data.created_at);
			console.log(`ACCEPT -TIME VC CREATED `);
			this.setState({
				vc_created_at: date,
			});

			this.setActiveVideoChat(true);
			
			if (inModal){
				this.closeModal();
				console.log("OPENING MODAL");
				window.$("#meet_gs1_peer_modal").modal('show')
			}

		} catch (error) {
			console.log("Error - Accept Match");
			console.error(error);
			if (!error.response)
				return;
			
			if(error.response.data.data.code == "VC_MEETING_DECLINED") {
				this.resetVCState();
			}
		}
		
	}

	listenForVCEvents = () => {
		console.log("Listening for VC events");
		if (this.state.pusher == null && this.state.match_id != null) {
			console.log("Listening to pusher for decline - ");
			let pusher = new Pusher(PUSHER_APP_KEY, {
				cluster: PUSHER_CLUSTER,
				encrypted: false
			});
			this.state.pusher = pusher;

			pusher.connection.bind('error', function (err) {
				if (err?.code === 404) {
					console.log('Over limit!');
				}
			});
			console.log("PUSHER CHANNEL");
			console.log('videochat.decline.' + this.state.match_id);
			const channel = pusher.subscribe('videochat.decline.' + this.state.match_id);
			channel.bind("App\\Events\\VideoChatDeclineEvent", data => {
				console.log("RECEIVED A DECLINE");
				this.props.openSnackbar("Meeting was declined by " + data.username);
				this.resetVCState()
				this.endChat();
			});
			console.log(`CHANNEL: ${"videochat.end." + this.state.match_id}`);
			const end_channel = pusher.subscribe('videochat.end.' + this.state.match_id);
			end_channel.bind("App\\Events\\VideoChatEndedEvent", data => {
				console.log("GOT END EVENT");
				this.props.openSnackbar("Meeting was ended by " + data.username);
				this.resetVCState();
				this.endChat();
			});
		}
	}
	
	setTwilioToken = (twilio_token) => {
		this.setState({
			twilio_token: twilio_token
		});
	}

	setTwilioIdentity = (twilio_identity) => {
		this.setState({
			twilio_identity: twilio_identity
		});
	}

	setTwilioRoom = (twilio_room) => {
		this.setState({
			twilio_room: twilio_room
		});
	}

	setMatchedUser = (matched_user) => {
		this.setState({
			matched_user: matched_user
		});
	}

	setFOI = (foi) => {
		this.setState({
			foi: foi
		});
	}


	render() {
		return (
			<div>
				<MeetGS1Body 
					key={this.state.childKey} 
					event={this.props.event}
					listenForVCEvents={this.listenForVCEvents}
					declineMatch={this.declineMatch}
					twilio_room={this.state.twilio_room} 
					twilio_identity={this.state.twilio_identity} 
					twilio_token={this.state.twilio_token} 
					matched_user={this.state.matched_user} 
					match_id={this.state.match_id} 
					user={this.props.user}
					foi={this.state.foi}
					resetVCState={this.resetVCState}
					endChat={this.endChat}	
					setMatchId={this.setMatchId}
					setTwilioToken={this.setTwilioToken} 
					setTwilioIdentity={this.setTwilioIdentity} 
					setTwilioRoom={this.setTwilioRoom}
					setMatchedUser={this.setMatchedUser}
					setVC_State={this.setVC_State}
					setActiveVideoChat={this.setActiveVideoChat}
					setFOI={this.setFOI}
					event= {this.props.event} 
					active_videochat={this.state.active_videochat}
					close={this.close}
					acceptMatch={this.acceptMatch}
					gotMatch={this.gotMatch}
					vc_created_at={this.state.vc_created_at}
				/>
				<Modal 
					onRequestClose={this.closeModal}
					isOpen={this.state.requestedChat}
				>
					<MatchFound
						key={this.state.childKey} 
						event={this.props.event}
						listenForVCEvents={this.listenForVCEvents}
						declineMatch={this.declineMatch}
						twilio_room={this.state.twilio_room} 
						twilio_identity={this.state.twilio_identity} 
						twilio_token={this.state.twilio_token} 
						matched_user={this.state.matched_user} 
						match_id={this.state.match_id} 
						user={this.props.user}
						foi={this.state.foi}
						resetVCState={this.resetVCState}
						endChat={this.endChat}	
						setMatchId={this.setMatchId}
						setTwilioToken={this.setTwilioToken} 
						setTwilioIdentity={this.setTwilioIdentity} 
						setTwilioRoom={this.setTwilioRoom}
						setMatchedUser={this.setMatchedUser}
						setVC_State={this.setVC_State}
						setActiveVideoChat={this.setActiveVideoChat}
						setFOI={this.setFOI}
						event= {this.props.event} 
						active_videochat={this.state.active_videochat}
						inNotificationModal={true}
						closeModal={this.closeModal}
						acceptMatch={this.acceptMatch}

						
					/>
				</Modal>
			</div>
		);
  	}
};


export default withSnackbar(MeetGS1Peers, options);


