import React from 'react'
import SpeakerProfile from './SpeakerProfile'
import SpeakerSessions from './SpeakerSessions'
import profilePic from '../../../../assets/Gs1/profile1.png';
import moment from 'moment';
const des = `Vice President of Standards Development who has spent the last 18+
years with GS1 working with an international
community to collaboratively define new and better ways to solve
business problems / drive efficiency. His GS1 career
spans roles from work group facilitation to leadership roles in the
technical development of GS1 standards.
Prior to joining GS1, Andrew worked in industry with companies such
as Black & Decker and Leviton mfg. as well as
leading implementations of supply chain software for Manugistics
both in the US and at their Tokyo office. Andrew has a
B.S. in Economics from Rochester Institute of Technology and an MBA
focused on International Finance from St. Johns
                        University.`
export default function Speaker({ speaker }) {


    const sessions = JSON.parse(speaker?.assigned_sessions)
    let sortedSessions = sessions?.sort(
      (a, b) => new Date(a?.SessionStartDate) - new Date(b?.SessionStartDate)
    );
    return (
      <>
        <div className="speaker-profile-wrapper">
          <div className="row">
            <SpeakerProfile
              speakerProfilePic={speaker?.first_name}
              speakerDescription={speaker?.biography}
              speakerTitle={speaker?.title}
              speakerCompany={speaker?.company}
              speakerName={speaker?.first_name + " " + speaker?.last_name}
              speakerProfilePic={speaker?.profile_image_url}
            />
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="speakers-sessions">
                <div className="session-title">
                  <h3>Sessions</h3>
                </div>
                <div
                  className="accordion sessions-accordion"
                  id="sessions-accordion"
                >
                  {sortedSessions?.map((session, index) => {
                    // const date = moment(session?.SessionStartDate + 'Z').format('MMMM Do YYYY');
                    const date = moment(session?.SessionStartDate + "Z").format(
                      "ll"
                    );
                    const startTime = moment(
                      session?.SessionStartDate + "Z"
                    ).format("LT");
                    const EndTime = moment(
                      session?.SessionEndDate + "Z"
                    ).format("LT");
                    // const date = moment(session?.SessionStartDate + 'Z').format('ll');
                    const day = moment(session?.SessionStartDate + "Z").format(
                      "dddd"
                    );
                    const timeZone = moment().tz(session?.timezone)?.zoneAbbr();
                    // console.log('date' + day)
                    return (
                      <SpeakerSessions
                        key={index}
                        index={index}
                        sessionDay={day}
                        sessionDate={date}
                        sessionEndTime={EndTime}
                        sessionStartTime={startTime}
                        sessionTimezone={timeZone}
                        sessionTopic={session?.SessionName}

                        // sessionTime={speaker ?}
                        // speakerSessionDescription={speaker ?}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
