
import React, { Component } from 'react'
import { baseUrl } from '../../../../../../constants/url/urlConstants';
import { OnAirVote_Channel, PUSHER_APP_KEY, PUSHER_CLUSTER, VoteEvent } from '../../../../../../constants/Pusher/PusherConstants';
import { toast } from 'react-toastify';
import Pusher from 'pusher-js';
import DBNetwork from "../../../../../../api/DBNetwork";
import $ from 'jquery';
import PusherWrapper from '../../../../../../hooks/PusherWrapper';
import { Context as GS1Context } from '../../../../../../context/GS1Context';
export default class Vote extends Component {
    static contextType = GS1Context;
    constructor(props) {
        super(props);
        this.state = {
            vote: props.vote ? props.vote : [],
            pusher: null,
        }
    }

    setOptions = (e, index, value) => {
        e.preventDefault();
        const list = [...this.state.vote];
        let vote = { ...this.state.vote[index] };
        vote.optionSelected = value;
        list[index] = vote;
        this.setState((prevState, props) => ({
            ...prevState, vote: list,
        }));

    }

    onSubmitVote = (e, index, queId) => {
        e.preventDefault();
        if (this.state.vote[index].optionSelected) {
            const url = baseUrl('api/send-vote-answer');
            const payload = {
                q_id: queId,
                user_id: this.user_id,
                option_id: this.state.vote[index].optionSelected,
                event_id: this.props?.eventId
            };

            DBNetwork.post(url, { ...payload }).then(
                response => {
                    if (response.status) {
                        toast(`🤙🏻You submitted option ${payload?.option_id}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        const list = [...this.state.vote];
                        let vote = { ...this.state.vote[index] };
                        vote.isSubmitted = true;
                        list[index] = vote;
                        this.setState((prevState, props) => ({
                            ...prevState, vote: list,
                        }));
                    }



                }).catch(err => console.log(err))
        } else {
            toast(`Please select a option`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    componentWillUnmount() {
        if (this.props.eventId) {
            const eventId = this.props.eventId;
            const list = [OnAirVote_Channel + eventId];
            PusherWrapper.sharedInstance().removeChannels(list);

        }

    }

    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
            const channel = pusher.subscribe(OnAirVote_Channel + this.props.eventId);
            channel.bind(VoteEvent, data => {
                const index = this.state.vote.findIndex(x => x.id === data.vote.id);
                if (index !== -1) {
                    let req = this.state.vote[index]
                    let list = [...this.state.vote]
                    if (req) {
                        if (data.vote?.options <= 0) {
                            list.splice(index, 1);

                        } else {
                            let appendingObject = { ...req }
                            appendingObject.answer_id = req.optionSelected;
                            appendingObject.isSubmitted = req.isSubmitted;
                            appendingObject.live = data.vote?.live;
                            appendingObject.options = data.vote?.options;
                            appendingObject.question = data.vote?.question;
                            // appendingObject.req = null;
                            list[index] = appendingObject;
                        }

                    } else {
                        let oldObject = { ...list[index] };
                        oldObject.live = data.vote?.live;
                        list[index] = { ...oldObject };
                    }
                    const { voteUpdated, updateAllUserVotes } = this.context;
                    updateAllUserVotes(list);
                    this.setState((prevState, props) => ({
                        ...prevState, vote: list,
                    }));
                    // this.props.isVoteEmpty(this.state.vote.find(d => d.live !== 0), 'voteNull');

                    // this.props.isVoteEmpty(this.state.vote.filter(vote => vote.live !== 0)?.length, 'voteNull');

                    let mList = [...this.state.vote, data.vote].filter(vote => vote.live !== 0);
                    if (mList.length === 0) {
                        window.$('#collapseVote').removeClass('show');
                        window.$('.full-width-box').removeClass('active');
                    } else if (mList.length >= 1 && !window.$('#collapseVote').hasClass('show')) {

                        window.$('.inner-video-wrap').removeClass("toggle-active");
                        window.$('.gs-box-wrap-modal').collapse('hide');
                        window.$('.full-width-box').removeClass('active');
                        window.$('#collapseVote').collapse('show')
                    }
                }
                else {
                    let appendingObject = { ...data.vote }
                    appendingObject.answer_id = 0;
                    appendingObject.isSubmitted = false;
                    appendingObject.live = data.vote?.live;
                    const { voteUpdated, updateAllUserVotes } = this.context;
                    // voteUpdated(data.vote)
                    let list = [...this.state.vote, appendingObject];
                    this.setState((prevState, props) => ({
                        ...prevState, vote: list,
                    }));
                    updateAllUserVotes([...list]);
                    // this.props.isVoteEmpty(this.state.vote.find(d => d.live !== 0), 'voteNull');
                    // this.props.isVoteEmpty(this.state.vote.filter(vote => vote.live !== 0)?.length, 'voteNull');
                    let mList = [...this.state.vote, data.vote].filter(vote => vote.live !== 0);

                    if (mList.length === 0) {
                        window.$('#collapseVote').removeClass('show');

                    } else if (mList.length >= 1 && !window.$('#collapseVote').hasClass('show')) {

                        window.$('.inner-video-wrap').removeClass("toggle-active");
                        window.$('.gs-box-wrap-modal').collapse('hide');
                        window.$('.full-width-box').removeClass('active');
                        window.$('#collapseVote').collapse('show')
                    }
                }
            });
        }
    }
    render() {
        if (!this.props.isVoteAvail) {
            return <></>
        }
        let votesList;
        let nothingLive = this.state.vote.find(d => d.live !== 0);
        const votes = [...this.state.vote];
        if (votes?.length > 0) {
            votesList = votes.map((vote, index) => {
                if (vote.live === 0) return;
                return <div key={index} className="card popup-card-wrapper">
                    <div className="card-header py-2 px-0" id={`headingPoll${index}`}>
                        <h3 className='mb-0 py-2 d-flex justify-content-between'>
                            <a className='btn btn-link btn-block collapsed'
                                type='button'
                                data-toggle="collapse"
                                data-target={`#collapsePoll${index}`}
                                aria-expanded="false"
                                aria-controls={`collapsePoll${index}`} >
                                {vote?.question}
                            </a>
                        </h3>
                    </div>
                    <div id={`collapsePoll${index}`}
                        className="collapse"
                        aria-labelledby={`headingPoll${index}`}
                        data-parent="#accordionVote">
                        <div className="card-body">

                            {(this.state.vote[index].isSubmitted || (vote?.answer_id && vote?.answer_id !== 0)) ?
                                <div className="poll-answer-wrap polling-answer">
                                    <div className="poll-answer-wrap polling-answer">
                                    <h5>Submitted Answer :{!this.state.vote[index].optionSelected ?
                                        (vote?.answer_id && vote.options?.find(option => parseInt(option?.id) === parseInt(vote?.answer_id)).option)
                                        : this.state.vote[index].isSubmitted && vote.options?.find(option => parseInt(option?.id) === parseInt(this.state.vote[index].optionSelected)).option}                    </h5>
                                </div>
                            </div>
                                : <form className="voting-form py-3">
                                    <div className="db-radio-check">
                                        {vote.options?.map((option, i) => {
                                            return <label onClick={(e) => this.setOptions(e, index, option?.id)} key={i} className="checkcontainer">
                                                <input
                                                    value={option?.id}
                                                    onClick={(e) => this.setOptions(e, index)}
                                                    type="radio"
                                                    checked={this.state.vote[index].optionSelected === option?.id}
                                                    name='vote' />
                                                <span className="radiobtn"></span>
                                                {option?.option}
                                            </label>
                                        }
                                        )}

                                    </div>
                                    <div onClick={(e) => this.onSubmitVote(e, index, vote?.id)} className="text-center mt-5">
                                        <a href="#" className="btn vw-btn vw-btn-black">Send</a>
                                    </div>
                                </form>}

                        </div>
                    </div>
                </div >
            })
        }

        return (
            <div id="collapseVote" className="collapse collapseVote vote-box-wrap box-wrap-modal gs-box-wrap-modal">

                <div className="card box-wrap-card">
                    <div className="card-header p-0">
                        <div className="page-heading">
                            <h4 className="modal-title page-title float-right"> Vote</h4>


                        </div>
                    </div>
                    {!nothingLive ? <div className="db-no-data">No Vote is live</div> : <div className="card-body gs-card-body-scrollbar pt-0">
                        <div className="accordion event-info-accordion QA-accordion" id="accordionVote">
                            {votesList}
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
