import React from 'react'
import profilePic from '../../../../assets/images/user3-128x128.jpg';
import logo from '../../../../assets/Gs1/1200px-Logo_GS1.svg.png';
import logout from "../../../../hooks/logout";
import { useHistory } from 'react-router';
import Location from "../../../../hooks/Location";
import { imageWithStorage } from '../../../../constants/url/urlConstants';
import profilePlaceholder from '../../../../assets/user/avatarplaceholder.png';
import { Link } from 'react-router-dom';
let VP = [14, 12.5, 12, 11.5, 11, 10.5, 10, 9.5, 9];

export default function Gs1Header({ height, user, profilePic, showModal }) {
    const history = useHistory();
    const [, event] = Location();

    const onLogout = () => {
        logout();
        history.replace(`/GS1/${event}/login`)

    }

    return (
        <nav id="nav" className="navbar navbar-expand-lg navbar-light gs-navbar-light">
            <div className="logo-wrap ">
                <Link className="navbar-brand close-all-modal" to='#'>
                    <img src={logo} className="img-fluid" alt="" />
                    <span>Global Forum 2021</span>
                </Link>
            </div>
            <button className="navbar-toggler third-button" type="button" data-trigger="#main_nav">
                <div className="animated-icon"><span></span><span></span><span></span></div>
            </button>
            <div className="navbar-collapse" id="main_nav">
                <div className="offcanvas-header canvas-side-header mt-3">
                    <div className="logo-wrap">
                        <Link className="navbar-brand" to='#'>
                            <img src={logo} className="img-fluid" alt="" />
                            <span>Global Forum 2021</span>
                        </Link>
                    </div>
                    <div className="offcanvas-close">

                        <button className="navbar-toggler third-button" type="button" data-trigger="#main_nav">
                            <div className="animated-icon"><span></span><span></span><span></span></div>
                        </button>
                    </div>
                </div>

                <div className="user-deatil-wrap ml-auto">
                    <div className="user-panel">
                        <div className="user-img-wrap">

                            <img src={user?.ImageURL ? user?.ImageURL : profilePlaceholder} className="img-fluid rounded-circle" alt="" />
                        </div>
                        <div className="user-info-wrap">
                            <a href="#" data-target="#myprofilemodal" data-toggle="modal" className="user-name">{`${user?.FirstName ? user?.FirstName : ''} ${user?.LastName ? user?.LastName : null}`}</a>
                            <div className="user-actions">
                                <a onClick={onLogout} href="#"><i className="fa fa-sign-out fa-rotate-180"></i></a>
                                <a href="#"><i className="fas caret-down "></i></a>
                                <div className="dropdown setting-dropdown">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button" id="dropdownMenu2"
                                        data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="true">
                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <a data-toggle="modal" data-target="#myprofilemodal" className="dropdown-item" type="button" >My Profile</a>
                                        <a data-tab="tt-my-tab" data-toggle="modal" data-target="#program_modal" class="dropdown-item tab-direct" type="button">My Program</a>
                                        {/* <a data-tab="tt-all-tab" data-toggle="modal" data-target="#program_modal" class="dropdown-item tab-direct" type="button">Full Program</a> */}
                                        {/* <a data-toggle="modal" data-target="#program_modal" className="dropdown-item tab-direct" type="button">My Program</a>
                                        <a data-toggle="modal" data-target="#program_modal" className="dropdown-item tab-direct" type="button">Full Program</a> */}
                                        {/* <a data-toggle="modal" data-target="#speakers_modal" className="dropdown-item" type="button">Speakers</a> */}
                                        <a className="dropdown-item"
                                            data-toggle="modal"
                                            data-target="#modalFAQ"
                                            type="button"
                                            href="#">Need Help? - Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    )
}
