import { IncommingCall } from "./IncommingCall";
import { LetterPagination } from "./LetterPagination";
import { OneDayAppointment } from "./OneDayAppointment";
import { Context as networkingToolContext } from "./../../../context/NetworkTollContext";
import { Context as defaultContext } from "./../../../context/DefaultSectionContext";
import { useContext, useEffect, useState } from "react";
import { CardProfile } from "./CardProfile";
import user_img from "../../../assets/images/user1-128x128.jpg";
import { Accepted } from "./Appointment/Accepted";
import { Pending } from "./Appointment/Pending";
import {OptionConstants} from "../../../constants/Constants";
import { changeColor } from "../../../utlis/changeColor";
const OnlineUsers = () => {
  const {
    // fetchOnlineUsers,
    state: { details },
  } = useContext(networkingToolContext);

  const {
    state: { onlineUsers },
  } = useContext(defaultContext);

  const [currentTime, setCurrentTime] = useState(new Date());

  const [currentLatter, setCurrentLatter] = useState({
    latter: "all",
    date: new Date(),
  });
  const [appointmentUser, setAppointmentUser] = useState(null);
  const [isFetching, setIsFatching] = useState(false);
  const [pusherOnlineUsers, setPusherOnlineUsers] = useState([]);
  useEffect(() => {
    if (onlineUsers && details) {
      setPusherOnlineUsers(
        onlineUsers?.members?.filter((u) => {
          return (
            currentLatter?.latter === "all" ||
            u.name
              ?.toLowerCase()
              .startsWith(currentLatter?.latter?.toString().toLowerCase())
          );
        })
      );
      updateColor()
    }
  }, [details, currentLatter, onlineUsers]);

  let onlineUsersEl;
  if (pusherOnlineUsers.length > 0) {
    onlineUsersEl = pusherOnlineUsers.map((user, index) => {
      return (
        <CardProfile
          setAppointmentUser={setAppointmentUser}
          key={index}
          event={details?.event}
          user={user}
        />
      );
    });
  } else {
    onlineUsersEl = <h3> No user found! </h3>;
  }


  const updateColor = () => {
    setTimeout(function(){
      const colorCode = details?.event?.color;
      changeColor(
        colorCode,
        `.my_appointment_section .nav-link`,
        `{background-color: #ffffff!important;color:${colorCode}!important;}`
      );
      changeColor(
        colorCode,
        `.my_appointment_section .nav-tabs`,
        `{border: 1px solid ${colorCode}!important;}`
      );

      changeColor(
        colorCode,
        `.my_appointment_section .nav-item .active`,
        `{background-color: ${colorCode}!important;border-color:${colorCode}!important;}`
      );

      changeColor(
        colorCode,
        `.df-networking-wrap`,
        `{border-color: ${colorCode}!important;}`
      );

      changeColor(
        colorCode,
        `.start_one_on_one`,
        `{border-color: ${colorCode}!important;}`
      );
      

      changeColor(
        colorCode,
        `.filter-items.active`,
        `{border-color: ${colorCode}!important;}`
      );
    },1000)
  }
  

  // reduce<U>(callbackfn: (previousValue: U, currentValue: number, currentIndex: number, array: Uint8ClampedArray) => U, initialValue: U): U;
  const appointment = details?.networking_tool?.appointments.reduce((prev, curr, index) => {
    if (curr.status === OptionConstants.PENDING){
      prev.pendings.push(curr);
    }else if (curr.status === OptionConstants.ACCEPTED){
      prev.accepteds.push(curr);
    }
    return prev
  }, {pendings: [], accepteds: []});

 const pendings = appointment.pendings.map( p => {
   return <Pending appointment={p} />
 });

  const isAlreadyStarted = (date) => {
    let threash_hold = new Date(date)
    return currentTime >= threash_hold;
  }
  
  const joinTime =  details?.networking_tool?.config?.nt_show_join; 
  const onLoby = (date) => {
    var lobyDateTime = Date.parse(date)
    lobyDateTime = lobyDateTime - (joinTime * 60 * 1000);
    let threash_hold = new Date(lobyDateTime);
    return currentTime >= threash_hold;
  }
  useEffect(() => {
    updateColor()
  }, [details?.event?.color])

  useEffect(() => {
    setInterval(function() {
      // do stuff
      setCurrentTime(new Date())
    }, 2000);    
  }, [])

  const accepteds = appointment.accepteds.map( p => {
    return <Accepted alreadyStarted={isAlreadyStarted(p.date)} onLoby={onLoby(p.date)} appointment={p}/>
  });

  const currentLatterRequest = (latter) => {
    setCurrentLatter({ latter: latter, date: new Date() });
  };

  const panding_notfiy = Object.values(details?.networking_tool?.appointments).filter(element => element.status === "pending").length;

  return (    
    <div className="networking-wrap">
      <LetterPagination
        setCurrentLatter={currentLatterRequest}
        currentLatter={currentLatter.latter}
        alphabets={
          onlineUsers?.members
            ?.filter((u) => u.name !== null)
            ?.map((u) => u.name.charAt(0).toUpperCase()) ?? []
        }
      />
      <div className="profile-card-wrap">
        <div className="networking-card-wrap">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
              <div className="networking--my-appointment">
                <div className="appointment_heading">
                  <h5>My Appointments</h5>
                </div>
                <div className="my_appointment_section">
                  <div className="tap_header">
                  {panding_notfiy > 0 ? <span class="badge badge-pill badge-danger">{panding_notfiy}</span> : ""}
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        ACCEPTED
                      </a>
                    </li>
                    <li className="nav-item">
                   

                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        PENDING
                      </a>
                    </li>
                  </ul>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="tabs-1"
                      role="tabpanel"
                    >
                      {accepteds}
                    </div>
                    <div
                      className="tab-pane"
                      id="tabs-2"
                      role="tabpanel"
                    >
                      {pendings}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dft-appointment-listing">
                {/*{appointments}*/}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
              <div className="networking-cards-list scrollbar">
                {!isFetching && onlineUsersEl}
                {isFetching && <h3> Fetching Data ....</h3>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineUsers;
