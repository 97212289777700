import React, { useState } from 'react'
import ChatInput from '../../../../../../UIComponets/ChatInput';


export default function Chat({ chatData, chat, userDetails, username = 'Sagar Pandey' }) {


    let chats;
    if (chatData?.length > 0) {
        chats = chatData?.map((chat, index) => {
            const trimmedName = chat?.user_name
            const userId = chat?.user_id;
            return <div key={index} className={`direct-chat-msg ${userId ? (userId === userDetails ? 'right' : '') : (chat?.user_id === userDetails ? 'right' : '')}`}>
                <div className="direct-chat-text">
                    {chat?.message}
                </div>
                <div className="direct-chat-infos">
                    <span
                        className="direct-chat-timestamp">{chat?.time}</span>&nbsp;
                    <span className="direct-chat-name">{userId ? (userId === userDetails ? 'You' : chat?.user_name) : (chat?.user_id === userDetails ? chat?.user_name : 'You')}</span>
                </div>
            </div>
        });

    }
    return (
        <>
            <div className="card-body">
                <div className="direct-chat-messages gs-direct-chat-messages">
                    {chats}
                </div>

            </div>

        </>
    )
}
