import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import { baseUrl } from '../../../../../../constants/url/urlConstants';
import { OnAirRating_Channel, PUSHER_APP_KEY, PUSHER_CLUSTER, RatingEvent } from '../../../../../../constants/Pusher/PusherConstants';
import { Context as GS1Context } from '../../../../../../context/GS1Context';
import Pusher from 'pusher-js';
import toast from 'react-hot-toast';
import DBNetwork from "../../../../../../api/DBNetwork";
import star from 'react-rating-stars-component/dist/star';
import $ from 'jquery';
import PusherWrapper from '../../../../../../hooks/PusherWrapper';
class Rating extends React.Component {
    static contextType = GS1Context
        ;
    constructor(props) {
        super(props);

        this.state = {
            ratingStars: '',
            message: '',
            rating: props.rating ? props.rating : [],
            isComponentMounted: false,
            eventId: props.eventId,
            pusher: null,
            isupdate: false,
        }
        // console.log("Channels[eventId] " + props);
    }

    onTextChange = (e, queId, index) => {
        const text = `TextRating${queId}`
        const list = [...this.state.rating];
        let rating = { ...this.state.rating[index] };
        rating.review = e.target.value;
        list[index] = rating;

        // alert(queId);
        this.setState((prevState, props) => ({
            ...prevState, rating: list,
        }));
    }
    ratingChanged = (newRating, queId, index) => {

        const list = [...this.state.rating];
        let rating = { ...this.state.rating[index] };
        rating.req = { ...rating.req || {}, given_star: newRating };
        list[index] = rating;
        this.setState((prevState, props) => ({
            ...prevState, rating: list,
        }));

    };


    onSubmitRating = async (e, queId, index) => {
        e.preventDefault();
        if (this.state.rating[index].answered === undefined) {

        }
        const headers = {
            Authorization: ` Bearer ${this.props.token} `
        }
        const url = baseUrl('api/send-rating');
        // const stars = this.state.rating[index].answered;
        const review = this.state.rating[index].review
        const obj = { ...this.state.rating[index] };
        const req = obj.req;


        const payload = {
            event_id: this.props.eventId,
            q_id: queId,
            star: req.given_star,
            review: review
        }

        const response = await DBNetwork.post(url, { ...payload });
        console.log(response);
        if (response.data?.status) {

            const list = [...this.state.rating];
            let rating = { ...this.state.rating[index] };
            rating.isRated = true

            const resObj = response.data.response[rating.id]
            if (resObj && resObj.answered) {
                rating.given_star = resObj.answered;
            }
            list[index] = rating;
            this.setState((prevState, props) => ({
                ...prevState, rating: list,
            }));
            toast(`your rated ${this.state.rating[index].req?.given_star} stars.`);
        }
    }

    setRating = ratings => {
        this.setState((prevState, props) => ({
            ...prevState, ratings: ratings,
        }));
    }
    componentWillUnmount() {
        if (this.props.eventId) {
            const eventId = this.props.eventId;
            const list = [OnAirRating_Channel + eventId];
            PusherWrapper.sharedInstance().removeChannels(list);

        }

    }

    componentDidMount() {


        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
          
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const channel = pusher.subscribe(OnAirRating_Channel + this.props.eventId);
            channel.bind(RatingEvent, data => {
                // this.setState({ chats: [...this.state.chats, data.message], test: '' });
                let index = this.state.rating.findIndex((obj) => obj.id === data.rating.id)
                if (index !== -1) {
                    let req = this.state.rating[index].req;
                    let list = [...this.state.rating];
                    const review = this.state.rating[index].review
                    if (req) {
                        if (data?.rating?.deleted) {
                            list.splice(index, 1);
                        } else {
                            let appendingObject = { ...data.rating }
                            appendingObject.given_star = req.given_star;
                            appendingObject.review = review;
                            appendingObject.isRated = true;
                            appendingObject.req = null;
                            appendingObject.question = data.rating?.question;
                            appendingObject.star = data.rating?.star;
                            list[index] = appendingObject;
                        }
                        // list.splice(index, 1);

                    } else {
                        if (data.rating.deleted) {
                            list.splice(index, 1);
                        } else {
                            let oldObject = { ...list[index] };
                            oldObject.live = data.rating.live;
                            oldObject.question = data.rating.question;
                            oldObject.star = data.rating.star;
                            list[index] = { ...oldObject };
                        }

                    }
                    this.setState((prevState, props) => ({
                        ...prevState, rating: list, isupdate: true
                    }));

                    // this.props.isRatingEmpty(this.state.rating.filter(d => d.live !== 0)?.length, 'ratingNull');
                    const { updateAllUserRatings } = this.context;
                    updateAllUserRatings([...list]);
                    let mList = [...this.state.rating, data.rating].filter(rating => rating.live !== 0);
                    if (mList.length === 0) {
                        window.$('#rating-box-wrapper').removeClass('show');
                        window.$('.full-width-box').removeClass('active');
                    } else if (mList.length >= 1 && !window.$('#rating-box-wrapper').hasClass('show')) {
                        window.$('.inner-video-wrap').removeClass("toggle-active");
                        window.$('.gs-box-wrap-modal').collapse('hide');
                        window.$('.full-width-box').removeClass('active');
                        window.$('#rating-box-wrapper').collapse('show')
                    }
                } else {
                    let appendingObject = { ...data.rating }
                    appendingObject.given_star = 0;
                    appendingObject.review = '';
                    appendingObject.isRated = false;
                    appendingObject.req = null;
                    appendingObject.question = data.rating.question
                    if (data.rating.live) toast(`Hey ${data.rating?.question} is Live.`);
                    let list = [appendingObject, ...this.state.rating]
                    this.setState((prevState, props) => ({
                        ...prevState, rating: list,
                        isupdate: true,
                    }));
                    const { updateAllUserRatings } = this.context;
                    updateAllUserRatings([...list]);
                    let mList = [...this.state.rating, data.rating].filter(rating => rating.live !== 0);
                    if (mList.length === 0) {
                        window.$('#rating-box-wrapper').removeClass('show');
                        window.$('.full-width-box').removeClass('active');
                    } else if (mList.length >= 1 && !window.$('#rating-box-wrapper').hasClass('show')) {
                        window.$('.inner-video-wrap').removeClass("toggle-active");
                        window.$('.gs-box-wrap-modal').collapse('hide');
                        window.$('.full-width-box').removeClass('active');
                        window.$('#rating-box-wrapper').collapse('show')
                    }
                    // if (data.rating.live) {
                    // this.props.isRatingEmpty(this.state.rating.filter(d => d.live !== 0)?.length, 'ratingNull');

                    // let isAnyRatingLive = this.state.rating.find(d => d.live !== 0)?.length;
                    // if (isAnyRatingLive) {
                    //     // isAnyRatingLive?.length
                    //     this.props.isRatingEmpty(false, 'ratingNull');
                    // }
                    // else {
                    //     this.props.isRatingEmpty(true, 'ratingNull');
                    // }

                    // }
                    // this.props.isRatingEmpty(this.state.rating.filter(d => d.live !== 0)?.length, 'ratingNull');
                    // $('.fixed-menu-item').removeClass('active')
                    // window.$('.inner-video-wrap').removeClass("toggle-active");
                    // window.$('.gs-box-wrap-modal').collapse('hide');
                    // window.$('.full-width-box').removeClass('active');
                    // window.$('#rating-box-wrapper').collapse('show')
                }
                // this.setRating(data.message);
            });
        }
    }

    render() {
        if (!this.props.isRatingAvail) {
            return <></>
        }
        let ratingQue;
        let anyRatingLive = this.state.rating?.find(x => x.live !== 0);
        if (this.state.rating.length > 0) {
            ratingQue = this.state.rating?.map((rating, index) => {
                const date = moment(rating?.created_at + 'Z').format('DD MMM, YYYY');
                if (rating?.live === 0) return

                return (<>
                    <div key={index} className="card popup-card-wrapper">
                        <div className="card-header p-0" id={`rating${index}`}>
                            <h3 className="mb-0 py-1 d-flex justify-content-between">
                                <a className="btn btn-link btn-block collapsed" type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseRating${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapseRating${index}`}>
                                    {/* {que?.question} */}
                                    {rating?.question}
                                </a>
                            </h3>
                            <div id={`collapseRating${index}`}

                                className="collapse"
                                aria-labelledby={`#rating${index}`}
                                data-parent={'#accordionRating'}
                            >
                                <div className="card-body">
                                    <div className="rating-wrapper">
                                        {(rating.given_star !== 0 || rating.isRated) ? <> <ReactStars
                                            count={rating.given_star ? rating.given_star : (rating.req?.given_star || 0)}
                                            size={30}
                                            value={rating.answered}
                                            color="#ffd700"
                                            activeColor="#ffd700"
                                        />
                                            <div className="submitted-ans-wrapper">
                                                <div className="poll-answer-wrap polling-answer">
                                                    <h5> Submitted Rating : {rating.given_star ? rating.given_star
                                                        : (rating.req?.given_star || 0)} Star</h5>
                                                </div>
                                            </div>
                                            {rating.review && <div className="db-no-data text-left justify-content-start"> Review : {rating.review ? rating.review
                                                : (rating.review || '')}
                                            </div>}</> : <form action="" method="get">

                                                <div id="starsRating" className="rating-stars-wrapper">
                                                    <ReactStars
                                                        count={rating?.star}
                                                        onChange={(newRating) => this.ratingChanged(newRating, rating.id, index)}
                                                        size={45}
                                                        // isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        // classNames={props.faIConMainContainer}
                                                        activeColor="#ffd700"
                                                        color="#212529"
                                                    />
                                                </div>
                                                <div className="rating-head">
                                                    <p>FeedBack</p>
                                                </div>
                                                <div className="form-group">
                                                    <textarea onChange={(e) => this.onTextChange(e, rating.id, index)}
                                                        value={rating.review ? rating.review : ''}
                                                        type="text" name={'TextRating' + rating.id} rows="3"
                                                        className="form-control" placeholder=""></textarea>
                                                </div>
                                                <div className="form-group text-center">
                                                    <button onClick={(e) => this.onSubmitRating(e, rating.id, index)} className="btn btn-rating"
                                                        type="submit">Send</button>
                                                </div>
                                            </form>

                                        }
                                    </div>

                                </div>
                            </div >
                        </div>
                    </div>
                </>
                )
            })
        }

        return (

            <div id="rating-box-wrapper"
                className={`rating-box-wrap gs-box-wrap-modal rounded-0 `}
                aria-labelledby="">
                <div className="card box-wrap-card">
                    <div className={`card-header p-0`}>
                        <div className="page-heading">
                            <h4 className="modal-title page-title float-right">
                                Session Rating</h4>
                        </div>
                    </div>
                    {!anyRatingLive ? <div className='db-no-data'>No Rating is live</div> :
                        <div className="card-body gs-card-body-scrollbars px-3">
                            <div className="accordion event-info-accordion QA-accordion"
                                id={'accordionRating'}>
                                {ratingQue}
                            </div>
                        </div>}

                </div>
            </div>
        );
    }
}




export default Rating;
