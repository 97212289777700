
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../../../constants/url/urlConstants';
import PusherConstants, { OffAirPollEvent, OffAirPoll_Channel, OnAirPollEvent, OnAirPoll_Channel, PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../../../constants/Pusher/PusherConstants';
import Pusher from 'pusher-js';
import DBNetwork from "../../../../../../api/DBNetwork";
import toast from 'react-hot-toast';
import $ from 'jquery';
import PusherWrapper from '../../../../../../hooks/PusherWrapper';
import { Context as GS1Context } from "../../../../../../context/GS1Context";

export default class Poll extends Component {
    static contextType = GS1Context;
    constructor(props) {
        super(props);
        this.state = {
            poll: props.poll ? props.poll : {},
            // optionSelected: props.answer_id !== 0 ? props.answer_id : '',
            pusher: null,
            isSubmitted: false,
            idLoading: false,


        }
    }
    onOptionSelection = (e, id) => {
        e.preventDefault();
        let _poll = { ...this.state.poll }
        _poll._answer_id = id;

        this.setState((prevState, props) => ({
            ...prevState, poll: _poll,
        }));
    }

    onSubmitPoll = async () => {
        this.setState((prevState, props) => ({
            ...prevState, idLoading: true,
        }));
        const url = baseUrl('api/send-poll-answer');
        const question_id = this.state.poll?.id
        const option_id = this.state.poll?._answer_id;
        const event_id = this.props?.eventId;
        if (option_id) {
            const response = await DBNetwork.post(url, { question_id, option_id, event_id });
            if (response.status) {
                toast.success(`🤙🏻You submitted option ${this.state.poll?.
                    options?.find(x => x.id === parseInt(this.state.poll?._answer_id))?.option}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                let _poll = { ...this.state.poll };
                _poll.answer_id = option_id;
                this.setState((prevState, props) => ({
                    ...prevState, poll: _poll, isLoading: false
                }));
            }
        } else {
            toast(`Please Select a Value `);

        }

    }
    componentWillUnmount() {
        if (this.props.eventId) {
            const eventId = this.props.eventId;
            const list = [OffAirPoll_Channel + eventId, OnAirPoll_Channel + eventId];
            PusherWrapper.sharedInstance().removeChannels(list);
        }

    }
    componentDidMount() {
        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();

            this.state.pusher = pusher;
          
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            const onAirchannel = pusher.subscribe(OnAirPoll_Channel + this.props.eventId);
            onAirchannel.bind(OnAirPollEvent, data => {
                // console.log('[OnAirPollEvent]' + JSON.stringify(data))
                if (this.state.poll.id === data?.poll.id) {
                    let _poll = { ...this.state.poll }
                    _poll.answer_id = this.state.poll._answer_id
                    _poll.live = data?.poll?.live;
                    this.setState((prevState, props) => ({
                        ...prevState, poll: _poll
                    }));
                    // const { updateAllUserPolls } = this.context;
                    // updateAllUserPolls({ ..._poll });
                    if (_poll.live === 0) {
                        window.$('#collapsePoll').removeClass('show');
                        window.$('.full-width-box').removeClass('active');
                    } else {
                        window.$('.inner-video-wrap').removeClass("toggle-active");
                        window.$('.gs-box-wrap-modal').collapse('hide');
                        window.$('.full-width-box').removeClass('active');
                        window.$('#collapsePoll').collapse('show')
                    }

                    // toast(`${data?.poll?.question} Poll is Live `);
                } else {

                    const url = baseUrl('api/get-poll-question');

                    DBNetwork.post(url, { event_id: this.props?.eventId })
                        .then(response => {
                            // const { updateAllUserPolls } = this.context;
                            // updateAllUserPolls({ ...response.data.poll })
                            if (Object.keys(response.data.poll).length > 0) {
                                if (response.data.poll?.live === 0) {
                                    window.$('#collapsePoll').removeClass('show');
                                    window.$('.full-width-box').removeClass('active');
                                } else if (!window.$('#collapsePoll').hasClass('show')) {
                                    window.$('.inner-video-wrap').removeClass("toggle-active");
                                    window.$('.gs-box-wrap-modal').collapse('hide');
                                    window.$('.full-width-box').removeClass('active');
                                    window.$('#collapsePoll').collapse('show')
                                }

                                this.setState((prevState, props) => ({
                                    ...prevState, poll: response.data.poll
                                }));
                            } else {
                                window.$('#collapsePoll').removeClass('show');
                                window.$('.full-width-box').removeClass('active');
                            }
                            // this.props.isPollEmpty(response.data.poll?.live, 'pollNull');



                        })
                        .catch(e => console.log(e))

                }
            });
            const offAirChannle = pusher.subscribe(OffAirPoll_Channel + this.props.eventId);

            offAirChannle.bind(OffAirPollEvent, data => {
                if (this.state.poll.id === data?.poll.id) {
                    // console.log('[OffAirPollEvent]' + JSON.stringify(data))
                    let _poll = { ...this.state.poll }
                    _poll.live = data?.poll?.live;

                    if (_poll._answer_id !== 0) {
                        this.setState((prevState, props) => ({
                            ...prevState, poll: {}
                        }));
                        this.props.isPollEmpty(false, 'pollNull');
                        // const {updateAllUserPolls} = this.context;
                        // updateAllUserPolls({})

                    } else {
                        const newObj = { ...data.poll }
                        newObj.answer_id = _poll.answer_id;
                        this.setState((prevState, props) => ({
                            ...prevState, poll: newObj
                        }));
                        // this.props.isPollEmpty(newObj?.live, 'pollNull');
                        // const { updateAllUserPolls } = this.context;
                        // updateAllUserPolls({ ...newObj })
                    }

                    window.$('#collapsePoll').removeClass('show');
                    window.$('.full-width-box').removeClass('active');
                    // toast(`${data?.poll?.question} Poll is Live `);
                } else {
                    const url = baseUrl('api/get-poll-question');
                    DBNetwork.post(url, { event_id: this.props?.eventId })
                        .then(response => {
                            console.log(response.data)
                            if (Object.keys(response.data.poll).length > 0) {
                                // const { updateAllUserPolls } = this.context;
                                // updateAllUserPolls({ ...response.data.poll })

                                window.$('#collapsePoll').removeClass('show');
                                window.$('.full-width-box').removeClass('active');

                                this.setState((prevState, props) => ({
                                    ...prevState, poll: response.data.poll
                                }));

                                // this.props.isPollEmpty(response.data.poll?.live, 'pollNull');

                            }
                        })
                        .catch(e => console.log(e))

                }

            });
        }
    }
    //
    render() {
        if (!this.props.isPollAvail) {
            return <></>;
        }
        let poll = { ...this.state.poll };
        let answered;
        if (poll?.answered !== 0) {
            answered = poll?.options?.find(x => x.id === poll?.answered)?.option
        }
        let pollQuestion;
        let pollOptions;

        if (poll) {
            pollQuestion = poll?.question;
            if (poll.options?.length > 0) {
                pollOptions = poll?.options?.map((option, index) => {
                    return <label onClick={(e) => this.onOptionSelection(e, option.id)}
                        key={index} className="checkcontainer">
                        <input type="radio"
                            name="poll"
                            value={option?.id}
                            checked={this.state.poll._answer_id === option.id}
                        />
                        <span className="radiobtn"></span>
                        {option?.option}
                    </label>

                })
            }

        }

        return (
            <div id='collapsePoll'
                className="collapse poll-box-wrap box-wrap-modal gs-box-wrap-modal collapsepoll">
                <div className="card box-wrap-card">
                    <div className="card-header p-0">
                        <div className="page-heading">
                            <h4 className="modal-title page-title float-right">Poll</h4>
                        </div>
                    </div>
                    <div className="card-body gs-card-body-scrollbar pt-0">
                        {(poll?.answer_id !== 0) && pollQuestion ? <>
                            <div className="submitted-ans-wrapper">
                                <div className="poll-question-wrap polling-question" >
                                    <h4>Asked Question : {pollQuestion}</h4>
                                </div>
                                <div className="poll-answer-wrap polling-answer">
                                    <h5>Submitted Answer: {poll?.answer_id !== 0 && poll?.options?.find(x => x.id === parseInt(poll?.answer_id))?.option}</h5>
                                </div>
                            </div>

                        </> : <form className="voting-form py-4">
                            <div className="voting-que">
                                <h4 className="text-center">{pollQuestion}</h4>
                                </div>
                                <div >
                                    <div className="db-radio-check m-auto">
                                        {pollOptions}
                                    </div>
                                    {
                                        pollQuestion ? <div className="text-center mt-2">
                                            <Link onClick={this.onSubmitPoll}
                                                to="#"
                                                className="btn vw-btn vw-btn-black">
                                                Send
                                          {this.state.isLoading && <i aria-hidden="true"
                                                    className={'fa fa-spinner fa-spin'}>
                                                </i>}
                                            </Link>
                                        </div> : <div
                                            className="db-no-data">
                                                No Live Poll!
                            </div>
                                    }
                                </div>
                            </form>}

                    </div>
                </div>
            </div>
        )
    }
}
