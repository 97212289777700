import React, { useContext, useEffect, useState } from 'react'
import NotificationCenter from '../../../hooks/NotificationCenter';
import { ListProfile } from './ListProfile'
import { PlayerChat } from './PlayerChat'
import { commonUserAppointmentEvent, Context as networkingToolContext } from './../../../context/NetworkTollContext';
import VideoChat from '../VideoChat/VideoChat';
import { Waiting } from './Waiting';
import { OnlineUserList } from './OnlineUserList';
import Img from '../../components/defaultLayout/Img';
import UserProfileImage from '../../components/defaultLayout/UserProfileImage';
import { useToasts } from "react-toast-notifications";
import { ErrorOption } from "../../components/ToastOptions";
import { Error, OnlineUser, TwillioParticipant } from "../../../Utils/Utils";
import { Context as defaultContext } from "../../../context/DefaultSectionContext";


const ENDCALLBUTTON = "ENDCALL_BUTTON";

export const OneToOne = () => {
    const {
        fetchMatchedUser,
        endChat,
        state: {
            videoChat,
            videoChatState,
            details,
            dice_matched,
            onlineUsers,
            connectedUser
        }
    } = useContext(networkingToolContext);
    const {
        state: {
            onlineUsers: {
                membersMap,
                members
            }
        },
    } = useContext(defaultContext);

    const { addToast } = useToasts();
    const [isFetchingMatchedUser, setIsFetchingMatchedUser] = useState(false);
    const userDetailsMap = onlineUsers?.users?.reduce((result, user) => {
        result[user.id] = user;
        return result;
    }, {});

    const [isWating, setIsWating] = useState(true);
    const [loading, setLoading] = useState({ isLoading: false, type: null })
    const Loader = <i aria-hidden="true" className={`fa fa-spinner fa-spin`} />
    const [isTab, setTab] = useState({ val: 1 });

    const onClickDiv = (val) => {
        setTab(prev => {
            return { ...prev, val }
        });
    }

    const endingCall = (e) => {
        e.preventDefault();
        setLoading({ isLoading: true, type: ENDCALLBUTTON })
        setTimeout(() => {
            endChat(videoChat?.match_id, (response, error) => {
                if (response) {
                    setLoading({ isLoading: false, type: null })
                } else if (error) {
                    addToast(Error(error).getResponseError(), ErrorOption)
                }
            })
        }, 2000)
    }
    useEffect(() => {

        const gotMatch = () => {

            if (videoChat?.match_id && !isFetchingMatchedUser) {
                setIsFetchingMatchedUser(true)
                fetchMatchedUser(videoChat?.match_id, (response) => {
                    setIsFetchingMatchedUser(false)
                })
            }
        }
        NotificationCenter.sharedInstance().registerNotification("participantConnected", (participants) => {
            if (participants.length > 0) {
                setIsWating(false)
            }
            gotMatch();
        })

        NotificationCenter.sharedInstance().registerNotification("participantDisconnected", ({ participants, disconnected }) => {
            if (participants.length > 0) {
                setIsWating(false)
            }
            if (participants.length === 0) {

                endChat(videoChat?.match_id)
            }
            const twillioParticipant = TwillioParticipant(disconnected);
            let message = "Participant Disconnected";
            const userID = twillioParticipant.getUserID()
            if (userID) {
                const userDetails = userDetailsMap[userID];
                if (userDetails) {
                    const user = OnlineUser(userDetails);
                    const userName = user.getFullName() ?? "Participant";
                    const isHostDisconnected = videoChat?.matched_users?.host_id == userID;
                    message = `${ userName }${isHostDisconnected ? " (Host)" : ""} Disconnected The Call`;
                }
            }
            addToast(message, ErrorOption);
            gotMatch();
        })

    }, [videoChat])
    const [isAudioEnable, setAudioEnable] = useState(true);
    const [isVideoEnable, setVideoEnable] = useState(true);

    const onVideoSwitchClick = (e) => {
        const isChecked = e.target.checked;
        setVideoEnable(isChecked);
        NotificationCenter.sharedInstance().broadcastNotification("onVideoSwitchClick", isChecked)
    }

    const onAudioSwitchClick = (e) => {
        const isChecked = e.target.checked;
        setAudioEnable(isChecked);
        NotificationCenter.sharedInstance().broadcastNotification("onAudioSwitchClick", isChecked)
    }

    const [connectedUserMap, setConnectedUserMap] = useState({});
    useEffect(() => {
        let _connectedUserMap = connectedUser?.reduce(function (acc, cur, i) {
            acc[cur.id] = cur;
            return acc;
        }, {});
        if (!_connectedUserMap) {
            _connectedUserMap = {}
        }
        setConnectedUserMap(_connectedUserMap);
    }, [connectedUser])
    
    let isAvailable = [];
    if (members.length > 0) {
        isAvailable = members?.filter(user => !connectedUserMap[user.user_id]).map((user, index) => {
            return (user);
        })
    }

    console.log(isAvailable);

    const isLoadingByType = (type) => loading.isLoading && loading.type === type

    return (
        <>
            <div className="row">
                <div hidden={isWating} className="col-12 col-md-12 col-lg-12">

                    <div className="player-chat-room">
                        <div className="row">
                            <div className="col-md-12 col-lg-7 col-xl-8">
                                <div class="player_chat_room_inner">
                                    <div className="playerchat_header d-flex justify-content-between align-items-center">
                                        <div className="player1-chat chat-player host-player">
                                            <div className="player-imgframe">
                                                <UserProfileImage
                                                    src={videoChat?.matched_users?.avatar}
                                                    className="img-fluid rounded-circle"
                                                    alt="Player-Image"
                                                />
                                            </div>
                                            <div className="ml-3 chat-player-name player1-name text-center pt-3">
                                                <h6 className="player_name">{videoChat?.matched_users?.name}</h6>
                                                {videoChat?.matched_users?.host_id === videoChat?.matched_users?.id ?
                                                    <p className="player_designation">Host {videoChat ? `(${videoChat?.foi})` : `(${dice_matched?.matched_on})` }</p>
                                                    : ""
                                                }
                                            </div>
                                        </div>

                                        <div className="player2-chat chat-player">
                                            <div className="player-imgframe">
                                                <UserProfileImage src={details?.user?.avtar} className="img-fluid rounded-circle" />

                                            </div>
                                            <div className="ml-3 chat-player-name player2-name text-center pt-3">
                                                <h6 className="player_name">{details?.user?.name} (Me)</h6>
                                                {videoChat?.matched_users?.host_id === details?.user?.id ?
                                                    <p className="player_designation">Host {dice_matched ? `(${dice_matched?.matched_on})` : `(${videoChat?.foi})` }</p>
                                                    : ""
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">

                                        </div>


                                        <div className="col-12">
                                            <div className="video-chatting-wrap">
                                                <div className="video-chat-wrapper">
                                                    <VideoChat />
                                                </div>
                                            </div>
                                            <div className="video-actions-wrapper d-flex align-items-center justify-content-center">
                                                <div className="video-action-wrap">
                                                    <div className="video-option-icon">
                                                        <i
                                                            className="fa fa-video-camera"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="custom-control custom-switch dft-custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customSwitch1"
                                                            onClick={(e) => onVideoSwitchClick(e)}
                                                            defaultChecked={isVideoEnable}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="customSwitch1"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div className="video-action-wrap">
                                                    <div className="video-option-icon">
                                                        <i
                                                            className="fa fa-microphone"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="custom-control custom-switch dft-custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customSwitch2"
                                                            onClick={(e) => onAudioSwitchClick(e)}
                                                            defaultChecked={isAudioEnable}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="customSwitch2"
                                                        ></label>
                                                    </div>
                                                </div>
                                                <div className="video-action-wrap">
                                                    <div className="video-disconnet-btn">
                                                        <a href="#" disabled={loading.isLoading} onClick={endingCall} className="video-disconnet-btn">
                                                            {isLoadingByType(ENDCALLBUTTON) ? Loader : <i disabled={loading.isLoading} className="fa fa-phone" aria-hidden="true" />}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-12 col-xl-12">

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5 col-xl-4">
                                <div className="row h-100">                                                        
                                    <div className="one_by_one_chat_area">
                                        <ul >
                                            <li onClick={() => onClickDiv('1')} className={`${isTab.val == "1" || isAvailable.length <= 0 ? 'active' : ""}`}>
                                                <a>Chat</a>
                                            </li>
                                            {isAvailable.length > 0 && <li onClick={() => onClickDiv('2')} className={`${isTab.val == "2" ? 'active' : ""}`}>
                                                <a>People</a>
                                            </li>
                                            }
                                            
                                        </ul>
                                        <div className="tab-content">
                                            <div className={isTab.val == "1" || isAvailable.length <= 0 ? 'd-block' : "d-none" }>
                                                <PlayerChat />
                                            </div>
                                            {isAvailable.length > 0 && <div className={isTab.val == "2" ? 'd-block' : "d-none" }>
                                                {<OnlineUserList />}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isWating && <Waiting />}
        </>
    )
}
