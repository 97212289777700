import React from 'react';
import { Link } from 'react-router-dom';

export default function Links(props) {
    return (
        <a onClick={props.onSelectedOption}
            href={props.href} className={props.className}
            {...props.attributes}
            data-toggle={props.datatoggle}
            data-target={props.dataTarget}
            aria-expanded={props.areaExpanded}
            aria-controls={props.areaControls}
           style={props.style}
        >
            {props.heading}

        </a>
    )
}
