import { imageWithStorage } from "../../../constants/url/urlConstants";

const Img = (props) => {
    return <img
        src={imageWithStorage + props.src}
        onError={(e) => {
            e.target.onerror = null;
            if (props.placeholder) e.target.src = props.placeholder;
        }}
        alt="" className={props.className} />
}

export default Img;

// "img-fluid rounded-circle"