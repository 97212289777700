import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context as DefaultSectionContext } from '../../../../../context/DefaultSectionContext';
import DefaultSectionSocket from '../../DefaultSectionSocket/DefaultSectionSocket';


const Channel = ({ channels, mainChannel, isLoading, channelSelected, eventId, toShow }) => {
    const { fetchEventsSubChannelData, eventViewed } = useContext(DefaultSectionContext)
    const [onChannelSelect, setOnChannelSelect] = useState(null);

    const fetchSubChannelData = async () => {
        const event = channels[0].slug
        const organization = channels[0].organization_slug;
        const url = `${organization}/${event}`;
        try {
            isLoading(true);
            await fetchEventsSubChannelData(url, channels[0].event_id);
            setOnChannelSelect(channels[0].event_id);
            isLoading(false);
        } catch (err) {
            throw new Error(err);
        }
    }

    // useEffect(() => {
    //     if (mainChannel?.islive) {
    //         setOnChannelSelect(mainChannel?.id)
    //     } else {
    //         const isSubchannelExists = channels?.length > 0;
    //         if (isSubchannelExists) {
    //             // setOnChannelSelect(happeningNow?.selected_channel?.id)
    //             fetchSubChannelData();
    //         }
    //     }
    // }, [])


    const onSubChannelSelected = async (e, channelId, organization, event) => {
        e.preventDefault();
        if (channelId === channelSelected) return null;
        const url = `${organization}/${event}`;
        const eventViewUrl = `${organization}/${event}/event-view`
        if (!sessionStorage.getItem('channelSelected')) {
            sessionStorage.setItem('channelSelected', url)
        }
        if (!(channelId === channelSelected)) {
            sessionStorage.setItem('channelSelected', JSON.stringify({ url, id: channelId }));
        }
        try {
            isLoading(true)
            await fetchEventsSubChannelData(url, channelId, (response) => { });
            eventViewed(eventViewUrl);

            isLoading(false);
            setOnChannelSelect(channelId);
        } catch (err) {
            isLoading(false)
            console.log(err);
        }
    }

    const onSubChannelSocketUpdate = async data => {
        // console.log(data)
        const url = `${mainChannel?.organization_slug}/${data?.slug}`;
        try {
            isLoading(true)
            await fetchEventsSubChannelData(url, data?.id, (response) => { });
            isLoading(false);
            setOnChannelSelect(data?.id);
        } catch (err) {
            isLoading(false)
            throw new Error(err);
        }

    }

    let channelsList;
    if (channels?.length > 0) {
        // if (channels?.length  1) return null;
        channelsList = channels?.map((channel, index) => {
            return <Link
                key={index}
                className={`btn ${channelSelected === channel?.event_id ? 'active' : ''}`}

                onClick={(e) => onSubChannelSelected(e, channel?.event_id, channel?.organization_slug, channel?.slug)}
                to="#"
                type="button">
                {channel?.name}
            </Link>
        })
    } else return (
        <section className="channel-button-wrapper">
            <div className="container-fluid">
                <div className="channel-btn-group"></div>
            </div>
            <DefaultSectionSocket
                eventId={eventId}
                channel={onSubChannelSocketUpdate}
            />
        </section>
    );


    return <>
        <DefaultSectionSocket
            eventId={eventId}
            channel={onSubChannelSocketUpdate} />
        {/* {toShow && */}
        <section className="channel-button-wrapper">
            <div className="container-fluid">
                <div className="channel-btn-group">
                    <Link
                        className={`btn ${channelSelected === mainChannel?.id ? 'active' : ''}`}
                        onClick={(e) => onSubChannelSelected(e,
                            mainChannel?.id,
                            mainChannel?.organization_slug,
                            mainChannel?.slug)}
                        to="#"
                        type="button">
                        {mainChannel?.name}
                    </Link>
                    {/* {channels?.length > 1 ? channelsList : null} */}
                    {channelsList}
                </div>
            </div>
        </section >
        {/* } */}
    </>
}

export default Channel;