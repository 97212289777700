import React, { Component } from 'react';
import Pusher from 'pusher-js';
import PusherWrapper from '../../../../../hooks/PusherWrapper';

export default class HappeningNowSocket extends Component {

    constructor(props) {
        super(props);

        this.state = {

            pusher: null
        }

    }
    componentWillUnmount() {
        if (this.props.happeningNow.id) {
            const id = this.props.happeningNow.id;
            const list = ['ChannelUpdate.' + id, 'liveSessionUpdate.' + id];
            PusherWrapper.sharedInstance().removeChannels(list);

        }

    }
    componentDidMount() {

        if (this.state.pusher == null) {
            let pusher = PusherWrapper.sharedInstance().pusher();
            this.state.pusher = pusher;
            const channel = pusher.subscribe('ChannelUpdate.' + this.props.happeningNow.id);
            channel.bind("App\\Events\\ChannelUpdateEvent", data => {
                if (!process.env.production) {
                    // console.log("HappeningNowSocket[componentDidMount > ChannelUpdateEvent] " + JSON.stringify(data));
                }
                this.props.happeningNowData(data);
            });
            const channel1 = pusher.subscribe('liveSessionUpdate.' + this.props.happeningNow.id);
            channel1.bind("App\\Events\\SessionUpdateEvent", data => {
                if (!process.env.production) {
                    // console.log("HappeningNowSocket[componentDidMount > SessionUpdateEvent] " + JSON.stringify(data));
                }
                this.props.happeningNowData(data, 'session');
            });
        }
    }
    render() {

        return (
            <> </>
        );
    }
}
