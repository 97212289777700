import React from 'react'
import ChatInput from '../ChatInput';
import QandAChat from '../QandAChat/QandAChat'
import DBNetwork from "../../api/DBNetwork";
import Pusher from 'pusher-js'
import { baseUrl } from '../../constants/url/urlConstants';
import { PUSHER_APP_KEY, PUSHER_CLUSTER, QAAnswerEvent, QA_Answer_Channel } from '../../constants/Pusher/PusherConstants';

class QandAccordian extends React.Component {
    constructor(props) {
        super(props);
        console.log("URLLLLL " + process.env.URL_TEST)
        this.state = {
            answer: '',
            user: '',
            chats: props.chat ? props.chat : [],
            q_and_a: this.props.q_and_a ? props.q_and_a : [],
            eventId: this.props.eventId,
            pusher: null,
            user: props.user ? props.user : {},
            token: props.token ? props.token : null,

        }
        console.log("Channels[eventId] " + props);
    }

    onTextChangeInput = (e, index) => {
        // alert(e.target.value);
        var q_and_aList = [...this.state.q_and_a];
        var answer = { ...this.state.q_and_a[index] };
        console.log(answer);
        answer.answer = e.target.value;
        q_and_aList[index] = answer;

        // alert(queId);
        this.setState((prevState, props) => ({
            ...prevState, q_and_a: q_and_aList
        }));
    }
    // setMessage = (e) => {
    //     this.setState({ message: e.target.value });
    // };
    setUser = (userName) => {
        this.setState((prevState, props) => ({
            ...prevState, user: userName
        }));
    };
    setChats = (chat) => {
        this.setState((prevState, props) => ({
            ...prevState, chats: [...this.state.chats, chat]
        }));
    };
    setsetIsComponentMounted = (isComponentMounted) => {
        this.setState((prevState, props) => ({
            ...prevState, isComponentMounted: isComponentMounted
        }));
    };

    componentDidMount() {


        this.setState((prevState, props) => ({
            ...prevState, q_and_a: this.props.q_and_a ? props.q_and_a : [],
        }));


        if (this.state.pusher == null) {
            let pusher = new Pusher(PUSHER_APP_KEY, {
                cluster: PUSHER_CLUSTER,
                encrypted: false
            });
            this.state.pusher = pusher;
            Pusher.log = (msg) => {
                console.log("Pusher[log] " + msg);
            };
            pusher.connection.bind('error', function (err) {
                if (err?.code === 404) {
                    console.log('Over limit!');
                }
            });
            // pusher.allChannels().forEach(channel => console.log(channel.name));
            // const channel = pusher.subscribe(QA_Answer_Channel + this.props.eventId);
            // channel.bind(QAAnswerEvent, data => {
            //     console.log(data)

            // });
            const channel = pusher.subscribe(this.props.eventId + 'qa_answer.' + this.props.userDetails);
            channel.bind("App\\Events\\QAAnswerEvent", data => {
                console.log(data)

            });
        }
    }

    onSendMessageEnter = (e, question_id, index) => {
        e.preventDefault();
        const url = baseUrl('api/send-answer');
        // const username = window.prompt('Username: ');
        this.setUser('Harshal');
        const payload = {
            username: this.state.username,
            question_id,
            event_id: this.props.eventId,
            answer: this.state.q_and_a[index].answer,

        };

        DBNetwork.post(url, { ...payload },
        ).then(response => {
            // this.setChats(response.data.message);
            console.log(response.data)
            if (response.data) {
                let list = [... this.state.q_and_a]
                let answer = list[index];
                // answer.answers = []
                let answers = answer.answers ? answer.answers : [];
                answers.push(response.data)
                answer.answers = answers;
                answer.answer = ''
                list[index] = answer

                this.setState((prevState, props) => ({
                    ...prevState, q_and_a: [...list]
                }));
            }
        });
    }
    render() {
        console.log(this.props.userDetails)
        const quetions = [...this.state.q_and_a]
        let questions;
        if (quetions?.length > 0) {
            questions = quetions?.map((que, i) => {
                return <React.Fragment key={i}>
                    <div className="card popup-card-wrapper">
                        <div className="card-header p-0" id={`headingQA${i}`}>
                            <h3 className="mb-0 d-flex justify-content-between">
                                <a className="btn btn-link" type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapseQAOne${i}`}
                                    aria-expanded="false"
                                    aria-controls={`collapseQAOne${i}`}>
                                    {que?.question}
                                    <div className="d-flex QA-meta-text">
                                        <div className="time">{que?.question}</div>
                                        <div className="name">{que?.user_name}
                                        </div>
                                    </div>
                                </a>
                            </h3>
                            <QandAChat index={i} attributes={{
                                id: `collapseQAOne${i}`,
                                class: "collapse",
                                'aria-labelledby': `#headingQA${i}`,
                                'data-parent': `#${this.props.id}`
                            }}
                                chatMessages={que?.answers}
                            >
                                <div className="input-group mb-2">

                                    <input value={que.answer ? que.answer : ''}
                                        onChange={(e) => this.onTextChangeInput(e, i)}
                                        type="text"
                                        className="form-control fix-rounded-right"
                                        placeholder="Type bericht"
                                    // onKeyPress={(e) => this.onSendMessageEnter(e, que.id, i)}
                                    />
                                </div>
                                <div
                                    className="input-group-append">
                                    <span className="input-group-text bg-white">
                                        <a href="#">
                                            <i onClick={(e) => this.onSendMessageEnter(e, que.id, i)}
                                                className="fa fa-paper-plane-o" aria-hidden="true">
                                            </i>
                                        </a>
                                    </span>
                                </div>

                            </QandAChat >
                        </div>
                    </div>
                </React.Fragment>

            })
        }

        return (
            <div className="card-body px-1">

                <div className="accordion event-info-accordion QA-accordion"
                    id={this.props.id}>
                    {questions}

                </div>
            </div>
        )
    }
}

export default QandAccordian;





