"use strict";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {formReducer, FORM_INPUT_UPDATE, INTERESET_CHECKBOX_UPDATE} from "../../hooks/TextInputReducer";
import "react-calendar/dist/Calendar.css";
import telnet from "../../assets/images/telenet_owler.png";
import logo from "../../assets/images/powered.png";
import fileUpload from "../../assets/images/file-upload.png";
import { Link, useHistory } from "react-router-dom";
import TextComponent from "../../UIComponets/TextComponent";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as DefaultSectionContext } from "../../context/DefaultSectionContext";
import { CustomButton } from "../../UIComponets/CustomButton";
import RadioButton from "../../UIComponets/radioButton/RadioButton";
import { UseToast } from "../../hooks/toastHook";
import { useToasts } from "react-toast-notifications";
import useStyle from "../../hooks/cssLoad";
import Location from "../../hooks/Location";
import Loader from "../../UIComponets/Loader";
import ErrorPage from "../../Route/ErrorPage";
import LoaderDefaultSection from "../../UIComponets/LoaderDefaultSection";
import toast from "react-hot-toast";
import {
  imageBaseUrl,
  imageWithStorage,
} from "../../constants/url/urlConstants";
import { changeColor } from "../../utlis/changeColor";
import Calendar from "react-calendar";
import DatePicker from "react-date-picker";
import moment from "moment";
import placeholderBody from "../../assets/images/bg_body.jpg";
import placeholderPattern from "../../assets/images/body.png";
import useBodyClass from "../../hooks/BodyClass";
import DropDown from "../../UIComponets/DropDown/DropDown";
import { Context as languageContext } from "../../context/LanguageContext";
import HtmlParser from "react-html-parser";
import { ShowEventFee } from "../Payment/ShowEventFee";
import { ShowPaymentMethods } from "../Payment/ShowPaymentMethods";
import { PaymentSuccess } from "../Payment/PaymentSuccess";
import { PaymentState } from "../Payment/PaymentState";
import { PaymentStatus } from "../Payment/PaymentStatus";
import { PaymentFailed } from "../Payment/PaymentFailed";
import { PaymentCanceled } from "../Payment/PaymentCanceled";
import { PaymentExpired } from "../Payment/PaymentExpired";
import { PaymentOpen } from "../Payment/PaymentOpen";
import {OptionConstants} from "../../constants/Constants";
import Support from "../../Default/components/defaultLayout/body/DesktopView/Support/Support";
import { Base64 } from 'js-base64';


// import { useCookies } from 'react-cookie';

const passwordRequired = {
  name: "",
  last_name: "",
  email: "",
  password: "",
};
const passwordRequiredValid = {
  name: false,
  last_name: false,
  email: false,
  password: false,
};
const Register = ({ isLoggedIn, showLoginForm }) => {
  useStyle("/script/default/defaultStyle.css");
  const [clientName, eventName] = Location();
  const history = useHistory();
  const { addToast } = useToasts();
  const url = `api/${clientName}/${eventName}`;

  const isRegisterPage = history.location.pathname
      .split("/")
      .includes("register");
  // console.log('isRegisterPage', isRegisterPage)
  //*******************  React States And Context  *******************
  const {
    onRegister,
    defaultAuthenticationWhileRegistration,
    state: {
      registrationPageData: {
        event,
        client,
        status,
        page_title,
        fields,
        languages,
        translations,
        welcome_widget,
        dbvideo,
        payment,
        options,
        networking_tool
      },
    },
  } = useContext(AuthContext);
  const { onUserLoggedIn, onRegisterSuccess } = useContext(
      DefaultSectionContext
  );
  const {
    changeLangauge,
    state: { langauge },
  } = useContext(languageContext);


  const [currentLanguage, setCurrentLanguage] = useState("");
  const [paymentState, setPaymentState] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentSuccessSeen, setPaymentSuccessSeen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    setPaymentState(payment?.state);
    setPaymentStatus(payment?.status);
    setPaymentSuccessSeen(payment?.seen);
  }, [payment]);
  const [gender, setGender] = useState("male");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [profilePic, setProfilePic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userLogging, setuserLogging] = useState(false);
  const [fileBase64String, setFileBase64String] = useState("");
  useBodyClass("default-active");
  const [isWelcomeWidget, setIsWelcomeWidget] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [calVal, setCalValue] = useState(new Date());

  // const [cookies, setCookie] = useCookies(['truid']);

  useEffect(() => {
    setCurrentLanguage(langauge);
  }, [langauge]);

  useEffect(() => {
    changeColor(
        dbvideo?.input_placeholder_text_color,
        `::placeholder`,
        `{color:${dbvideo?.input_placeholder_text_color}!important;}`
    );
    changeColor(
        dbvideo?.input_placeholder_text_color,
        `::-ms-input-placeholder`,
        `{color:${dbvideo?.input_placeholder_text_color}!important;}`
    );
    changeColor(
        dbvideo?.input_placeholder_text_color,
        `::-ms-input-placeholder`,
        `{color:${dbvideo?.input_placeholder_text_color}!important;}`
    );
    changeColor(
        dbvideo?.input_text_text_color,
        `input`,
        `{color:${dbvideo?.input_text_text_color}!important;}`
    );
    changeColor(
        dbvideo?.dropdown_text_color,
        `select`,
        `{color:${dbvideo?.input_text_text_color}!important;}`
    );
    changeColor(
        dbvideo?.label_text_color,
        `label`,
        `{color:${dbvideo?.label_text_color}!important;}`
    );
    changeColor(
        dbvideo?.link_text_color,
        `a[target]`,
        `{color:${dbvideo?.input_text_text_color}!important;}`
    );
    changeColor(
        event?.color,
        `::-webkit-scrollbar-thumb`,
        `{background:${event?.color}!important;}`
    );
    changeColor(
      dbvideo?.riziv_label_color,
      `.riziv_fields_decription`,
      `{color:${dbvideo?.riziv_label_color}!important;}`
  );
  }, [dbvideo]);
  
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: !event?.password_only
        ? { ...passwordRequired }
        : {
          name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
        },

    inputValidities: !event?.password_only
        ? { ...passwordRequiredValid }
        : {
          name: false,
          last_name: false,
          email: false,
          password: false,
          password_confirmation: false,
        },
    formIsValid: false,

    isPasswordMatched: false,
  });
  const currentPageTranslation = translations?.registration;
  const namePlaceholder = currentPageTranslation?.name_placeholder;
  const lastnamePlaceholder = currentPageTranslation?.lastname_placeholder;
  const emailPlaceholder = currentPageTranslation?.email_placeholder;
  const passwordPlaceholder = currentPageTranslation?.password_placeholder;
  const confirmPasswordPlaceholder = currentPageTranslation?.confirm_password_placeholder;
  const errorText = currentPageTranslation?.required_error_text;
  const uniqueCodePlaceholder = currentPageTranslation?.unique_code_placeholder;

  const facebookPlaceholder = currentPageTranslation?.facebook_placeholder;
  const linkedInPlaceholder = currentPageTranslation?.linkedIn_placeholder;
  const twitterPlaceholder = currentPageTranslation?.twitter_placeholder;
  const companyPlaceholder = currentPageTranslation?.company_placeholder;
  const functionPlaceholder = currentPageTranslation?.function_placeholder;

  const nameErrorText = namePlaceholder + " " + errorText;
  const lastnameErrorText = lastnamePlaceholder + " " + errorText;
  const emailErrorText = emailPlaceholder + " " + errorText;
  const passwordErrorText = passwordPlaceholder + " " + errorText;
  const confirmPasswordErrorText = confirmPasswordPlaceholder + " " + errorText;
  const [isAvatarSelectionEnable, setIsAvatarSelectionEnable] = useState(false);
  //*******************  React Life Cycles Hooks *******************

  useEffect(() => {
    setIsAvatarSelectionEnable(options?.includes("registration_avatar"));
  }, [options]);
  useEffect(() => {
    const bodyClass = document.querySelector(".default-active");
    let url;
    if (bodyClass) {
      if (event?.regi_background_type === "image") {
        if (event?.regi_background) {
          url = `url('${
              imageWithStorage + event?.regi_background
          }') center center no-repeat`;
        } else {
          url = `url('${placeholderBody}') center center no-repeat`;
        }
        bodyClass.style.background = url;
      } else if (event?.regi_background_type === "pattern") {
        if (event?.regi_background) {
          url = `background : url('${
              imageBaseUrl + event?.regi_background
          }' ) repeat; background-size:auto!important;`;
        } else {
          url = `background : url('${placeholderPattern}' ) repeat; background-size:auto!important;`;

          // bodyClass.style.backgroundSize = 'auto!important'
        }
        bodyClass.style.cssText = url;
      } else if (event?.regi_background_type === "color") {
        bodyClass.style.background = event?.regi_background;
      }
    }
    const colorCode = event?.color;
    changeColor(
        "transparent",
        `.login-fixed-wrapper::before`,
        `{background-color: transparent !important;}`
    );
  }, [event?.regi_background, event?.regi_background_type]);

  useEffect(() => {
    setGender("male");
    // document.title = 'DB Video Registration'
  }, []);

  const [globalLan, setGlobalLan] = useState(null);

  const backToRegister = (e) => {
    e.preventDefault();
    localStorage.removeItem("defaultLogin");
  };

  const onGlobalLangChange = (lan) => {
    changeLangauge(lan);
    setGlobalLan(lan);
  };
  useEffect(() => {
    setIsLoading(true);
    const onRegister = async () => {
      const url = `api/${clientName}/${eventName}`;
      try {
        await defaultAuthenticationWhileRegistration(url, (response) => {
          // localStorage.setItem("defaultLogin", response);
          // alert("RESSSS")

          if (
              (response.data?.payment?.state === PaymentState.PaymentSucessfull &&
                  response?.data?.payment?.seen === 1) ||
              !response.data?.payment
          ) {
            const token = localStorage.getItem("defaultLogin");
            if (token) {
              history.replace(`/${clientName}/${eventName}`);
            }
          }
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    onRegister();
  }, [globalLan]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      if (event?.favicon) {
        favicon.href = imageWithStorage + event?.favicon;
      } else if (event?.logo) {
        favicon.href = imageWithStorage + event?.logo;
      }
    }
  }, [event?.logo]);
  // ******************* *  Function Declaration  *******************

  const inputChangeHandler = useCallback(
      (inputIdentifier, inputValue, inputValidity) => {
        dispatchFormState({
          type: FORM_INPUT_UPDATE,
          value: inputValue,
          isValid: inputValidity,
          input: inputIdentifier,
        });
      },
      [dispatchFormState]
  );

  const [selectedInterest, setSelectedInterest] = useState({});

  const interestCheckBoxHandler = useCallback(
      (inputIdentifier, inputValue) => {
        setSelectedInterest( pre => {
          return {
            ...pre,
            [inputIdentifier]: inputValue
          }
        })
      },
      []
  );

  useEffect(() => {
    inputChangeHandler();
  }, []);
  const toShowPassword = useCallback(
      (show, type) => {
        if (type === "password") setShowPassword(show);
        else if (type === "confirmPassword") setShowConfirmPassword(show);
      },
      [setShowPassword, setShowConfirmPassword]
  );
  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var Base64 = reader.result;
        // console.log(Base64);
        setFileBase64String(Base64);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  const onRegisterUser = async (e) => {
    e.preventDefault();
    let _inputStates = { ...formState.inputValues };
    delete _inputStates.name;
    delete _inputStates.last_name;
    delete _inputStates.email;
    delete _inputStates.password;
    delete _inputStates.password_confirmation;
  // alert(JSON.stringify(selectedInterest))

    let interest = [];
    if ( selectedInterest) {
      interest = Object.keys(selectedInterest).filter(k => selectedInterest[k] === true )
    }

    if (fields?.length > 0) {
      const isValid = fields?.filter(field => field?.type == 'riziv' && field?.is_required).reduce((prev, next) => {
        let riziv_encode = (_inputStates[next?.field].replace(/\D+/g, ""));
        const isValidRiz =  riziv_encode.length === 11
        if (isValidRiz){
          _inputStates[next?.field] = Base64.encode(riziv_encode);
        }
        return prev && isValidRiz
      }, true)
      if (!isValid){
        addToast("Must Enter 11 Digits!", {
          appearance: "error",
          autoDismiss: true,
        });
        return 
      }
    }

    // if(_inputStates){
    //   Object.keys(_inputStates).map((el, key) => {
    //       if(el === "riziv-en"){
    //         _inputStates[el] =  _inputStates[el].replace(/\D+/g, "");
    //       }
    //   });
    // }

    const data = {
      name: formState.inputValues.name,
      last_name: formState.inputValues.last_name,
      email: formState.inputValues.email,
      password: formState.inputValues.password,
      // password_confirmation: formState.inputValues.password_confirmation,
      password_confirmation: formState.inputValues.password,
      extra: { ..._inputStates, newsletter },
      avatar: fileBase64String,
      // avatar: profilePic,
      // newsletter: newsletter,
      interests: interest
    };

    if (currentLanguage) {
      data["locale"] = currentLanguage;
    }

    if (
        event?.password_required &&
        formState.inputValues.password !==
        formState.inputValues.password_confirmation
    ) {
      addToast("Password Did not Matched", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    for (const [field_slug, field_original] of Object.entries(fieldsRequired)) {
      if (data?.extra) {
        if (!data?.extra[field_slug]) {
          addToast(`${field_original} is required`, {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
      }
    }

    if (formState.inputValues?.name === "") {
      addToast("Please Enter Name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (formState.inputValues?.last_name === "") {
      addToast("Please Enter Last Name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (formState.inputValues?.email === "") {
      addToast("Enter Email Address", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (event?.password_only && formState.inputValues.password === "") {
      addToast("Enter Password", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (event?.marketing_checkbox && !newsletter) {
      addToast("Plese Select Checkbox", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }


    try {
      setuserLogging(true);
      await onRegister(data, url, (response) => {
        if (response?.status) {
          changeLangauge(currentLanguage);
          // setCookie('truid', response.user.id, { path: '/' });

          // isLoggedIn(false);
          if (localStorage.getItem("defaultLogin")) {
            localStorage.removeItem("defaultLogin");
            localStorage.setItem(
                "defaultLogin",
                JSON.stringify({ access_token: response?.access_token })
            );
          } else {
            localStorage.setItem(
                "defaultLogin",
                JSON.stringify({ access_token: response?.access_token })
            );
          }

          if (response.user?.payment?.state === PaymentState.IsRegistered) {
            setUserDetails({ ...response });
            setPaymentState(PaymentState.IsRegistered);
            setuserLogging(false);
            return;
          } else {
            addToast(response?.message, {
              appearance: "success",
              autoDismiss: true,
            });

            // history.push(`/${clientName}/${eventName}`);
            onUserLoggedIn(response.user);
            onRegisterSuccess();
            setuserLogging(false);
            // history.push(`/${clientName}/${eventName}`);
            window.location = `/${clientName}/${eventName}`;
          }

          // onUserLoggedIn(response.user);
          if (!isRegisterPage) {
            // isLoggedIn(false);
          }
        } else {
          addToast(response?.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setuserLogging(false);
          // isLoggedIn(true);
          if (!isRegisterPage) {
            // isLoggedIn(true);
          }
        }
      });
    } catch (error) {
      setuserLogging(false);
      addToast("Error occurred While Registering!.", {
        appearance: "error",
        autoDismiss: true,
        TransitionState: "exiting",
      });
      setuserLogging(false);
    }
  };

  const setAvatar = (e) => {
    encodeFileBase64(e.target.files[0]);
    setProfilePic(URL.createObjectURL(e.target.files[0]));
  };
  const onRegisterUserViaURL = async (e) => {
    e.preventDefault();
    let _inputStates = { ...formState.inputValues };
    delete _inputStates.name;
    delete _inputStates.last_name;
    delete _inputStates.email;
    delete _inputStates.password;
    delete _inputStates.password_confirmation;

    const data = {
      name: formState.inputValues.name,
      last_name: formState.inputValues.last_name,
      email: formState.inputValues.email,
      password: formState.inputValues.password,
      // password_confirmation: formState.inputValues.password_confirmation,
      password_confirmation: formState.inputValues.password,
      extra: { ..._inputStates },
      avatar: fileBase64String,
      // avatar: profilePic,
      newsletter: newsletter,
    };

    if (
        event?.password_required &&
        formState.inputValues.password !==
        formState.inputValues.password_confirmation
    ) {
      addToast("Password Did not Matched", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (formState.inputValues.password?.first_name === "") {
      addToast("Please Enter Name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (formState.inputValues.password?.last_name === "") {
      addToast("Please Enter Last Name", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    } else if (formState.inputValues.password?.email === "") {
      addToast("Enter Email Address", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    try {
      await onRegister(data, url, (response) => {
        if (response?.status) {
          onRegisterSuccess();
          localStorage.setItem(
              "defaultLogin",
              JSON.stringify(response?.access_token)
          );
          // history.push(`/${clientName}/${eventName}`);
          onUserLoggedIn(response.user);
          // setCookie('truid', response.user.id, { path: '/' });

          // isLoggedIn(false);
          history.push(`/${clientName}/${eventName}`);
          // onUserLoggedIn(response.user)
          addToast(response?.message, {
            appearance: "success",
            autoDismiss: true,
          });
          if (!isRegisterPage) {
            // isLoggedIn(true);
          }
        } else {
          addToast(response?.message, {
            appearance: "error",
            autoDismiss: true,
          });

          if (!isRegisterPage) {
            // isLoggedIn(false);
          }
        }
      });
    } catch (error) {
      addToast("Error occurred While Registering!.", {
        appearance: "error",
        autoDismiss: true,
        TransitionState: "exiting",
      });
    }
  };
  useEffect(() => {
    if (welcome_widget?.content) {
      setIsWelcomeWidget(true);
      setIsPopUp(welcome_widget?.popup);
    }
  }, [welcome_widget]);
  useEffect(() => {
    if (isPopUp) {
      setTimeout(() => {
        window.$("#splashRegistrationeModal").modal("show");
      }, 1000);
    }
  }, [isPopUp]);
  useEffect(() => {
    changeColor(event?.color);
    changeColor(
        event?.color,
        `.db-icheck
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type="hidden"]
  + label::before`,
        "border-color"
    );
    changeColor(
        event?.color,
        `.db-icheck
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before`,
        "border-color"
    );
    changeColor(
        event?.color,
        `.db-icheck > input:first-child:checked + input[type="hidden"] + label::after
        `,
        "border-color"
    );
    changeColor(
        event?.color,
        `.checkcontainer input:checked ~ .radiobtn:after`,
        `{background:${event?.color}!important;color:${event?.color}!important}`
    );
    changeColor(
        event?.color,
        `.checkcontainer .radiobtn:after`,
        `{background:${event?.color}!important;}`
    );
    changeColor(
        event?.color,
        `.checkcontainer input:checked ~ .radiobtn`,
        `{border:2px solid ${event?.color}!important;}`
    );
    changeColor(
        event?.color,
        `.db-icheck > input:first-child + input[type="hidden"] + label::before, .db-icheck > input:first-child + label::before`,
        `{border:2px solid ${event?.color}!important;}`
    );
    changeColor(
        event?.color,
        `.db-icheck > input:first-child:checked + input[type="hidden"] + label::after, .db-icheck > input:first-child:checked + label::after`,
        `{border:2px solid ${event?.color}!important;border-left:0!important;border-top:0!important}`
    );

    changeColor(
        event?.color,
        `.cmn-hover-btn:hover`,
        `{background:${event?.color}!important;color:#fff!important;}`
    );
    changeColor(
        event?.color,
        `.vw-btn-white:hover:hover`,
        `{background:${event?.color}!important;color:#fff!important;}`
    );
    changeColor(
        event?.color,
        ".vw-form-wrap .input-group-append .input-group-text a",
        `{color:${event?.color}!important;}`
    );
  }, [isLoading]);

  const onDateUpdate = useCallback((id, inputValue, inputValidity) => {
    const _date = moment(inputValue).format("Y-MM-DD");
    inputChangeHandler(id, inputValue, inputValidity);
  }, []);

  const onCheckBoxChecked = useCallback((id, inputValue, inputValidity) => {
    inputChangeHandler(id, inputValue, inputValidity);
  }, []);

  let InputFields;
  let inputKeys = [];
  let fieldsRequired = {};

  const onDropDownChage = (value) => {
    formState.inputValues[value.key] = value.value;
  };

  if (fields?.length > 0) {
    InputFields = fields?.map((inputType, index) => {
      if (inputType.is_required) {
        fieldsRequired[inputType.field] = inputType.original;
      }
      if (
          inputType?.type === "date" &&
          !formState.inputValues[inputType.original]
      ) {
        formState.inputValues[inputType?.original] = new Date();
      }
      if (
          inputType?.type === "checkbox" &&
          !formState.inputValues[inputType.original]
      ) {
        formState.inputValues[inputType?.original] = false;
      }
      if (!inputKeys.includes(inputType?.field)) {
        inputKeys.push(inputType?.field);
      }
      return (
          <>
            {inputType?.type === "text" ? (
                <TextComponent
                    key={index}
                    id={inputType?.field}
                    registerFormContainerStyle="form-group mb-3"
                    inputStyle="form-control"
                    showError={inputType.is_required === 0 ? false : true}
                    registerForm
                    inputType={inputType?.type}
                    errorText={inputType?.original + " Required ."}
                    onInputChange={inputChangeHandler}
                    placeholder={`${inputType?.original} ${
                        inputType?.is_required ? "*" : ""
                    }`}
                    errorTextStyle={"textDanger"}
                    hotReload={true}
                    name={inputType?.field}
                />
            ) : null}

            {inputType?.type === "radio" ? (
                <div className="db-radio-check form-row">
                  {inputType?.values.split(";").map((option, i) => {
                    return (
                        <TextComponent
                            key={i}
                            labelClassName="checkcontainer"
                            radioButton
                            id={inputType?.original}
                            title={option}
                            style={{ color: event?.color }}
                            spanClassName="radiobtn"
                            name={inputType?.original}
                            value={option}
                            onInputChange={inputChangeHandler}
                        />
                    );
                  })}
                </div>
            ) : null}
            {inputType?.type === "checkbox" && (
                <div className="form-group my-4">
                  <div className="db-icheck d-inline mb-2 form-register-checkbox">
                    <input
                        type="checkbox"
                        id={inputType?.original}
                        onClick={(e) =>
                            onCheckBoxChecked(
                                inputType?.original,
                                e.target.checked,
                                true
                            )
                        }
                    />
                    <label htmlFor={inputType?.original}>
                      {inputType?.original}
                    </label>
                  </div>
                </div>
            )}
            {inputType?.type === "date" && (
                <div className="date-lable-format">
                  <label className="Reg-Date">{inputType?.original}</label>
                  <DatePicker
                      onChange={(value) => {
                        const _value = moment(value).format("Y-M-D");
                        // alert(date);
                        onDateUpdate(String(inputType?.original), value, true);
                      }}
                      value={formState.inputValues[inputType.original]}
                      className="form-control form-group mb-3 "
                  />
                </div>
            )}
            {inputType?.type === "dropdown" && (
                <div className="form-group my-4">
                  <div className="db-icheck d-inline mb-2">
                    <select
                        onChange={(e) =>
                            onDropDownChage({
                              key: inputType?.field,
                              value: e.target.value,
                            })
                        }
                        className="form-control"
                        id={inputType?.original}
                    >
                      <option value="">{inputType?.original}</option>
                      {inputType?.values.split(";").map((option, i) => {
                        return (
                            <option
                                key={i}
                                {...(i === 0 ? "selected" : "")}
                                value={option}
                            >
                              {option}
                            </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
            )}

            {inputType?.type === "riziv" ? (
              <div className="riziv_fields">
                <TextComponent
                    key={index}
                    id={inputType?.field}
                    registerFormContainerStyle="form-group mb-3"
                    inputStyle="form-control"
                    showError={inputType.is_required === 0 ? false : true}
                    riziv
                    phone
                    inputType={inputType?.type}
                    errorText={inputType?.original + " Required Only Numbers."}
                    onInputChange={inputChangeHandler}
                    onKeyUp={inputChangeHandler}
                    onkeypress={inputChangeHandler}
                    placeholder={`${inputType?.original} ${inputType?.is_required ? "* 00.00.00-000.00" : ""}`}
                    errorTextStyle={"textDanger"}
                    hotReload={true}
                    name={inputType?.field}
                />
                <p className="riziv_fields_decription">{dbvideo?.riziv_decription}</p>
              </div>
            ) : null}

          </>
      );
    });
  }
  if (isLoading) return <LoaderDefaultSection />;

  let _constIsValid = Object.fromEntries(
      Object.entries(formState.inputValues).filter(([_, second]) => second === "")
  );
  const _isRequired = fields?.filter((required) => required.is_required === 0);
  const constIsValid = Object.keys(_constIsValid).length;

  const date = moment(new Date()).format("Y-MM-DD");
  const welcome_widget_content = HtmlParser(welcome_widget?.content);
  let MarketingCheckbox;
  if (event?.marketing_checkbox) {
    MarketingCheckbox = (
        <>
          {event?.marketing_text ? (
              <div className="form-group mt-3">
                <div className="db-icheck d-inline">
                  <input
                      type="checkbox"
                      id="marketing"
                      onClick={(e) => setNewsletter(e.target.checked)}
                      value={newsletter}
                  />
                  <label htmlFor="marketing">{event?.marketing_text}</label>
                  {/* <label htmlFor="marketing">
            News Letter Keep reading to learn the best practices for obtaining
            valid agreement to your
          </label> */}
                </div>
              </div>
          ) : (
              ""
          )}
          {event?.marketing_file && event.marketing_file_title ? (
              <Link
                  target="_blank"
                  to={{
                    pathname:
                        `${process.env.REACT_APP_IMAGE_WITH_STORAGE}/` +
                        event?.marketing_file,
                  }}
                  className="privacy-policy-link"
              >
                {event?.marketing_file_title}
              </Link>
          ) : (
              ""
          )}
        </>
    );
  }

  let RegisterForm;
  if (event?.password_required) {
    RegisterForm = (
        <>
          <TextComponent
              id="name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              showError
              registerForm
              inputType="text"
              errorText={nameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={namePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          <TextComponent
              id="last_name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              showError
              registerForm
              inputType="text"
              errorText={lastnameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={lastnamePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          <TextComponent
              id="email"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              email
              showError
              registerForm
              inputType="email"
              errorText={emailErrorText}
              onInputChange={inputChangeHandler}
              placeholder={emailPlaceholder}
              hotReload={true}
          />
          {/* {InputFields} */}
          {InputFields}
          <TextComponent
              id="password"
              registerFormContainerStyle="input-group mb-3"
              inputStyle="form-control"
              required
              password
              showError
              registerForm
              fafa
              registerFormStyle="input-group-prepend"
              spanclassName="input-group-text cmn-bg-color"
              inputType={showPassword ? "text" : "password"}
              spanId="inputPassword"
              faIcon="fa fa-lock"
              areaHidden={"true"}
              ariaLabel="password"
              ariaDescribedby="inputPassword"
              faEyeContainer="input-group-append"
              faEyeSpanClassName="input-group-text cmn-text-color"
              faEyeAriaHidden={"true"}
              faEyeIcon={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              passwordType="password"
              // onEyeClick={ }
              toShowPassword={toShowPassword}
              onInputChange={inputChangeHandler}
              placeholder={passwordPlaceholder}
              hotReload={true}
          />
          <TextComponent
              id="password_confirmation"
              registerFormContainerStyle="input-group mb-3"
              inputStyle="form-control"
              required
              showError
              password
              passwordValue={formState?.inputValues.password}
              // passwordErrorText={formState.isPasswordMatched ? 'Password Does not match!' : ''}
              registerForm
              fafa
              registerFormStyle="input-group-prepend"
              spanclassName="input-group-text cmn-bg-color"
              inputType={showConfirmPassword ? "text" : "password"}
              spanId="confirmPassword"
              faIcon="fa fa-lock"
              areaHidden={"true"}
              ariaLabel="confirm-password"
              ariaDescribedby="confirmPassword"
              faEyeContainer="input-group-append"
              faEyeSpanClassName="input-group-text cmn-text-color"
              faEyeAriaHidden={"true"}
              faEyeIcon={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              toShowPassword={toShowPassword}
              passwordType="confirmPassword"
              onInputChange={inputChangeHandler}
              placeholder={confirmPasswordPlaceholder}
              hotReload={true}
          />
        </>
    );
  } else if (event?.password_only) {
    RegisterForm = (
        <>
          <TextComponent
              id="name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              // initialValue={'harshal'}
              required
              showError
              registerForm
              inputType="text"
              errorText={nameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={namePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          <TextComponent
              id="last_name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              showError
              registerForm
              inputType="text"
              errorText={lastnameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={lastnamePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          {/* <TextComponent
          id="last_name"
          registerFormContainerStyle="form-group mb-3"
          inputStyle="form-control"
          required
          showError
          registerForm
          inputType="text"
          errorText="Last Name Required"
          onInputChange={inputChangeHandler}
          placeholder="Last Name"
        /> */}
          <TextComponent
              id="email"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              email
              showError
              registerForm
              inputType="email"
              errorText={emailErrorText}
              onInputChange={inputChangeHandler}
              placeholder={emailPlaceholder}
              hotReload={true}
          />
          {InputFields}
          <TextComponent
              id="password"
              registerFormContainerStyle="input-group mb-3"
              inputStyle="form-control"
              required
              password
              showError
              registerForm
              fafa
              registerFormStyle="input-group-prepend"
              spanclassName="input-group-text cmn-bg-color"
              inputType={showPassword ? "text" : "password"}
              spanId="inputPassword"
              faIcon="fa fa-lock"
              areaHidden={"true"}
              ariaLabel="password"
              ariaDescribedby="inputPassword"
              faEyeContainer="input-group-append"
              faEyeSpanClassName="input-group-text cmn-text-color"
              faEyeAriaHidden={"true"}
              faEyeIcon={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
              passwordType="password"
              // onEyeClick={ }
              toShowPassword={toShowPassword}
              onInputChange={inputChangeHandler}
              // placeholder="Unique Code*"
              placeholder={
                event?.unique_code ? uniqueCodePlaceholder : passwordPlaceholder
              }
              hotReload={true}
          />
        </>
    );
  } else {
    RegisterForm = (
        <>
          <TextComponent
              id="name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              // initialValue={'harshal'}
              required
              showError
              registerForm
              inputType="text"
              errorText={nameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={namePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          <TextComponent
              id="last_name"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              // initialValue={'harshal'}
              required
              showError
              registerForm
              inputType="text"
              errorText={lastnameErrorText}
              onInputChange={inputChangeHandler}
              placeholder={lastnamePlaceholder}
              errorTextStyle={"textDanger"}
              hotReload={true}
          />
          {/* <TextComponent
          id="last_name"
          registerFormContainerStyle="form-group mb-3"
          inputStyle="form-control"
          required
          showError
          registerForm
          inputType="text"
          errorText="Last Name Required"
          onInputChange={inputChangeHandler}
          placeholder="Last Name"
        /> */}
          <TextComponent
              id="email"
              registerFormContainerStyle="form-group mb-3"
              inputStyle="form-control"
              required
              email
              showError
              registerForm
              inputType="email"
              errorText={emailErrorText}
              onInputChange={inputChangeHandler}
              placeholder={emailPlaceholder}
              hotReload={true}
          />
          {/* {InputFields} */}
          {InputFields}
        </>
    );
  }

  const LanguageMenu =
    languages && Object.keys(languages).length > 1 > 0 ? (
      <div className="db-language-wrap ml-auto">
        <div className="form-goup">
          <DropDown
            classAdd={"custom-select"}
            items={languages}
            selected={langauge}
            onChange={(data) => onGlobalLangChange(data)}
          />
        </div>
      </div>
    ) : (
      ""
    );

    let SupportComp = (
      <React.Fragment key="Support">
        {options?.includes("support") && (
          <Support script={event?.support} />
        )}
      </React.Fragment>
    );

  return (
    <section className="login-fixed-wrapper">
      <div className="container">
        <div className="login-logo-top ">
          {/* <img src={logo} alt="" className="img-fluid" /> */}
        </div>
        {LanguageMenu}
        <div className="log-reg-inner-box">
          <div
            className={`register-form-wrap vw-form-wrap ${
              !isWelcomeWidget ? "vh-full-height" : ""
            }`}
          >
            <form
              action=""
              className="register-form"
              onSubmit={(e) => onRegisterUser(e)}
            >
              {!payment || paymentState === PaymentState.IsPayamentRequired ? (
                <div className="form-logo-wrap">
                  <div className="logo-img-frame">
                    <img
                      src={imageBaseUrl + event?.logo}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="logo-brand-name ">
                    <h2>{event?.title}</h2>
                    <span
                      className="cmn-bg-color"
                      style={{ backgroundColor: event?.color }}
                    >
                      Event
                    </span>
                  </div>
                </div>
              ) : null}
              {isWelcomeWidget && !isPopUp && (
                <div className="wb-splash-reg-wrapper">
                  <div className="wb-splash-inner">
                    {welcome_widget_content}
                  </div>
                </div>
              )}
              {(!paymentState ||
                paymentState === PaymentState.IsPayamentRequired) && (
                <div className="login--inner--wrap login-box register-box">
                  <div className="row align-items-center justify-content-center">
                    <div className={`col-12 col-md-4`}>
                      {isAvatarSelectionEnable && (
                          <div className="text-center">
                            <label
                                htmlFor="photo-upload"
                                className="custom-file-upload fas "
                                style={{ backgroundColor: event?.color }}
                            >
                              <div className="img-wrap img-upload">
                                <img
                                    htmlFor="photo-upload"
                                    className="img-fluid"
                                    src={profilePic ? profilePic : fileUpload}
                                />
                              </div>
                              <input
                                  onChange={(e) => setAvatar(e)}
                                  id="photo-upload"
                                  type="file"
                              />
                            </label>
                          </div>
                      )}
                      {RegisterForm}

                      {languages && Object.keys(languages).length > 1 && (
                          <DropDown
                              selected={langauge}
                              classAdd={"form-control"}
                              items={languages}
                              onChange={(data) => {
                                setCurrentLanguage(data);
                              }}
                          />
                      )}
                      {MarketingCheckbox}
                      {/* {event?.security === 'registration' ? DefaultRegistration : InputFields} */}
                    </div>

                     {options?.includes(OptionConstants.NETWORKINGTOOL) && <div className={`col-12 col-md-4`}>

                       <TextComponent
                          id="facebook_link"
                          registerFormContainerStyle="form-group mb-3"
                          inputStyle="form-control"
                          required
                          showError
                          registerForm
                          inputType="text"
                          // errorText={lastnameErrorText}
                          onInputChange={inputChangeHandler}
                          placeholder={facebookPlaceholder}
                          hotReload={true}
                      />
                      <TextComponent
                          id="linkedin_link"
                          registerFormContainerStyle="form-group mb-3"
                          inputStyle="form-control"
                          required
                          showError
                          registerForm
                          inputType="text"
                          // errorText={lastnameErrorText}
                          onInputChange={inputChangeHandler}
                          placeholder={linkedInPlaceholder}
                          hotReload={true}
                      />
                      <TextComponent
                          id="twitter_link"
                          registerFormContainerStyle="form-group mb-3"
                          inputStyle="form-control"
                          required
                          showError
                          registerForm
                          inputType="text"
                          // errorText={lastnameErrorText}
                          onInputChange={inputChangeHandler}
                          placeholder={twitterPlaceholder}
                          hotReload={true}
                      />
                      <TextComponent
                          id="company"
                          registerFormContainerStyle="form-group mb-3"
                          inputStyle="form-control"
                          required
                          showError
                          registerForm
                          inputType="text"
                          // errorText={lastnameErrorText}
                          onInputChange={inputChangeHandler}
                          placeholder={companyPlaceholder}
                          hotReload={true}
                      />
                      <TextComponent
                          id="function"
                          registerFormContainerStyle="form-group mb-3"
                          inputStyle="form-control"
                          required
                          showError
                          registerForm
                          inputType="text"
                          // errorText={lastnameErrorText}
                          onInputChange={inputChangeHandler}
                          placeholder={functionPlaceholder}
                          hotReload={true}
                      />
                       <div className="form-group mt-3">
                       { networking_tool?.interests?.map((data) => {
                         return <div className="db-icheck d-inline mr-4 mt-2">
                           <input
                               type="checkbox"
                               id={"interest_" + data.id}
                               onClick={(e) => interestCheckBoxHandler(
                                   data.id,
                                   e.target.checked,
                                   true
                               )}
                               value={data.id}
                           />
                           <label htmlFor={"interest_" + data.id}>{data?.interest}</label>
                         </div>


                       })}
                       </div>

                     </div>}
                  </div>
                </div>
              )}
              {paymentState === PaymentState.IsPayamentRequired ? (
                <ShowEventFee />
              ) : null}
              {/* Show payment methods */}
              {paymentState === PaymentState.IsRegistered && !paymentStatus ? (
                <ShowPaymentMethods
                  backToRegister={backToRegister}
                  userDetails={userDetails}
                />
              ) : null}
              {/* Show payment failed */}
              {paymentState === PaymentState.IsRegistered &&
              paymentStatus == PaymentStatus.PaymentFailed ? (
                <PaymentFailed
                  backToRegister={backToRegister}
                  userDetails={userDetails}
                />
              ) : null}

              {/* Show payment canceled */}
              {paymentState === PaymentState.IsRegistered &&
              paymentStatus == PaymentStatus.PaymentCanceled ? (
                <>
                  <PaymentCanceled
                    backToRegister={backToRegister}
                    userDetails={userDetails}
                  />
                </>
              ) : null}

              {/* Show payment expired */}
              {paymentState === PaymentState.IsRegistered &&
              paymentStatus == PaymentStatus.PaymentExpired ? (
                <>
                  <PaymentExpired
                    backToRegister={backToRegister}
                    userDetails={userDetails}
                  />
                </>
              ) : null}

              {/* Show payment successful */}
              {paymentState === PaymentState.PaymentSucessfull &&
              !paymentSuccessSeen ? (
                <>
                  <PaymentSuccess
                    clientName={clientName}
                    eventName={eventName}
                  />
                </>
              ) : null}

              {/* Show payment pending */}
              {paymentState === PaymentState.IsRegistered &&
              paymentStatus == PaymentStatus.PaymentOpen ? (
                <>
                  <PaymentOpen clientName={clientName} eventName={eventName} />
                </>
              ) : null}

              {!payment || paymentState === PaymentState.IsPayamentRequired ? (
                <div className="login--inner--wrap login-box register-box">
                  <div className="row justify-content-center">
                    <div className="col-5">
                      <div className="d-flex justify-content-center my-3">
                        {userLogging ? (
                          <a
                            href="#"
                            className="btn vw-btn register-btn vw-btn-white mb-3 cmn-text-color w-50 registration-btn"
                          >
                            <i
                              aria-hidden="true"
                              // style={{ color: event?.color }}
                              className={
                                "fa fa-spinner fa-spin mr-3 text-black"
                              }
                            ></i>
                            <span className="text-black">Loading...</span>
                          </a>
                        ) : (
                          <>
                            <button
                              type="submit"
                              style={{ display: "none" }}
                            ></button>
                            <CustomButton
                              type="submit"
                              href="#"
                              style={{ color: event?.color }}
                              // onClick={
                              //   isRegisterPage ? onRegisterUserViaURL : onRegisterUser
                              // }
                              onClick={onRegisterUser}
                              // className={`btn vw-btn register-btn vw-btn-white mb-3 ${formState.formIsValid && newsletter && profilePic ? '' : 'disabled'}`}
                              // className={`btn vw-btn register-btn vw-btn-white mb-3 cmn-text-color ${
                              //   constIsValid === 0 ? "" : "disabled"
                              // } `}
                              className={`btn vw-btn register-btn vw-btn-white mb-3 w-50 cmn-text-color registration-btn`}
                              name={
                                translations?.registration?.watch_now_button
                              }
                              // disabled={!formState.formIsValid}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      {/* Pop UP*/}
      {/* {welcome_widget?.popup ? HtmlParser(welcome_widget?.content) : ''} */}

      {/* <!-- Button trigger modal --> */}
      {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#splashRegistrationeModal">
        Launch demo modal
      </button> */}

      {/* <!-- Modal --> */}
      {isWelcomeWidget && isPopUp ? (
        <div
          className="modal fade modal-wb-splash"
          id="splashRegistrationeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="splashRegistrationeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="wb-splash-reg-wrapper">
                  <div className="wb-splash-inner">
                    {welcome_widget_content}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {SupportComp}
    </section>
  );
};

export default Register;
