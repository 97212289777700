import React, {Component} from 'react';

import profilePlaceholder from '../../../../../assets/user/avatarplaceholder.png';

class MatchFound extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		console.log("Compontent mounted");
		this.props.listenForVCEvents();
	}


	render = () => {
		let body;

		return (
		<div className="container">
			<div className="dice-wrap dice-pairing-wrap">
				<div className="peers-wrapper">

					<div id="rollingDice" className="dice-btn-wrap">
						<h2 className="match-blue-text">Let's chat!</h2>
					</div>

					<div className="row align-items-center my-5 py-5">
						<div className="col-lg-3 order-lg-first col-md-6 order-md-first">
							<div className="player player1">
								<div className="player-imageframe text-center">
									<img src={this.props.user.avtar ? this.props.user.avtar : profilePlaceholder} className="img-fluid" alt="" />
								</div>
								<div className="player-name text-center">
									<h4 className="match-blue-text">{this.props.user.name + ' ' + this.props.user.last_name}</h4>
								</div>
							</div>
						</div>

						<div className="col-lg-6 order-lg-2 col-md-12 order-md-last">
							<div className="row align-items-center">
								<div className="col-12">
									<div className="player-name matching-intrest text-center">
										<h4 className="match-blue-text text-uppercase">Matching Interest {this.props.foi ? this.props.foi : ''}</h4>
									</div>
								</div>

								<div className="col-12">
									<div className="dice-btn-wrap py-5">
										<button type="button"
											className="btn btn-sm btn-flat btn-dice text-uppercase px-5 mb-2 mx-2" onClick={() => this.props.acceptMatch(this.props.inNotificationModal)}>
												Start 1 on 1
										</button>
										<button type="button"
											className="btn btn-sm btn-dice-decline btn-flat btn-dice text-uppercase px-5 mb-2 mx-2" onClick={this.props.declineMatch}>
											Decline Match
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-3 order-lg-last col-md-6 order-md-2">
							<div className="player player2">
								<div className="player-imageframe text-center">
									<img src={this.props.matched_user ? (this.props.matched_user.avatar ? this.props.matched_user.avatar : profilePlaceholder) : profilePlaceholder} className="img-fluid" alt="" />
								</div>
								<div className="player-name text-center">
									<h4 className="match-blue-text">{this.props.matched_user ? this.props.matched_user.name : ''}</h4>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
		)

	}
}


export default MatchFound;
