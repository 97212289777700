import React, { useEffect, useState } from "react";
import HeaderVideo from "./HeaderVideo";
import cssLoad from "../../hooks/cssLoad";
import { Link } from "react-router-dom";

const Header = ({ setLanguage, selectedLanguage }) => {
  const [video, setVideo] = useState(537279384);
  const [isVideoClose, setIsVideoClose] = useState(false);

  useEffect(() => {
    setVideo(537279384);
  }, []);
  cssLoad("/script/LandingPage/modal.css");
  const onModalOpen = () => {
    setIsVideoClose(false);
  };
  return (
    <section className="wb-main-header">
      <div className="wb-header-content">
        <div className="container-fluid">
          <div className=" row">
            <div className="col-12 col-md-7">
              <div className="wb-head">
                <div className="wb-top-lang">
                  <div className="wb-logo-container">
                    <div className="wb-logo-wrap">
                      <img
                        src="/script/LandingPage/images/main-logo.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="wb-language-wrap">
                    <div className="wb-website-lang">
                      <Link
                        to="?nl"
                        onClick={() => setLanguage("nl")}
                        className={`wb-language wb-nl-lang ${
                          selectedLanguage === "nl" ? "active" : ""
                        } `}
                      >
                        nl
                      </Link>
                      <Link
                        to="?en"
                        onClick={() => setLanguage("en")}
                        className={`wb-language wb-eng-lang ${
                          selectedLanguage === "en" ? "active" : ""
                        } `}
                      >
                        eng
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="wb-big-txt-wrap animate__animated animate__fadeInUp">
                  <h1 className="wb-big-head">Webble-Up</h1>
                  <h4 className="wb-sub-head">INTERACTIVE LIVE EVENTS</h4>
                </div>
                <div className="wb-desc-wrap">
                  {selectedLanguage === "nl" && (
                    <p>
                      Wil je een prikkelend online evenement organiseren? Soepel
                      schakelen van het podium naar de huiskamer? Razendsnel
                      connecteren over de landsgrenzen heen? Dat vraagt om een
                      straffe virtuele component, die je publiek mee op digitaal
                      sleeptouw neemt.
                    </p>
                  )}
                  {selectedLanguage === "en" && (
                    <p>
                      Do you want to organize an exciting online event? Want to
                      switch smoothly from the stage to the living room? Want to
                      connect at lightning speed across national borders? This
                      requires a strong virtual component that takes your
                      audience on a digital drag.
                    </p>
                  )}
                </div>
                <div className="wb-btn-wrap">
                  <div className="wb-btn-group">
                    <a href="#About_Us" className="btn animated-button wb-btn">
                      {selectedLanguage === "nl" ? "meer info" : "more info"}
                    </a>
                    <a
                      href="#Contact_Us"
                      className="btn animated-button wb-btn"
                    >
                      {selectedLanguage === "nl"
                        ? "contacteer ons"
                        : "contact us"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wb-vector-wrap">
        <div className="wb-grn-img-wrap">
          <img
            src="/script/LandingPage/images/head-bottom.png"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="wb-header-shap">
          <svg className="svg-clip">
            <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
              <path d="M0.899,0.002 H1 L1,1 H0.953 L0.879,0.998 C0.872,0.998,0.849,0.995,0.81,0.99 C0.772,0.985,0.751,0.98,0.745,0.978 L0.69,0.965 L0.621,0.944 L0.556,0.919 L0.482,0.884 L0.415,0.849 L0.335,0.801 L0.273,0.761 L0.224,0.727 C0.208,0.715,0.17,0.687,0.148,0.666 C0.126,0.645,0.107,0.626,0.101,0.619 C0.091,0.611,0.067,0.583,0.046,0.545 C0.019,0.497,0.03,0.512,0.019,0.491 C0.01,0.475,0.006,0.45,0.005,0.44 C0.008,0.452,-0.005,0.381,0.008,0.33 C0.025,0.265,0.019,0.293,0.027,0.269 C0.034,0.25,0.04,0.242,0.043,0.24 L0.065,0.208 C0.07,0.201,0.086,0.183,0.11,0.166 C0.14,0.144,0.145,0.143,0.167,0.133 C0.189,0.122,0.209,0.116,0.236,0.108 C0.263,0.1,0.276,0.098,0.297,0.093 C0.314,0.089,0.352,0.083,0.369,0.081 L0.452,0.07 L0.545,0.059 C0.574,0.056,0.633,0.049,0.642,0.048 C0.651,0.046,0.684,0.041,0.699,0.039 L0.758,0.029 L0.899,0.002"></path>
            </clipPath>
          </svg>
          <div className="head-clipped">
            <div className="play-btton">
              <a
                onClick={() => setVideo(537279384)}
                // onClick={onModalOpen}
                href="#"
                data-toggle="modal"
                data-target="#videoModal"
              >
                <svg
                  className="wb-play-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 124 124"
                >
                  <g
                    id="Group_703"
                    data-name="Group 703"
                    transform="translate(-1471 -370)"
                  >
                    <g
                      id="Group_704"
                      data-name="Group 704"
                      transform="translate(-4 32)"
                    >
                      <g id="Group_713" data-name="Group 713">
                        <g
                          id="Ellipse_3"
                          data-name="Ellipse 3"
                          transform="translate(1475 338)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="10"
                        >
                          <circle cx="62" cy="62" r="62" stroke="none" />
                          <circle cx="62" cy="62" r="57" fill="none" />
                        </g>
                      </g>
                      <path
                        id="Path_825"
                        data-name="Path 825"
                        d="M202.091,124.574l-26.469-15.648a3.589,3.589,0,0,0-5.444,3.105v31.289a3.606,3.606,0,0,0,5.444,3.105l26.469-15.64a3.606,3.606,0,0,0,0-6.212Z"
                        transform="translate(1349.884 271.882)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <HeaderVideo
        video={video}
        isVideoClose={isVideoClose}
        setIsVideoClose={setIsVideoClose}
        setVideo={setVideo}
      />
    </section>
  );
};

export default Header;
