import axios from "axios";
import { url as baseUrl } from '../constants/url/urlConstants';
import {getLanQueryParam} from "../Utils/Utils";
import {getLang} from "../context/LanguageContext";
// import { useLocation } from 'react-router-dom'

const DBNetwork = axios.create({ baseURL: `${process.env.REACT_APP_API_HOST}/` });
DBNetwork.interceptors.request.use(
    async (config) => {
        const location = window.location.pathname.toLowerCase();
        let transformedData;
        const authPayload = localStorage.getItem('GS1_User');
        if (location.includes("gs1") && authPayload && authPayload.length > 0) {
            transformedData = JSON.parse(authPayload);
        } else {
            const userData = localStorage.getItem('defaultLogin')
            transformedData = JSON.parse(userData);
        }

        if (transformedData) {
            const { access_token } = transformedData;
            if (access_token) {
                config.headers.Authorization = `Bearer ${access_token}`;
            }
        }
        // console.log("ALL METHOD " + config.method)
        if (config.method === "get") {
            const system_id = localStorage.getItem('system_id');
            if (system_id){
                if (system_id && system_id !== '') {
                    // config.params["system_id"] = system_id;
                    if (!config.params) {
                        config.params = { system_id: system_id }
                    } else {
                        config.params.system_id = system_id
                    }
                }
            }


        } else if (config.method === "post") {
           const system_id = localStorage.getItem('system_id');

            if (system_id && system_id !== '') {
                // config.params["system_id"] = system_id;
                if (!config.data) {
                    config.data = { system_id: system_id }
                } else {
                    config.data.system_id = system_id
                }
                console.log("DBNetwork [DATA] " + JSON.stringify(config.data));
            }
        }
        let param = {...config.params};
        let lanParam = getLanQueryParam(getLang());
        for (const [key, value] of Object.entries(lanParam)) {
            param[key] = value
        }
        config.params = param;

        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
DBNetwork.interceptors.response.use(
    function (successRes) {
        if (successRes.data && successRes.data.system_id){
            localStorage.setItem('system_id', successRes.data.system_id);
        }
        return successRes;
    },
    function (error) {
        return Promise.reject(error);
    });
export default DBNetwork;