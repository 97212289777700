import React, { useContext } from 'react'
import { imageBaseUrl } from '../../constants/url/urlConstants';
import { Context as AuthContext } from "../../context/AuthContext";
import { BackToRegistration } from './BackToRegistration';
import declineGif from "../Payment/img/declined.gif";
import ReactHtmlParser from "react-html-parser";


export const PaymentFailed = () => {
    let failedReason = '';
    const {
      state: {
        registrationPageData: { event, payment, translations, dbvideo },
      },
    } = useContext(AuthContext);

    if (payment?.item?.details?.failureMessage){
      failedReason = payment?.item?.details?.failureMessage;
    }
      return (
        <>
          <div className="wb-splash-reg-wrapper">
            <div className="wb-splash-inner">
              <div className="form-logo-wrap mb-0">
                <div className="logo-img-frame">
                  <img
                    // src={event?.logo}
                    src={imageBaseUrl + event?.logo}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="logo-brand-name ">
                  <h2 style={{ color: "#000" }}>{event?.title}</h2>
                  <span className="cmn-bg-color">Event</span>
                </div>
              </div>
              {dbvideo?.payment_failed ? (
                ReactHtmlParser(
                  dbvideo?.payment_failed.replace("[[reason]]", failedReason)
                )
              ) : (
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="font-weight-bold my-5">
                      <img height="100" src={declineGif} />
                      Payment failed
                    </h1>
                    <h5>{failedReason}</h5>
                  </div>
                </div>
              )}
              <BackToRegistration />
            </div>
          </div>
        </>
      );
}
