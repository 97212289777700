import React, {Component} from 'react';
import { Item } from 'semantic-ui-react';

import Profile from './Profile';

class ProfileList extends Component {
	constructor(props) {
		super(props);
	}


	render() {
		return (
			<div className="profile-card-wrap">
				<div className="meet-card-wrap">
					<div className="meet-cards-list">
						{this.props.users.map((item, index) => {
							return (
								<Profile
									key={index}
									user={item}
									setMatchId={this.props.setMatchId}
									setTwilioToken={this.props.setTwilioToken}
									setTwilioIdentity={this.props.setTwilioIdentity}
									setTwilioRoom={this.props.setTwilioRoom}
									setMatchedUser={this.props.setMatchedUser}
									setVC_State={this.props.setVC_State}
									setActiveVideoChat={this.props.setActiveVideoChat}
									setFOI={this.props.setFOI}
									listenForVCEvents={this.props.listenForVCEvents}
									declineMatch={this.props.declineMatch}
									match_id={this.props.match_id}
									twilio_room={this.props.twilio_room}
									twilio_identity={this.props.twilio_identity}
									twilio_token={this.props.twilio_token}
									matched_user={this.props.matched_user}
									foi={this.props.foi}
									event={this.props.event}
									gotMatch={this.props.gotMatch}
									acceptMatch={this.props.acceptMatch}
								/>)
						})}
					</div>
				</div>
			</div>
				

		);
		
	}
}


export default ProfileList;