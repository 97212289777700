import {
  EVENT_INFO,
  ORGANIZATION_EVENTS,
  ISVALID,
  EVENT_INFO_ON_SOCKET_UPDATE,
  UPDATE_PINNED_ITEMS,
  VOD_SECTION_CATEGORY_SELECTED,
  DEFAULT_SUB_CHANNEL,
  IS_POLL_AVAIL,
  IS_RATING_AVAIL,
  IS_VOTE_AVAIL,
  ON_USER_LOGIN,
  UPDATE_PROFILE,
  ON_USER_REGISTER,
  FETCH_MESSAGES,
  DELETE_CHATS,
  PUSHER_TICKER,
  PUSHER_SPONSER,
  PUSHER_VOD,
  PUSHER_BREAKOUT,
  PUSHER_INSERTCHAT,
  PUSHER_DELETECHAT,
  PUSHER_QNA,
  INSERT_QNA,
  PUSHER_ONAIR_POLL,
  PUSHER_OFFAIR_POLL,
  FETCH_POLL,
  PUSHER_ONAIR_VOTE,
  ON_SUBMITE_VOTE,
  PUSHER_ONAIR_RATING,
  ON_SEND_RATING,
  ON_SUBMITE_POLL,
  PUSHER_TIMETABLE,
  PUSHER_ONLINE_MEMBERS,
  PUSHER_REMOVE_MEMBERS,
  PUSHER_ADD_MEMBERS,
  SEND_CHAT_MESSAGE,
  BREAKOUT_FILTER,
} from "../constants/ActionsConstants/actiontypes";
import { PusherEvent } from "../hooks/PusherEvent";
import createDataContext from "./createDataContext";
import { baseUrl } from "../constants/url/urlConstants";
import DBNetwork from "../api/DBNetwork";
import PusherWrapper from "../hooks/PusherWrapper";
import { dispatch } from "react-hot-toast";
import { getLang } from "./LanguageContext";
import getAuthDetails from "../hooks/getAuthDetails";

const authReducer = (state, action) => {
  let members = state.onlineUsers?.members ?? [];
  switch (action.type) {
    case EVENT_INFO:
      let _payload = { ...action.payload };
      let ratingPayload = _payload?.page_data?.rating;
      let votePayload = _payload?.page_data?.vote;
      let pollPayload = _payload?.page_data?.poll;
      let isRatingAvail;
      let isPollAvail;
      let isVoteAvail;
      if (ratingPayload?.length > 0) {
        isRatingAvail = ratingPayload.filter(
          (rating) => rating.live !== 0
        ).length;
        if (isRatingAvail) {
          _payload.page_data.isRatingAvail = true;
        } else {
          _payload.page_data.isRatingAvail = false;
        }
      } else _payload.page_data.isRatingAvail = false;

      if (votePayload?.length > 0) {
        isVoteAvail = votePayload.filter((vote) => vote.live !== 0).length;
        if (isVoteAvail) {
          _payload.page_data.isVoteAvail = true;
        } else {
          _payload.page_data.isVoteAvail = false;
        }
      } else _payload.page_data.isVoteAvail = false;

      if (pollPayload) {
        if (Object.keys(pollPayload)?.length > 0) {
          isPollAvail = pollPayload?.live;
          if (isPollAvail) {
            _payload.page_data.isPollAvail = true;
          } else {
            _payload.page_data.isPollAvail = false;
          }
        } else _payload.page_data.isPollAvail = false;
      }

      // if (_payload.page_data.mainchannel.islive) _payload.page_data.channelSelected = _payload.page_data.mainchannel?.id
      if (action?.id) {
        _payload.page_data.channelSelected = action.id;
      } else {
        _payload.page_data.channelSelected = _payload.page_data.mainchannel?.id;
      }

      return {
        ...state,
        eventsPageData: { ..._payload },
      };

    case DEFAULT_SUB_CHANNEL:
      let _subChannelState = { ...action.payload };
      let _ratingPayload = _subChannelState?.page_data?.rating;
      let _votePayload = _subChannelState?.page_data?.vote;
      let _pollPayload = _subChannelState?.page_data?.poll;
      let _isRatingAvail;
      let _isPollAvail;
      let _isVoteAvail;
      if (_ratingPayload?.length > 0) {
        _isRatingAvail = _ratingPayload.filter(
          (rating) => rating.live !== 0
        ).length;
        if (_isRatingAvail) {
          _subChannelState.page_data.isRatingAvail = true;
        } else {
          _subChannelState.page_data.isRatingAvail = false;
        }
      } else _subChannelState.page_data.isRatingAvail = false;

      if (_votePayload?.length > 0) {
        _isVoteAvail = _votePayload.filter((vote) => vote.live !== 0).length;
        if (_isVoteAvail) {
          _subChannelState.page_data.isVoteAvail = true;
        } else {
          _subChannelState.page_data.isVoteAvail = false;
        }
      } else _subChannelState.page_data.isVoteAvail = false;

      if (_pollPayload) {
        if (Object.keys(_pollPayload)?.length > 0) {
          _isPollAvail = _pollPayload?.live;
          if (_isPollAvail) {
            _subChannelState.page_data.isPollAvail = true;
          } else {
            _subChannelState.page_data.isPollAvail = false;
          }
        } else _subChannelState.page_data.isPollAvail = false;
      }
      if (_subChannelState.page_data.mainchannel.islive)
        _subChannelState.page_data.channelSelected =
          _subChannelState.page_data.mainchannel.id;
      _subChannelState.page_data.channelSelected = action.channelSelected;
      _subChannelState.page_data.event = _subChannelState?.page_data?.event;
      // _subChannelState.page_data.event = action.event
      state.eventsPageData = { ..._subChannelState };
      return {
        ...state,
      };

    case UPDATE_PINNED_ITEMS:
      let _pinnedItemsState = { ...state.eventsPageData?.page_data };
      if (action.payload?.type === "chat")
        _pinnedItemsState.pinned_chat = action?.payload?.chat;
      if (action.payload?.type === "qa")
        _pinnedItemsState.pinned_qa = action?.payload?.qa;
      state.eventsPageData.page_data = { ..._pinnedItemsState };

      return {
        ...state,
      };

    case ON_USER_LOGIN:
      let _userDetails = { ...state.eventsPageData.user };
      _userDetails = { ...action.payload };
      state.eventsPageData.user = { ..._userDetails };
      return { ...state };

    case ON_USER_REGISTER:
      let _userRegisterDetails = { ...state.eventsPageData.user };
      _userRegisterDetails = { ...action.payload };
      state.eventsPageData.user = { ..._userRegisterDetails };
      return { ...state };

    case EVENT_INFO_ON_SOCKET_UPDATE:
      let _oldState = { ...state };
      return {
        ..._oldState,
      };

    case IS_POLL_AVAIL:
      let _pinnedPollState = { ...state.eventsPageData?.page_data };
      _pinnedPollState.isPollAvail = action?.payload.isPollAvail;
      _pinnedPollState.poll = action?.payload.poll;
      state.eventsPageData.page_data = { ..._pinnedPollState };
      return { ...state };

    case IS_RATING_AVAIL:
      let _pinnedRatingState = { ...state.eventsPageData?.page_data };
      _pinnedRatingState.isRatingAvail = action?.payload.isRatingAvail;
      _pinnedRatingState.rating = action?.payload.rating;
      state.eventsPageData.page_data = { ..._pinnedRatingState };
      return { ...state };

    case IS_VOTE_AVAIL:
      let _pinnedVoteState = { ...state.eventsPageData?.page_data };
      _pinnedVoteState.isVoteAvail = action?.payload.isVoteAvail;
      _pinnedVoteState.vote = action?.payload.vote;
      state.eventsPageData.page_data = { ..._pinnedVoteState };
      return { ...state };

    case ORGANIZATION_EVENTS:
      return {
        ...state,
        organizationEvents: action.payload,
      };
    case ISVALID:
      return {
        ...state,
        isValid: action.payload,
      };
    case VOD_SECTION_CATEGORY_SELECTED:
      let _oldVODState = { ...state?.eventsPageData?.page_data };
      let _newVODState = [...action?.payload];
      _oldVODState.vod = _newVODState;
      state.eventsPageData.page_data = _oldVODState;
      return {
        ...state,
      };
    case FETCH_MESSAGES:
      let chats = [...action?.payload?.chat].reverse();
      let pageData = { ...state?.eventsPageData?.page_data };
      let _chats = [...pageData?.chat];
      const all_chats = chats.concat(_chats);
      pageData.chat = all_chats;
      state.eventsPageData.page_data = pageData;
      return {
        ...state,
      };
    case DELETE_CHATS:
      let page_data = { ...state?.eventsPageData?.page_data };
      const dChats = [...page_data.chat];

      // alert(action.payload);
      let _dChats = dChats.filter((t) => {
        return !action.payload.includes(t.id);
      });
      page_data.chat = _dChats;
      state.eventsPageData.page_data = page_data;
      return {
        ...state,
      };
    case PUSHER_TICKER:
      let ticker_page_data = { ...state?.eventsPageData?.page_data };
      ticker_page_data.tickers = action.payload.tickers;
      state.eventsPageData.page_data = ticker_page_data;
      return {
        ...state,
      };

    case PUSHER_SPONSER:
      let sponser_page_data = { ...state?.eventsPageData?.page_data };
      sponser_page_data.sponser = action.payload.sponser;
      state.eventsPageData.page_data = sponser_page_data;
      return {
        ...state,
      };
    case PUSHER_VOD:
      let vod_page_data = { ...state?.eventsPageData?.page_data };
      const _vod = action.payload.vod ?? [];
      const _category = action.payload.category ?? [];
      vod_page_data.category = _category;
      vod_page_data.vod = _vod;
      state.eventsPageData.page_data = vod_page_data;
      return {
        ...state,
      };
    case PUSHER_BREAKOUT:
      let breakout_page_data = { ...state?.eventsPageData?.page_data };
      const brackoutroom = action.payload.breakout_room;
      breakout_page_data.breakout_room = brackoutroom;
      state.eventsPageData.page_data = breakout_page_data;
      return {
        ...state,
      };
    case PUSHER_INSERTCHAT:
      let chat_page_data = { ...state?.eventsPageData?.page_data };
      const _message = { ...action.payload.message };
      let chat_list = chat_page_data.chat ?? [];
      let sn_index = chat_list.findIndex((t) => t.id == _message.id);
      if (sn_index !== -1) {
        chat_list[sn_index] = _message;
      } else {
        chat_list.push(_message);
      }
      chat_page_data.chat = [...chat_list];
      state.eventsPageData.page_data = chat_page_data;
      return {
        ...state,
        pusherEvent: {
          action: "insert_chat",
          data: _message,
        },
      };
    case SEND_CHAT_MESSAGE:
      let send_chat_page_data = { ...state?.eventsPageData?.page_data };
      let chat_list_send = send_chat_page_data.chat ?? [];

      const sn_message = {
        ...action.payload.message,
        moderated_msg: action.payload?.moderate_message,
      };
      let snc_index = chat_list_send.findIndex((t) => t.id == sn_message.id);
      let sn_chat_list = send_chat_page_data.chat ?? [];
      if (snc_index !== -1) {
        sn_chat_list[snc_index] = sn_message;
      } else {
        sn_chat_list.push(sn_message);
      }
      send_chat_page_data.chat = sn_chat_list;
      state.eventsPageData.page_data = send_chat_page_data;
      return {
        ...state,
      };
    case PUSHER_DELETECHAT:
      let dchat_page_data = { ...state?.eventsPageData?.page_data };
      const _dmessage = { ...(action.payload ?? {}) };
      const dchat_list = dchat_page_data.chat ?? [];
      let _deleteChats = dchat_list.filter((t) => {
        return !_dmessage?.chat_id.includes(t.id);
      });
      dchat_page_data.chat = _deleteChats;
      state.eventsPageData.page_data = dchat_page_data;
      return {
        ...state,
        pusherEvent: {
          action: "delete_chat",
          data: _dmessage,
        },
      };
    case PUSHER_QNA:
      let qna_page_data = { ...state?.eventsPageData?.page_data };
      const _qna_message = { ...(action.payload ?? {}) };
      const qna_list = qna_page_data.question_answer ?? [];
      const qna_index = qna_list.findIndex(
        (x) => x.id === _qna_message.qandAQuestion.id
      );
      if (qna_index !== -1) {
        let answer = [
          ...(qna_list[qna_index].answers ?? []),
          _qna_message?.qandAAnswer,
        ];
        qna_list[qna_index].answers = answer;
      }
      qna_page_data.question_answer = qna_list;
      state.eventsPageData.page_data = qna_page_data;
      return {
        ...state,
        pusherEvent: {
          action: PusherEvent.ANSWER_REC,
          data: _qna_message,
        },
      };
    case INSERT_QNA:
      let ins_page_data = { ...state?.eventsPageData?.page_data };
      const ins_qna_message = { ...(action.payload ?? {}) };
      const ins_qna_list = [
        ...(ins_page_data.question_answer ?? []),
        ins_qna_message,
      ];
      ins_page_data.question_answer = ins_qna_list;
      state.eventsPageData.page_data = ins_page_data;
      return {
        ...state,
      };

    case PUSHER_ONAIR_POLL:
      let onAir_page_data = { ...state?.eventsPageData?.page_data };
      const onAir_message = { ...(action.payload?.poll ?? {}), answer_id: 0 };

      if (onAir_page_data?.poll?.id === onAir_message?.id) {
        onAir_page_data.poll = onAir_message;
        onAir_page_data.isPollAvail = true;
        state.pusherEvent = {
          action: PusherEvent.ONAIR_POLL_UPDATE,
          data: onAir_message,
        };
      } else {
        const event_id = action.payload?.event?.id;
        const system_id = localStorage.getItem("system_id");
        fetchPoll(action.dispatch)({
          event_id: event_id,
          system_id: system_id,
        });
      }
      state.eventsPageData.page_data = onAir_page_data;

      return {
        ...state,
      };
    case PUSHER_OFFAIR_POLL:
      let offAir_page_data = { ...state?.eventsPageData?.page_data };
      offAir_page_data.poll = {};
      offAir_page_data.isPollAvail = false;
      state.eventsPageData.page_data = offAir_page_data;
      return {
        ...state,
        pusherEvent: {
          action: PusherEvent.OFFAIR_POLL,
          data: action.payload.poll,
        },
      };
    case FETCH_POLL:
      let poll_page_data = { ...state?.eventsPageData?.page_data };
      poll_page_data.poll = { ...action.payload.poll };
      poll_page_data.isPollAvail = action.payload?.poll?.live;
      state.eventsPageData.page_data = poll_page_data;
      return {
        ...state,
        pusherEvent: {
          action: PusherEvent.ONAIR_POLL,
          data: action.payload.poll,
        },
      };
    case PUSHER_TIMETABLE:
      let time_table_page_data = { ...state?.eventsPageData?.page_data };
      time_table_page_data.timetable = action.payload.timetable;
      state.eventsPageData.page_data = time_table_page_data;
      return {
        ...state,
      };
    case PUSHER_ONAIR_VOTE:
      let onAir_Vote_page_data = { ...state?.eventsPageData?.page_data };
      const onAir_Vote_message = { ...(action.payload?.vote ?? {}) };
      let vote_list = onAir_Vote_page_data.vote ?? [];
      const on_air_vot_index = vote_list.findIndex(
        (vote) => vote.id === onAir_Vote_message?.id
      );
      let vote_action = null;
      if (on_air_vot_index !== -1) {
        const answerId = vote_list[on_air_vot_index].answer_id;
        vote_list[on_air_vot_index] = {
          ...onAir_Vote_message,
          answer_id: answerId,
        };
        vote_action = PusherEvent.ONAIR_VOTE_UPDATE;
      } else {
        vote_list.push(onAir_Vote_message);
        vote_action = PusherEvent.ONAIR_VOTE;
      }
      const _isAvail = vote_list.filter((vote) => vote.live !== 0).length;
      onAir_Vote_page_data.vote = vote_list;
      onAir_Vote_page_data.isVoteAvail = _isAvail;
      state.eventsPageData.page_data = onAir_Vote_page_data;
      return {
        ...state,
        pusherEvent: {
          action: vote_action,
          data: onAir_Vote_message,
        },
      };
    case ON_SUBMITE_VOTE:
      let onAir_Vote_submit_page_data = { ...state?.eventsPageData?.page_data };
      const onAir_Vote_submit_message = { ...(action.payload ?? {}) };
      let vote_list_submit = onAir_Vote_submit_page_data.vote ?? [];
      const on_air_vot_sub_index = vote_list_submit.findIndex(
        (vote) => vote.id === onAir_Vote_submit_message?.id
      );
      if (on_air_vot_sub_index !== -1) {
        const answerId = onAir_Vote_submit_message?.answered;
        vote_list_submit[on_air_vot_sub_index] = {
          ...onAir_Vote_submit_message,
          answer_id: answerId,
        };
        onAir_Vote_submit_page_data.vote = vote_list_submit;
      }
      state.eventsPageData.page_data = onAir_Vote_submit_page_data;
      return {
        ...state,
      };
    case ON_SUBMITE_POLL:
      let pull_submit_page_data = { ...state?.eventsPageData?.page_data };
      const pull_submit_message = {
        ...(action.payload?.poll ?? {}),
        answer_id: action.payload?.poll?.answered,
      };
      pull_submit_page_data.poll = pull_submit_message;
      state.eventsPageData.page_data = pull_submit_page_data;
      return {
        ...state,
      };

    case PUSHER_ONAIR_RATING:
      let rating_onAir_page_data = { ...state?.eventsPageData?.page_data };
      const rating_onAir_message = { ...(action.payload.rating ?? {}) };
      let rating_onAir_list = rating_onAir_page_data.rating ?? [];
      const rating_onAir_index = rating_onAir_list.findIndex(
        (x) => x.id === rating_onAir_message.id
      );
      let ration_action = null;
      if (rating_onAir_index !== -1) {
        if (rating_onAir_message.deleted) {
          rating_onAir_list.splice(rating_onAir_index, 1);
          ration_action = PusherEvent.ONAIR_RATING_DELETE;
        } else {
          rating_onAir_list[rating_onAir_index] = {
            ...rating_onAir_message,
            given_star: 0,
          };
          ration_action = PusherEvent.ONAIR_RATING_UPDATE;
        }
      } else {
        rating_onAir_list.push({ ...rating_onAir_message, given_star: 0 });
        ration_action = PusherEvent.ONAIR_RATING;
      }
      rating_onAir_page_data.rating = rating_onAir_list;
      const _isRatAvail = rating_onAir_list.filter(
        (rating) => rating.live !== 0
      ).length;
      rating_onAir_page_data.isRatingAvail = _isRatAvail;
      state.eventsPageData.page_data = rating_onAir_page_data;

      return {
        ...state,
        pusherEvent: {
          action: ration_action,
          data: rating_onAir_message,
        },
      };
    case ON_SEND_RATING:
      let send_rating_page_data = { ...state?.eventsPageData?.page_data };
      const _send_rating_message = { ...(action.payload.rating ?? {}) };
      const send_rating_list = send_rating_page_data.rating ?? [];
      const send_rating_index = send_rating_list.findIndex(
        (x) => x.id === _send_rating_message.id
      );
      if (send_rating_index !== -1) {
        send_rating_list[send_rating_index] = _send_rating_message;
      }
      send_rating_page_data.question_answer = send_rating_list;
      return {
        ...state,
      };
    case PUSHER_ONLINE_MEMBERS:
      const me = action.payload?.me;
      const memmbers_map = action.payload?.members ?? {};
      const keys = Object.keys(memmbers_map);
      const _members = keys
        .map((k) => {
          let data = memmbers_map[k];
          data["id"] = k;
          return data;
        })
        .filter((u) => u.id != me.id);
     const map = _members?.reduce((prev, current) => {
       return {
         ...prev,
         [current.user_id]: current
       }
     }, {})

      return {
        ...state,
        onlineUsers: { me: action.payload?.me, members: _members, membersMap: map },
      };
    case PUSHER_ADD_MEMBERS:
      let userObject = action.payload;
      for (const [key, value] of Object.entries(action.payload?.info ?? {})) {
        userObject[key] = value;
      }
      let index = members.findIndex((u) => u.id == userObject.id);
      if (index === -1) {
        members.push(userObject);
        const map = members?.reduce((prev, current) => {
          return {
            ...prev,
            [current.user_id]: current
          }
        }, {})

        return {
          ...state,
          onlineUsers: { ...state, members: members, membersMap: map},
        };
      }

    case PUSHER_REMOVE_MEMBERS:
      members = members.filter((x) => x?.id != action.payload?.id);
      const _map = members?.reduce((prev, current) => {

        return {
          ...prev,
          [current.user_id]: current
        }
      }, {})
      return {
        ...state,
        onlineUsers: { ...state, members: members, membersMap: _map },
      };

    case BREAKOUT_FILTER:
      let breakout_rating_page_data = { ...state?.eventsPageData?.page_data };
      breakout_rating_page_data.breakout_room = action.payload;
      state.eventsPageData.page_data = breakout_rating_page_data;

      return {
        ...state,
      };
    default:
      return state;
  }
};
//******************************** fetch Events Page Data ********************************88
const fetchEventsPage = (dispatch) => async (url, id, callback) => {
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const newURL = url;
  const currentURL = localStorage.getItem("event-url");
  let slug = baseUrl(`api/${url}`);
  const headers = {
    Authorization: ` Bearer ${token?.access_token}
    `,
  };

  try {
    const response = await DBNetwork.get(slug, { headers });
    if (callback) {
      callback(response.data);
    }
    
    if (response.status === 200) {
        localStorage.setItem("lan",response?.data?.current_locale)
    }

    if (response?.data?.page_data?.event?.security === "public") {
      localStorage.removeItem("defaultLogin");
    }
    if (response.status === 200 && newURL !== currentURL) {
      localStorage.removeItem("lan");
      localStorage.setItem("event-url", newURL);
    }
    PusherWrapper.sharedInstance().unregisterAllChannels();
    dispatch({ type: EVENT_INFO, payload: response.data, id });
    const system_id = localStorage.getItem("system_id");
    const uniqueId = getAuthDetails(response.data.user?.id, system_id);
    const eventData = {
      ...response.data.page_data.event,
      user: { id: uniqueId },
    };
    PusherWrapper.sharedInstance().registerChannels(eventData, {
      setPusherTickerData: (data) => setPusherTickerData(dispatch)(data),
      setSponserData: (data) => setSponserData(dispatch)(data),
      setVodData: (data) => setVodData(dispatch)(data),
      setBreakOutData: (data) => setBreakOutData(dispatch)(data),
      insertChatMessage: (data) => insertChatMessage(dispatch)(data),
      pusherEvent: (data) => pusherEvent(dispatch)(data),
      setQnAData: (data) => setQnAData(dispatch)(data),
      setOnAirPoll: (data) => setOnAirPoll(dispatch)(data),
      setOffAirPoll: (data) => setOffAirPoll(dispatch)(data),
      setOnAirVote: (data) => setOffAirVote(dispatch)(data),
      setOnAirRating: (data) => setOnAirRating(dispatch)(data),
      setPusherOnlineUser: (data) => setPusherOnlineUser(dispatch)(data),
      memberAdd: (data) => memberAdd(dispatch)(data),
      memberRemove: (data) => memberRemove(dispatch)(data),
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: ISVALID, payload: { status: false, error: e } });
  }
};

// fetch SubChannels data
const getOrganizationEvents = (dispatch) => async (url) => {
  const slug = baseUrl(`api/${url}`);

  try {
    const response = await DBNetwork.get(slug);
    console.log(response.data);
    dispatch({ type: ORGANIZATION_EVENTS, payload: response.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: ISVALID, payload: false });
  }
  // alert('Event Org');
};
//******************************** On Event Page Socket Updates ********************************

const fetchEventsPageOnSocketUpdate = (dispatch) => async (url, callback) => {
  const slug = baseUrl(`api/${url}`);
  alert();
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const headers = {
    Authorization: ` Bearer ${token?.access_token}
    `,
  };
  try {
    const response = await DBNetwork.get(slug, { headers });
    // callback(response.data)
    dispatch({ type: EVENT_INFO_ON_SOCKET_UPDATE, payload: response.data });
    console.log(response.data);
  } catch (e) {
    console.log(e);
    // dispatch({ type: ISVALID, payload: false });
  }
};

// ********************* for user Details Update ****************************

const updateUserDetails = (dispatch) => async (payload) => {
  await dispatch({ type: ON_USER_LOGIN, payload });
  // await dispatch({ type: ON_USER_LOGIN, payload: data })
};

const onUserLoggedIn = (dispatch) => async (data) => {
  await dispatch({ type: ON_USER_LOGIN, payload: data });
};
const onRegisterSuccess = (dispatch) => async (data) => {
  await dispatch({ type: ON_USER_REGISTER, payload: data });
};
//******************************** Get VOD data by category and Pagination ********************************
const fetchCategoryVodData = (dispatch) => async (data, callback) => {
  const slug = baseUrl(`api/vod/category`);
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const headers = {
    Authorization: ` Bearer ${token?.access_token}
    `,
  };
  try {
    const response = await DBNetwork.post(slug, { ...data }, { headers });
    if (response.status) callback(response.data?.vod);
    dispatch({
      type: VOD_SECTION_CATEGORY_SELECTED,
      payload: response.data?.vod,
    });
  } catch (e) {
    console.log(e);
    // dispatch({ type: ISVALID, payload: false });
  }
};

//******************************** Get Breakout Room data by category and Pagination ********************************
const fetchBreakoutRoomData = (dispatch) => async (data, callback) => {
  const slug = baseUrl(`api/breakoutroom/loadmore`);
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const headers = {
    Authorization: ` Bearer ${token?.access_token}
    `,
  };
  let isUrlHit = true;
  if (isUrlHit) {
    try {
      const response = await DBNetwork.post(slug, { ...data }, { headers });
      if (response.status) callback(response.data?.breakout_room);
      isUrlHit = false;
    } catch (e) {
      console.log(e);
    }
  }
};

const filterBreakoutRoomData = (dispatch) => async (data, callback) => {
  const slug = baseUrl(`api/event/${data.event_id}/breakout/category`);
  let isUrlHit = true;
  if (isUrlHit) {
    try {
      const response = await DBNetwork.post(slug, { category: data.category });
      callback(response.data?.vod);
      dispatch({ type: BREAKOUT_FILTER, payload: response.data?.breaouts });
    } catch (e) {
      console.log(e);
    }
  }
};

//********************************* On sub-channel Selection //*********************************

const fetchEventsSubChannelData = (dispatch) => async (url, channel) => {
  let slug;
  // const slug = baseUrl(`api/${url}?${system_id}`);
  slug = baseUrl(`api/${url}`);

  try {
    const response = await DBNetwork.get(slug);
    // callback(response.data)
    if (response?.data?.page_data?.event?.security === "public") {
      localStorage.removeItem("defaultLogin");
    }
    await dispatch({
      type: DEFAULT_SUB_CHANNEL,
      payload: response.data,
      channelSelected: channel,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: ISVALID, payload: { status: false, error: err } });
  }
};
//********************************* for Pinning and unPinning features Buttons //*********************************

const pinnedItems = (dispatch) => async (data, type, chat, qa, callback) => {
  let slug;
  slug = baseUrl(`api/pinned-widget-toggle`);
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const headers = {
    Authorization: ` Bearer ${token?.access_token}
    `,
  };
  try {
    const response = await DBNetwork.post(slug, { ...data }, { headers });

    if (response.data.status) {
      await dispatch({
        type: UPDATE_PINNED_ITEMS,
        payload: { chat, qa, type },
      });
      callback(response.data);
    } else {
      await dispatch({
        type: UPDATE_PINNED_ITEMS,
        payload: { chat: false, qa: false, type },
      });
      callback(response.data);
    }
  } catch (e) {
    callback(e.response.data);
    console.log(e);
  }
};
const onPinnedPoll = (dispatch) => async (data) => {
  await dispatch({ type: IS_POLL_AVAIL, payload: data });
};
const onPinnedRating = (dispatch) => async (data) => {
  await dispatch({ type: IS_RATING_AVAIL, payload: data });
};
const onPinnedVote = (dispatch) => async (data) => {
  await dispatch({ type: IS_VOTE_AVAIL, payload: data });
};

//******************************** fetch Events Page Data ********************************88
const fetchChatMessages = (dispatch) => async (event_id, chat_id, callback) => {
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  let slug = baseUrl(`api/chat/load-more`);
  const headers = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token?.access_token}`;
  }
  try {
    const response = await DBNetwork.post(
      slug,
      { event_id: event_id, chat_id: chat_id },
      { headers }
    );
    if (callback) {
      callback(response.data);
    }
    if (response.data.status === true) {
      dispatch({ type: FETCH_MESSAGES, payload: response.data });
    }
  } catch (e) {
    if (callback) {
      callback({ status: false });
    }
  }
};

const onSendQuestion = (dispatch) => async (payload, callback) => {
  const url = baseUrl("api/send-question");
  const response = await DBNetwork.post(url, { ...payload });
  if (callback) {
    callback(response.data);
  }
};
const onSubmitVote = (dispatch) => async (payload, callback) => {
  const url = baseUrl("api/send-vote-answer");
  const response = await DBNetwork.post(url, { ...payload });
  if (callback) {
    callback(response.data);
  }
  dispatch({ type: ON_SUBMITE_VOTE, payload: response.data });
};

const submitPoll = (dispatch) => async (payload, callback) => {
  const url = baseUrl("api/send-poll-answer");
  const response = await DBNetwork.post(url, { ...payload });
  if (callback) {
    callback(response.data);
  }
  dispatch({ type: ON_SUBMITE_POLL, payload: response.data });
};

const onSendRating = (dispatch) => async (payload, callback) => {
  const url = baseUrl("api/send-rating");
  const response = await DBNetwork.post(url, { ...payload });
  if (callback) {
    callback(response.data);
  }
  dispatch({ type: ON_SEND_RATING, payload: response.data });
};
const deleteChats = (dispatch) => async (chats, callback) => {
  await dispatch({ type: DELETE_CHATS, payload: chats });
};
const setPusherTickerData = (dispatch) => async (data) => {
  const eventID = data?.event?.id;
  const url = baseUrl(`api/event/${eventID}/tickers`);
  const response = await DBNetwork.get(url);
  if (response.status === 200) {
    dispatch({ type: PUSHER_TICKER, payload: response.data });
  }
};
const setSponserData = (dispatch) => async (data) => {
  const eventID = data?.event?.id;
  const url = baseUrl(`api/event/${eventID}/sponsers`);
  const response = await DBNetwork.get(url);
  if (response.status === 200) {
    dispatch({ type: PUSHER_SPONSER, payload: response.data });
  }
};
const setVodData = (dispatch) => async (data) => {
  const eventID = data?.event?.id;
  const url = baseUrl(`api/event/${eventID}/vods`);
  const response = await DBNetwork.get(url);
  if (response.status === 200) {
    dispatch({ type: PUSHER_VOD, payload: response.data });
  }
};
const setBreakOutData = (dispatch) => async (data) => {
  const eventID = data?.event?.id;
  const url = baseUrl(`api/event/${eventID}/breakouts`);
  const response = await DBNetwork.get(url);
  if (response.status === 200) {
    dispatch({ type: PUSHER_BREAKOUT, payload: response.data });
  }
};
const insertChatMessage = (dispatch) => (data) => {
  dispatch({ type: PUSHER_INSERTCHAT, payload: data });
};
const pusherEvent = (dispatch) => async (event) => {
  const data = event.data;
  if (data.type === "delete_chat_message" && data.chat_id?.length > 0) {
    dispatch({ type: PUSHER_DELETECHAT, payload: data });
  } else if (data.type === "timetable_update") {
    const eventID = event?.event?.id;
    const url = baseUrl(`api/event/${eventID}/timetable`);
    const response = await DBNetwork.get(url);
    if (response.status === 200) {
      dispatch({ type: PUSHER_TIMETABLE, payload: response.data });
    }
  }
};

const onSendChatMessage = (dispatch) => async (data, completion) => {
  try {
    const url = baseUrl("api/send-chat-message");
    const response = await DBNetwork.post(url, data);
    completion(response, null);
    dispatch({ type: SEND_CHAT_MESSAGE, payload: response.data });
  } catch (error) {
    completion(null, error);
  }
};
const setPusherOnlineUser = (dispatch) => (data) => {
  dispatch({ type: PUSHER_ONLINE_MEMBERS, payload: data });
  // alert(JSON.stringify(data))
};
const memberAdd = (dispatch) => (data) => {
  // alert(JSON.stringify(data))
  dispatch({ type: PUSHER_ADD_MEMBERS, payload: data });
};
const memberRemove = (dispatch) => (data) => {
  // alert(JSON.stringify(data))
  dispatch({ type: PUSHER_REMOVE_MEMBERS, payload: data });
};
const setQnAData = (dispatch) => (data) => {
  dispatch({ type: PUSHER_QNA, payload: data });
};
const setOnAirPoll = (dispatch) => (data) => {
  dispatch({ type: PUSHER_ONAIR_POLL, payload: data, dispatch: dispatch });
};
const setOffAirPoll = (dispatch) => (data) => {
  dispatch({ type: PUSHER_OFFAIR_POLL, payload: data, dispatch: dispatch });
};
const setOffAirVote = (dispatch) => (data) => {
  dispatch({ type: PUSHER_ONAIR_VOTE, payload: data, dispatch: dispatch });
};
const setOnAirRating = (dispatch) => (data) => {
  dispatch({ type: PUSHER_ONAIR_RATING, payload: data, dispatch: dispatch });
};

const sendQnA = (dispatch) => async (data, callback) => {
  const url = baseUrl("api/send-question");
  const response = await DBNetwork.post(url, { ...data });
  if (callback) callback(data);
  dispatch({ type: INSERT_QNA, payload: response.data });
};

const fetchPoll = (dispatch) => async (data) => {
  const url = baseUrl("api/get-poll-question");
  DBNetwork.post(url, { ...data }).then((response) => {
    dispatch({ type: FETCH_POLL, payload: response.data });
  });
};

const vodView = (dispatch) => async (vod_id, callback) => {
  const url = baseUrl(`api/vod/views/${vod_id}`);
  DBNetwork.get(url).then((response) => {
     if (callback) callback(response);
  });
}

//********************************* Context and Provider //***********************************************
export const { Context, Provider } = createDataContext(
  // Reducer Function
  authReducer,
  {
    // Dispatch Functions
    fetchEventsPage,
    getOrganizationEvents,
    fetchEventsPageOnSocketUpdate,
    pinnedItems,
    fetchCategoryVodData,
    fetchBreakoutRoomData,
    fetchEventsSubChannelData,
    onPinnedPoll,
    onPinnedRating,
    onPinnedVote,
    onUserLoggedIn,
    updateUserDetails,
    onRegisterSuccess,
    fetchChatMessages,
    deleteChats,

    setPusherTickerData,
    sendQnA,
    onSendRating,
    onSubmitVote,
    onSendQuestion,
    submitPoll,
    onSendChatMessage,
    filterBreakoutRoomData,
    vodView
  },
  // States
  {
    isValid: { status: true },
    eventsPageData: {},
    organizationEvents: {},
    isSubChannelDataRequest: true,
  }
);
