import React from 'react'

export default function VideoVaultWrapper({ className, id, ariaLabelledBy, children }) {
    return (
        <div key={id} className={className} id={id} role="tabpanel"
            aria-labelledby={ariaLabelledBy}>
            <div className="video-vault-box">
                <div className="row">
                    {children}
                </div>
            </div>
        </div>

    )
}
