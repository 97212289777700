import React from "react";
import { AppointmentProfile } from "./AppointmentProfile";

export const AppointmentList = ({ appointment }) => {
  const profiles = <AppointmentProfile user={appointment?.user} />;

  return (
    <div className="dft-appoints-wrap">
      <div className="dft-appoint-date-wrap">
        <h5>
          {/*<span className="dft-week-day">{appointment?.day}</span>{" "}*/}
          <span className="dft-date-day">{appointment?.date}</span>
        </h5>
      </div>
      <div className="dft-appoint-client-wrap">{profiles}</div>
    </div>
  );
};
