import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import ChatInput from '../ChatInput';
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';

const ChatAction = props => {
    return <Link to='#'>{props.title}</Link>
}


const QandAChat = (props) => {
    const inputRef = useRef();
    const [message, setMessage] = useState('');
    const scrollToBottom = useScrollToBottom();
    const onSendMessage = e => {
        e.preventDefault();
    }
    let chats;
    // console.log(ref);
    useEffect(() => {
        if (inputRef.current) {
            const scroll =
                inputRef.current.scrollHeight -
                inputRef.current.clientHeight;
            inputRef.current.scrollTo(0, scroll);
            // console.log(inputRef.current.sco)
        }
        // scrollToBottom()
    }, [props?.chatMessages])

    if (props?.chatMessages?.length > 0) {
        chats = props.chatMessages?.map((chat, index) => {
            const trimmedName = chat?.user_name
            const userId = chat?.user_id;
            let user;
            let userDetails;
            if (userId === props.userDetails) {
                user = true;
                userDetails = props.userDetails;
            }
            else if (chat?.ip_address === props.userDetails) {
                user = false;
                userDetails = props.userDetails;

            }
            const style = {
                borderColor: props.color
            }
            return <div key={index} className={`direct-chat-msg ${user ? (userId === userDetails ? '' : 'right') : (chat?.ip_address === userDetails ? '' : 'right')
                } `}>
                <div className={`direct-chat-text ${user ? (userId === userDetails ? 'right' : '') : (chat?.ip_address === userDetails ? 'right' : '')}  `}
                    style={user ? (userId === userDetails ? null : style) : (chat?.ip_address === userDetails ? null : style)}
                >
                    {chat?.answer}
                </div>
                {props.chat && <div className="direct-chat-actions">

                </div>}
                <div className="direct-chat-infos">
                    <span className="direct-chat-timestamp cmn-text-color" style={{ color: props.color }}>{chat?.time}</span>&nbsp;
                    <span className="direct-chat-name">{user ? (userId === userDetails ? 'You' : 'Moderator -' + chat?.user_name) : (chat?.ip_address === userDetails ? 'You' : 'Moderator -' + chat?.user_name)
                    } </span>
                </div>
            </div>
        });
    }
    return (

        <div ref={inputRef} index={props.key} {...props.attributes} >
            <div className="card-body">
                <div className="direct-chat-messages fix-chat-qa">
                    {chats}
                </div>
                {props.children}
            </div>
        </div >


    )
}

// export default forwardRef(QandAChat);
export default QandAChat;

// id="collapseOneQAMode" className="collapse" aria-labelledby="hdngOneQAMode" data-parent="#accordionQAMode1"