import React, { useContext, useEffect, useState } from "react";
import { ListProfile } from "./ListProfile";
import { Context as networkingToolContext } from "./../../../context/NetworkTollContext";
import UserProfileImage from "../../components/defaultLayout/UserProfileImage";
import { VideoChatState } from "../../../hooks/VideoChatState";
const DECLINING = "DECLINING";
export const Waiting = () => {
  const {
    endChat,
    declineMatch,
    clearIncommingCall,
    state: {
      videoChat,
      details: { user, videoChatState },
      dice_matched
    },
  } = useContext(networkingToolContext);

  const [timer, setTimer] = useState(3 * 60);
  const [currentState, setCurrnetState] = useState(videoChatState);
  const [loading, setLoading] = useState({isLoading: false, type: null});
  const isLoadingByType = (type) => loading.type === type && loading.isLoading

  const display = (seconds) => {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);
    const minutes = (seconds % 3600) / 60;

    return [minutes, seconds % 60].map(format).join(":");
  };

  const runCounter = () => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  };
  useEffect(() => {
    if (timer > 0) {
      runCounter();
    } else {
      if (videoChat?.match_id) {
        endChat( videoChat?.match_id)
      }
    }
  }, [timer]);

  useEffect(() => {
    setCurrnetState((pre) => {
      if (!pre || videoChatState === VideoChatState.CALLING) {
        runCounter();
      }
      return videoChatState;
    });
  }, [videoChatState]);

  const declineCall = async (e) => {
    e.preventDefault();
    if (loading.isLoading) return;
    setLoading({isLoading: true, type: DECLINING})
    setTimeout( () =>  {
      declineMatch(videoChat?.match_id, (response, error) => {
        setLoading({isLoading: false, type: null})
      });
      clearIncommingCall();
    }, 2000)
  };

 const Loader  = (text) => <> <i aria-hidden="true" className={`fa fa-spinner fa-spin`}></i> {text} </>

  return (
    <>
    <div className="decline text-right mt-2">
      <a href="#" className="btn btn-danger btn-sm" onClick={(e) => declineCall(e)}>
          { isLoadingByType(DECLINING) ? Loader("Decline") : <i disabled={loading.isLoading} className="fa fa-phone-slash" aria-hidden="true"> Decline </i>} 
      </a>
    </div>
    <div className="col-12 col-md-9 col-lg-10">
      <div className="player-chat-room">
        <div className="row">
          <div className="col-1 ">
            <div className="player1-chat chat-player host-player">
              <div className="player-imgframe">
                <UserProfileImage
                  src={videoChat?.matched_users?.avatar}
                  className="img-fluid rounded-circle"
                  alt="Player-Image"
                />
              </div>
              <div className="chat-player-name player1-name text-center pt-3">
                <h6 className="player_name">
                  {videoChat?.matched_users?.name}
                </h6>
                {videoChat?.matched_users?.host_id ===
                videoChat?.matched_users?.id ? (
                  <p className="player_designation">Host { videoChat.foi ? `(${videoChat.foi})` : `(${dice_matched?.matched_on})` }</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-11">
            <div className="meeting-welcome-note">
              <div className="meeting-welcome-wrap">
                <div className="meeting-start-soon">
                  <h5>Please wait for connection...</h5>
                  <h5 className="call--timer-waiting">
                    Call Ended in<span>{display(timer)} </span> minutes
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
