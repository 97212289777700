import React, { useContext, useEffect, useState } from "react";
import { changeColor } from "../../../../../../../utlis/changeColor";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderInfiniteScroll from "../../../../../../../UIComponets/LoaderInfiniteScroll/LoaderInfiniteScroll";
import { Context as DefaultSectionContext } from "../../../../../../../context/DefaultSectionContext";
const infiniteScroll = {
  height: "auto",
  overflowY: "auto",
  overflowX: "hidden",
};
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
const buttonStyle = {
  background: "#db4c0c",
  color: "#ffffff",
  textDecoration: "none",
  marginBottom: "10px",
  marginTop: "0px",
  padding: "5px 10px",
  textTransform: "uppercase",
  textAlign: "center",
  fontWeight: "600",
  margin: "5px 0",
  borderRadius: "0.25rem 0 0 0.25rem",
};
const spinnerStyle = {
  color: "#db4c0c",
};
const ChatMessages = ({ messages, colorCode }) => {
  const {
    fetchChatMessages,
    state: {
      eventsPageData: {
        page_data,
        translations: { chat },
      },
    },
  } = useContext(DefaultSectionContext);

  const dbvideo = page_data?.dbvideo ?? {};

  const [hasMoreData, setHasMoreData] = useState(messages?.length > 0);
  const [isLoadig, setIsLoading] = useState(false);

  useEffect(() => {
    changeColor(
      colorCode,
      `.popup-card-wrapper .right .direct-chat-text`,
      `{background:${colorCode}!important;border-color:${colorCode}!important;}`
    );
  }, [messages]);

  useEffect(() => {
    if (messages && messages.length < 20) {
      setHasMoreData(false);
    }
  }, []);

  const fetchMoreData = () => {
    setIsLoading(true);
    const event_id = page_data?.event?.id;
    const lastMessageID = page_data?.chat?.first()?.id;
    if (event_id && lastMessageID) {
      fetchChatMessages(event_id, lastMessageID, (data) => {
        if (data.status === false || data.chat?.length < 20) {
          setHasMoreData(false);
        }
        setIsLoading(false);
      });
    }
  };

  const getStyleObject = (type) => {
    let obj = {};
    const btnColorKey = type + "_btn_color";
    if (dbvideo[btnColorKey]) {
      obj.backgroundColor = dbvideo[btnColorKey];
    }
    const btnTextColorKey = type + "_btn_text_color";
    if (dbvideo[btnTextColorKey]) {
      obj.color = dbvideo[btnTextColorKey];
    }
    return obj;
  };

  return (
    <div className="event-info-accordion QA-accordion">
      <div className="card popup-card-wrapper">
        {/* <div id="chat-body"> */}
        <div
          className="direct-chat-messages menu-wrapper"
          style={{ textAlign: "center" }}
        >
          {hasMoreData && !isLoadig && (
            <a
              style={{ ...getStyleObject("chat"), ...buttonStyle }}
              className="fixed-menu-item chat-menu small-iframe cmn-bg-color"
              onClick={fetchMoreData}
            >
              {chat?.loadmore}
            </a>
          )}
          {isLoadig && (
            <i
              className="fa fa-spinner fa-pulse fa-3x fa-fw"
              style={spinnerStyle}
              aria-hidden="true"
            ></i>
          )}
          {messages}
        </div>
      </div>
    </div>
  );
};

export default ChatMessages;
