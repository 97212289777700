import React, { useEffect, useReducer, useState } from 'react'
import Calendar from 'react-calendar';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            };
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            };
        default:
            return state;
    }
};
const TextComponent = (props) => {
    //*******************  React States And Context  *******************
    const [isClickedOnEye, setIsClickedOnEye] = useState(false)
    const [isClickedOnEyeConfirmPassword, setIsClickedOnEyeConfirmPassword] = useState(false)
    const [rizivNumber, setRizivNumber] = useState("");



    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : '',
        isValid: props.initiallyValid,
        touched: false
    });
    if (props.clearField) {
        dispatch({ type: INPUT_CHANGE, value: '', isValid: false });
    }

    const { onInputChange, id } = props;

    //*******************  React Life Cycle Hooks  *******************
    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        } else if (props.radioButton) {
            onInputChange(id, inputState.value, inputState.isValid);
        }else if (props.hotReload){
            onInputChange(id, inputState.value, inputState.isValid);
        } 
    }, [inputState, onInputChange, id]);

    //*******************  Function Declaration  *******************
    const textChangeHandler = e => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passRegex = /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,15})$/;
        //const phone   = /^[-()+ .]?(?:[0-9]+[-()+ .]+)+[0-9]+$/;
       

        let isValid = true;

        if (props.required && e.target.value.trim().length === 0) {
            isValid = false;
        }
        if (props.email && !emailRegex.test(e.target.value.toLowerCase())) {
            isValid = false;
        }
        if (props.password && props.min != null && +e.target.value < props.min) {
            isValid = false;
        }
        if (props.max != null && +e.target.value > props.max) {
            isValid = false;
        }
        if (props.minLength != null && e.target.value.length < props.minLength) {
            isValid = false;
        }
        if (props.minLength != null && e.target.value.length < props.minLength && props.passwordValue !== e.target.value) {
            isValid = false;
        }

        if(props.phone){
            let rg = /(\d{2})(\d{1,3})/
            let token = "$1.$2"
            const text = e.target.value;
            const rawText = text.replaceAll(".", "").replaceAll("-", "")
            const length = rawText.length;
            if (length > 11) return;
            if (length > 5  &&  length <= 7) {
                 rg = /(\d{2})(\d{2})(\d{1,2})/
                 token = "$1.$2.$3"
            }else if (length > 7  &&  length <= 10) {
                rg = /(\d{2})(\d{2})(\d{1,2})(\d{1,3})/
                token = "$1.$2.$3-$4"
           }else if (length > 10  &&  length <= 11) {
            rg = /(\d{2})(\d{2})(\d{1,2})(\d{1,3})(\d{1,3})/
            token = "$1.$2.$3-$4.$5"
          }
            let onlyDigits = rawText.replace(rg, token);
            setRizivNumber(onlyDigits);
            // const onlyDigits = e.target.value.replace(/(\d{2})(\d{3})(\d{2})(\d{3})(\d{2})/,"$1.$2.$3-$4.$5");
        }
   

     

        dispatch({ type: INPUT_CHANGE, value: e.target.value, isValid: isValid });
    };

    const lostFocusHandler = () => {
        dispatch({ type: INPUT_BLUR });
    };
    const showPassword = (e) => {
        e.preventDefault();
        if (props.passwordType === 'password') {
            setIsClickedOnEye(!isClickedOnEye);
            props.toShowPassword(isClickedOnEye, 'password');
        } else if (props.passwordType === 'confirmPassword') {
            setIsClickedOnEyeConfirmPassword(!isClickedOnEyeConfirmPassword);
            console.log('confirmPassword')
            props.toShowPassword(isClickedOnEyeConfirmPassword, 'confirmPassword');
        }
    }
    return (
        <React.Fragment>
            <div className={props.containerStyle}>
                {props.fileInput && <label className={props.fileLabelStyle}>
                    <input
                        value={inputState.value}
                        onChange={textChangeHandler}
                        type='file'
                        className={props.fileInputStyle}
                        onBlur={lostFocusHandler}
                        name={props.filename}
                    />{props.fileLabelName}
                </label>
                }

                {props.accountDetails && <React.Fragment>
                    {props.label && <label className={props.labelStyle}>{props.labelName}</label>}
                    <input
                        value={inputState.value}
                        onChange={textChangeHandler}
                        type={props.inputType}
                        className={props.inputStyle}
                        onBlur={lostFocusHandler}
                        name={props.name}
                        placeholder={props.placeholder}
                       />
                    {props.password && <div className={props.faEyeContainer}>
                        <span className={props.faEyeClass}>
                            <a href="">
                                <i className={props.faEyeIcon} aria-hidden={props.faEyeAriaHidden}> </i>
                            </a>
                        </span>
                    </div>
                    }
                </React.Fragment>
                }
                {props.registerForm &&
                    <div className={props.registerFormContainerStyle}>
                        {props.fafa && <div className={props.registerFormStyle}>
                            <span className={props.spanclassName} style={props.style}
                                id={props.spanId}
                            ><i className={props.faIcon}
                                aria-hidden={props.areaHidden}></i></span>
                        </div>}

                        <input type="email"
                            className="form-control"
                            placeholder="Username"
                            value={inputState.value}
                            onChange={textChangeHandler}
                            type={props.inputType}
                            className={props.inputStyle}
                            onBlur={lostFocusHandler}
                            name={props.name}
                            placeholder={props.placeholder}
                            aria-label={props.ariaLabel}
                            aria-describedby={props.ariaDescribedby}/>

                        {props.password && <div className={props.faEyeContainer}>
                            <span onClick={(e) => showPassword(e)} className={props.faEyeSpanClassName}>
                                <a href="#">
                                    <i className={props.faEyeIcon} aria-hidden={props.faEyeAriaHidden}> </i>
                                </a>
                            </span>
                        </div>
                        }
                    </div>
                }
                {props.riziv &&
                    <div className={props.registerFormContainerStyle}>
                        {props.fafa && <div className={props.registerFormStyle}>
                            <span className={props.spanclassName} style={props.style}
                                id={props.spanId}
                            ><i className={props.faIcon}
                                aria-hidden={props.areaHidden}></i></span>
                        </div>}

                        <input type="email"
                            className="form-control"
                            placeholder="Username"
                            value={rizivNumber.replace(/(?!-)[^0-9.]/g, "")}
                            // value={inputState.value}
                            onChange={textChangeHandler}
                            type={props.inputType}
                            className={props.inputStyle}
                            onBlur={lostFocusHandler}
                            name={props.name}
                            placeholder={props.placeholder}
                            aria-label={props.ariaLabel}
                            aria-describedby={props.ariaDescribedby}/>

                        {props.password && <div className={props.faEyeContainer}>
                            <span onClick={(e) => showPassword(e)} className={props.faEyeSpanClassName}>
                                <a href="#">
                                    <i className={props.faEyeIcon} aria-hidden={props.faEyeAriaHidden}> </i>
                                </a>
                            </span>
                        </div>
                        }
                    </div>
                }
                {!props.forgetPassword && !props.resetPassword && !props.landingPage && props.showError &&
                    !inputState.isValid && inputState.touched && (
                        <div className={props.errorMessageContainerStyle}>
                            <p className='text-danger'>{props.errorText}</p>
                        </div>
                    )
                }
            </div >
            {props.forgetPassword && <><div> <input
                value={inputState.value}
                onChange={textChangeHandler}
                type={props.inputType}
                className="form-control"
                onBlur={lostFocusHandler}
                name={props.name}
                placeholder={props.placeholder}/>
            </div>
                {props.forgetPassword && !props.resetPassword &&
                    !inputState.isValid && inputState.touched && (
                        <div className={props.errorMessageContainerStyle}>
                            <p className='text-danger'>{props.errorText}</p>
                        </div>
                    )
                }
            </>}

            {props.resetPassword && <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text db-background  cmn-bg-color" id="inputUsername"><i className="fa fa-lock" aria-hidden="true"></i></span>
                </div>
                <input
                    value={inputState.value}
                    onChange={textChangeHandler}
                    type={props.inputType}
                    className="form-control"
                    onBlur={lostFocusHandler}
                    name={props.name}
                    placeholder={props.placeholder}/>
                {props.resetPassword && <div className={props.faEyeContainer}>
                    <span onClick={(e) => showPassword(e)} className={props.faEyeSpanClassName}>
                        <a href="#">
                            <i className={props.faEyeIcon}
                                aria-hidden={props.faEyeAriaHidden}> </i>
                        </a>
                    </span>
                </div>
                }

            </div>}
            {!props.forgetPassword && props.resetPassword && !props.landingPage &&
                !inputState.isValid && inputState.touched && (
                    <div className={props.errorMessageContainerStyle}>
                        <p className='text-danger'>{props.errorText}</p>
                    </div>
                )
            }

            {props.radioButton && <label className={props.labelClassName}> {props.title}
                <input
                    name={props.name}
                    style={props.style}
                    value={props.value}
                    checked={props.checked}
                    // onChange={(e) => props.onSelection(e.target.value)}
                    onChange={textChangeHandler}
                    type="radio"

                />
                <span className={props.spanClassName} ></span>
            </label>
            }

            {props.landingPage && <React.Fragment> <div className="form-group">
                {!props.textArea && <input type={props.inputType}
                    className="form-control"
                    onChange={textChangeHandler}
                    onBlur={lostFocusHandler}
                    name={props.name}
                    placeholder={props.placeholder}/>}
                {props.textArea && <textarea className="form-control"
                    rows="5"
                    onBlur={lostFocusHandler}
                    name={props.name}
                    onChange={textChangeHandler}
                    placeholder={props.placeholder}>
                </textarea>
                }
            </div>

            </React.Fragment>

            }
            {props.landingPage && !inputState.isValid && inputState.touched &&
                <div className={props.errorMessageContainerStyle}>
                    <p className='text-danger'>{props.errorText}</p>
                </div>}
        </React.Fragment>
    )
}

export default TextComponent;