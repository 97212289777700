import React, { useContext, useEffect, useState } from "react";
import TelnetHeader from "../Header/TelnetHeader";
import OnlineEvent from "../OnlineEvents/OnlineEvent";
import SingleEvent from "./SingleEvent/SingleEvent";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Location from "../../../../hooks/Location";
import ErrorPageDefault from "../../../../Route/ErrorPageDefault";
import { Context as defaultContext } from "../../../../context/DefaultSectionContext";
import useStyle from "../../../../hooks/cssLoad";
import { imageWithStorage } from "../../../../constants/url/urlConstants";
import {
  Organization_Channel,
  Organization_Event,
} from "../../../../constants/Pusher/PusherConstants";
import Loader from "../../../../UIComponets/Loader";
import LoaderDefaultSection from "../../../../UIComponets/LoaderDefaultSection";
import useBodyClass from "../../../../hooks/BodyClass";
import placeholder from "../../../../assets/images/upcoming/1.png";
import placeholderBody from "../../../../assets/images/bg_body.jpg";
import DBNetwork from "../../../../api/DBNetwork";
import Socket from "../../../../hooks/Socket";
import { ORGANIZATION_EVENTS } from "../../../../constants/ActionsConstants/actiontypes";
import { changeColor } from "../../../../utlis/changeColor";

const Home = () => {
  const [identifier] = Location();
  const { addToast } = useToasts();
  useBodyClass("organization-body");
  useStyle("/script/default/defaultStyle.css");
  // useStyle('/script/default/defaultStyle.css');
  const [isLoading, setIsLoading] = useState(false);
  const [allLiveEvents, setAllLiveEvents] = useState([]);

  const {
    getOrganizationEvents,
    state: {
      organizationEvents: { status, client, events },
      isValid,
    },
  } = useContext(defaultContext);

  const colorCode = client?.color;
  useEffect(() => {
    changeColor(colorCode, `.cmn-bg-color:hover`, `{background-color : #ffffff!important;color: ${colorCode}!important;}`)
    changeColor(colorCode, `.cmn-txt-color`, `{color : ${colorCode}!important;}`)
    changeColor(colorCode, `.cmn-bg-color`, `{background-color : ${colorCode} !important;color: #ffffff !important}`)
    changeColor(
      colorCode,
      `::-webkit-scrollbar-thumb`,
      `{background:${colorCode}!important;}`
    );
  }, [colorCode])

  const getEventsDetails = async () => {
    const url = identifier;
    setIsLoading(true);
    try {
      await getOrganizationEvents(url);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      addToast("Something went wrong! Please Try Again", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setIsLoading(false);
  };

  const onSocketUpdate = async () => {
    await getEventsDetails();
  };

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      if (client?.favicon) {
        favicon.href = imageWithStorage + client?.favicon;
      } else if (client?.logo) {
        favicon.href = imageWithStorage + client?.logo;
      }
    }
  }, [client?.logo]);

  useEffect(() => {
    getEventsDetails();
  }, []);

  useEffect(() => {
    const bodyClass = document.querySelector(".organization-body");
    let url;
    if (bodyClass) {
      if (client?.background_image) {
        url = `url('${imageWithStorage + client?.background_image}') no-repeat`;
        bodyClass.addEventListener("error", () => {
          url = `url('${placeholderBody}')`;
        });
      } else {
        if (status) url = `url('${placeholderBody}')`;
      }
      bodyClass.style.background = url;
    }
  }, [client?.background_image]);

  useEffect(() => {
    if (events) {
      let _liveEvent = events?.filter((a) => a.live === true);
      setAllLiveEvents(_liveEvent);
    }
  }, []);

  if (isLoading) return <LoaderDefaultSection />;

  if (!isValid) {
    return <ErrorPageDefault />;
  }

  let liveEvents;
  let allEvents;
  // let allLiveEvents;
  if (events?.length > 0) {
    let allLiveEvents = events?.filter((a) => a.live === true);
    if (allLiveEvents?.length > 0) {
      liveEvents = allLiveEvents?.map((liveEvent, index) => {
        // console.log('liveEvent?.title', liveEvent?.title)
        return (
          <div className="mb-3">
            <OnlineEvent
              key={index}
              locked={liveEvent?.security === "public" ? false : true}
              title={liveEvent?.title}
              client={client?.slug}
              color={liveEvent?.color}
              event={liveEvent?.slug}
              src={imageWithStorage + liveEvent?.logo}
              buttonTitle={
                liveEvent?.security === "public" ? "Watch Now" : "Login"
              }
            />
          </div>
        );
      });
    }
    //  for un-live events.
    const eventsData = events?.filter((a) => a.live !== true);
    allEvents = eventsData?.map((event, index) => {
      // const dateTime = event?.start_date?.split(' ');
      const date = moment(event?.start_date).format("L");
      const hTime = moment(event?.start_date).format("HH");
      const mTime = moment(event?.start_date).format("mm");
      const time = hTime + "." + mTime + "u";
      // const [date, time] = dateTime
      return (
        <React.Fragment key={index}>
          <SingleEvent
            locked={event?.security === "public" ? false : true}
            date={date + " - " + time}
            client={client?.slug}
            color={event?.color}
            event={event?.slug}
            title={event?.title}
            color={event?.color}
            src={imageWithStorage + event?.logo}
          />
        </React.Fragment>
      );
    });
  }

  return (
    <main className="upcoming-event-wrapper">
      <Socket
        onSocketUpdate={onSocketUpdate}
        list={[Organization_Channel + client?.id]}
        channel={Organization_Channel}
        event={Organization_Event}
        id={client?.id}
      />
      <TelnetHeader
        logo={imageWithStorage + client?.logo}
        title={client?.name}
      />
      <section className="event-list-wrapper">
        <div className="container">
          {liveEvents}
          <div className="up-sponsors-wrapper">
            <div className="up-sponsors-box-wrapper">{allEvents}</div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Home;
