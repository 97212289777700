import React from 'react'
import { Link } from 'react-router-dom';
import Location from '../../../../hooks/Location'
import telent from '../../../../assets/upcoming/2.png';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import placeholder from '../../../../assets/images/organization_PlaceHolder.jpeg';

const OnlineEvent = ({ locked, title, buttonTitle, src, event, client, color }) => {
    // const [client] = Location();

    const url = (client && event) ? `/${client}/${event}` : null
    return (
        <div className="upcoming-active-events upcoming-event-unlocked">
            <div className="row">
                <div className="col-7">
                    <div className={`sponsors-box-wrap ${locked ? "lock" : 'unlock'}`}>
                        <div className="sponsors-imageframe" style={{ border: `10px solid ${color}` }}>
                            <img src={src} alt="" className="img-fluid"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = placeholder
                                }}
                            />
                            <div className="upcoming-overlay">
                                <i className="material-icons">lock_outline</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <div className="upcoming-event-status">
                        <h3 className="cmn-txt-color">Live: <span className="event-heading">{title}</span></h3>
                        <div className="upcmng-login-wrap">
                            {url && <Link target="_blank" to={url}
                                className="btn btn-block btn-event-login w-75 cmn-bg-color">{buttonTitle}</Link>}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default OnlineEvent;