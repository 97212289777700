import React, { Component } from 'react';
import DBNetwork from '../../../../api/DBNetwork';
import { baseUrl } from '../../../../constants/url/urlConstants';
import { withSnackbar } from 'react-simple-snackbar'

import Dice from "./Dice";
import GlobalChat from './GlobalChat';
import VideoChat from './VideoChat/VideoChat';
import MatchFound from './Match/MatchFound';
import '../../../../Webble-Up/components/css/style.css'

import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../constants/Pusher/Pusher';

const options = {
	position: 'top-right',
	style: {
		backgroundColor: 'midnightblue',
		border: '2px solid lightgreen',
		color: 'lightblue',
		fontFamily: 'Menlo, monospace',
		fontSize: '20px',
		textAlign: 'center',
	},
	closeStyle: {
		color: 'lightcoral',
		fontSize: '16px',
	},
}
class MeetGS1Body extends Component {
	constructor(props) {
		super(props);

		console.log("PROPS MEET GS1 body");
		console.log(props);

		this.state = {
			show_dice: false,

			pusher: null
		};

		/*this.setTwilioToken = this.setTwilioToken.bind(this);
		this.setActiveVideoChat = this.setActiveVideoChat.bind(this);*/
	}

	check_for_dice_open = async () => {
		try {
			const url = baseUrl("api/video-chat/dice/authorised/" + this.props.event.id);
			const response = await DBNetwork.get(url);
			// If not 200, then the user can not roll the dice
			this.setState({
				show_dice: true
			});
		} catch (error) {
			console.log("ERROR");
		}
	}

	async componentDidMount() {

		await this.check_for_dice_open();

		if (this.state.pusher == null && this.props.event) {
			let pusher = new Pusher(PUSHER_APP_KEY, {
				cluster: PUSHER_CLUSTER,
				encrypted: false
			});
			this.state.pusher = pusher;

			pusher.connection.bind('error', function (err) {
				if (err?.code === 404) {
					console.log('Over limit!');
				}
			});

			const channel = pusher.subscribe('dice.state.' + this.props.event.id);
			channel.bind("App\\Events\\DiceStateEvent", async (data) => {
				console.log("INCOMMING REQUEST");
				try {
					if (data.state == 1) {
						await this.check_for_dice_open()
						// If not 200, then the user can not roll the dice
						if (this.state.show_dice)
							this.props.openSnackbar("The Meet GS1 dice are open");
					}
					else if (data.state == 0) {
						this.props.openSnackbar("The Meet GS1 dice are closed");
						this.setState({
							show_dice: false
						});
					}
				} catch (error) {
					console.log("NO MEET GS1");
				}
			});
		}

	}


	render() {
		const { openSnackbar, closeSnackbar } = this.props;
		let body;

		if (this.props.match_id != null && this.props.active_videochat)
			body = <VideoChat
				twilio_token={this.props.twilio_token}
				twilio_room={this.props.twilio_room}
				twilio_identity={this.props.twilio_identity}
				matched_user={this.props.matched_user}
				match_id={this.props.match_id}
				user={this.props.user}
				foi={this.props.foi}
				endChat={this.props.endChat}
				resetVCState={this.props.resetVCState}
				vc_created_at={this.props.vc_created_at}
			/>
		else if (this.props.match_id != null && this.props.matched_user)
			body = <MatchFound
				setMatchId={this.props.setMatchId}
				setTwilioToken={this.props.setTwilioToken}
				setTwilioIdentity={this.props.setTwilioIdentity}
				setTwilioRoom={this.props.setTwilioRoom}
				setMatchedUser={this.props.setMatchedUser}
				setVC_State={this.props.setVC_State}
				setActiveVideoChat={this.props.setActiveVideoChat}
				setFOI={this.props.setFOI}
				listenForVCEvents={this.props.listenForVCEvents}
				declineMatch={this.props.declineMatch}
				match_id={this.props.match_id}
				twilio_room={this.props.twilio_room}
				twilio_identity={this.props.twilio_identity}
				twilio_token={this.props.twilio_token}
				matched_user={this.props.matched_user}
				foi={this.props.foi}
				event={this.props.event}
				user={this.props.user}
				acceptMatch={this.props.acceptMatch}
				inNotificationModal={false}
			/>
		else if (this.state.show_dice) {
			body = <Dice
				setMatchId={this.props.setMatchId}
				setTwilioToken={this.props.setTwilioToken}
				setTwilioIdentity={this.props.setTwilioIdentity}
				setTwilioRoom={this.props.setTwilioRoom}
				setMatchedUser={this.props.setMatchedUser}
				setVC_State={this.props.setVC_State}
				setActiveVideoChat={this.props.setActiveVideoChat}
				setFOI={this.props.setFOI}
				listenForVCEvents={this.props.listenForVCEvents}
				declineMatch={this.props.declineMatch}
				match_id={this.props.match_id}
				twilio_room={this.props.twilio_room}
				twilio_identity={this.props.twilio_identity}
				twilio_token={this.props.twilio_token}
				matched_user={this.props.matched_user}
				foi={this.props.foi}
				event={this.props.event}
				user={this.props.user}
				acceptMatch={this.props.acceptMatch}>
			</Dice>
		} else {
			body = <GlobalChat
				setMatchId={this.props.setMatchId}
				setTwilioToken={this.props.setTwilioToken}
				setTwilioIdentity={this.props.setTwilioIdentity}
				setTwilioRoom={this.props.setTwilioRoom}
				setMatchedUser={this.props.setMatchedUser}
				setVC_State={this.props.setVC_State}
				setActiveVideoChat={this.props.setActiveVideoChat}
				setFOI={this.props.setFOI}
				listenForVCEvents={this.props.listenForVCEvents}
				declineMatch={this.props.declineMatch}
				match_id={this.props.match_id}
				twilio_room={this.props.twilio_room}
				twilio_identity={this.props.twilio_identity}
				twilio_token={this.props.twilio_token}
				matched_user={this.props.matched_user}
				foi={this.props.foi}
				event={this.props.event}
				user={this.props.user}
				gotMatch={this.props.gotMatch}
				acceptMatch={this.props.acceptMatch}
			/>
		}

		return (
			body
		)
	}
}

export default withSnackbar(MeetGS1Body, options);
