import React, { useContext, useEffect, useState } from "react";
import Body from "../defaultLayout/body/Body";

import HeaderComponent from "../defaultLayout/Header";
import Location from "../../../hooks/Location";
import { Context as defaultContext } from "../../../context/DefaultSectionContext";
import { useToasts } from "react-toast-notifications";
import { Redirect, useHistory } from "react-router-dom";
import ErrorPageDefault from "../../../Route/ErrorPageDefault";
import useBodyClass from "../../../hooks/BodyClass";
import useScript from "../../../hooks/useScript";
import getAuthDetails from "../../../hooks/getAuthDetails";
import useScriptHead from "../../../hooks/useScriptHead";
import LoaderDefaultSection from "../../../UIComponets/LoaderDefaultSection";
import useStyle from "../../../hooks/cssLoad";
import moment from "moment-timezone";
import logout from "../../../hooks/logout";
import Login from "../../../Auth/Login/Login";
import Register from "../../../Auth/Register/Register";
import LoginC from "../../../Auth/Login/LoginC";
import LoginViaPass from "../../../Auth/Login/LoginViaPass";
import { useCookies } from 'react-cookie';
import { useParams } from "react-router-dom";
import { Context as languageContext } from "../../../context/LanguageContext";
import { ErrorCode } from "../../../Utils/ErrorCode";
const Main = () => {
  const { passcode } = useParams()

  useBodyClass("default-active");
  useScriptHead("/js/swiper.js");
  // useScript("/js/defaultMain.js");
  useScript("/js/font-awesome.js");
  useScript("/js/VodSlider.js");
  useStyle("/script/default/defaultStyle.css");
  useStyle("/script/default/owl.carousel.min.css");
  const [clientName, event] = Location();
  const history = useHistory();
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const [isEventPublic, setIsEventPublic] = useState(false);
  const { addToast } = useToasts();
  const [cookies, setCookie] = useCookies(['truid']);

  //******************* React Context and States *******************
  const {
    fetchEventsPage,
    state: {
      eventsPageData: { status, client, header, user, userData, page_data, magic_link, magic_link_input_code },
      isValid
    },
  } = useContext(defaultContext);

  const {
    state: {
      langauge
    },
  } = useContext(languageContext)

  const [isLoading, setIsLoading] = useState(false);
  //**************************** React Hooks **************************************
  useEffect(() => {
    document.title = clientName;
  }, []);

  useEffect(() => {
    const eventsData = async (callback) => {
      let url;
      // const url = `samsung/look1`
      setIsLoading(true);
      let channelUrl = sessionStorage.getItem("channelSelected");
      const _channel = JSON.parse(channelUrl);
      if (channelUrl) {
        url = _channel.url;
      } else if (passcode) {
        url = `${clientName}/${event}/${passcode}`;
      } else {
        url = `${clientName}/${event}`;
      }
      // alert(url);
      try {
        // setIsLoading(true);
        await fetchEventsPage(url, _channel?.id, (data) => {
          callback(data);
        });
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        callback(null);
        setIsLoading(false);
        addToast("Something went wrong! Please Try Again", {
          appearance: "error",
          autoDismiss: true,
        });
        return <Redirect />;
      }
      setIsLoading(false);
    };

    eventsData((data) => {
      if (data?.status === false) {
        return <ErrorPageDefault />;
      }
      let lastPathComponent = "";
      let authPayload;
      if (data?.page_data?.event?.security === "registration") {
        lastPathComponent = "/login";
        setIsEventPublic(false);
        // localStorage.removeItem('eventPass');
        const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));

        if (
          pass?.event === clientName + event &&
          !localStorage.getItem("defaultLogin")
        ) {
          setIsLoginRequired(true);
        }
        if (!localStorage.getItem("defaultLogin")) {
          setIsLoginRequired(true);
          localStorage.removeItem(`${clientName}+${event}`);
          history.replace(`/${clientName}/${event}/register`);
        }
        authPayload = localStorage.getItem("defaultLogin");
        // if (!authPayload) setIsLoginRequired(true);
      } else if (data?.page_data?.event?.security === "password") {
        // authPayload = localStorage.getItem("eventPass");
        const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));

        if (!(pass?.event === clientName + event)) {
          setIsLoginRequired(true);
          history.replace(`/${clientName}/${event}/login`);
        }
        // if (!localStorage.getItem("eventPass")) {
        //   setIsLoginRequired(true);
        // }
      } else if (data?.page_data?.event?.security === "public") {
        setIsLoginRequired(false);
        setIsEventPublic(true);
        // localStorage.removeItem('defaultLogin');
        // localStorage.removeItem('eventPass');
        // console.log(authPayload);
      }

      if (!authPayload) {
        if (data?.page_data?.event?.security === "registration") {
          // setIsLoginRequired(true);
        }
        // setIsEventPublic(false);
        return;
      }
      const details = JSON.parse(authPayload);
      // console.log(details)
      if (!details?.access_token || !details?.expires_at) {
        if (data?.page_data?.event?.security === "registration")
          // setIsLoginRequired(true);
          return;
      }

      const current = moment(new Date());
      const cDate = current.tz("Europe/Brussels").format("YYYY-MM-DD HH:mm:ss");

      const expireDate = new Date(details.expires_at);
      const eDate = moment(expireDate).format("YYYY-MM-DD HH:mm:ss");
      let dateDiff = new Date(eDate) - new Date(cDate);
      if (eDate < cDate) {
        // logout();
        // history.push(url)
        // setIsLoginRequired(true);
        return;
      }
      setTimeout(() => {
        // console.log("AUTH [Logout Now]");
        // logout();
        // history.push(url)
        // setIsLoginRequired(true);
      }, dateDiff);

      // For PassWord
      const cPassDate = current
        .tz("Europe/Brussels")
        .format("YYYY-MM-DD HH:mm:ss");
      let today = new Date().toISOString().slice(0, 10);
      const passExp = new Date(details.expires_at);
      const passExpireDate = new Date(
        new Date(today).getTime() + 60 * 60 * 24 * 1000
      );
      const ePassDate = moment(passExpireDate).format("YYYY-MM-DD HH:mm:ss");
      let dateDiffPass = new Date(eDate) - new Date(cDate);
      if (ePassDate < cPassDate) {
        // localStorage.removeItem("eventPass");
      }
      setTimeout(() => {
        // localStorage.removeItem("eventPass");
      }, dateDiffPass);
    });
  }, [langauge]);

  useEffect(() => {
    let authPayload;
    if (page_data?.event?.security === "registration") {
      setIsEventPublic(false);
      localStorage.removeItem("eventPass");
      const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));

      if (
        pass?.event === clientName + event &&
        !localStorage.getItem("defaultLogin")
      ) {
        setIsLoginRequired(true);
        history.replace(`/${clientName}/${event}/register`);
        localStorage.removeItem(`${clientName}+${event}`);
      }
      if (!localStorage.getItem("defaultLogin")) {
        setIsLoginRequired(true);
        history.replace(`/${clientName}/${event}/register`);
      }
      authPayload = localStorage.getItem("defaultLogin");
      // localStorage.removeItem("eventPass");
    } else if (page_data?.event?.security === "password") {
      const pass = JSON.parse(localStorage.getItem(`${clientName}+${event}`));
      console.log("Pass" + pass?.event);
      console.log("Pass M" + clientName + event);
      if (!(pass?.event === clientName + event)) {
        setIsLoginRequired(true);
        history.replace(`/${clientName}/${event}/login`);
      }
      setIsEventPublic(false);
      // localStorage.removeItem('defaultLogin');
    } else if (page_data?.event?.security === "public") {
      setIsLoginRequired(false);
      setIsEventPublic(true);
      // logout();
      // localStorage.removeItem('defaultLogin');
      // localStorage.removeItem('eventPass');
      // console.log(authPayload);
    }
  }, [page_data?.event?.security]);

  if (isLoading) return <LoaderDefaultSection />;

  if (!(isValid?.status) && isValid?.error?.response?.status === ErrorCode.RESOURCE_NOT_FOUND) {
    return <ErrorPageDefault />;
  } else if (!isValid?.status && isValid?.error?.response?.status === ErrorCode.PAYMENT_NOT_VERIFIED) {     
    window.history.replaceState({}, '',`/${clientName}/${event}/register`) 
    return <Register />;
  } else if (magic_link) {
    // return <p> Passcode and isRequireINPUT : { magic_link_input_code ? 'true': 'false'}</p>
    return <LoginViaPass security={"password"} magic_link={true} magic_link_input_code={magic_link_input_code} />
  }
  const token = JSON.parse(localStorage.getItem("defaultLogin"));
  const system_id = localStorage.getItem("system_id");
  const uniqueId = getAuthDetails(user?.id, system_id);
  // console.log('system_id', system_id)
  let isLoginRendered = false;
  const onRemoved = (e) => {
    alert("Login to View Event");
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.location.href = `/${clientName}/${event}`;
  };
  let Component;

  // console.log("isLoginRequired", isLoginRequired);
  if (isLoginRequired) {
    // if (page_data?.event?.security === "password") {
    //   Component = (
    //     <>
    //       <LoginViaPass
    //         security={page_data?.event?.security}
    //         isLoggedIn={setIsLoginRequired}
    //       />
    //       <div onClick={onRemoved}>
    //         <HeaderComponent
    //           header={header}
    //           event={page_data?.event}
    //           security={page_data?.event?.security}
    //           user={user}
    //           isLoggedIn={setIsLoginRequired}
    //         />
    //         <Body
    //           pageData={page_data}
    //           userId={uniqueId}
    //           event={page_data?.event}
    //           security={page_data?.event?.security}
    //           systemId={system_id}
    //           channelSelected={page_data?.channelSelected}
    //           userDetails={user?.id}
    //         />
    //       </div>
    //       {setInterval(function () {
    //         //code goes here that will be run every 5 seconds.
    //         const items = window.$(".login-fixed-wrapper");
    //         if (isLoginRendered) {
    //           if (items.length === 0) {
    //             window.location.href = `/${clientName}/${event}`;
    //           } else {
    //             const wrapper = window.$(items[0]);
    //             wrapper.removeAttr("style");
    //             wrapper.css(
    //               "cssText",
    //               "position: fixed !important;  background-position: center !important;  background-size: cover !important;  background-blend-mode: multiply !important;  top: 0 !important;  left: 0 !important;  right: 0 !important;  bottom: 0 !important;  z-index: 99 !important;width:auto !important; height:auto !important"
    //             );
    //           }
    //         }
    //         if (items.length > 0) {
    //           isLoginRendered = true;
    //         }
    //       }, 1000)}
    //     </>
    //   );
    // }
    //   else if (page_data?.event?.security === "registration") {
    //     Component = (
    //       <>
    //         <Register
    //           security={page_data?.event?.security}
    //           isLoggedIn={setIsLoginRequired}
    //         />
    //         <div onClick={onRemoved}>
    //           <HeaderComponent
    //             header={header}
    //             event={page_data?.event}
    //             security={page_data?.event?.security}
    //             user={user}
    //             isLoggedIn={setIsLoginRequired}
    //           />
    //           <Body
    //             pageData={page_data}
    //             userId={uniqueId}
    //             event={page_data?.event}
    //             security={page_data?.event?.security}
    //             systemId={system_id}
    //             channelSelected={page_data?.channelSelected}
    //             userDetails={user?.id}
    //           />
    //         </div>
    //         {setInterval(function () {
    //           //code goes here that will be run every 5 seconds.
    //           const items = window.$(".login-fixed-wrapper");
    //           if (isLoginRendered) {
    //             if (items.length === 0) {
    //               window.location.href = `/${clientName}/${event}`;
    //             } else {
    //               const wrapper = window.$(items[0]);
    //               wrapper.removeAttr("style");
    //               // wrapper.css(
    //               //   "cssText",
    //               //   "position: fixed !important;  background-position: center !important;  background-size: cover !important;  background-blend-mode: multiply !important;  top: 0 !important;  left: 0 !important;  right: 0 !important;  bottom: 0 !important;  z-index: 99 !important;width:auto !important; height:auto !important"
    //               // );
    //             }
    //           }
    //           if (items.length > 0) {
    //             isLoginRendered = true;
    //           }
    //         }, 1000)}
    //       </>
    //     );
    //   }
    // }
  } else {
    Component = (
      <>
        <HeaderComponent
          header={header}
          event={page_data?.event}
          user={user}
          security={page_data?.event?.security}
          isLoggedIn={setIsLoginRequired}
          options={page_data?.option}
          dbvideo={page_data?.dbvideo}
        />
        <Body />
      </>
    );
  }


  setCookie('truid', user?.id, { path: '/' });
  // return <>{Component} </>;
  // return <>{Component} </>;
  return (
    <>
      <>
        <HeaderComponent
          header={header}
          event={page_data?.event}
          user={user}
          security={page_data?.event?.security}
          isLoggedIn={setIsLoginRequired}
          options={page_data?.option}
          dbvideo={page_data?.dbvideo}
        />
        <Body />
      </>
    </>
  );
};
export default Main;
