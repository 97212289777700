import React from 'react';

const AboutUs = ({ selectedLanguage }) => {
    return <section className="wb-about-wrap" id="About_Us">
        <div className="container-fluid">
            <div className="wb-virtueel-wrap">
                {selectedLanguage === 'nl' && <h3>
                    <span className="wb-grn-txt">Virtueel eventplatform</span> Webble-Up maakt vlot de vertaalslag van offline naar
                     <span className="wb-orng-txt"> online events</span>. Met een sterke drive om
te digitaliseren, rollen we de mouwen op voor jouw
<span className="wb-grn-txt"> hybride event</span>.
            </h3>}
                {selectedLanguage === 'en' && <h3>
                    <span className="wb-grn-txt">Virtual event platform</span> Webble-Up smoothly translates from offline to
                    <span className="wb-orng-txt"> online events</span>. With a strong drive to
to digitize, we roll up our sleeves for you <span className="wb-grn-txt"> hybrid event</span>.
            </h3>}
            </div>
            <div className="wb-event-wrapper">
                <div className="row">
                    <div className="col-12 col-md-7 mr-auto">
                        <div className="wb-evnt-cmn-wrap">
                            <div className="wb-evnt-heading wb-evnt-heading-left">
                                <div className="wb-title-before"></div>
                                {selectedLanguage === 'nl' && <h5>Je (online) event streamen</h5>}
                                {selectedLanguage === 'en' && <h5>Stream your (online) event</h5>}
                            </div>
                            <div className="wb-evnt-desc">
                                {selectedLanguage === 'nl' && <p>  Webble-Up is een virtueel eventplatform, dat digitale deuren opent voor jouw evenement, beurs of jaarlijkse board meetings. Terwijl jij je gasten samenbrengt, zetten wij onze schouders onder het virtuele luik. We duiken in je huisstijl, creëren
                                    <span className="wb-grn-txt"> een custom-made omgeving
                                    </span> en brengen je hybride of online event tot leven.
                                    <span className="wb-grn-txt"> Geavanceerd, gebruiksvriendelijk en 100% volgens jouw wensen</span>. Van Brussel tot Bangkok: de hele wereld zit op de eerste rij tijdens je virtuele evenement.
                            </p>}
                                {selectedLanguage === 'en' &&
                                    <p> Webble-Up is a virtual event platform that opens digital doors for your event, trade show or annual board meeting. While you bring your guests together, we put our shoulders under the virtual section. We delve into your corporate identity, create
                                      <span className="wb-grn-txt"> a custom-made environment
                                          </span> and bring your hybrid or online event to life.
                                           <span className="wb-grn-txt"> Advanced, user-friendly and 100% according to your wishes</span>
                                               . From Brussels to Bangkok: the whole world is in the front row during your virtual event.
                            </p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-7 ml-auto">
                        <div className="wb-evnt-cmn-wrap">
                            <div className="wb-evnt-heading wb-evnt-heading-right">
                                {selectedLanguage === 'nl' && <h5>Hybride events: verrassend virtueel</h5>}
                                {selectedLanguage === 'en' && <h5>Hybrid events: surprisingly virtual</h5>}
                                <div className="wb-title-before"></div>
                            </div>
                            <div className="wb-evnt-desc">
                                {selectedLanguage === 'nl' && <p>Een slaapverwekkende livestream zonder einde? Allesbehalve zelfs!  Webble-Up plaveit het digitale pad voor een onvergetelijke online experience. Dankzij <span className="wb-grn-txt">een waaier aan interactiemogelijkheden</span> kan jij moeiteloos connecteren met je publiek. Q&A’s, polls, break-out rooms, netwerkmogelijkheden …
                                <span className="wb-grn-txt">
                                        Webble-Up gaat verder dan het standaard streamingplatform</span> en zet maximaal in op een virtuele beleving met torenhoge wauw-factor. Dat jouw hybride event digitale potten zal breken, staat vast.
                                </p>}
                                {selectedLanguage === 'en' && <p>A mesmerizing live stream without end? Anything but even! Webble-Up paves the digital path for an unforgettable online experience. Thanks to
                                    <span className="wb-grn-txt"> a range of interaction options</span> you can effortlessly connect with your audience. Q & As, polls, break-out rooms, networking possibilities ...
                                <span className="wb-grn-txt">  Webble-Up goes further than the standard streaming platform
                                        </span>  and make the most of a virtual experience with a sky-high wow factor. It is certain that your hybrid event will break digital pots.
                                </p>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

};

export default AboutUs;