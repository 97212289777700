import React, { useContext, useEffect, useState } from "react";
import { Context as networkingToolContext } from "../../../context/NetworkTollContext";
import ReactAudioPlayer from 'react-audio-player';

import ring2 from "../../../../src/assets/audio/ring2.mp3";
const DECLINING = "DECLINING";
const ACCEPTING = "ACCEPTING";

export const IncommingCall = () => {
  const {
    declineMatch,
    videoChatAccept,
    clearIncommingCall,
    state: { incomingCall },
  } = useContext(networkingToolContext);

  const [details, setDetails] = useState(null);

  const [loading, setLoading] = useState({isLoading: false, type: null});
  const isLoadingByType = (type) => loading.type === type && loading.isLoading

  const accceptCall = async (e) => {
    e.preventDefault();
    if (loading.isLoading) return;

    setLoading({isLoading: true, type: ACCEPTING})
    await videoChatAccept(incomingCall?.match_id, (response, error) => {
      setLoading({isLoading: false, type: null})
    });
    clearIncommingCall();
    document.getElementById("video-chat-section").scrollIntoView({
      behavior: "smooth",
    });
  };

  const declineCall = async (e) => {
    e.preventDefault();
    if (loading.isLoading) return;
    setLoading({isLoading: true, type: DECLINING})
    setTimeout( () =>  {
      declineMatch(incomingCall?.match_id, (response, error) => {
        setLoading({isLoading: false, type: null})
      });
      clearIncommingCall();
    }, 2000)
  };

  useEffect(() => {
    setDetails(incomingCall);
  }, [incomingCall]);

  if (!details) {
    return <></>;
  }
  const Loader  = (text) =>  <>
    <i
      aria-hidden="true"
      className={`fa fa-spinner fa-spin`}>

  </i>
    {text}
  </>
  return (
    <div className="dft-call-connect-window">
      <audio loop="true" autoPlay>
        <source src={ring2} type="audio/mp3" />
      </audio>
      <div className="calling-widget">
        <div className="calling-detail">
          <h4>Incoming call from</h4>
          <h6>{details?.requesting_username}</h6>
          <div className="calling-action-wrap">

                <a
                  href="#"
                  onClick={(e) => accceptCall(e)}
                  className="calling-ans-btn"
                >
                  { isLoadingByType(ACCEPTING) ? Loader("Accepting") : <i    disabled={loading.isLoading} className="fa fa-phone" aria-hidden="true">
                    Accept
                  </i>}

                </a>
                <a
                  href="#"
                  onClick={(e) => declineCall(e)}
                  className="calling-decline-btn"
                >
                  { isLoadingByType(DECLINING) ? Loader("Rejecting") : <i disabled={loading.isLoading} className="fa fa-phone-slash" aria-hidden="true">
                    Reject
                  </i>}
                </a>

          </div>
        </div>
      </div>
    </div>
  );
};
