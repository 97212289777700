import { extend } from 'jquery';
import React, {Component} from 'react';
import DBNetwork from '../../../../../../api/DBNetwork';
import { baseUrl } from '../../../../../../constants/url/urlConstants';
import profilePlaceholder from '../../../../../../assets/user/avatarplaceholder.png';


class Profile extends Component {
	constructor(props) {
		super(props);
	
	}

	connect = async () => {


		console.log("CLICK CONNECT");
		try {

			const url = baseUrl(`api/video-chat/global/${this.props.event.id}/${this.props.user.id}}`);
			const response = await DBNetwork.get(url);
			console.log("response");
			console.log(response);
			console.log(response.data.data.match_id);
			if(response.data.data.match_id) {
				await this.props.gotMatch(response.data.data.match_id);
				await this.props.acceptMatch();
				this.props.listenForVCEvents();
			}

		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	gotMatch = async (match_id) =>{
		try {
			const url = baseUrl(`api/video-chat/match/${match_id}`);
			const response = await DBNetwork.get(url);
			this.props.setFOI(response.data.data.foi);
			this.props.setMatchId(match_id);
			this.props.listenForVCEvents();
			
			this.props.setMatchedUser(response.data.data.matched_users[0]);

			
		} catch (error) {
			console.log("Error");
			console.error(error);
		}
	}

	acceptMatch = async () => {
		try {
			const url = baseUrl(`api/video-chat/accept/${this.props.match_id}`);
			const response = await DBNetwork.get(url);
			

			this.props.setTwilioRoom(response.data.data.twilio_room);
			this.props.setTwilioToken(response.data.data.twilio_token);

			this.props.setActiveVideoChat(true);
			/*this.setState({
				accepted: true
			});*/
			
			console.log("RESPONSE");
			console.log(response);

		} catch (error) {
			console.log("Error");
			console.error(error);
		}
		
	}


	render() {
		return (
			<div className="card profile-card-wrapper">
				<div className="card-header">
					<div className="postion-wrap">
						<p>{this.props.user.title}</p>
						<i className="fa fa-circle status-on"></i>
					</div>
				</div>
				<div className="card-body">
					<div className="employee-wrapper">
						<div className="employee-info">
							<div className="employee-details-wrap">
								<div className="working-place">
									<h5>{this.props.user.company}</h5>
								</div>
								<div className="employee-name">
									<h2>{this.props.user.name} {this.props.user.last_name}</h2>
								</div>
							</div>
							<div className="employee-image-wrap">
								<div className="emp-image-frame">
									<img src={this.props.user ? (this.props.user.avatar ? this.props.user.avatar : profilePlaceholder) : profilePlaceholder} className="img-fluid" alt="profilepicture"></img>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className="card-footer">
					<div className="emp-contect-info">
						<div className="emp-social-wrap">
							{this.props.user.LinkedInURL &&
								<a target="_blank" href={this.props.user.LinkedInURL}><i className="fa fa-linkedin" aria-hidden="true"></i></a>}
							{this.props.user.TwitterURL &&
								<a target="_blank" href={this.props.user.TwitterURL}><i className="fa fa-twitter" aria-hidden="true"></i></a>}
						</div>
						<div className="emp-video-profile">
							<a href="#" onClick={this.connect}><i className="fa fa-video" 
									aria-hidden="true"></i>Connect</a>
						</div>
					</div>
				</div>
			</div>
		)
		
	}
}


export default Profile;