import React, {useContext} from 'react';
import {Context as defaultContext} from "../../../../../../../context/DefaultSectionContext";

const ChatModal = ({ children }) => {
    const {
        state: {
            eventsPageData: { page_data }
        },
    } = useContext(defaultContext);
    if (!page_data?.option) return <></>


    return (
        <div id="chat-box-wrapper" className={`chat-box-wrap box-wrap-modal full-width-box ${page_data?.option.includes('q_and_a') ? 'box-wrap-before': ''}`}>
            <div id="chat_messages" className="card box-wrap-card cmn-border-color">
                {children}
            </div >
        </div >
    );
};

export default ChatModal;