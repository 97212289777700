import React, { Component } from "react";
import useScript from "../../../../../../hooks/useScript";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Tickers_Channel,
  Ticker_Event,
} from "../../../../../../constants/Pusher/PusherConstants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Context as DefaultSectionContext} from "../../../../../../context/DefaultSectionContext";
class HighLightSectionMobile extends Component {
  static contextType = DefaultSectionContext;
  constructor(props) {
    super(props);
    this.state = {
      tickers: props.tickers || [],
    };
  }
  componentWillUnmount() {
    if (this.props.eventId) {
      const eventId = this.props.eventId;
      const list = [Tickers_Channel + eventId];
      PusherWrapper.sharedInstance().removeChannels(list);
    }
  }
  componentDidMount() {
    if (this.state.pusher == null) {
      let pusher = PusherWrapper.sharedInstance().pusher();

      this.state.pusher = pusher;
      pusher.log = (msg) => {
        // console.log("Pusher[log] " + msg);
      };
      pusher.connection.bind("error", function (err) {
        if (err?.code === 404) {
          console.log("Over limit!");
        }
      });
      // pusher.allChannels().forEach(channel => console.log(channel.name));
      const channel = pusher.subscribe(Tickers_Channel + this.props.eventId);
      channel.bind(Ticker_Event, (data) => {
        console.log(data);

        this.setState((prevState, props) => ({
          ...prevState,
          tickers: data.tickers,
        }));
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ tickers: nextProps.tickers || [] });
  }
  render() {
    let tickers = [...this.state.tickers];
    let tickerList;
    if (tickers?.length > 0) {
      let position = tickers?.sort((a, b) => a.position - b.position);
      tickerList = position?.map((ticker, index) => {
        return <li key={index}>{ticker.name}</li>;
      });
    } else return <></>;
    return (

        <section className="highlight-wrapper mb-4">
          <div className="marquee-text-wrapper">
            <div className="container-fluid container-md">
              <div className="d-flex">
                <p className="highlight-heading">{this.context?.state?.eventsPageData?.translations?.highlight?.title}</p>
                <marquee className="marquee-text" behavior="" direction="">
                  <ul>{tickerList}</ul>
                </marquee>
              </div>
            </div>
          </div>
        </section>
    );
  }
}
export default HighLightSectionMobile;
