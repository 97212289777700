import React from 'react'

const MobileViewWrapper = ({
    className,
    id,
    linkTitle,
    dataTarget,
    ariaExpanded,
    ariaControls, children, onClick, style }) => {
    return (
        <div
            className={className}
            onClick={onClick}
            style={{ background: style?.backgroundColor }}
        >
            <div className="card-header" id={id}>
                <h2 className="mb-0">
                    <a
                        className="btn btn-link btn-block"
                        type="button"
                        data-toggle="collapse"
                        data-target={dataTarget}
                        aria-expanded={ariaExpanded}
                        aria-controls={ariaControls}
                        style={{ color: style?.color }}
                    >
                        {linkTitle}
                    </a>
                </h2>
            </div>
            {children}
        </div>
    );
}
export default MobileViewWrapper;
