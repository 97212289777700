import React, { forwardRef, useContext } from "react";
import { Context as defaultContext } from "../../../../../../../context/DefaultSectionContext";

const ChatInput = (
  {
    message,
    setMessage,
    onSendMessageEnter,
    onSendMessage,
    isLoading,
    containerClass,
  },
  ref
) => {

  const {
    state: {
      eventsPageData: {
        translations: {
          chat
        }
      }
    },
  } = useContext(defaultContext);

  return (
    <div className={containerClass}>
      <input
        ref={ref}
        type="text"
        placeholder={`${chat?.answer_placeholder}`}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={onSendMessageEnter}
        required="required"
        className="form-control fix-rounded-right"
      />
      <div className="input-group-append">
        <span className="input-group-text bg-white">
          <a onClick={onSendMessage} href="#">
            <i
              aria-hidden="true"
              className={`fa ${isLoading ? "fa-spinner fa-spin" : "fa-paper-plane-o"
                }`}
            ></i>
          </a>
        </span>
      </div>
    </div>
  );
};

export default forwardRef(ChatInput);
