import React from 'react'


const FAQ = (props) => {

    return (
        <div className="modal gs-modal fade fade-scale"
             id="modalFAQ" tabIndex="-1"
             aria-labelledby="modalFAQLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header gs-modal-header faq-modal-header">
                        <div className="page-heading">
                            <h4 className="modal-title page-title">Faq</h4>
                            <div className="faq-heading">
                                <h4 className="faq-title">Global forum Virtual attendee platform</h4>
                            </div>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true"><i className="material-icons">close</i></span>
                        </button>
                    </div>
                    <div className="modal-body gs-modal-body">
                        <div className="faq-wrapper">
                            <div className="faq-que-accordion-wrapper">
                                <div id="accordionFAQTop"
                                     className="accordion time-table-accordion tt-accordion faq-accordion">
                                    {/* <!-- Accordion 1 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue1">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left" data-toggle="collapse"
                                                   data-target="#faqQue1Content" aria-expanded="true"
                                                   aria-controls="faqQue1Content">
                                                    What time zone will the event be in?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue1Content" className="collapse faq-collapse-contant show"
                                             aria-labelledby="faqQue1" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            All times listed for the event are in Central European Time.
                                                            We are proud to say that the attendees of our event hail
                                                            from all over the world. We have created a conference agenda
                                                            that aims
                                                            to accommodate all participants to the best of our ability.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 1 --> */}
                                    {/* <!-- Accordion 2 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue2">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue2Content"
                                                   aria-expanded="true" aria-controls="faqQue2Content">
                                                    How do I get into the event?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue2Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue2" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            First, make sure you are <a target="_blank"
                                                                                        href="https://cvent.me/kMPOWK">registered</a> for
                                                            the event. Once you request a verification code, we’ll send
                                                            it via text and email, so be sure to check both.
                                                            This process usually takes a couple of minutes. If you still
                                                            haven’t received your verification code, try clicking
                                                            “Didn't receive a code? Try again” link on the Attendee Hub
                                                            login page.
                                                            If you still do not receive the code, please use the Help
                                                            Desk chat in the bottom right corner of the login page,
                                                            email us at <a target="_blank"
                                                                           href="mailto: globalforum@gs1.org?subject=question about GS1 Global Forum ">globalforum@gs1.org</a>.
                                                            <img src="/img/loginscreen.png" alt=""/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 2 --> */}
                                    {/* <!-- Accordion 3 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue3">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue3Content"
                                                   aria-expanded="true" aria-controls="faqQue3Content">
                                                    I didn’t receive an email to access the Global Forum Virtual
                                                    Attendee Platform.
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue3Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue3" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            First, make sure you are <a target="_blank"
                                                                                        href="https://cvent.me/kMPOWK">registered</a> for
                                                            the event. If you are registered, you can access the event
                                                            and use your email and confirmation number. You
                                                            may have opted out of communication from us, email us at <a
                                                            target="_blank"
                                                            href="mailto: globalforum@gs1.org?subject=question about GS1 Global Forum ">globalforum@gs1.org</a> from
                                                            your registered email so that we can retrieve your
                                                            confirmation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 3 --> */}
                                    {/* <!-- Accordion 4 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue4">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue4Content"
                                                   aria-expanded="true" aria-controls="faqQue4Content">
                                                    Why did I get logged out?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue4Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue4" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>For privacy reasons, your login only stays valid for 72
                                                            hours. You will also be logged out after 24 hours of
                                                            inactivity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 4 --> */}
                                    {/* <!-- Accordion 5 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue5">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue5Content"
                                                   aria-expanded="true" aria-controls="faqQue5Content">
                                                    I can't use Zoom. Can I attend this event?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue5Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue5" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            While certain elements will leverage Zoom as a host
                                                            platform, none of the plenary sessions will be hosted in
                                                            Zoom. If you are not able to use Zoom for the general
                                                            session <a target="_blank"
                                                                       href="mailto: globalforum@gs1.org?subject=question about GS1 Global Forum ">please
                                                            let us know</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 5 --> */}
                                    {/* <!-- Accordion 6 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue6">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue6Content"
                                                   aria-expanded="true" aria-controls="faqQue6Content">
                                                    How do I join a session?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue6Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue6" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>Sessions will open 5 minutes prior to their start time. The
                                                            ‘Join Session’ button will become readily available at that
                                                            time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 6 --> */}
                                    {/* <!-- Accordion 7 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue7">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue7Content"
                                                   aria-expanded="true" aria-controls="faqQue7Content">
                                                    I want to watch a session again or watch a session I missed. Will
                                                    sessions be available on-demand?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue7Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue7" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>Recordings will be available within a few hours after the
                                                            sessions finishes. Find the session recordings in the
                                                            programme tab, navigate to the session, click the replay
                                                            button.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 7 --> */}
                                    {/* <!-- Accordion 8 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue8">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue8Content"
                                                   aria-expanded="true" aria-controls="faqQue8Content">
                                                    How do I ensure the best video experience?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue8Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue8" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            Just like any livestream, there are things you can do to
                                                            ensure the best viewing experience. Make sure you have a
                                                            strong internet connection. It also helps to close
                                                            non-essential tabs in
                                                            your browser. If you do experience buffering or your video
                                                            freezes, try reloading the session page. If that doesn’t
                                                            work feel free to <a target="_blank"
                                                                                 href="mailto: globalforum@gs1.org?subject=question about GS1 Global Forum ">contact
                                                            us</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 8 --> */}
                                    {/* <!-- Accordion 9 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue9">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue9Content"
                                                   aria-expanded="true" aria-controls="faqQue9Content">
                                                    When I leave the session, I can no longer hear the speaker.
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue9Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue9" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            Your session experience is designed to be immersive. Just
                                                            like an in-person session, if you leave the room you can’t
                                                            hear the speaker! If you navigate to another event page,
                                                            your session
                                                            will stop. Simply click back into the session to return to
                                                            the Zoom or Livestream.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 9 --> */}
                                    {/* <!-- Accordion 10 --> */}
                                    <div className="card tt-card">
                                        <div className="card-header tt-card-header" id="faqQue10">
                                            <h5 className="mb-0 faq-question">
                                                <a className="btn btn-block btn-link text-left collapsed collapsed"
                                                   data-toggle="collapse" data-target="#faqQue10Content"
                                                   aria-expanded="true" aria-controls="faqQue10Content">
                                                    Can I share links to the Global Forum Virtual Attendee Platform?
                                                </a>
                                            </h5>
                                        </div>

                                        <div id="faqQue10Content" className="collapse faq-collapse-contant"
                                             aria-labelledby="faqQue10" data-parent="#accordionFAQTop">
                                            <div className="card-body">
                                                <div className="faq-description">
                                                    <div className="session-full-info-wrap">
                                                        <p>
                                                            Absolutely yes! Please feel free to share link to the
                                                            platform, but in order for the recipient to access, they
                                                            must first <a target="_blank"
                                                                          href="mailto: globalforum@gs1.org?subject=question about GS1 Global Forum ">register</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Accordion 10 --> */}
                                </div>
                            </div>
                            <div className="all-tt-content faq-all-tab-content">
                                <nav>
                                    <div className="nav nav-tabs gs-nav-tabs" id="inner-nav-tab" role="tablist">
                                        <a className="nav-link tt-nav-link active" id="faq-inn-tab1" data-toggle="tab"
                                           href="#faq-tab-content1" role="tab" aria-controls="faq-tab-content1"
                                           aria-selected="true">Registration</a>
                                        <a className="nav-link tt-nav-link" id="faq-inn-tab2" data-toggle="tab"
                                           href="#faq-tab-content2" role="tab" aria-controls="faq-tab-content2"
                                           aria-selected="false">General</a>
                                        <a className="nav-link tt-nav-link" id="faq-inn-tab3" data-toggle="tab"
                                           href="#faq-tab-content3" role="tab" aria-controls="faq-tab-content3"
                                           aria-selected="false">Technology</a>
                                        <a className="nav-link tt-nav-link" id="faq-inn-tab4" data-toggle="tab"
                                           href="#faq-tab-content4" role="tab" aria-controls="faq-tab-content4"
                                           aria-selected="false">Getting involved</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="inner-tabContent-faq">
                                    <div className="tab-pane fade show active" id="faq-tab-content1" role="tabpanel"
                                         aria-labelledby="faq-inn-tab1">
                                        <div className="time-table-container">
                                            <div className="timetable-row">
                                                <div id="accordionFAQ"
                                                     className="accordion time-table-accordion tt-accordion faq-accordion">

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq11Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq11QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq11QueCollapse">
                                                                    Do i need to download Zoom?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq11QueCollapse"
                                                             className="collapse faq-collapse-contant show"
                                                             aria-labelledby="faq11Que" data-parent="#accordionFAQ">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            If you dont't have Zoom installed already:
                                                                            Yes
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq12Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq12QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq12QueCollapse">
                                                                    Can I log in with a smart phone and which devices is
                                                                    the platform compatible with?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq12QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq12Que" data-parent="#accordionFAQ">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes you can, the best way to view is with
                                                                            your phone healt in landscape modus.
                                                                        </p>
                                                                        <p>
                                                                            All devices are compatible
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq13Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq13QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq13QueCollapse">
                                                                    Can I register on behalf of someone else?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq13QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq13Que" data-parent="#accordionFAQ">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            No
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="faq-tab-content2" role="tabpanel"
                                         aria-labelledby="faq-inn-tab2">
                                        <div className="time-table-container">
                                            <div className="timetable-row">
                                                <div id="accordionFAQ1"
                                                     className="accordion time-table-accordion tt-accordion faq-accordion">

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq21Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq21QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq21QueCollapse">
                                                                    Does the platform allow private chat messaging
                                                                    between attendees?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq21QueCollapse"
                                                             className="collapse faq-collapse-contant show"
                                                             aria-labelledby="faq21Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes, but you need to agree with the GDPR
                                                                            rules upfront Only public information with
                                                                            agreement is used.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq22Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq22QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq22QueCollapse">
                                                                    Do all questions have to be posted publicly during a
                                                                    session?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq22QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq22Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Questions will not be posted publicly. The
                                                                            moderator will gather all the questions and
                                                                            will resume them in the Q&A timeslot.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq23Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq23QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq23QueCollapse">
                                                                    Is the online event content secure on the platform ?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq23QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq23Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq24Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq24QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq24QueCollapse">
                                                                    How can I see who is in the session?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq24QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq24Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            You can see who is online in the platform
                                                                            via the networking area.
                                                                        </p>
                                                                        <p>
                                                                            If you are in a zoom session you are
                                                                            available to see who is in the session
                                                                        </p>
                                                                        <p>
                                                                            During the plenaries & Daily Studio you are
                                                                            not able to see the attendees.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq25Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq25QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq25QueCollapse">
                                                                    Can I record the session?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq25QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq25Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            No, but all content will be available on VOD
                                                                            ( Video On Demand ) via the program chapter.
                                                                        </p>
                                                                        <p>
                                                                            Content will be online max. 4 hours after
                                                                            the live session.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq26Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq26QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq26QueCollapse">
                                                                    Where do I find my recording?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq26QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq26Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            all content will be available on VOD ( Video
                                                                            On Demand ) via the program chapter.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq27Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq27QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq27QueCollapse">
                                                                    What's the difference between a chat and Q&A?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq27QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq27Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            with a chat you can ask any question ore
                                                                            make some remarks
                                                                        </p>
                                                                        <p>
                                                                            with a Q&A you see the questions ( multiple
                                                                            choice ) on which you can give your answer
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq28Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq28QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq28QueCollapse">
                                                                    Will speaker presentation slides be made available
                                                                    after the event?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq28QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq28Que" data-parent="#accordionFAQ1">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes, they will be available in the “
                                                                            Presentation “ section
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="faq-tab-content3" role="tabpanel"
                                         aria-labelledby="faq-inn-tab3">
                                        <div className="time-table-container">
                                            <div className="timetable-row">
                                                <div id="accordionFAQ2"
                                                     className="accordion time-table-accordion tt-accordion faq-accordion">

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq31Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq31QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq31QueCollapse">
                                                                    Do I need to have a webcam for the event?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq31QueCollapse"
                                                             className="collapse faq-collapse-contant show"
                                                             aria-labelledby="faq31Que" data-parent="#accordionFAQ2">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes if you want to articipate in the
                                                                            videochat.
                                                                        </p>
                                                                        <p>
                                                                            In this videochat you still are able to
                                                                            switch of your camera.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq32Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq32QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq32QueCollapse">
                                                                    What is the recommended internet speed to access a
                                                                    virtual event?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq32QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq32Que" data-parent="#accordionFAQ2">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Min. 10 Mbit/sec but the higher the better
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq33Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq33QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq33QueCollapse">
                                                                    Why is there an echo in my online session?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq33QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq33Que" data-parent="#accordionFAQ2">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            • The most common reason is that someone has
                                                                            its speakers to loud with his microphone
                                                                            open. We recommend people to use headsets or
                                                                            earplugs and only open their microphone
                                                                            when they need to speak .
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq34Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq34QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq34QueCollapse">
                                                                    Where can I go for help with audio problems?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq34QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq34Que" data-parent="#accordionFAQ2">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            In the NEED HELP section via chat.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq35Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq35QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq35QueCollapse">
                                                                    Can I use a Bluetooth headset?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq35QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq35Que" data-parent="#accordionFAQ2">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="faq-tab-content4" role="tabpanel"
                                         aria-labelledby="faq-inn-tab4">
                                        <div className="time-table-container">
                                            <div className="timetable-row">
                                                <div id="accordionFAQ3"
                                                     className="accordion time-table-accordion tt-accordion faq-accordion">

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq41Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq41QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq41QueCollapse">
                                                                    Can the event attendees access the event sessions
                                                                    after the event is over?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq41QueCollapse"
                                                             className="faq-collapse-contant collapse show"
                                                             aria-labelledby="faq41Que" data-parent="#accordionFAQ3">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            Yes
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq42Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq42QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq42QueCollapse">
                                                                    How can I find out about future virtual events?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq42QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq42Que" data-parent="#accordionFAQ3">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card tt-card">
                                                        <div className="card-header tt-card-header" id="faq43Que">
                                                            <h5 className="mb-0 faq-question">
                                                                <a className="btn btn-block btn-link text-left collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#faq43QueCollapse" aria-expanded="true"
                                                                   aria-controls="faq43QueCollapse">
                                                                    How can I give my feedback?
                                                                </a>
                                                            </h5>
                                                        </div>

                                                        <div id="faq43QueCollapse"
                                                             className="collapse faq-collapse-contant"
                                                             aria-labelledby="faq43Que" data-parent="#accordionFAQ3">
                                                            <div className="card-body">
                                                                <div className="faq-description">
                                                                    <div className="session-full-info-wrap">
                                                                        <p>
                                                                            After each session there will be a popup in
                                                                            which you can give your rating and some
                                                                            textual feedback. This is available for the
                                                                            Plenaries & the Daily Studio. For the Zoom
                                                                            sessions a separate survey will be sent to
                                                                            you.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>

    )

}

export default FAQ;