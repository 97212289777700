import React, { useContext, useEffect, useState } from "react";
import IFrame from "../../../../../UIComponets/iframe/IFrame";
import MobileViewWrapper from "./MobileViewWrapper/MobileViewWrapper";
import QandA from "../DesktopView/QandA/QandA";
import ChatMenu from "../DesktopView/chatMenu/Chats";
import RatingMenu from "../DesktopView/ratingMenu/RatingMenu";
import PollMenu from "../DesktopView/PollMenu/PollMenu";
import VoteMenu from "../DesktopView/voteMenu/VoteMenu";
import EventInfoSection from "../DesktopView/EventInfoSection.js/EventInfoSection";
import VodSection from "../DesktopView/VodSection/VodSection";
import BreakOutRoomMobile from "../DesktopView/BreakOutRoom/BreakOutRoomMobile";
import PartnersSection from "../DesktopView/PartnersScection/PartnersSection";
import Support from "../DesktopView/Support/Support";
import SocialWall from "../DesktopView/SocialWall/socialWall";
import HighLightSectionMobile from "../DesktopView/HighlightSection/HighlightSectionMobile";
import useScript from "../../../../../hooks/useScript";
import VODModalM from "../DesktopView/VodSection/VODModalMobile";
import LoaderDefaultSection from "../../../../../UIComponets/LoaderDefaultSection";
import useWindowDimensions from "../../../../../hooks/WindowDimension";
import VodSectionMobile from "../DesktopView/VodSection/VodSectionMobile";
import { Context as defaultContext } from "../../../../../context/DefaultSectionContext";
import getAuthDetails from "../../../../../hooks/getAuthDetails";
import { PusherEvent } from "../../../../../hooks/PusherEvent";
import AdvertisementSection from "../DesktopView/AdvertismentSection/AdvertismentSection";
import HtmlParser from "react-html-parser";
export default function Index({
  setChatChanged,
  onPin,
}) {
  useScript("/js/navBarCanvas.js");
  const [isVODLive, setIsVODLive] = useState(null);
  const [isBreakoutRoomLive, setIsBreakoutRoomLive] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [title, setTitle] = useState(null);
  const [isSponsers, setSponsers] = useState(false);
  useScript("/js/MobileSlider.js");
  // const { height, width } = useWindowDimensions();
  // console.log("width", width);

  const {
    state: {
      eventsPageData: { user, page_data,
        translations
      },
      pusherEvent
    },
  } = useContext(defaultContext);
  useEffect(() => {
    if ((pusherEvent?.action === PusherEvent.ONAIR_POLL_UPDATE || pusherEvent?.action === PusherEvent.ONAIR_POLL) && pusherEvent?.data) {
      if (
        page_data?.poll.live &&
        !window.$("#collapseFour").hasClass("show")
      ) {
        window.$("#collapseFour").removeClass()
        window.$("#collapseFour").addClass("collapse show")
      }
    } else if (pusherEvent?.action === PusherEvent.OFFAIR_POLL) {
      window.$("#collapseFour").removeClass()
      window.$("#collapseFour").addClass("collapse")
    }
  }, [pusherEvent]);
  const pageData = page_data;
  const userDetails = user?.id;
  const systemId = localStorage.getItem("system_id");
  const userId = getAuthDetails(user?.id, systemId);
  const pinnedChat = page_data?.pinned_chat;
  const pinnedQA = page_data?.pinned_qa;
  const isPinChat = pinnedChat;
  const isPinQandA = pinnedQA;
  const isPollAvail = page_data?.isPollAvail;
  const isRatingAvail = page_data?.isRatingAvail;
  const isVoteAvail = page_data?.isVoteAvail;

  useEffect(() => {
    if (pageData?.vod?.length > 0) {
      setIsVODLive(pageData?.vod?.filter((vod) => vod?.status === 1)?.length);
    } else {
      setIsVODLive(0);
    }
    if (pageData?.breakout_room?.length > 0) {
      setIsBreakoutRoomLive(
        pageData?.breakout_room?.filter((rooms) => rooms?.status === 1)?.length
      );
    }
  }, []);

  const getStyleObject = (type) => {
    let obj = {};
    const btnColorKey = type + "_btn_color";
    if (pageData?.dbvideo[btnColorKey]) {
      obj.backgroundColor = pageData?.dbvideo[btnColorKey];
    }
    const btnTextColorKey = type + "_btn_text_color";
    if (pageData?.dbvideo[btnTextColorKey]) {
      obj.color = pageData?.dbvideo[btnTextColorKey];
    }
    return obj;
  };

  // useEffect(() => {
  //   window.$(".mobile-carousel").owlCarousel({
  //     loop: true,
  //     nav: false,
  //     autoplay: true,
  //     dots: false,
  //     // navText: [
  //     //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
  //     //   '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  //     // ],
  //     autoplayTimeout: 6000,
  //     items: 1,
  //   });
  // }, []);

  const setDetails = (videoID, title) => {
    setVideoId(videoID);
    setTitle(title);
  };

  let TIME_TABLE =
    pageData?.timetable || pageData?.files?.length > 0 ? (

      <MobileViewWrapper
        className="card mobile-view-card time-table-mobile-card"
        id={"headingSix"}
        dataTarget={"#collapseSix"}
        ariaControls={"collapseSix"}
        linkTitle={translations?.event_info?.title}
        style={getStyleObject('event_info')}

      >
        <div
          id="collapseSix"
          className="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordionMobileView"
        >
          <EventInfoSection
            events={pageData?.timetable}
            files={pageData?.files}
            eventId={pageData?.event?.id}
            option={pageData?.option}
            color={pageData?.event?.color}
          />
        </div>
      </MobileViewWrapper>
    ) : null;

  const chaSecMargin = (
    <section className="channel-button-wrapper">
      <div className="container-fluid">
        <div className="channel-btn-group"></div>
      </div>
    </section>
  );

  // console.log("VOD", pageData?.option?.includes("vod") && pageData?.isVODAvail);
  // console.log("isBreakoutRoomLive", isBreakoutRoomLive);
  let TickersComp = (
    <>
      {pageData?.option?.includes("ticker") && (
        <>
          {!pageData?.option?.includes("embed_player") && chaSecMargin}
          <HighLightSectionMobile
            tickers={pageData?.tickers}
            eventId={pageData?.event?.id}
          />
        </>
      )}
    </>
  );
  let Partners = (
    <>
      {pageData?.option?.includes("sponsers") && (
        <>
          {!pageData?.option?.includes("embed_player") && chaSecMargin}
          <MobileViewWrapper
            className="card mobile-view-card partners-mobile-card"
            id={"headingNine"}
            dataTarget={"#collapseNine"}
            ariaControls={"collapseNine"}
            linkTitle={translations?.sponsers?.title}
            style={getStyleObject('sponsers')}
            onClick={() => {
              if (!isSponsers) {
                window.dispatchEvent(new Event('resize'));
              }
            }}
          >
            <div
              id="collapseNine"
              className="collapse"
              aria-labelledby="headingNine"
              data-parent="#accordionMobileView"
            >
              <PartnersSection
                sponsers={pageData?.sponser}
                eventId={pageData?.event?.id}
              />
            </div>
          </MobileViewWrapper>
        </>
      )}
    </>
  );
 
  let VOD = (
    <>
      {pageData?.option?.includes("vod") && isVODLive ? (
        <>
          {/* {!pageData?.option?.includes("embed_player") && chaSecMargin} */}
          <MobileViewWrapper
            className="card mobile-view-card vod-mobile-card"
            id={"headingSeven"}
            dataTarget={"#collapseSeven"}
            ariaControls={"collapseSeven"}
            linkTitle={translations?.vod?.title}
            style={getStyleObject('vod')}
          >
            <div
              id="collapseSeven"
              className="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordionMobileView"
            >
              <VodSectionMobile
                vod={pageData?.vod}
                systemId={systemId}
                category={pageData?.category}
                eventId={pageData?.event?.id}
                color={pageData?.event?.color}
                setIsVODLive={setIsVODLive}
                setDetails={setDetails}
              />
            </div>
          </MobileViewWrapper>
        </>
      ) : null}
    </>
  );

  let BreakOut = (
    <>
      {pageData?.option?.includes("breakout_rooms") && isBreakoutRoomLive ? (
        <>
          {!pageData?.option?.includes("embed_player") && chaSecMargin}
          <MobileViewWrapper
            className="card mobile-view-card breakout-mobile-card"
            id={"headingEight"}
            dataTarget={"#collapseEight"}
            ariaControls={"collapseEight"}
            linkTitle={translations?.breakout?.title}
            style={getStyleObject('breakout')}
          >
            <div
              id="collapseEight"
              className="collapse"
              aria-labelledby="headingEight"
              data-parent="#accordionMobileView"
            >
              <BreakOutRoomMobile
                rooms={pageData?.breakout_room}
                eventId={pageData?.event?.id}
                setIsBreakoutRoomsLive={setIsBreakoutRoomLive}
              />
            </div>
          </MobileViewWrapper>
        </>
      ) : (
        <BreakOutRoomMobile
          rooms={pageData?.breakout_room}
          eventId={pageData?.event?.id}
          setIsBreakoutRoomsLive={setIsBreakoutRoomLive}
          socketOn
        />
      )}
    </>
  );

  let SocialComp = (
    <>
      {pageData?.option?.includes("social_wall") && (
        <>
          {!pageData?.option?.includes("embed_player") && chaSecMargin}
          <MobileViewWrapper
            className="card mobile-view-card socialWall-mobile-card"
            id={"#collapseTen"}
            dataTarget={"#collapseTen"}
            ariaControls={"collapseTen"}
            linkTitle={translations?.social_wall?.title}
            style={getStyleObject('social_wall')}
          >
            <div
              id="collapseTen"
              className="collapse"
              aria-labelledby="collapseTen"
              data-parent="#accordionMobileView"
            >
              <SocialWall
                socialWall={pageData?.event?.social_wall}
                eventId={pageData?.event?.id}
              />
            </div>
          </MobileViewWrapper>
        </>
      )}
    </>
  );

  let SupportComp = (
    <>
      {pageData?.option?.includes("support") && (
        <Support script={pageData?.event?.support} />
      )}
    </>
  );
  let Q_N_A = (
    <>

      {pageData?.option?.includes("q_and_a") && !pinnedQA ? (
        <MobileViewWrapper
          className="card mobile-view-card QA-mobile-card"
          id={"headingOne"}
          dataTarget={"#collapseOne"}
          ariaControls={"collapseOne"}
          linkTitle={translations?.qa?.title}
          style={getStyleObject('qa')}
        >
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionMobileView"
          >
            <QandA
              onPinQandA={() => onPin("qa")}
              isPinQandA={pinnedQA}
              q_and_a={pageData?.question_answer}
              color={pageData?.event?.color}
              userDetails={userDetails}
              userId={userId}
              systemId={systemId}
              eventId={pageData?.event?.id}
            />
          </div>
        </MobileViewWrapper>
      ) : null}
    </>
  );
  let CHAT = (
    <>
      {pageData?.option?.includes("chat") && !pinnedChat ? (
        <MobileViewWrapper
          className="card mobile-view-card chat-mobile-card"
          id={"headingTwo"}
          dataTarget={"#collapseTwo"}
          ariaControls={"collapseTwo"}
          linkTitle={translations?.chat?.title}
          style={getStyleObject("chat")}
        >
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionMobileView"
          >
            <ChatMenu
              chatData={pageData?.chat}
              onPinChat={() => onPin("chat")}
              isPinChat={pinnedChat}
              userDetails={userDetails}
              userId={userId}
              color={pageData?.event?.color}
              onChatChange={setChatChanged}
              systemId={systemId}
              eventId={pageData?.event?.id}
            />
          </div>
        </MobileViewWrapper>
      ) : null}
    </>
  );
  const RATING = (
    <>
      {pageData?.option?.includes("rating") && isRatingAvail && (
        <MobileViewWrapper
          className="card mobile-view-card rating-mobile-card"
          id={"headingThree"}
          dataTarget={"#collapseThree"}
          ariaControls={"collapseThree"}
          linkTitle={translations?.rating?.title}
          style={getStyleObject("rating")}
        >
          <div
            id="collapseThree"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionMobileView"
          >
            {/* dvnjldvjkldbvjkdjkvbjkbvdbvbdkjbkdj */}
            <RatingMenu
              rating={pageData?.rating}
              userDetails={userDetails}
              systemId={systemId}
              userId={userId}
              color={pageData?.event?.color}
              eventId={pageData?.event?.id}
            />
          </div>
        </MobileViewWrapper>
      )}
    </>
  );



  const POLL = (
    <>
      {pageData?.option?.includes("poll") && (
        <MobileViewWrapper
          className="card mobile-view-card poll-mobile-card"
          id={"headingFour"}
          dataTarget={"#collapseFour"}
          ariaControls={"collapseFour"}
          linkTitle={translations?.poll?.title}
          style={getStyleObject("poll")}
        >
          <div
            id="collapseFour"
            className="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionMobileView"
          >
            <PollMenu
              poll={pageData?.poll}
              userDetails={userDetails}
              systemId={systemId}
              userId={userId}
              color={pageData?.event?.color}
              eventId={pageData?.event?.id}
            />
          </div>
        </MobileViewWrapper>
      )}
    </>
  );

  const VOTE = (
    <>
      {pageData?.option?.includes("vote") && isVoteAvail && (
        <MobileViewWrapper
          className="card mobile-view-card vote-mobile-card"
          id={"headingFive"}
          dataTarget={"#collapseFive"}
          ariaControls={"collapseFive"}
          linkTitle={translations?.vote?.title}
          style={getStyleObject("vote")}
        >
          <div
            id="collapseFive"
            className="collapse"
            aria-labelledby="headingFive"
            data-parent="#accordionMobileView"
          >
            <VoteMenu
              vote={pageData?.vote}
              systemId={systemId}
              userDetails={userDetails}
              userId={userId}
              color={pageData?.event?.color}
              onChatChange={setChatChanged}
              eventId={pageData?.event?.id}
            />
            )
          </div>
        </MobileViewWrapper>
      )}
    </>
  );


  // const Advertisement = (
  //   <React.Fragment key="Advertisement">
  //     {page_data?.option?.includes("banner") && (
  //       <>
  //         {/* {!page_data?.option?.includes("embed_player") && chaSecMargin} */}
  //         <MobileViewWrapper
  //         className="card mobile-view-card vote-mobile-card"
  //         id={"headingSix"}
  //         dataTarget={"#collapseSix"}
  //         ariaControls={"collapseSix"}
  //         linkTitle={translations?.vote?.title}
  //         style={getStyleObject("vote")}
  //       >
  //         <div
  //           id="collapseSix"
  //           className="collapse"
  //           aria-labelledby="headingSix"
  //           data-parent="#accordionMobileView"
  //         >
  //             <AdvertisementSection />
  //           )
  //         </div>
  //       </MobileViewWrapper>
  //       </>
  //     )}
  //   </React.Fragment>
  // );

  let Advertisement = (
    <>
      {pageData?.option?.includes("banner") && (
        <>
          {!pageData?.option?.includes("embed_player") && chaSecMargin}
          <AdvertisementSection />
        </>
      )}
    </>
  );

  let Hybrid = (
      <>
        {pageData?.option?.includes("hybrid") && (
            HtmlParser(page_data?.dbvideo?.hybrid ?? <></>)
        )}
      </>
  );
  const layout_map = {
    qa: Q_N_A,
    rating: RATING,
    chat: CHAT,
    poll: POLL,
    timetable: TIME_TABLE,
    vod: VOD,
    breakout_room: BreakOut,
    sponsors: Partners,
    social_wall:SocialComp,
    banner: Advertisement,
    vote:VOTE,
    hybrid: Hybrid
  };

  let components = [];
  if (
    pageData &&
    pageData?.arrangelayout &&
    pageData?.arrangelayout?.mobile.length > 0
  ) {
  let layouts = [...pageData?.arrangelayout.mobile];
  // layouts = layouts.sort((i1, i2) => {
  //   return i1.position < i2.position;
  // });
  layouts = layouts.sort((i1, i2) => i1.position - i2.position);

    let _layouts = [];
    layouts.forEach((item) => {
      if (layout_map[item.name]) {
        _layouts.push(layout_map[item.name]);
      }
    });
    _layouts.push(...[SupportComp]);
    components = _layouts;
  } else {
    components = [
      Q_N_A,
      CHAT,
      RATING,
      POLL,
      VOTE,
      TIME_TABLE,
      VOD,
      BreakOut,
      SocialComp,
      Advertisement,
      Partners,
      SupportComp,
      Hybrid
    ];
  }

  let Layout = components;
  // console.log("ABC", window.innerHeight());
  return (
    <main className="mobile-view">
      {/* <!-- header Section --> */}
      <section className="header-wrapper video-wrapper">
        <div className="container-fluid">
          <IFrame
            id="embedIframe"
            containerWrapper3="embed-responsive embed-responsive-16by9"
            iframe={pageData?.event?.iframe}
          />
        </div>
      </section>
      {TickersComp}
      <div className="container-fluid">
        <div
          className="accordion accordion-mobile-view"
          id="accordionMobileView"
        >
          {Layout}
        </div>
      </div>

      {/* <VODModalM
        src={videoId}
        setVideoId={setVideoId}
        // id="#vodModalMobile"
        color={pageData?.event?.color}
        title={title}
      /> */}
      {/* <div
        className="modal fade"
        id="vodModal"
        aria-labelledby="vodModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <div className="heading-wrap-vod">
                <span style={{ color: pageData?.event?.color }}>{title}</span>
              </div>
              <button
                onClick={() => setVideoId(null)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times fa-2x" aria-hidden="true"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9 height_0">
                {isLoading ? (
                  <LoaderDefaultSection />
                ) : (
                  <iframe
                    className="embed-responsive-item"
                    src={`https://player.vimeo.com/video/${videoId}`}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    style={{ color: pageData?.event?.color }}
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setVideoId(null)}
                type="button"
                className="btn vw-btn vw-btn-black "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
}
