import React from 'react'
import placeholder from '../../../../../assets/images/organization_PlaceHolder.jpeg';


const SingleEvent = ({ locked, date, time, title, src, color, event, client }) => {
    const url = (client && event) ? `/${client}/${event}` : null

    return (
        <div className={`sponsors-box-wrap ${locked ? 'lock' : 'unlock'}`}>
            <a target="_blank" href={url}>
                <div className="sponsors-imageframe" style={{ border: `10px solid ${color}` }}>
                    <img src={src}
                        alt="" className="img-fluid"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = placeholder
                        }}
                    />
                    <div className="upcoming-overlay">
                        <i className="material-icons">lock_outline</i>
                    </div>
                </div>
            </a>
            <div className="title-wrapper">
                <div className="date-time-wrap">
                    <p> <span className="up-date" style={{ color }}>{date}</span>
                        <span className="up-time">{time}</span> </p>
                </div>
                <div className="up-event-title">
                    <p>{title?.toUpperCase()}</p>
                </div>
            </div>
        </div>
    );
}

export default SingleEvent;