import React from 'react'

export default function Wrapper({ children, id }) {
    return (
        <div className="time-table-container">
            <div className="timetable-row">
                <div id={id} className="accordion time-table-accordion tt-accordion">
                    {children}
                </div>
            </div>
        </div>
    )
}
