import Pusher from "pusher-js";
import React, {Component, useContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../../../../constants/url/urlConstants";
import PusherConstants, {
  OnAirVote_Channel,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  VoteEvent,
} from "../../../../../../constants/Pusher/PusherConstants";
import DBNetwork from "../../../../../../api/DBNetwork";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Context as defaultContext,
  Context as DefaultSectionContext
} from "../../../../../../context/DefaultSectionContext";
import toastProp from "../../../../../../data/toastProp";
import getAuthDetails from "../../../../../../hooks/getAuthDetails";
import {PusherEvent} from "../../../../../../hooks/PusherEvent";
const VoteMenu = (props) => {
  const {
    onSubmitVote,
    state: {
      eventsPageData: {
        user,
        page_data: {
          vote,
          event
        }
      },
      pusherEvent
    },
  } = useContext(defaultContext);

  useEffect(() => {
    if ((pusherEvent?.action === PusherEvent.ONAIR_VOTE_UPDATE || pusherEvent?.action === PusherEvent.ONAIR_VOTE) && pusherEvent?.data){
      if (
          vote.length >= 1 &&
          !window.$("#collapseVote").hasClass("show")
      ) {
        window.$(".box-wrap-modal").removeClass("show");
        window.$(".inner-video-wrap").removeClass("toggle-active");
        window.$(".gs-box-wrap-modal").collapse("hide");
        window.$(".full-width-box").removeClass("active");
        window.$("#collapseVote").collapse("show");
      }
    }
  }, [pusherEvent]);
  const [isLoading, setIsLoading] = useState(false)
  const [initial, setInitial] = useState(false)
  const systemId = localStorage.getItem("system_id");;
  const color = event?.color;
  const eventId = event?.id;
  const user_id = getAuthDetails(user?.id, systemId);
  const [voteMap, setVoteMap] = useState({})

  const setOptions = (e, index, value) => {
    e.preventDefault();

    const list = [...vote];
    let _vote = { ...vote[index] };
    _vote.optionSelected = value;
    list[index] = _vote;
    let _voteMap = {...voteMap}
    _voteMap[_vote?.id] = value;
    setVoteMap(_voteMap);
  };
  const submitVote = (e, index, queId) => {
    e.preventDefault();

    if (voteMap[queId]) {
      setIsLoading(true)
      const payload = {
        q_id: queId,
        user_id: user_id,
        option_id: voteMap[queId],
        event_id: eventId,
        system_id: systemId,
      };
      const _answer = vote[index]?.options?.find(
          (option) => option?.id === payload?.option_id
      )?.option;
      onSubmitVote(payload, (data) => {
        setIsLoading(false)
        toast(`🤙🏻You submitted option ${_answer}`, toastProp);
      })
    } else {
      toast(`Please select a option`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let votesList;
  const votes = [...vote];
  if (votes?.length > 0) {
    votesList = votes?.map((vt, index) => {
      if (vt.live === 0) return;
      return (
          <div key={index} className="card popup-card-wrapper">
            <div
                id={`#headingVoteDefault${index}`}
                className="card-header py-2 px-0"
            >
              <h3 className="mb-0 d-flex justify-content-between">
                <a
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapseVoteDefault${index}`}
                    aria-expanded="false"
                    aria-controls={`collapseVoteDefault${index}`}
                    className="btn btn-link collapsed"
                >
                  {vt?.question}
                </a>
              </h3>
            </div>
            <div
                id={`collapseVoteDefault${index}`}
                aria-labelledby={`headingVoteDefault${index}`}
                data-parent={`#accordionPoll`}
                className="collapse"
            >
              <div className="card-body">
                {
                (vt?.answer_id && vt?.answer_id !== 0) ? (
                    <div className="poll-answer-wrap polling-answer">
                      <div className="poll-answer-wrap polling-answer">
                        <h5>
                          Submitted Answer :
                          {
                            vt.options?.find(
                                  (option) =>
                                      parseInt(option?.id) ===
                                      parseInt(vote[index].answer_id)
                              ).option}{" "}
                        </h5>
                      </div>
                    </div>
                ) : (
                    <form action="" className="voting-form">
                      <div className="db-radio-check pl-5">
                        {vt.options?.map((option, i) => {
                          return (
                              <label
                                  onClick={(e) =>
                                      setOptions(e, index, option?.id)
                                  }
                                  key={i}
                                  className="checkcontainer"
                              >
                                <input
                                    value={option?.id}
                                    type="radio"
                                    name="vote"
                                    checked={
                                      voteMap[vt?.id] ===
                                      option?.id
                                    }
                                />
                                <span className="radiobtn"></span>
                                {option?.option}
                              </label>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                        <div
                            onClick={(e) => submitVote(e, index, vt?.id)}
                            className="text-center mt-5"
                        >
                          {!isLoading ? (
                              <a href="#" className="btn vw-btn vw-btn-black">
                                Send
                              </a>
                          ) : (
                              <a href="#" className="btn vw-btn vw-btn-black">
                                <i
                                    aria-hidden="true"
                                    className={"fa fa-spinner fa-spin"}
                                ></i>
                              </a>
                          )}
                        </div>
                      </div>
                    </form>
                )}
              </div>
            </div>
          </div>
      );
    });
  }
  return (
      <div
          className="card box-wrap-card cmn-border-color"
          style={{ borderColor: color }}
      >
        <div className="card-header bg-white py-1 px-2">
          <div className="card-actions-wrap d-flex justify-content-end align-items-center">
            <a
                href=""
                data-toggle="collapse"
                className="rcc-menu"
                data-target="collapseVote"
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          <div
              id={`accordionPoll`}
              className="accordion event-info-accordion QA-accordion"
          >
            {votesList}
          </div>
        </div>
      </div>
  );

}

export default VoteMenu;
