import React, {useContext, useState} from "react";
import user_img from "../../../../assets/images/user1-128x128.jpg";
import UserProfileImage from "../../../components/defaultLayout/UserProfileImage";
import {Appointment, Error} from "../../../../Utils/Utils";
import {useToasts} from "react-toast-notifications";
import {Context as networkingToolContext} from "../../../../context/NetworkTollContext";
import {ErrorOption, SuccessOption} from "../../../components/ToastOptions";

export const Accepted =  ({appointment, alreadyStarted, onLoby}) => {

  const appointmentModel = Appointment(appointment);

  const [loading, setLoading] = useState({isLoading: false, type: null})
  const { addToast } = useToasts();

  const {
    updateAppointment,
    videoConnect,
    state: { details },
  } = useContext(networkingToolContext);
  const dice_matched ="";
  const appointmentVideoConnect = () => {
    videoConnect(details?.event, appointment?.user, dice_matched, (response, error) => {
      // setIsLoading(false)
      if (error) {
        addToast(error.response.data.message, ErrorOption);
      }
    });
  };

  const callApi = (type) => {
    setLoading( { isLoading: true, type: type })
    updateAppointment({status: type, ...appointmentModel.getRequestPayload()}, (res, err) => {
      if (res){
        addToast(res.data.message, SuccessOption);
      }else if (err) {
        addToast(Error(err).getResponseError(), ErrorOption);
      }
      setLoading({ isLoading: false, type: null })
    })
  }
  const Loading =  <i
      aria-hidden="true"
      className={`fa fa-spinner fa-spin`}
  ></i>

  const isLoading = (type) => (loading.isLoading && loading.type === type)
  return (
    <div className="dft-appointment-acceptedlist">
      <div className="row no-gutters">
        <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="acceptedlist-img">
            {((details?.user?.id !== appointment.host_id)) ?
            <UserProfileImage src={appointmentModel.getHostAvatar()} />
            : <UserProfileImage src={appointmentModel.getAvatar()} /> }
          </div>
        </div>
        <div className="col-md-8 col-lg-8 col-xl-9">
          <div className="acceptedlist-info">
            <div className="accepted_infobox">
              <h6>{appointmentModel.getFormatedDate()}</h6>
              {((details?.user?.id !== appointment.host_id)) ? <h5>{appointmentModel.getHostFullName()}</h5> : <h5>{appointmentModel.getFullName()}</h5> }
              {((details?.user?.id !== appointment.host_id)) ? <p>{appointmentModel.getHostOrganization()}</p> : <p>{appointmentModel.getOrganization()}</p> }
            </div>
            { (alreadyStarted || onLoby) && <div className="accepted-join-btn">
              <button className="btn" onClick={ appointmentVideoConnect } >join</button>
              {/* TODO Add "disabled" class when join is disabled */}
            </div> }
          </div>
        </div>
      </div>
    </div>
  );
};
