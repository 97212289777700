import React from 'react'
import Speaker from '../Speaker/Speaker';
import ReactHtmlParser from 'react-html-parser';

const HappeningEventDetails = ({ id, data, ariaLabelledBy, dataParent, description }) => {
    let speaker;
    if (data.length > 0) {
        speaker = data?.map((speaker, index) => {
       return (
              <React.Fragment key={index}>
                <Speaker
                  speakerImage={speaker?.profile_image_url}
                  speakerName={speaker?.first_name +' '+ speaker?.last_name}
                  speakerDesignation={speaker?.title}
                  speakerCompanyName={speaker?.company}

                />
              </React.Fragment>
            );
        })
    }

    return (
        <div key={id} id={id} className="collapse" aria-labelledby={ariaLabelledBy}
            data-parent={dataParent}>
            <div className="card-body">
                <div className="session-description">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-9">
                            <div className="ssn-speakers-detail-wrap">
                                {speaker}
                            </div>
                            <div className="session-full-info-wrap">
                                {ReactHtmlParser(description)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default HappeningEventDetails;