

export const QA_Answer_Channel = 'qa_answer.';
export const QAAnswerEvent = "App\\Events\\QAAnswerEvent"
export const Chat_Channel = 'chat.'
export const ChatEvent = "App\\Events\\ChatEvent"
export const OnAirPoll_Channel = 'onAirPoll.'
export const OnAirPollEvent = "App\\Events\\OnAirPoll"
export const OffAirPoll_Channel = 'offAirPoll.'
export const OffAirPollEvent = "App\\Events\\OffAirPoll"
export const OnAirRating_Channel = 'onAirRating.'
export const RatingEvent = "App\\Events\\RatingEvent"
export const OnAirVote_Channel = 'onAirVote.'
export const VoteEvent = "App\\Events\\VoteEvent"
export const Tickers_Channel = 'tickerupdate.';
export const Ticker_Event = "App\\Events\\TickerForEvent"
export const VOD_Channel = 'vodupdate.';
export const VOD_Event = "App\\Events\\VodUpdateEvent";
export const VOD_Channel_defaultSection = 'defaultvod.';
export const VOD_Event__defaultSection = "App\\Events\\DefaultVodEvent";
export const Sponsor_Channel = 'sponserUpdate.';
export const Sponsor_Event = "App\\Events\\SponserForEvent";
export const Breakout_Channel = 'brackoutRoom.';
export const Breakout_Event = "App\\Events\\BrackoutRoomUpdateEvent";
export const Organization_Channel = 'organization_event_update.';
export const Organization_Event = "App\\Events\\OrganizationEventsUpdate";
export const CommonEventFront = "App\\Events\\CommonEventFront";

export const Video_Decline_Channel = 'videochat.decline.';
export const Video_Decline_Event = "App\\Events\\VideoChatDeclineEvent"

export const Video_End_Channel = 'videochat.end.';
export const Video_End_Event = "App\\Events\\VideoChatEndedEvent"


export const VideoChatGlobalRequestChannel = 'videochat.globalrequest.';
export const VideoChatGlobalRequestEvent = "App\\Events\\VideoChatGlobalRequestEvent"

export const VideoChatMessageChannel = 'videochat.chat.';
export const VideoChatMessageEvent = "App\\Events\\VideoChatMessageEvent"

export const CommonUserEvent = "App\\Events\\CommonUserEvent";
export const CommonUserEventChannel = 'commonuser_';

export const PUSHER_APP_KEY = `${process.env.REACT_APP_PUSHER_APP_KEY}`
export const PUSHER_CLUSTER = `${process.env.REACT_APP_PUSHER_CLUSTER}`





