import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import { ADD_SESSION_LINK } from '../../../../../constants/url/urlConstants'
import { Context as GS1Context } from '../../../../../context/GS1Context'

const EventButton = (props) => {
    return (
        <a href={props?.href}
            target={props?.target}>
            <p className={props.className}>{props.title}</p>
        </a>
    )
}

export default function HappeningEventHeader(props) {

    const { attendingEvent, joinEvent } = useContext(GS1Context)

    const onAttendingEvent = async () => {
        try {
            await attendingEvent();
        } catch (e) {
        }
    }
    const onJoinEvent = async () => {
        try {
            await joinEvent();
        } catch (e) {
        }
    }

    return (
        <h5 className="mb-0">
            <div className="row">
                <div className="col-10">
                    <a className="btn btn-block btn-link collapsed" data-toggle="collapse"
                        data-target={props.dataTarget} aria-expanded={props.areaExpanded}
                        aria-controls={props.ariaControls}>
                        <div className="row">
                            <div className="col-4 ">
                                <div className="text-left pl-5">
                                    <span className="ssn-time">{props.day}</span> - <span className="ssn-time">{props.date}  {props.month}</span><br />
                                    <span className="ssn-time">{props.time}</span>
                                </div>

                            </div>
                            <div className="col-8">
                                <h3>{props.title}
                                </h3>
                                {/* {props.highlight && <div className="ssn-category">
                                    <span className="gs-bg-pink">{props.highlight}</span>
                                </div>} */}
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-2 text-center">
                    <div className={props.is_live ? "ssn-label-flex" : 'ssn-label-flex'
                    } >
                        <div className="ssn-labels">
                            {props?.attended && !props.type &&
                                <p className={`ssn-attending gs-${props?.attended ? 'lime' : 'lavender'}`}>
                                    {props?.attended && 'You Are Registered'}
                                </p>}
                            {/* {props?.attended && <EventButton title="Join Session"
                            onClick={onAttendingEvent}
                            className='ssn-attending gs-bg-green' />} */}
                            {props?.attended && props?.link && !props.type && <EventButton
                                title="Replay Session"
                                target={props?.target}
                                href={props.link}
                                className='ssn-attending gs-tangerine' />}
                            {props?.attended && props?.link && props.type && <EventButton
                                title="Replay Session"
                                target={props?.target}
                                href={props.link}
                                className='ssn-attending gs-tangerine' />}
                            {!props?.attended && <EventButton
                                title="Add Session"
                                target={props?.target}
                                href={ADD_SESSION_LINK}
                                className='ssn-attending gs-lavender' />}
                        </div>
                        {props.is_live ? <div className='status-live'>
                            <i className='fa fa-wifi'></i>

                        </div> : ''}
                    </div>
                </div>
            </div>
        </h5 >

    )
}
