import React from 'react'
import { Link } from 'react-router-dom'


export default function HeaderLogo(props) {
    return (
        <div className={props.container}>
            <img src={props.src} alt="" className={props.imageClassName} />
        </div>
    )
}
