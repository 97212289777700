import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Events from "./Events";
import { Context as defaultContext } from "../../../../../../context/DefaultSectionContext";
import { Link } from "react-router-dom";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
import DownloadLink from "react-download-link";
import moment from "moment";
import Location from "../../../../../../hooks/Location";
import { Context as languageContext } from "../../../../../../context/LanguageContext";

const EventInfoSection = ({ children }) => {
  const [clientName, eventName] = Location();
  const {
    state: {
      eventsPageData: { page_data, translations }
    },
  } = useContext(defaultContext);

  const {
    state: {
      langauge
    },
  } = useContext(languageContext);

  const events = page_data?.timetable
  const files = page_data?.files

  const option = page_data?.option
  const color = page_data?.event?.color

  let EventList;
  let Announcements;
  let isEventInfo = false;

  if (files?.length === 0 && (events?.length === 0 || !events)) {
    return (
      <section className="channel-button-wrapper">
        <div className="container-fluid">
          <div className="channel-btn-group"></div>
        </div>
      </section>
    );
  }
  if (events?.event_info?.length > 0) {
    EventList = events?.event_info?.map((eve, i) => {
      const month = moment(eve?.date).format("MMMM");
      const date = moment(eve?.date).format("DD");
      const year = moment(eve?.date).format("YYYY");
      const fulldate = date + " " + month + " " + year;
      // console.log();
      return (
        <div key={i} className="card">
          <div className="card-header" id={`headingOne${i}`}>
            <h2 className="mb-0">
              <a
                className={`btn btn-link collapsed`}
                type="button"
                data-toggle="collapse"
                data-target={`#collapseOne${i}`}
                aria-expanded="true"
                aria-controls={`collapseOne${i}`}
              >
                {fulldate}
              </a>
            </h2>
          </div>
          <div
            id={`collapseOne${i}`}
            className={`collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`headingOne${i}`}
            data-parent="#accordionTimeTable"
          >
            <div className="card-body">
              <div className="event-table-wrap table-responsive">
                <table className="table event-info-table text-nowrap">
                  <tbody>
                    {eve?.events?.length > 0 ? (
                      eve?.events?.map((event, index) => {
                        let time = ''
                        if (event?.time) {
                          time = event?.time
                            .split(":")
                            ?.splice(0, 2)
                            ?.join(":");
                        }

                        return (
                          <React.Fragment key={index}>
                            <Events
                              eventTime={time}
                              file={event?.file}
                              eventCount={index + 1}
                              color={color}
                              link={event?.link}
                              vimeoId={event?.vimeo_id}
                              eventDescription={event?.title}
                              target={event?.target}
                            />
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <div>{translations?.event_info?.no_events.title}</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    });
  } else {
    EventList = null;
    isEventInfo = true;
  }
  if (files?.length > 0) {
    Announcements = files?.map((file, i) => {
      const filetype = file?.file?.split(".");
      const [, filename] = filetype;
      return (
        <tr key={i}>
          {/* {imageWithStorage + file?.file} */}
          <td>
            <a target="_blank" download href={imageWithStorage + file?.file}>
              <i className="far fa-copy ev-ylw-icon  cmn-text-color"></i>
            </a>
            {/* <DownloadLink
              label={
                <i
                  className="far fa-copy ev-ylw-icon  cmn-text-color"
                  style={{ color }}
                ></i>
              }
              filename={imageWithStorage + file?.file}
              exportFile={() => Promise.resolve("cached data here …")}
              tagName={"a"}
            /> */}
          </td>
          <td colSpan="3">{file?.title}</td>
          <td>{filename?.toUpperCase()}</td>
        </tr>
      );
    });
  } else {
    Announcements = null;
  }

  let isFilesAvil = Announcements && option?.includes("file");
  let isEventsAvil = EventList && option?.includes("timetable");

  return (
    <section className="event-info-wrap">
      <div className="container-fluid">
        {option?.includes("file") || option?.includes("timetable")
          ? children
          : null}
        <div className="row">
          {!EventList ? null : (
            <div className={isFilesAvil ? "col-12 col-md-8 " : "col-12 "}>
              <div
                className="accordion event-info-accordion"
                id="accordionTimeTable"
              >
                {EventList}
              </div>
            </div>
          )}
          {option?.includes("file") && Announcements && (
            <div className={isEventsAvil ? "col-12 col-md-4  " : "col-12 "}>
              <div className="download-wrapper">
                <div className="card">
                  <div className="card-body table-responsive">
                    <table className="table">
                      <tbody>{Announcements}</tbody>
                    </table>
                    <div className="text-right my-5">
                      {files?.length > 1 && (
                        <a href={`${process.env.REACT_APP_API_HOST}/api/${clientName}/${eventName}/filesdownload?locale=${langauge}`} target="_blank" className="text-dark">
                          <i
                            className="fa fa-download ev-ylw-icon mr-2  cmn-text-color"
                            style={{ color }}
                          ></i>

                          {translations?.event_info?.download_all?.title}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventInfoSection;
