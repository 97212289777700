//******************************************* Default Context Constants *******************************************

// Login registration
export const REGISTRATION = "REGISTRATION";
export const LOGIN = "LOGIN";
export const AUTHENTICATION = "AUTHENTICATION";
export const DEFAULT_AUTHENTICATION_WHILE_REGISTRATION =
  "DEFAULT_AUTHENTICATION_WHILE_REGISTRATION";
export const DEFAULT_AUTHENTICATION_WHILE_LOGIN =
  "DEFAULT_AUTHENTICATION_WHILE_LOGIN";
export const DEFAULT_REGISTRATION = "DEFAULT_REGISTRATION";
export const ON_USER_LOGIN = "ON_USER_LOGIN";
export const ON_USER_REGISTER = "ON_USER_REGISTER";
export const FORGET_PASSWORD_DATA = "FORGET_PASSWORD_DATA";
export const RESET_PASSWORD_DATA = "RESET_PASSWORD_DATA";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
// main Page data
export const EVENT_INFO = "EVENT_INFO";
export const EVENT_INFO_ON_SOCKET_UPDATE = "EVENT_INFO_ON_SOCKET_UPDATE";
export const ORGANIZATION_EVENTS = "ORGANIZATION_EVENTS";
export const UPDATE_PINNED_ITEMS = "UPDATE_PINNED_ITEMS";
// On Sub-channel selection
export const DEFAULT_SUB_CHANNEL = "DEFAULT_SUB_CHANNEL";
export const IS_POLL_AVAIL = "IS_POLL_AVAIL";
export const IS_VOTE_AVAIL = "IS_VOTE_AVAIL";
export const IS_VOD_AVAIL = "IS_VOD_AVAIL";
export const IS_RATING_AVAIL = "IS_RATING_AVAIL";
// Vod section category
export const VOD_SECTION_CATEGORY_SELECTED = "VOD_SECTION_CATEGORY_SELECTED";

//******************************************* GS1 Context Constants *******************************************
export const GS1AUTHENTICATION = "GS1AUTHENTICATION";
export const GS1LOGIN = "GS1LOGIN";
export const GS1SUB_CHANNEL = "GS1SUB_CHANNEL";
export const RATING_UPDATED = "RATING_UPDATED";
export const UPDATE_ALL_USER_RATINGS = "UPDATE_ALL_USER_RATINGS";
export const UPDATE_ALL_USER_VOTES = "UPDATE_ALL_USER_VOTES";
export const UPDATE_ALL_USER_POLLS = "UPDATE_ALL_USER_POLLS";
export const VOTE_UPDATED = "VOTE_UPDATED";
export const POLL_UPDATED = "POLL_UPDATED";
export const DEFAULT_LOGIN = "DEFAULT_LOGIN";
export const ISVALID = "ISVALID";
export const GS1SUB_CHANNEL_HAPPENING_NOW = "GS1SUB_CHANNEL_HAPPENING_NOW";
export const GS1SUB_UPDATE_HAPPENING_NOW_DATA =
  "GS1SUB_UPDATE_HAPPENING_NOW_DATA";
export const GS1SUB_UPDATE_SESSION_DATA = "GS1SUB_UPDATE_SESSION_DATA";
export const GS1SUB_UPDATE_LIVE_SESSION_DATA =
  "GS1SUB_UPDATE_LIVE_SESSION_DATA";
export const FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE =
  "FETCH_SUBCHANNEL_WHEN_MAIN_UNLIVE";

export const CHANGE_LANGAUGE = "CHANGE_LANGAUGE";

// GS1
export const GS1DATA = "GS1DATA";

//Fetch Data
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const DELETE_CHATS = "DELETE_CHATS";
export const ON_SUBMITE_VOTE = "ON_SUBMITE_VOTE";
export const ON_SUBMITE_POLL = "ON_SUBMITE_POLL";
export const ON_SEND_RATING = "ON_SEND_RATING";

export const PUSHER_TICKER = "PUSHER_TICKER";
export const PUSHER_SPONSER = "PUSHER_SPONSER";
export const PUSHER_VOD = "PUSHER_VOD";
export const PUSHER_BREAKOUT = "PUSHER_BREAKOUT";
export const PUSHER_INSERTCHAT = "PUSHER_INSERTCHAT";
export const PUSHER_DELETECHAT = "PUSHER_DELETECHAT";
export const PUSHER_QNA = "PUSHER_QNA";
export const INSERT_QNA = "INSERT_QNA";
export const FETCH_POLL = "FETCH_POLL";
export const PUSHER_ONAIR_POLL = "PUSHER_ONAIR_POLL";
export const PUSHER_OFFAIR_POLL = "PUSHER_OFFAIR_POLL";
export const PUSHER_ONAIR_VOTE = "PUSHER_ONAIR_VOTE";
export const PUSHER_ONAIR_RATING = "PUSHER_ONAIR_RATING";
export const PUSHER_TIMETABLE = "PUSHER_TIMETABLE";
export const PUSHER_ONLINE_MEMBERS = "PUSHER_ONLINE_MEMBERS";
export const PUSHER_ADD_MEMBERS = "PUSHER_ADD_MEMBERS";
export const PUSHER_REMOVE_MEMBERS = "PUSHER_REMOVE_MEMBERS";

export const NET_TOLL_VIDEO_CONNECT = "NET_TOLL_VIDEO_CONNECT";
export const NET_TOLL_DETAILS = "NET_TOLL_DETAILS";
export const NET_CHEK_FOR_DICE_OPEN = "NET_CHEK_FOR_DICE_OPEN";
export const RESET_VC_STATE = "RESET_VC_STATE";
export const GOT_MATCH = "GOT_MATCH";
export const VIDEO_CHAT_ACCEPT = "VIDEO_CHAT_ACCEPT";
export const FETCH_ONLINE_USERS = "FETCH_ONLINE_USERS";
export const SET_METCH_ID = "SET_METCH_ID";
export const SHOW_INCOMING_CALL = "SHOW_INCOMING_CALL";
export const CLEAR_INCOMING_CALL = "CLEAR_INCOMING_CALL";
export const DECLINE_CALLBACK = "DECLINE_CALLBACK";
export const ENDCHAT_CALLBACK = "ENDCHAT_CALLBACK";
export const DECLINE_CALLBACK_TRIGGER = "DECLINE_CALLBACK_TRIGGER";
export const ENDCHAT_CALLBACK_TRIGGER = "ENDCHAT_CALLBACK_TRIGGER";
export const SET_VIDEO_CHAT_STATE = "SET_VIDEO_CHAT_STATE";
export const VIDEO_CHAT_CONNECTING = "VIDEO_CHAT_CONNECTING";
export const VIDEO_CHAT_MESSAGE_RECIEVED = "VIDEO_CHAT_MESSAGE_RECIEVED";
export const FETCH_MATCHED = "FETCH_MATCHED";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const PUSHER_APPOINTMENT_UPDATE = "PUSHER_APPOINTMENT_UPDATE";
export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export const BREAKOUT_FILTER = "BREAKOUT_FILTER";
export const DICE_MATCHEDED = "DICE_MATCHEDED";
export const DICE_MATCHEDED_CLEAR = "DICE_MATCHEDED_CLEAR";
export const PUSHER_APPOINTMENT_EVENT = "PUSHER_APPOINTMENT_EVENT";
