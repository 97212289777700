import React, { Component } from 'react';
import DBNetwork from '../../../../../api/DBNetwork';
import { baseUrl } from '../../../../../constants/url/urlConstants';
import { withSnackbar } from 'react-simple-snackbar'

import Dice from "./Dice";
import GlobalChat from './GlobalChat';
import VideoChat from './VideoChat/VideoChat';
import MatchFound from './Match/MatchFound';

import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from '../../../../../constants/Pusher/Pusher';

const options = {
	position: 'top-right',
	style: {
		backgroundColor: 'midnightblue',
		border: '2px solid lightgreen',
		color: 'lightblue',
		fontFamily: 'Menlo, monospace',
		fontSize: '20px',
		textAlign: 'center',
	},
	closeStyle: {
		color: 'lightcoral',
		fontSize: '16px',
	},
}
class MeetGS1Body extends Component {
	constructor(props) {
		super(props);

		console.log("PROPS MEET GS1 body");
		console.log(props);

		this.state = {
			show_dice: false,

			pusher: null
		};

		/*this.setTwilioToken = this.setTwilioToken.bind(this);
		this.setActiveVideoChat = this.setActiveVideoChat.bind(this);*/
	}
	


	render() {
		const { openSnackbar, closeSnackbar } = this.props;
		return (
			<div>
				<GlobalChat
				setMatchId={this.props.setMatchId}
				setTwilioToken={this.props.setTwilioToken}
				setTwilioIdentity={this.props.setTwilioIdentity}
				setTwilioRoom={this.props.setTwilioRoom}
				setMatchedUser={this.props.setMatchedUser}
				setVC_State={this.props.setVC_State}
				setActiveVideoChat={this.props.setActiveVideoChat}
				setFOI={this.props.setFOI}
				listenForVCEvents={this.props.listenForVCEvents}
				declineMatch={this.props.declineMatch}
				match_id={this.props.match_id}
				twilio_room={this.props.twilio_room}
				twilio_identity={this.props.twilio_identity}
				twilio_token={this.props.twilio_token}
				matched_user={this.props.matched_user}
				foi={this.props.foi}
				event={this.props.event}
				user={this.props.user}
				gotMatch={this.props.gotMatch}
				acceptMatch={this.props.acceptMatch}
			/>
			</div>
		)
	}
}

export default withSnackbar(MeetGS1Body, options);
