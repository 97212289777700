import React, { Component, useContext, useEffect } from "react";
import useScript from "../../../../../../hooks/useScript";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import {
  Tickers_Channel,
  Ticker_Event,
} from "../../../../../../constants/Pusher/PusherConstants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Context as defaultContext,
  Context as DefaultContext,
} from "../../../../../../context/DefaultSectionContext";
import { TickerAxis } from "../../../../../../hooks/TickerAxis";
const HighLightSection = () => {
  let carousel = React.createRef();

  const {
    state: {
      eventsPageData: {
        page_data: { tickers, dbvideo },
        translations: { highlight },
      },
    },
  } = useContext(defaultContext);
  useEffect(() => {
    window.updateTickerItems();
  }, [tickers]);


  const getStyleObject = (type) => {

    let obj = {};
    const btnColorKey = type + '_color';
    if (dbvideo[btnColorKey]) {
      obj.backgroundColor = dbvideo[btnColorKey];
    }
    const btnTextColorKey = type + '_text_color';
    if (dbvideo[btnTextColorKey]) {
      obj.color = dbvideo[btnTextColorKey];
    }
    return obj;
  }
  // style={getStyleObject("ticker_text")}


  let tickerList;
  if (tickers?.length > 0) {
    let position = tickers?.sort((a, b) => a.position - b.position);
    tickerList = position?.map((ticker, index) => {
      return (
          <React.Fragment key={index}>
            {/* <div className="swiper-slide"> */}
            <div className="flip-text">
              <p style={{color: dbvideo?.ticker_text_btn_text_color}}>{ticker.name}</p>
            </div>
            {/* </div> */}
          </React.Fragment>
      );
    });
  } else return <></>;
  const axis = dbvideo?.ticker_direction ?? TickerAxis.HORIZONTAL;
  return (
    <section className="highlight-wrapper">
      <div className="marquee-text-wrapper">
        <div className="container-fluid p-0">
          <div className="d-flex">
            <p className="highlight-heading d-flex align-items-center justify-content-center" style={getStyleObject("ticker_btn")}>
              <span>{highlight?.title}</span>
            </p>
            <div className="flip-text-container"  style={{backgroundColor: dbvideo?.ticker_text_btn_color}}>
              <Carousel
                autoPlay
                showArrows={false}
                showStatus={false}
                axis={axis}
                showIndicators={false}
                verticalSwipe="standard"
                infiniteLoop={true}
                dynamicHeight={true}
                showThumbs={false}
                centerMode={false}
                ref={carousel}
              >
                {tickerList}
              </Carousel>
            </div>
            {/* <div className="flip-text-container">
                <marquee>
                  <p>{tickerList}</p>
                </marquee>
              </div> */}
            {/* <div className="swiper-container flip-text-container">
                <div className="swiper-wrapper">{tickerList}</div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default HighLightSection;
