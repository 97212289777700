const getAuthDetails = (userDetails, system_Id) => {
    let uniqueId;
    if (userDetails) {
        uniqueId = userDetails
    } else {
        uniqueId = system_Id
    }

    return uniqueId;
};

export default getAuthDetails;