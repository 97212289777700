import React from 'react';

const Powered = ({ selectedLanguage }) => {
    return <section className="wb-powered-wrap">
        <div className="container-fluid">
            <div className="wb-event-wrapper">
                <div className="row">
                    <div className="col-12 col-md-7 ml-auto">
                        <div className="wb-evnt-cmn-wrap wb-powered-cmn-wrap">
                            <div className="wb-evnt-heading wb-evnt-heading-right">
                                {<h5>Powered by DB Video</h5>}
                                <div className="wb-title-before"></div>
                            </div>
                            <div className="wb-evnt-desc wb-power-desc">
                                {selectedLanguage === 'nl' && <p>De motor achter Webble-Up? Dat is DB Video, een ambitieus videoproductiebedrijf met een toekomstgerichte mindset. Dankzij hun doorgedreven expertise kunnen wij je van A tot Z ontzorgen bij het organiseren van je online event. De oplossingen zijn grensverleggend, de service is verregaand. Webble-Up scherpt de digitale pijlen, speelt met frisse features en zet je hybride event stevig op de rails. Hands-on en hoogtechnologisch!
                                </p>}
                                {selectedLanguage === 'en' && <p>The engine behind Webble-Up? That's DB Video, an ambitious video production company with a forward-looking mindset. Thanks to their extensive expertise, we can unburden you from A to Z when organizing your online event. The solutions are groundbreaking, the service is far-reaching. Webble-Up sharpens the digital arrows, plays with fresh features and puts your hybrid event firmly on track. Hands-on and high-tech!
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wb-powered-img-wrap">
                <img src="/script/LandingPage/images/studio.png" className="img-fluid" alt="" />
            </div>
        </div>

    </section>

};

export default Powered;