
import { Context as networkingToolContext } from './../../../context/NetworkTollContext';
import { useContext, useEffect, useState } from "react";
import { ListProfile } from "./ListProfile"
import { useToasts } from "react-toast-notifications";
import { Context as defaultContext } from './../../../context/DefaultSectionContext';

export const OnlineUserList = () => {
    const {
        state: {
            details,
            connectedUser,
        }
    } = useContext(networkingToolContext);

    const {
        state: {
            onlineUsers
        }
    } = useContext(defaultContext)

    useEffect(() => {
        if (onlineUsers) {
            setPusherOnlineUsers(onlineUsers?.members ?? [])
        }
    }, [details, onlineUsers]);

    const [pusherOnlineUsers, setPusherOnlineUsers] = useState([])

    const [connectedUserMap, setConnectedUserMap] = useState({});
    useEffect(() => {
        let _connectedUserMap = connectedUser?.reduce(function (acc, cur, i) {
            acc[cur.id] = cur;
            return acc;
        }, {});
        if (!_connectedUserMap) {
            _connectedUserMap = {}
        }
        setConnectedUserMap(_connectedUserMap);
    }, [connectedUser])
    const { addToast } = useToasts();


    let onlineUsersEl;
    if (pusherOnlineUsers.length > 0) {
        onlineUsersEl = pusherOnlineUsers?.filter(user => !connectedUserMap[user.user_id]).map((user, index) => {
            return (<ListProfile
                key={index}
                event={details?.event}
                user={user}
            />
            );
        })
    }
    return (
        <div className="col-12 col-md-12 col-lg-12">
            <div className="dft-appointment-wrapper">
                <div className="dft-appointment-listing">
                    <div className="dft-appoints-wrap">
                        <div className="dft-appoint-client-wrap">
                            {onlineUsersEl}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}