import React from "react";
import image1 from "../../../../../../assets/images/card.png";
import {
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  VOD_Channel,
  VOD_Channel_defaultSection,
  VOD_Event,
  VOD_Event__defaultSection,
} from "../../../../../../constants/Pusher/PusherConstants";
import Pusher from "pusher-js";
import { Context as DefaultContext } from "../../../../../../context/DefaultSectionContext";
import PusherWrapper from "../../../../../../hooks/PusherWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderInfiniteScroll from "../../../../../../UIComponets/LoaderInfiniteScroll/LoaderInfiniteScroll";
import VODModal from "./VODModal";
import Pagination from "react-js-pagination";
import VODModalM from "./VODModalMobile";
import { imageWithStorage } from "../../../../../../constants/url/urlConstants";
// import "bootstrap/less/bootstrap.less";
const infiniteScroll = {
  height: "auto",
  overflowY: "auto",
  overflowX: "hidden",
};
export default class VodSection extends React.Component {
  static contextType = DefaultContext;
  constructor(props) {
    super(props);
    this.state = {
      VOD: props.vod || [],
      category: props.category || [],
      selectedCategory: "",
      isLoading: false,
      pusher: null,
      hasMoreVOD: true,
      videoId: null,
      title: null,
      VodCount: null,
      activePage: 1,
    };
  }
  onSetVideoVimeoId = (videoVimeoId) => {
    this.setState((prevState, props) => ({
      ...prevState,
      videoVimeoId: videoVimeoId,
      isModalOpen: !this.state.isModalOpen,
    }));
  };

  // Remove subscribers of the VOD event.
  componentWillUnmount() {
    if (this.props.eventId) {
      const eventId = this.props.eventId;
      const list = [VOD_Channel_defaultSection + eventId];
      PusherWrapper.sharedInstance().removeChannels(list);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ VOD: nextProps.vod || [] });
  }
  chunkArrayInGroups = (arr, size) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
    }
    return myArray.length;
  };
  componentDidMount() {
    // if (this.props.vod.length === 0) {
    //   this.props.isVodLive(this.props.vod.length);
    // }

    if (this.state.pusher == null) {
      let pusher = PusherWrapper.sharedInstance().pusher();
      this.state.pusher = pusher;
      this.state.pusher = pusher;
      pusher.log = (msg) => {
        // console.log("Pusher[log] " + msg);
      };
      pusher.connection.bind("error", function (err) {
        if (err?.code === 4004) {
          console.log("Over limit!");
        }
      });
      // pusher.allChannels().forEach(channel => console.log(channel.name));
      const channel = pusher.subscribe(
        VOD_Channel_defaultSection + this.props.eventId
      );
      channel.bind(VOD_Event__defaultSection, (data) => {
        console.log(data);
        let index = this.state.VOD.findIndex((vod) => vod.id === data.vods?.id);
        let _allVOD = [...this.state.VOD];
        let currentVOD;
        if (index !== -1) {
          if (data?.vods?.deleted) {
            _allVOD.splice(index, 1);
          } else {
            currentVOD = { ..._allVOD[index] };
            currentVOD.slug = data?.vods?.slug;
            currentVOD.thumbnail = data?.vods?.thumbnail;
            currentVOD.status = data?.vods?.status;
            currentVOD.vimeo_id = data?.vods?.vimeo_id;
            currentVOD.title = data?.vods?.title;
            currentVOD.link = data?.vods?.link;
            currentVOD.file = data?.vods?.file;
            currentVOD.position = data?.vods?.position;
            _allVOD[index] = { ...currentVOD };
          }
          const VodCount = this.chunkArrayInGroups(_allVOD, 3);
          this.setState((prevState, props) => ({
            ...prevState,
            VOD: _allVOD,
            VodCount,
          }));
          if (this.props.setIsVODLive) {
            this.props.setIsVODLive(
              _allVOD.filter((vod) => vod?.status === 1)?.length
            );
          }
        } else {
          _allVOD = [...this.state.VOD, data?.vods];
          // _allVOD = [data?.vods, ...this.state.VOD];
          const VodCount = this.chunkArrayInGroups(_allVOD, 3);
          this.setState((prevState, props) => ({
            ...prevState,
            VOD: _allVOD,
            VodCount,
          }));
          // this.VodStatus();
          if (this.props.setIsVODLive) {
            this.props.setIsVODLive(
              _allVOD.filter((vod) => vod?.status === 1)?.length
            );
          }
        }
      });
    }
  }

  VodStatus = () => {
    // const { isVodLive } = this.context;
    // isVodLive(this.state.VOD.filter((vod) => vod?.status === 1)?.length);
  };

  // On category change function.
  onCategorySelection = async (value) => {
    this.setState((prevState, props) => ({
      ...prevState,
      selectedCategory: value,
      isLoading: true,
      hasMoreVOD: true,
    }));
    const payload = {
      event_id: this.props.eventId,
      category: value,
      // , length
    };
    setTimeout(() => { }, 200);
    const { fetchCategoryVodData } = this.context;
    try {
      await fetchCategoryVodData(payload, (response) => {
        this.setState((prevState, props) => ({
          ...prevState,
          VOD: response,
          isLoading: false,
        }));
      });
    } catch (err) {
      this.setState((prevState, props) => ({
        ...prevState,
        isLoading: false,
      }));
      throw new Error(err);
    }
  };

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  fetchMoreData = async () => {
    const {
      fetchCategoryVodData,
      state: {
        eventsPageData: {
          page_data: { event },
        },
      },
    } = this.context;
    const payload = {
      event_id: event?.id,
      category: this.state.selectedCategory,
      length: this.state.VOD?.length,
    };
    try {
      await fetchCategoryVodData(payload, (response) => {
        if (response?.length < 6) {
          this.setState((pre, next) => {
            return {
              ...pre,
              hasMoreVOD: false,
            };
          });
          return;
        }
        console.log(response);
        this.setState((previousState) => ({
          ...previousState,
          VOD: [...previousState.VOD, ...response],
        }));
      });
    } catch (err) {
      throw new Error(err);
    }
  };
  setVideoId = (Id, title, v_id) => {
    let sliced;
    if (Id) {
      sliced = Id.split('/');
      sliced = sliced[0];
    } else {
      sliced = null;
    }
    this.setState((previousState) => ({
      ...previousState,
      videoId: sliced,
      title,
      id: v_id
    }));

    if (this.props.mobile) {
      this.props.setDetails(sliced, title);
    }
  };
  render() {
    let vodImage;
    let category;

    let vod = [...this.state.VOD];
    let isAnyLive = vod.filter((vod) => vod?.status === 1)?.length;
    // console.log(vod);
    if (vod?.length > 0) {
      vodImage = vod?.map((vod, index) => {
        if (!vod?.status) return;
        return (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4">
            <div className="vod-item">
              <div className="card">
                <div className="card-body">
                  <a
                    onClick={() => this.setVideoId(vod?.vimeo_id, vod?.title, vod?.id)}
                    href=""
                    data-toggle="modal"
                    // data-target={
                    //   this.props.mobile ? "#vodModalMobile" : "#vodModal"
                    // }
                    data-target={
                      this.props.mobile ? "#vodModalMobile" : "#vodModal"
                    }
                  >
                    <div className="vod-image-frame">
                      <div className="et_pb_image_wrap">
                        <img
                          src={vod?.thumbnail}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                  <div className="vod-details-wrap">
                    <div className="vod-links">
                      {vod?.file && (
                        <a
                          href={imageWithStorage + vod?.file}
                          download
                          target="_blank"
                        >
                          <i className="fa fa-copy" aria-hidden="true"></i>
                        </a>
                      )}
                      {vod?.link && (
                        <a href={vod?.link} target={vod?.target}>
                          <i className="fa fa-link" aria-hidden="true"></i>
                        </a>
                      )}
                    </div>
                    <div className="vod-chapter">
                      <a
                        onClick={() =>
                          this.setVideoId(vod?.vimeo_id, vod?.title)
                        }
                        href=""
                        data-toggle="modal"
                        data-target="#vodModal"
                      >
                        {vod?.title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else vodImage = <></>;
    // let categories = Array.from(this.state.category);
    let categories = [...this.state.category];
    if (categories?.length > 0) {
      category = categories.map((cat, index) => {
        return (
          <option key={index} value={cat?.id}>
            {cat?.name}
          </option>
        );
      });
    } else category = <></>;

    if (!isAnyLive && categories?.length === 0) {
      return <></>;
    }

    // Returned JSX element
    return (
      <>
        <section className="vod-wrapper">
          <div className="vod-section">
            <div className="container-fluid">
              <div className="heading-wrap">
                <span>VOD</span>
              </div>
              {categories?.length > 0 && (
                <div className="text-right">
                  <div className="ml-auto w-25">
                    <select
                      mutiple="true"
                      onChange={(e) => this.onCategorySelection(e.target.value)}
                      className="custom-select mr-sm-2"
                      id="inlineFormCustomSelect"
                    >
                      <option value="">All</option>
                      {category}
                    </select>
                  </div>
                </div>
              )}
              {isAnyLive !== 0 ? (
                <>
                  {this.state.isLoading ? (
                    <div className="vod-item">
                      <div className="card">
                        <div className="card-body">
                          <div className="text-center">
                            <div
                              className="spinner-border text-primary text-center"
                              role="status"
                            >
                              <span className="visually-hidden"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={450}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      /> */}

                      <InfiniteScroll
                        dataLength={this.state.VOD.length}
                        next={this.fetchMoreData}
                        style={infiniteScroll}
                        hasMore={this.state.hasMoreVOD}
                        loader={<LoaderInfiniteScroll />}
                      >
                        <div className="row">{vodImage}</div>
                      </InfiniteScroll>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {/* } */}
            </div>
          </div>
        </section>

        <VODModal
          src={this.state.videoId}
          setVideoId={this.setVideoId}
          color={this.props.color}
          title={this.state.title}
          id={this.state.id}
        />
      </>
    );
  }
}
