import { Link } from "react-router-dom";
import cssLoad from "../hooks/cssLoad";
import dbLogo from "../assets/images/DB-video-favicon/mstile-150x150.png";
import "./css/error.css";
import { useEffect } from "react";
const ErrorPage = () => {
  cssLoad("/script/LandingPage/error.css");
  useEffect(() => {
    document.title = "Page Not Found";
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = "/script/LandingPage/images/error-fevicon.png";
    }
  }, []);
  return (
      <main>
        <div className="wb-error-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 m-auto">
                <div className="wb-err-content">
                  <div className="wb-err-text">
                    <h1 className="wb-grn-txt">Error</h1>
                  </div>
                  <div className="wb-err404-text">
                    <h1 className="">404</h1>
                    <div className="wb-err404-bg">
                      <img
                          src="/script/LandingPage/images/Vector-3.png"
                          className="img-fluid"
                          alt=""
                      />
                    </div>
                  </div>
                  <h5 className="wb-oops-text">Oops... page not found</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
  );
};
export default ErrorPage;
