import React from "react";
import HappeningEventDetails from "../HappeningNow/HappeningEvent/HappeningEventDetails";
import HappeningEventHeader from "../HappeningNow/HappeningEvent/HappeningEventHeader";
import Wrapper from "../Wrapper/Wrapper";
import ProgrammeToggle from './ProgrammeToggle';
import moment from "moment"
const CategoryTabs = ({ href, id, title, ariaControls, ariaSelected, className }) => {
    return <a
        className={className}
        id={id}
        data-toggle="tab"
        href={href}
        role="tab"
        aria-controls={ariaControls}
        aria-selected={ariaSelected}>
        {title}
    </a>
}
const MySession = ({ mySession, navId, contentId, fromWhere }) => {
    let mySessionCategoryList;
    let mySessionCategoryListContent;

    // ****************************** for mySession mySession ******************************

    if (mySession?.length > 0) {
        mySessionCategoryList = mySession?.map((category, catIndex) => {
            let categoryListHref = `tt-mySession-cat-${catIndex}-content-${fromWhere}`;
            let categoryListID = `tt-mySession-cat-${catIndex}-tab-${fromWhere}`;

            return <CategoryTabs key={catIndex}
                className={catIndex === 0 ? 'nav-item nav-link active' : 'nav-item nav-link'}
                href={`#${categoryListHref}`}
                id={categoryListID}
                title={category?.category}
                ariaControls={categoryListHref}
                ariaSelected={catIndex === 0 ? true : false}
            />
        });

        // category List content Map

        mySessionCategoryListContent = mySession?.map((category, catIndex) => {
            let categoryListHref = `tt-mySession-cat-${catIndex}-content-${fromWhere}`;
            let categoryListID = `tt-mySession-cat-${catIndex}-tab-${fromWhere}`;
            let items = category?.items
            return <div key={catIndex}
                className={`tab-pane fade ${catIndex === 0 ? ' show active' : ''}`}
                id={categoryListHref}
                role="tabpanel"
                aria-labelledby={categoryListID}>
                <div className="timetable-content">
                    <nav>
                        <div className="nav nav-tabs gs-nav-tabs"
                            id="inner-nav-tab"
                            role="tablist">

                            {/* session Day Loop */}

                            {Object.keys(items).map((dayKey, dayIndex) => {
                                let dayKeyHref = `tt-mySession-cat-${catIndex}-day-${dayIndex}-content-${fromWhere}`;
                                let dayKeyTab = `tt-mySession-cat-${catIndex}-day-${dayIndex}-tab-${fromWhere}`;
                                return <ProgrammeToggle key={dayIndex}
                                    className={`nav-link tt-nav-link ${dayIndex === 0 ? 'active' : ''}`}
                                    id={dayKeyTab}
                                    // data-toggle="tab"
                                    href={`#${dayKeyHref}`}
                                    ariaControls={dayKeyHref}
                                    ariaSelected={dayIndex === 0 ? true : false}
                                    title={dayKey}
                                />
                            })
                            }

                        </div>
                    </nav>
                    <div className="tab-content" id="inner-tabContent-tt">

                        {/* session Day content Loop */}

                        {Object.keys(items).map((dayKey, dayIndex) => {
                            let dayKeyHref = `tt-mySession-cat-${catIndex}-day-${dayIndex}-content-${fromWhere}`;
                            let dayKeyTab = `tt-mySession-cat-${catIndex}-day-${dayIndex}-tab-${fromWhere}`;
                            return <div key={dayIndex} className={`tab-pane fade ${dayIndex === 0 ? 'show active' : ''}`}
                                id={dayKeyHref}
                                role="tabpanel"
                                aria-labelledby={dayKeyTab}>
                                <Wrapper id={`accordionTT-cat-${catIndex}-day-${dayIndex}-${fromWhere}`}>
                                    {items[dayKey]?.map((session, sessionIndex) => {
                                        let categoryHighlight = JSON.parse(session?.category);

                                        // session details
                                        let sessionId = `tt-cat-${catIndex}-day-${dayIndex}-session-${sessionIndex}-tab-${fromWhere}`;
                                        let sessionHref = `tt-cat-${catIndex}-day-${dayIndex}-session-${sessionIndex}-content-${fromWhere}`;
                                        let month = moment(session?.date).format('MMM');
                                        let date = moment(session?.date).format('D');
                                        let day = moment(session?.date).format('ddd');
                                        let [, endTime] = session.end !== null ? session.end?.split(' ') : ['', '']
                                        let startTime = session.end !== null ? session?.time?.split(':').splice(0, 2)?.join(':') : null
                                        let timeZone = moment().tz(session?.timezone).zoneAbbr();

                                        let time;
                                        if (endTime) {
                                            endTime = endTime?.split(':').splice(0, 2)?.join(':');
                                            time = `${startTime} - ${endTime} (${timeZone})`
                                        }

                                        return <div key={sessionIndex} className="card tt-card">
                                            <div className="card-header tt-card-header"
                                                id={sessionId}>
                                                <HappeningEventHeader
                                                    dataTarget={`#${sessionHref}`}
                                                    areaExpanded={sessionIndex === 0 ? true : false}
                                                    ariaControls={sessionHref}
                                                    link={session?.link}
                                                    target={session?.target}
                                                    attended={session?.attended}
                                                    time={time}
                                                    day={day}
                                                    date={date}
                                                    is_live={session?.is_live}
                                                    month={month}
                                                    type={'mySession'}
                                                    title={session?.title}
                                                    highlight={categoryHighlight?.name} />
                                            </div>
                                            <HappeningEventDetails
                                                id={sessionHref}
                                                data={session?.speakers}
                                                description={session?.description}
                                                dataParent={`#accordionTT-cat-${catIndex}-day-${dayIndex}-${fromWhere}`}
                                            />
                                        </div>
                                    })}
                                </Wrapper>
                            </div>

                        })
                        }
                    </div>
                </div>
            </div>


        });

    } else {
        mySessionCategoryListContent = <div>No session Preset</div>
    }


    return <> <nav>
        <div className="nav nav-tabs gs-nav-tabs gs-cate-nav"
            id={navId}
            role="tablist">
            {mySessionCategoryList}
        </div>
    </nav>
        <div className="tab-content"
            id={contentId}>
            {mySessionCategoryListContent}
        </div>
    </>
}
export default MySession;