import React from 'react';
import FeaturesList from './FeaturesList';

const Features = () => {
    return <section className="wb-featured-wrapper">
        <div className="container-fluid">
            <div className="wb-featured-wrap">
                <div className="wb-features-bg">
                    <img src="/script/LandingPage/images/featured-bg.png" alt="" />
                </div>
                <div className="wb-evnt-heading wb-evnt-heading-left">
                    <div className="wb-title-before"></div>
                    <h5>Features</h5>
                </div>
                <div className="wb-feature-grid-wrap">
                    <div className="wb-featured-items-wrap">
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                    viewBox="0 0 88.171 88.17">
                                    <path id="chat"
                                        d="M433.881,158.775a44.085,44.085,0,1,0-78.3,27.794l-9.721,16.291,24.28-4.625a43.787,43.787,0,0,0,9.272,3.384c.2.049.4.093.6.139.5.112.992.218,1.493.313q.538.1,1.081.193c.228.037.454.078.683.112.569.085,1.143.156,1.719.219.179.02.358.039.538.056q.923.089,1.856.141l.372.016c.675.031,1.354.051,2.038.051q1.138,0,2.261-.057A44.087,44.087,0,0,0,433.881,158.775Z"
                                        transform="translate(-345.71 -114.69)" />
                                </svg>
                                {/* <!-- <i className="fa fa-comment" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Chat</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 124.097 91.764">
                                    <g id="Group_687" data-name="Group 687" transform="translate(-218.095 -77.13)">
                                        <path id="Path_807" data-name="Path 807"
                                            d="M318.128,155.167a32,32,0,1,1,56.828,20.172l7.055,11.824-17.622-3.357a31.75,31.75,0,0,1-6.729,2.456c-.146.035-.292.068-.438.1-.359.081-.72.158-1.083.228q-.391.075-.785.14c-.165.027-.329.057-.5.081-.413.062-.83.113-1.248.158q-.2.022-.391.041-.67.064-1.347.1l-.27.012c-.49.023-.983.037-1.479.037q-.825,0-1.641-.041A32,32,0,0,1,318.128,155.167Z"
                                            transform="translate(-39.929 -18.269)" />
                                        <path id="Path_808" data-name="Path 808"
                                            d="M298.588,117.442a37.812,37.812,0,1,0-67.157,23.839l-8.338,13.973,20.825-3.967a37.538,37.538,0,0,0,7.952,2.9c.172.042.345.08.518.12.424.1.85.187,1.28.269q.461.088.928.165c.2.032.389.067.585.1.488.073.98.133,1.474.187.154.017.308.034.462.048q.791.077,1.591.121c.106.006.213.009.319.014.58.027,1.161.044,1.748.044q.976,0,1.94-.049A37.813,37.813,0,0,0,298.588,117.442Z"
                                            transform="translate(0)" stroke="#fff" strokeMiterlimit="10"
                                            strokeWidth="5" />
                                        <g id="Group_685" data-name="Group 685"
                                            transform="translate(252.258 107.933)">
                                            <path id="Path_809" data-name="Path 809"
                                                d="M287.19,142.993l3.281,4.419H284.5l-1.549-2.552a6.986,6.986,0,0,1-1.207.091,8.114,8.114,0,1,1,5.443-1.958Zm-5.443-2.756c2.027,0,3.143-1.435,3.143-3.576,0-2.186-1.116-3.6-3.143-3.6-2.073,0-3.166,1.412-3.166,3.6C278.581,138.8,279.675,140.237,281.747,140.237Z"
                                                transform="translate(-273.434 -128.394)" fill="#fff" />
                                        </g>
                                        <g id="Group_686" data-name="Group 686"
                                            transform="translate(304.856 130.506)">
                                            <path id="Path_810" data-name="Path 810"
                                                d="M375.536,180.972h-5.375l-.8,2.437h-5.307l5.9-16.126h5.831l5.877,16.126h-5.33Zm-2.688-8.291-1.457,4.487h2.915Z"
                                                transform="translate(-364.057 -167.284)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-comments" aria-hidden="true"></i> --> */}

                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Q&A</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="45"
                                    viewBox="0 0 117.778 88.442">
                                    <path id="Path_811" data-name="Path 811"
                                        d="M108.6,39.184H9.177A9.177,9.177,0,0,0,0,48.361v56.594a9.178,9.178,0,0,0,9.177,9.177H50.631l-4.088,11.133c-.407,1.108.255,2.36,1.247,2.36H72.576c.992,0,1.654-1.252,1.247-2.36l-4.088-11.133H108.6a9.178,9.178,0,0,0,9.177-9.177V48.361A9.177,9.177,0,0,0,108.6,39.184ZM57.784,105.446a2.4,2.4,0,1,1,2.4,2.4A2.4,2.4,0,0,1,57.784,105.446Zm54.587-8.164H5.407V52.992c0-4.239,3.732-7.676,8.335-7.676h90.293c4.6,0,8.335,3.436,8.335,7.676Z"
                                        transform="translate(0 -39.184)" />
                                </svg>
                                {/* <!-- <i className="fa fa-desktop" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Second Screen</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="80" height="50"
                                    viewBox="0 0 129.503 88">
                                    <g id="Group_688" data-name="Group 688" transform="translate(770.606 -31)">
                                        <rect id="Rectangle_1265" data-name="Rectangle 1265" width="129.503"
                                            height="41.067" rx="20.533" transform="translate(-770.606 31)" />
                                        <rect id="Rectangle_1266" data-name="Rectangle 1266" width="95.795"
                                            height="41.067" rx="20.533" transform="translate(-770.606 77.933)" />
                                        <rect id="Rectangle_1267" data-name="Rectangle 1267" width="33.858"
                                            height="33.858" rx="16.929" transform="translate(-767.002 34.604)"
                                            fill="#fff" />
                                        <rect id="Rectangle_1268" data-name="Rectangle 1268" width="33.858"
                                            height="33.858" rx="16.929" transform="translate(-767.002 81.538)"
                                            fill="#fff" />
                                        <path id="Path_812" data-name="Path 812"
                                            d="M-702,352.7a2.419,2.419,0,0,0,3.42,0l3.127-3.127,3.127,3.127a2.418,2.418,0,0,0,3.42,0,2.418,2.418,0,0,0,0-3.42l-3.127-3.127,3.127-3.127a2.418,2.418,0,0,0,0-3.42,2.419,2.419,0,0,0-3.42,0l-3.127,3.127-3.127-3.127a2.418,2.418,0,0,0-3.42,0,2.418,2.418,0,0,0,0,3.42l3.127,3.127L-702,349.281A2.418,2.418,0,0,0-702,352.7Z"
                                            transform="translate(-54.621 -247.688)" fill="#49c168" />
                                        <path id="Path_813" data-name="Path 813"
                                            d="M-689.908,103.729l-6.351,6.351-2.32-2.321a2.418,2.418,0,0,0-3.42,0,2.418,2.418,0,0,0,0,3.42l4.031,4.031a2.418,2.418,0,0,0,1.71.708,2.418,2.418,0,0,0,1.71-.708l8.062-8.062a2.418,2.418,0,0,0,0-3.42,2.418,2.418,0,0,0-3.42,0Z"
                                            transform="translate(-54.621 -57.937)" fill="#49c168" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-poll" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Poll</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 113.281 88">
                                    <g id="Group_696" data-name="Group 696" transform="translate(-78.614 -118.201)">
                                        <g id="Group_693" data-name="Group 693"
                                            transform="translate(78.614 159.939)">
                                            <g id="Group_690" data-name="Group 690">
                                                <g id="Group_689" data-name="Group 689">
                                                    <path id="Path_814" data-name="Path 814"
                                                        d="M126.928,266.262a3.333,3.333,0,0,0-2.692-2.269l-12.723-1.849-5.69-11.521a3.465,3.465,0,0,0-5.974,0l-5.69,11.521-12.693,1.849a3.331,3.331,0,0,0-1.842,5.684l9.2,8.945-2.169,12.66a3.331,3.331,0,0,0,4.838,3.511l11.34-5.98,11.374,5.98a3.319,3.319,0,0,0,1.549.383,3.38,3.38,0,0,0,1.966-.633,3.343,3.343,0,0,0,1.326-3.262l-2.169-12.66,9.2-8.945A3.326,3.326,0,0,0,126.928,266.262Z"
                                                        transform="translate(-78.614 -248.914)" />
                                                </g>
                                            </g>
                                            <g id="Group_692" data-name="Group 692" transform="translate(64.803)">
                                                <g id="Group_691" data-name="Group 691" transform="translate(0)">
                                                    <path id="Path_815" data-name="Path 815"
                                                        d="M329.878,266.262a3.333,3.333,0,0,0-2.692-2.269l-12.724-1.849-5.69-11.521a3.465,3.465,0,0,0-5.974,0l-5.69,11.521-12.693,1.849a3.331,3.331,0,0,0-1.842,5.684l9.2,8.945-2.169,12.66a3.331,3.331,0,0,0,4.837,3.511l11.341-5.98,11.374,5.98a3.319,3.319,0,0,0,1.549.383,3.38,3.38,0,0,0,1.966-.633A3.343,3.343,0,0,0,322,291.282l-2.169-12.66,9.2-8.945A3.326,3.326,0,0,0,329.878,266.262Z"
                                                        transform="translate(-281.562 -248.914)" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_695" data-name="Group 695"
                                            transform="translate(111.015 118.201)">
                                            <g id="Group_694" data-name="Group 694">
                                                <path id="Path_816" data-name="Path 816"
                                                    d="M228.4,135.548a3.333,3.333,0,0,0-2.692-2.269l-12.723-1.849-5.69-11.52a3.464,3.464,0,0,0-5.974,0l-5.69,11.52L182.94,133.28a3.331,3.331,0,0,0-1.842,5.684l9.2,8.945-2.169,12.66a3.331,3.331,0,0,0,4.837,3.511l11.341-5.98,11.374,5.98a3.321,3.321,0,0,0,1.549.383,3.381,3.381,0,0,0,1.966-.633,3.343,3.343,0,0,0,1.326-3.261l-2.169-12.66,9.2-8.945A3.326,3.326,0,0,0,228.4,135.548Z"
                                                    transform="translate(-180.089 -118.201)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-star" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Rating</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="80" height="50"
                                    viewBox="0 0 165.211 88">
                                    <g id="Group_700" data-name="Group 700" transform="translate(-31.176 -39.184)">
                                        <path id="Path_822" data-name="Path 822"
                                            d="M220.2,83.549h-61.06a5.637,5.637,0,0,0-5.637,5.637v34.758a5.637,5.637,0,0,0,5.637,5.636H184.6l-2.51,6.838c-.25.68.156,1.449.765,1.449H198.08c.609,0,1.016-.769.766-1.449l-2.511-6.838H220.2a5.637,5.637,0,0,0,5.637-5.636V89.186A5.637,5.637,0,0,0,220.2,83.549Zm-31.209,40.695a1.474,1.474,0,1,1,1.474,1.474A1.474,1.474,0,0,1,188.995,124.244Zm33.525-5.014H156.828v-27.2a4.929,4.929,0,0,1,5.119-4.714H217.4a4.929,4.929,0,0,1,5.119,4.714Z"
                                            transform="translate(-29.454 -10.682)" />
                                        <path id="Path_823" data-name="Path 823"
                                            d="M100.563,113.759h18.647V96.991H36.556V52.923c0-4.218,3.713-7.638,8.293-7.638h89.842c4.58,0,8.293,3.42,8.293,7.638V68.948h5.381V48.315a9.132,9.132,0,0,0-9.132-9.131H40.307a9.132,9.132,0,0,0-9.131,9.131v56.312a9.132,9.132,0,0,0,9.131,9.131H81.554l-4.068,11.077c-.4,1.1.254,2.348,1.241,2.348h24.662c.987,0,1.645-1.247,1.241-2.348Zm-9.5-6.257a2.388,2.388,0,1,1,2.389-2.388A2.388,2.388,0,0,1,91.058,107.5Z" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-laptop" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Break-out rooms</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 117.189 88">
                                    <g id="Group_698" data-name="Group 698" transform="translate(0 -39.184)">
                                        <path id="Path_819" data-name="Path 819"
                                            d="M108.058,39.184H9.132A9.132,9.132,0,0,0,0,48.316v56.312a9.132,9.132,0,0,0,9.132,9.132H50.378l-4.067,11.077c-.4,1.1.254,2.348,1.241,2.348H72.214c.987,0,1.646-1.246,1.241-2.348l-4.068-11.077h38.67a9.132,9.132,0,0,0,9.132-9.132V48.316A9.132,9.132,0,0,0,108.058,39.184ZM57.495,105.115a2.388,2.388,0,1,1,2.388,2.388A2.388,2.388,0,0,1,57.495,105.115Zm54.314-8.123H5.38V52.923c0-4.218,3.713-7.637,8.293-7.637h89.842c4.58,0,8.293,3.419,8.293,7.637Z" />
                                        <path id="Path_820" data-name="Path 820"
                                            d="M189.709,118.311l-16.2-9.577a2.2,2.2,0,0,0-3.332,1.9v19.149a2.207,2.207,0,0,0,3.332,1.9l16.2-9.572a2.207,2.207,0,0,0,0-3.8Z"
                                            transform="translate(-120.604 -49.076)" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-desktop" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Video on demand</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 117.189 88">
                                    <g id="Group_699" data-name="Group 699" transform="translate(0 -39.184)">
                                        <path id="Path_821" data-name="Path 821"
                                            d="M108.058,39.184H9.132A9.132,9.132,0,0,0,0,48.316v56.312a9.132,9.132,0,0,0,9.132,9.132H50.378l-4.067,11.077c-.4,1.1.254,2.348,1.241,2.348H72.214c.987,0,1.646-1.246,1.241-2.348l-4.068-11.077h38.67a9.132,9.132,0,0,0,9.132-9.132V48.316A9.132,9.132,0,0,0,108.058,39.184ZM57.495,105.115a2.388,2.388,0,1,1,2.388,2.388A2.388,2.388,0,0,1,57.495,105.115Zm54.314-8.123H5.38V52.923c0-4.218,3.713-7.637,8.293-7.637h89.842c4.58,0,8.293,3.419,8.293,7.637Z" />
                                        <rect id="Rectangle_1272" data-name="Rectangle 1272" width="99.277"
                                            height="13.112" rx="6.556" transform="translate(8.956 79.867)" />
                                        <rect id="Rectangle_1273" data-name="Rectangle 1273" width="27.474"
                                            height="10.775" rx="5.388" transform="translate(10.827 81.035)"
                                            fill="#fff" stroke="#cfcfcf" strokeMiterlimit="10" strokeWidth="1" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-laptop" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Ticker</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                    viewBox="0 0 88.116 88.116">
                                    <g id="Group_684" data-name="Group 684" transform="translate(-138.646 -98.76)">
                                        <rect id="Rectangle_1264" data-name="Rectangle 1264" width="88.116"
                                            height="88.116" rx="12" transform="translate(138.646 98.76)" />
                                        <path id="Path_806" data-name="Path 806"
                                            d="M236.978,181.045,214.59,203.432l-8.179-8.18a8.524,8.524,0,1,0-12.056,12.055l14.206,14.208a8.523,8.523,0,0,0,12.055,0L249.033,193.1a8.524,8.524,0,1,0-12.055-12.055Z"
                                            transform="translate(-38.99 -58.462)" fill="#fff" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-check-square" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Vote</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 94.368 88">
                                    <g id="Group_697" data-name="Group 697"
                                        transform="translate(-168.628 -168.628)">
                                        <path id="Path_817" data-name="Path 817"
                                            d="M252.828,174.551h-6.6v-3.158a2.765,2.765,0,1,0-5.529,0v3.158h-5.529v-3.158a2.765,2.765,0,1,0-5.529,0v3.158h-5.529v-3.158a2.765,2.765,0,1,0-5.529,0v3.158h-5.529v-3.158a2.765,2.765,0,1,0-5.529,0v3.158h-5.529v-3.158a2.765,2.765,0,1,0-5.529,0v3.158H190.93v-3.158a2.765,2.765,0,1,0-5.529,0v3.158h-6.6a10.179,10.179,0,0,0-10.168,10.168v7.6H263v-7.6A10.179,10.179,0,0,0,252.828,174.551Z"
                                            transform="translate(0 0)" />
                                        <path id="Rectangle_1269" data-name="Rectangle 1269"
                                            d="M0,0H94.368a0,0,0,0,1,0,0V46.335a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z"
                                            transform="translate(168.628 198.293)" />
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="13.384" cy="13.384"
                                            r="13.384" transform="translate(202.428 212.963)" fill="#fff" />
                                        <rect id="Rectangle_1270" data-name="Rectangle 1270" width="2.227"
                                            height="9.983" rx="1.113"
                                            transform="translate(224.68 226.347) rotate(90)" fill="#cfcfcf" />
                                        <rect id="Rectangle_1271" data-name="Rectangle 1271" width="2.227"
                                            height="9.983" rx="1.113" transform="translate(214.699 218.591)"
                                            fill="#cfcfcf" />
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-calendar-minus" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Timetable</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="50"
                                    viewBox="0 0 125.714 88">
                                    <path id="Path_818" data-name="Path 818"
                                        d="M18.857,69.714A12.571,12.571,0,1,0,6.286,57.143,12.583,12.583,0,0,0,18.857,69.714Zm88,0A12.571,12.571,0,1,0,94.286,57.143,12.583,12.583,0,0,0,106.857,69.714ZM113.143,76H100.571a12.534,12.534,0,0,0-8.859,3.654,28.732,28.732,0,0,1,14.752,21.489h12.964a6.279,6.279,0,0,0,6.286-6.286V88.571A12.583,12.583,0,0,0,113.143,76ZM62.857,76a22,22,0,1,0-22-22A21.989,21.989,0,0,0,62.857,76Zm15.086,6.286h-1.63a30.374,30.374,0,0,1-26.911,0h-1.63a22.634,22.634,0,0,0-22.629,22.629v5.657A9.431,9.431,0,0,0,34.571,120H91.143a9.431,9.431,0,0,0,9.429-9.429v-5.657A22.634,22.634,0,0,0,77.943,82.286ZM34,79.654A12.534,12.534,0,0,0,25.143,76H12.571A12.583,12.583,0,0,0,0,88.571v6.286a6.279,6.279,0,0,0,6.286,6.286H19.23A28.8,28.8,0,0,1,34,79.654Z"
                                        transform="translate(0 -32)" />
                                </svg>
                                {/* <!-- <i className="fa fa-users" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Sponsors</h5>
                            </div>
                        </div>
                        <div className="wb-features-item">
                            <div className="wb-ftchr-icon">
                                <svg className="ftchr-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                    viewBox="0 0 88 88">
                                    <g id="Group_702" data-name="Group 702"
                                        transform="translate(-118.133 -101.659)">
                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="44" cy="44" r="44"
                                            transform="translate(118.133 101.659)" />
                                        <g id="Group_701" data-name="Group 701"
                                            transform="translate(149.356 125.709)">
                                            <path id="Path_824" data-name="Path 824"
                                                d="M204.338,158.057c7.255,0,12.568,3.627,12.568,11.137,0,6.08-4.5,9.962-10.984,10.116l-.307,3.627H195.6l-.306-10.831h4.291c3.934,0,6.336-.766,6.233-2.963a1.771,1.771,0,0,0-1.788-1.89c-1.481,0-1.993,1.073-1.993,2.3H191.362C191.106,163.472,195.193,158.057,204.338,158.057Zm-3.577,39.9c-4.035,0-6.539-2.453-6.539-5.569,0-3.168,2.5-5.671,6.539-5.671,3.985,0,6.489,2.5,6.489,5.671C207.25,195.5,204.747,197.957,200.761,197.957Z"
                                                transform="translate(-191.351 -158.057)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                                {/* <!-- <i className="fa fa-question-circle" aria-hidden="true"></i> --> */}
                            </div>
                            <div className="wb-ftchr-title">
                                <h5>Support</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="wb-feature-grid-wrap">
                    <div className="wb-featured-items-wrap">
                        <FeaturesList fafaClassName="fa fa-comments"
                            title="Chat" />
                        <FeaturesList fafaClassName="fa fa-comments"
                            title="Q&A" />
                        <FeaturesList fafaClassName="fa fa-desktop"
                            title="Second Screen" />
                        <FeaturesList fafaClassName="fa fa-poll"
                            title="Poll" />
                        <FeaturesList fafaClassName="fa fa-star"
                            title="Rating" />
                        <FeaturesList fafaClassName="fa fa-check-square"
                            title="Vote" />
                        <FeaturesList fafaClassName="fa fa-laptop"
                            title="Break-out rooms" />
                        <FeaturesList fafaClassName="fa fa-laptop"
                            title="Video on demand" />
                        <FeaturesList fafaClassName="fa fa-laptop"
                            title="Ticker" />
                        <FeaturesList fafaClassName="fa fa-calendar-minus"
                            title="Timetable" />
                        <FeaturesList fafaClassName="fa fa-users"
                            title="Sponsors" />
                        <FeaturesList fafaClassName="fa fa-question-circle"
                            title="Support" />
                    </div>
                </div>
            */}
            </div>
        </div>
    </section >

};

export default Features;