import React from 'react';
import { Link } from 'react-router-dom';

function AppointmentModal(props) {
    return (
        <>
            <div className="modal dft-modal fade fade-scale show" id="modalAppointment" tabIndex="-1" aria-labelledby="modalAppointmentLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content dft-modal-content">
                        <div className="modal-header dft-modal-header">
                            <h2 className="dft-modal-title text-uppercase">Make Appointment</h2>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true"><i className="material-icons">close</i></span>
                            </button>
                        </div>
                        <div className="modal-body dft-modal-body">
                            <div className="dft-appoint-wrap">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="dft-appointment-card">
                                            <div className="card profile-card-wrapper">
                                                <div className="card-header">
                                                    <div className="dft-position-wrap">
                                                        <p className="cmpny-function">Function</p>
                                                        <p className="cmpny-name">Company</p>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dft-employee-wrapper">
                                                        <div className="dft-employee-info">
                                                            <div className="employee-details-wrap">
                                                                <div className="employee-name">
                                                                    <h2>Edward Merill</h2>
                                                                </div>
                                                            </div>
                                                            <div className="employee-image-wrap">
                                                                <div className="emp-image-frame">
                                                                    <img src="../../WebbleUp_img/Networking/nt-4.png" className="img-fluid" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="dft-emp-contect-info">
                                                        <div className="emp-social-wrap">
                                                            <Link to="#">
                                                                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                                            </Link>
                                                            <Link to="#">
                                                                <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                                            </Link>
                                                            <Link to="#">
                                                                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="dft-appointment-time-wrap">
                                            <form className="appointment-form">
                                                <div className="form-group">
                                                    <label htmlFor="appointmentDateTime">date + time</label>
                                                    <input type="datetime-local" className="form-control" id="appointmentDateTime" />
                                                </div>
                                                <div className="form-group mt-5 mb-0">
                                                    <button className="btn dft-btn-black">Send invite</button>
                                                </div>
                                                <div className="form-group">
                                                    <div className="dft-success-message">
                                                        <h4>Your invitation is successfully sent</h4>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppointmentModal;