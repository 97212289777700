import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import telnet from "../../assets/images/telenet_owler.png";
import logo from "../../assets/images/powered.png";
import { formReducer, FORM_INPUT_UPDATE } from "../../hooks/TextInputReducer";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ThirdPartyLinks } from "../../UIComponets/ThirdPartyLinks";
import { CustomButton } from "../../UIComponets/CustomButton";
import TextComponent from "../../UIComponets/TextComponent";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as defaultContext, Context as DefaultSectionContext } from "../../context/DefaultSectionContext";
import useStyle from "../../hooks/cssLoad";
import Location from "../../hooks/Location";
import Pusher from "pusher-js";
import { useToasts } from "react-toast-notifications";
import {
  imageBaseUrl,
  imageWithStorage,
} from "../../constants/url/urlConstants";
import LoaderDefaultSection from "../../UIComponets/LoaderDefaultSection";
import Register from "../Register/Register";
import toast from "react-hot-toast";
import toastProp from "../../data/toastProp";
import { changeColor } from "../../utlis/changeColor";
import axios from "axios";
import DBNetwork from "../../api/DBNetwork";
import Main from "../../Default/components/Main/Main";
import placeholderBody from "../../assets/images/bg_body.jpg";
import placeholderPattern from "../../assets/images/body.png";
import useBodyClass from "../../hooks/BodyClass";
import { Context as languageContext } from "../../context/LanguageContext";
import DropDown from "../../UIComponets/DropDown/DropDown";

const LoginViaPass = ({ isLoggedIn, onRemoved, security, color, magic_link, magic_link_input_code }) => {
  //*******************  React States And Context  *******************
  useStyle("/script/default/defaultStyle.css");
  const [clientName, eventName] = Location();
  const history = useHistory();
  const {
    onLogin,
    defaultAuthenticationWhileLogin,
    state: {
      loginPageData: { event, client, status, page_title, languages, translations },
    },
  } = useContext(AuthContext);
  const {
    changeLangauge,
    state: {
      langauge
    },
  } = useContext(languageContext);
  const { onUserLoggedIn } = useContext(DefaultSectionContext);
  const isLoginPage = history.location.pathname.split("/").includes("login");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLogging, setIsUserLogging] = useState(false);
  const [error, setError] = useState(null);
  const [showRegForm, setShowRegForm] = useState(false);
  const { addToast } = useToasts();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  useBodyClass("default-active");
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      password: "",
      email: "",
    },
    inputValidities: {
      password: false,
      email: false,
    },
    formIsValid: false,
  });
  const buttonRef = useRef();
  //*******************  React Life Cycles Hooks *******************

  useEffect(() => {
    document.title = "DB Video Login";
    if (isLoginPage && localStorage.getItem("defaultLogin")) {
      // history.replace(`/${clientName}/${eventName}`);
    }
  }, []);

  // ******************* */ Function Declaration  *******************
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  useEffect(() => {
    let channelUrl = sessionStorage.getItem("channelSelected");
    const _channel = JSON.parse(channelUrl);
    let url;
    if (channelUrl) {
      url = "api/" + _channel.url;
    } else {
      url = `api/${clientName}/${eventName}`;
    }
    const onLoginAuth = async () => {
      setIsLoading(true);
      await defaultAuthenticationWhileLogin(url);
      setIsLoading(false);
    };
    onLoginAuth();

    const pass = JSON.parse(localStorage.getItem(`${clientName}+${eventName}`));
    if (pass) {
      history.replace(`/${clientName}/${eventName}`);
    }
  }, [security, langauge]);
  let LoginDetail;
  useEffect(() => {
    changeColor(event?.color);
    changeColor(
      event?.color,
      `.db-icheck
    > input:first-child:not(:checked):not(:disabled):hover
    + input[type="hidden"]
    + label::before`,
      "border-color"
    );
    changeColor(
      event?.color,
      `.db-icheck
    > input:first-child:not(:checked):not(:disabled):hover
  
    + label::before`,
      "border-color"
    );
    changeColor(
      event?.color,
      `.db-icheck > input:first-child:checked + input[type="hidden"] + label::after
          `,
      "border-color"
    );
    changeColor(
      event?.color,
      `.db-icheck > input:first-child:checked + label::after`,
      "border-color"
    );
    changeColor(
      event?.color,
      `.cmn-hover-btn:hover`,
      `{background:${event?.color}!important;color:#fff!important;}`
    );
    changeColor(
      event?.color,
      `.vw-btn-white:hover:hover`,
      `{background:${event?.color}!important;color:#fff!important;}`
    );
    changeColor(
      event?.color,
      `.db-icheck > input:first-child + input[type="hidden"] + label::before, .db-icheck > input:first-child + label::before`,
      `{border:2px solid ${event?.color}!important;}`
    );
    changeColor(
      event?.color,
      `.db-icheck > input:first-child:checked + input[type="hidden"] + label::after, .db-icheck > input:first-child:checked + label::after`,
      `{border:2px solid ${event?.color}!important;border-left:0!important;border-top:0!important}`
    );
  }, [isLoading]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (favicon) {
      if (event?.favicon) {
        favicon.href = imageWithStorage + event?.favicon;
      } else if (event?.logo) {
        favicon.href = imageWithStorage + event?.logo;
      }
    }
  }, [event?.logo]);

  useEffect(() => {
    const bodyClass = document.querySelector(".default-active");
    let url;
    if (bodyClass) {
      if (event?.regi_background_type === "image") {
        if (event?.regi_background) {
          url = `url('${imageWithStorage + event?.regi_background
            }') center center no-repeat`;
        } else {
          url = `url('${placeholderBody}') center center no-repeat`;
        }
        bodyClass.style.background = url;
      } else if (event?.regi_background_type === "pattern") {
        if (event?.regi_background) {
          url = `background : url('${imageBaseUrl + event?.regi_background
            }' ) repeat; background-size:auto!important;`;
        } else {
          url = `background : url('${placeholderPattern}' ) repeat; background-size:auto!important;`;

          // bodyClass.style.backgroundSize = 'auto!important'
        }
        bodyClass.style.cssText = url;
      } else if (event?.regi_background_type === "color") {
        bodyClass.style.background = event?.regi_background;
      }
    }
    const colorCode = event?.color;
    changeColor(
      'transparent',
      `.login-fixed-wrapper::before`,
      `{background-color: transparent !important;}`
    );
  }, [event?.regi_background, event?.regi_background_type]);

  const setPasswordValue = (e) => {
    setPassword(e.target.value);
    if (buttonRef) {
      if (e.key === "Enter") {
        buttonRef.current.focus();
      }
    }
  };
  const login = async (url, payload, type) => {
    setIsUserLogging(true);
    await onLogin(
      url,
      { ...payload },
      type,
      clientName,
      eventName,
      (response) => {
        if (response.status) {
          // console.log(response);
          if (type === "password") {
            const pass = JSON.parse(
              localStorage.getItem(`${clientName}+${eventName}`)
            );
            if (!(pass?.event === clientName + eventName)) {
              localStorage.setItem(
                `${clientName}+${event?.slug}`,
                JSON.stringify({ event: clientName + eventName })
              );
            }
          }
          setIsUserLogging(false);

          history.push(`/${clientName}/${eventName}`);
          onUserLoggedIn(response.user);
          // isLoggedIn(false);
        } else {
          toast.error(response.message, toastProp);
          setIsUserLogging(false);
          // isLoggedIn(true);
          setError(response.message);
        }
      }
    );
  };
  // const loginViaURL = async (url, payload, type) => {
  //   await onLogin(
  //     url,
  //     { ...payload },
  //     type,
  //     clientName,
  //     eventName,
  //     (response) => {
  //       if (response.status) {
  //         localStorage.setItem("defaultLogin", JSON.stringify({ response }));
  //         history.push(`/${clientName}/${eventName}`);
  //         onUserLoggedIn(response.user);
  //       } else {
  //         addToast(response.message, {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //         setError(response.message);
  //       }
  //     }
  //   );
  // };

  // const onUserLogin = async (e) => {
  //   e.preventDefault();
  //   let data = {
  //     email: formState.inputValues.email,
  //     password: formState.inputValues.password,
  //   };
  //   let channelUrl = sessionStorage.getItem("channelSelected");
  //   const _channel = JSON.parse(channelUrl);
  //   let url;
  //   if (channelUrl) {
  //     url = "api/" + _channel.url;
  //   } else {
  //     url = `api/${clientName}/${eventName}`;
  //   }

  //   const urlEvent = `${url}/user/authenticate`;
  //   if (isLoginPage) {
  //     loginViaURL(urlEvent, data, "login");
  //   } else {
  //     login(urlEvent, data, "login");
  //   }
  // };

  const { fetchEventsPage } = useContext(defaultContext);
  const onUserLoginViaPassword = (e) => {
    e.preventDefault();
    let data = { password: password };
    // if (data === "")
    if (password === "") {
      toast.error("Please enter password", toastProp);
      return;
    }
    let url;
    let channelUrl = sessionStorage.getItem("channelSelected");
    const _channel = JSON.parse(channelUrl);
    if (channelUrl) {
      url = "api/" + _channel.url;
    } else if (magic_link) {
      url = `api/${clientName}/${eventName}/${formState.inputValues.password}`;
    } else {
      url = `api/${clientName}/${eventName}`;
    }
    const urlEvent = `${url}/check-event-password`;

    if (isLoginPage) {
      login(urlEvent, data, "password");
    } else if (magic_link) {
      const _ul = `${clientName}/${eventName}/${password}`;
      fetchEventsPage(_ul);
    } else {
      login(urlEvent, data, "password");
    }
  };

  if (isLoading) return <LoaderDefaultSection />;
  let bgStyle;
  let txtColor;
  let borderColor;

  if (event?.security === "password" || security === "password") {
    // chgColor = 'password';
    bgStyle = { backgroundColor: event?.color };
    txtColor = { color: event?.color };
    borderColor = { borderColor: event?.color };
    LoginDetail = (
      <div className="row">
        <div className="col-12 col-md-10 m-auto">
          {magic_link ?
            <div className="text-center text-white my-4">
              <p>Welcome at this web event.
                <br />This event is secured by a "Magic link" <br />please contact your administrator or enter with your code</p>
            </div>
            : ''}
        </div>
        <div className="col-12 col-md-6 m-auto ">
          {error && (
            <div className="bg-danger text-white py-2 px-4 m-3">
              <i className="fa fa-times" aria-hidden="true"></i>&nbsp;
              {error}
            </div>
          )}



          {((magic_link && magic_link_input_code) || !magic_link) && <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span
                style={{ backgroundColor: event?.color }}
                className="input-group-text"
                id="inputPassword"
              >
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              value={password}
              placeholder={magic_link_input_code ? `${translations?.registration?.unique_code_placeholder}*` : `${translations?.registration?.password_placeholder}*`}
              aria-label="Password"
              onChange={(e) => setPasswordValue(e)}
              aria-describedby="inputPassword"
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="input-group-append"
            >
              <span className="input-group-text">
                <Link href="#" style={{ color: event?.color }}>
                  <i
                    className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                    aria-hidden="true"
                  ></i>
                </Link>
              </span>
            </div>
          </div>}
          {isUserLogging ? (
            <a
              href="#"
              className="btn btn-block vw-btn login-btn vw-btn-white m-auto w-75 cmn-text-color cmn-hover-btn d-flex align-items-center justify-content-center"
            >
              <i
                aria-hidden="true"
                // style={{ color: event?.color }}
                className={"fa fa-spinner fa-spin mr-3 text-black"}
              ></i>
              <span className="text-black">Loading...</span>
            </a>
          ) : (
            ((magic_link && magic_link_input_code) || !magic_link) && <Link
              ref={buttonRef}
              onClick={onUserLoginViaPassword}
              // style={props.style}
              to={"#"}
              className="btn btn-block vw-btn login-btn vw-btn-white m-auto w-75 cmn-text-color cmn-hover-btn"
            >
              {translations?.registration?.watch_now_button}
            </Link>
          )}
          {/* <CustomButton
            href="#"
            type="submit"
            onClick={onUserLoginViaPassword}
            className={`btn btn-block vw-btn login-btn vw-btn-white mb-3 cmn-text-color cmn-hover-btn `}
            name="Submit"
          /> */}
        </div>
      </div >
    );
  }
  const LanguageMenu = (languages && Object.keys(languages).length > 1 ?
    <div className="db-language-wrap ml-auto">
      <div className="form-goup">
        <DropDown classAdd={"custom-select"} items={languages} selected={langauge} onChange={data => changeLangauge(data)} />
      </div>
    </div>
    : '')

  return (
    <>
      <section className="login-fixed-wrapper">
        <div className="container">
          <div className="login-logo-top navbar-fixed">
            {/* <img src={logo} alt="" className="img-fluid" /> */}
          </div>
          {LanguageMenu}
          <div className="login-box register-box">
            <div className="login-form-wrap vw-form-wrap">
              <form
                action=""
                onSubmit={onUserLoginViaPassword}
                className="login-form"
              >
                <div className="form-logo-wrap">
                  <div className="logo-img-frame">
                    <img
                      src={imageBaseUrl + event?.logo}
                      // src={event?.logo}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="logo-brand-name ">
                    <h2>{event?.title}</h2>
                    <span
                      className="cmn-bg-color"
                      style={{ backgroundColor: event?.color }}
                    >
                      {translations?.registration?.event_label}
                    </span>
                  </div>
                </div>
                {LoginDetail}
              </form>
            </div>
          </div>
        </div>
      </section>
      )
    </>
  );
};

export default LoginViaPass;
